import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class ApplicationBlocks extends Component {
  constructor (props) {
    super(props)
    this.ref = createRef()
    this.state = {}
  }

  async start (props = this.props) {
    console.warn('[ApplicationBlocks] [start]', { props })
    const { event, applicationBlocks, onReady, showLoading = false, overrideUserPublicKey } = props
    return new Promise(resolve => {
      if (applicationBlocks.length === 0) {
        typeof onReady === 'function' && onReady(false, false, undefined, this.restart.bind(this), this.ref.current)
        return resolve()
      }
      window.sticky.applications.blocks.render(event, this.ref.current, applicationBlocks, showLoading, undefined, { overrideUserPublicKey })
        .then(({ howToFree, exitedPrematurely, masterId, wentFullScreen }) => {
          this.howToFree = howToFree
          this.masterId = masterId
          typeof onReady === 'function' && onReady(exitedPrematurely, wentFullScreen, this.masterId, this.restart.bind(this), this.ref.current)
          return resolve()
        })
    })
  }

  async stop () {
    console.warn('[ApplicationBlocks] [stop]')
    this.howToFree && await this.howToFree()
  }

  async restart (props) {
    await this.stop()
    await this.start(props)
  }

  async componentDidMount () {
    await this.start(this.props)
  }
  async UNSAFE_componentWillReceiveProps (nextProps) {
    const { forceReRender, applicationBlocks } = this.props
    const currentConfigAsString = JSON.stringify(applicationBlocks)
    const newConfigAsString = JSON.stringify(nextProps.applicationBlocks)
    const shouldReRender = currentConfigAsString !== newConfigAsString
    console.warn('[ApplicationBlocks] [UNSAFE_componentWillReceiveProps]', { shouldReRender, forceReRender, nextProps, currentConfigAsString, newConfigAsString })
    if (shouldReRender || forceReRender) {
      await this.restart(nextProps)
    }
  }
  async componentWillUnmount () {
    await this.stop()
  }

  render () {
    const { className, applicationBlocks } = this.props
    if (applicationBlocks.length === 0) {
      return (
        <div style={{ marginBottom: '1rem' }} />
      )
    }
    return (
      <div className={classnames('component--application-blocks', className)} ref={this.ref} />
    )
  }
}
ApplicationBlocks.propTypes = {
  className: PropTypes.string,
  event: PropTypes.string,
  applicationBlocks: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      config: PropTypes.object.isRequired
    })
  ).isRequired,
  forceReRender: PropTypes.bool,
  onReady: PropTypes.func,
  showLoading: PropTypes.bool
}
