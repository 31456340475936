import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { subscribe, dispatch } from '../../../redux'
import { icons, Banner, Switch, Form, PatchableSetList, Input, UrlInput, Button, UploadImage, HotImage } from '@openbox-app-shared'

import { log } from '../../../log'

import H2 from '../../../components/H2'
import Box from '../../../components/Box'
import TabBar from '../../../components/TabBar'
import ChooseGateway from '../../../components/ChooseGateway/ChooseGateway'
import CodeEditor2 from '../../../components/CodeEditor2'
import dashboardIcons from '../../../icons'

const StyledTab = styled.div`
  .component--h2 + * {
    margin-top: 0.5rem;
  }
  > * + * {
    margin-top: 1rem;
  }
  .component--input.type--url + .component--button, img.hot + .component--form {
    margin-top: 1rem;
  }
  img.hot {
    width: 100%;
    max-width: 8rem;
  }
  .component--box.generate-redirect-code {
    max-width: 480px;
  }
  .component--box.generic {
    max-width: 390px;
  }
  .pay-redirect-url {
    margin-top: 2rem;
    .component--input {
      max-width: 464px;
    }
  }
  .component--banner + * {
    margin-top: 1rem;
  }
  .pay-redirect-url + .component--banner {
    margin-top: 1rem;
  }
`

const hasBackendLogic = application => application && application.backendLogic && application.backendLogic.length > 0

const frontendLogicTab = (context, extraProps) => {
  const { application } = context.state
  const reallyHasBackendLogic = hasBackendLogic(application)
  return {
    id: 'logic_frontend',
    name: 'Browser code',
    to: application ? `/me/flows/${application.id}/code/logic_frontend` : undefined,
    child: <>
      {reallyHasBackendLogic && (
        <Banner>
          <p>
            There is some server logic so this browser logic code will not run.
          </p>
        </Banner>
      )}
      <Form {...extraProps.debouncedProps}>
        <Input
          type='code'
          name='frontendLogic'
          value={application.frontendLogic}
        />
      </Form>
    </>
  }
}

function GenerateRedirectCode ({ onDone }) {
  const [url, setUrl] = useState('')
  return (
    <Box className='generate-redirect-code'>
      <H2>Generate redirect code</H2>
      <UrlInput
        name='url'
        label='URL'
        value={url}
        onChange={setUrl}
      />
      <Button icon={icons.inverted.check} backgroundColor='#26de81' onClick={v => onDone(url)}>Done</Button>
    </Box>
  )
}
GenerateRedirectCode.propTypes = {
  onDone: PropTypes.func
}

const backendLogicTab = (context, extraProps) => {
  const { application } = context.state
  let INPUT_OBJECT_KEYS = {
    v2: {
      user: {
        lightMode: false,
        connections: {
          CONNECTION_XYZ: ['config_1', 'config_2']
        }
      },
      application: {
        lightMode: false
      }
    },
    userPublicKey: 'public---',
    consumerAppUrl: '---',
    thingId: '(Sticky ID)',
    applicationId: '(Flow ID)',
    productId: '---',
    sessionId: '---',
    isKidSafe: true
  }
  application.waitForGeolocation && (() => {
    INPUT_OBJECT_KEYS = {
      ...INPUT_OBJECT_KEYS,
      'locationCity': '---',
      'locationRegion': '---',
      'locationCountry': '---'
    }
  })()
  return {
    id: 'logic_backend',
    name: 'Server code',
    to: `/me/flows/${application.id}/code/logic_backend`,
    child: <>
      <GenerateRedirectCode
        onDone={url => {
          application.backendLogic = `return \`${url}\`;`
          extraProps.debouncedProps.onChange({ application })
        }}
      />
      <Form {...extraProps.debouncedProps}>
        <CodeEditor2
          name='backendLogic'
          code={application.backendLogic}
          language='js'
          controls={['expand', 'save']}
          onControl={(whichControl, _) => {
            whichControl === 'save' && (() => {
              application.backendLogic = _
              extraProps.debouncedProps.onChange({ application })
              context.onSave()
            })()
          }}
        />
      </Form>
      <H2><code>input</code> object</H2>
      <Input type='code' value={`const input = ${JSON.stringify(INPUT_OBJECT_KEYS, null, 2)};`} />
    </>
  }
}

const tab = {
  id: 'code',
  name: '',
  inlineIcon: dashboardIcons.dotDotDot,
  to: (context) => `/me/flows/${context.state.application.id}/code`,
  child: function Child (context, extraProps) {
    const { user } = context.props
    const { application } = context.state

    const { viewSubroot } = context.props.match.params
    const reallyHasBackendLogic = hasBackendLogic(application)
    const backendLogicTabInstance = backendLogicTab(context, extraProps)
    const frontendLogicTabInstance = frontendLogicTab(context, extraProps)
    const logicsTabs = reallyHasBackendLogic ? [backendLogicTabInstance, frontendLogicTabInstance] : [frontendLogicTabInstance, backendLogicTabInstance]

    const onSavePayments = async (body) => {
      const entity = context.onUpdate(body)
      await context.onSave()
      return entity
    }
    const externalGatewaysString = application.externalGateways.toArray().map(eg => {
      return window.sticky.Stickypay.getGateway(eg).name
    })

    const payRedirectAttachQuery = typeof application.stickyretail.readFrom('payRedirectAttachQuery') === 'boolean' ? application.stickyretail.readFrom('payRedirectAttachQuery') : true
    const payRejectTotalZero = typeof application.stickyretail.readFrom('payRejectTotalZero') === 'boolean' ? application.stickyretail.readFrom('payRejectTotalZero') : false
    const rememberCard = typeof application.stickyretail.readFrom('rememberCard') === 'boolean' ? application.stickyretail.readFrom('rememberCard') : true
    const isMoto = typeof application.stickyretail.readFrom('isMoto') === 'boolean' ? application.stickyretail.readFrom('isMoto') : false
    const caresAboutCardName = typeof application.stickyretail.readFrom('caresAboutCardName') === 'boolean' ? application.stickyretail.readFrom('caresAboutCardName') : true
    const showCardImage = typeof application.stickyretail.readFrom('showCardImage') === 'boolean' ? application.stickyretail.readFrom('showCardImage') : true
    const autoCard = typeof application.stickyretail.readFrom('autoCard') === 'boolean' ? application.stickyretail.readFrom('autoCard') : false
    const showInStickyretail2 = typeof application.stickyretail.readFrom('showInStickyretail2') === 'boolean' ? application.stickyretail.readFrom('showInStickyretail2') : false
    return (
      <StyledTab>
        <>
          <Form
            onChange={(k, v) => {
              application.stickyretail.writeTo(k, v)
              context.onUpdate({ 'stickyretail': application.stickyretail })
            }}
          >
            <Switch
              name='showInStickyretail2'
              checked={showInStickyretail2}
            >
              Show this flow in {window.sticky._('STICKY_PAY')} / Take a payment
            </Switch>
          </Form>
          <H2>Code</H2>
          <Form {...extraProps.debouncedProps}>
            <Switch
              name='waitForGeolocation'
              checked={application.waitForGeolocation}
            >
              Wait for IP based geolocation
            </Switch>
            <Switch
              name='lightMode'
              checked={application.lightMode}
            >
              Light mode
            </Switch>
          </Form>
          <Box>
            <TabBar
              selectedTab={viewSubroot}
              tabs={logicsTabs}
            />
          </Box>

          <Box>
            <H2>Payments</H2>
            {application.stickypayApplePayEnabled === true && <Banner>
              <p>
                This flow has Apple Pay turned ON by exception.
              </p>
            </Banner>}
            {application.stickypayApplePayEnabled === false && <Banner>
              <p>
                This flow has Apple Pay turned OFF by exception.
              </p>
            </Banner>}
            {!application.gateway && application.externalGateways.toArray().length > 0 && (
              <Banner>
                <p>
                  You have chosen to also accept {externalGatewaysString.join(' / ')} but this flow is still not connected to a payment provider.
                </p>
                <p>
                  To fix this, choose the "Demo" payment provider below.
                </p>
              </Banner>
            )}
            <ChooseGateway
              user={user}
              entity={application}
              onSettingsValid={(gateway) => {
                log('[paymentProvider] [onSettingsValid]', { gateway })
                context.onUpdate({
                  gateway: gateway.id
                })
              }}
              triggerProps={{ applicationId: application.id }}
              onSave={onSavePayments}
            />
            <hr />
            <Box>
              <H2>Also accept...</H2>
              <PatchableSetList
                all={window.sticky.Stickypay.GATEWAYS.filter(g => g.isExternal && g.isExternal(user))}
                set={application.externalGateways}
                onUpdate={() => {
                  context.onUpdate({
                    externalGateways: application.externalGateways
                  })
                }}
              />
            </Box>
            <Form
              className='pay-redirect-url'
              onChange={(k, v) => {
                application.stickyretail.writeTo(k, v)
                context.onUpdate({ 'stickyretail': application.stickyretail })
              }}
            >
              <Switch
                name='payRejectTotalZero'
                checked={payRejectTotalZero}
              >
                Reject payments with total 0
              </Switch>
              <Switch
                name='rememberCard'
                checked={rememberCard}
              >
                Card payments: Remember card
              </Switch>
              <Switch
                name='isMoto'
                checked={isMoto}
              >
                Card payments: You are entering the card
              </Switch>
              <Switch
                name='caresAboutCardName'
                checked={caresAboutCardName}
              >
                Card payments: Collect name on card
              </Switch>
              <Switch
                name='showCardImage'
                checked={showCardImage}
              >
                Card payments: Show card image
              </Switch>
              <Switch
                name='autoCard'
                checked={autoCard}
              >
                Card payments: Automatically pay with a valid card
              </Switch>
              <hr />
              <Input
                label='Redirect URL when the consumer has paid'
                name='payRedirectUrl'
                value={application.stickyretail.readFrom('payRedirectUrl')}
              />
              <Switch
                name='payRedirectAttachQuery'
                checked={payRedirectAttachQuery}
              >
                Append with query parameters
              </Switch>
              {payRedirectAttachQuery && <Banner mood='good'>
                <p>
                  The URL will be appended with the following query parameters:
                </p>
                <ul className='plain'>
                  <li><code>success</code>: <code>true</code> or <code>false</code></li>
                  <li><code>unsuccessfulMessage</code>: Message if <code>success</code> is <code>false</code></li>
                  <li><code>paymentId</code>: Sticky payment ID</li>
                  <li><code>userPaymentId</code>: {user.name} payment ID</li>
                </ul>
              </Banner>}
            </Form>
          </Box>
          <Box className='generic icon'>
            <H2>Icon</H2>
            <HotImage src={application.baseIcon} alt='' />
            <Form
              onChange={(k, v) => {
                context.onUpdate({ 'baseIcon': v.url })
              }}
            >
              <UploadImage
                name='baseIcon'
                value={application.baseIcon}
              />
            </Form>
          </Box>
          <Box className='generic'>
            <Input
              label='ID'
              name='id'
              disabled
              value={application.id}
            />
          </Box>
          <Button
            backgroundColor='#26de81'
            icon={icons.inverted.add}
            onClick={() => {
              const body = {
                copy: {
                  events: application.events,
                  backendLogic: application.backendLogic,
                  frontendLogic: application.frontendLogic,
                  waitForGeolocation: application.waitForGeolocation,
                  backgroundColor: application.backgroundColor,
                  foregroundColor: application.primaryColor
                }
              }
              dispatch('APPLICATION_BASE_CREATE', { why: 'application--advanced', body, defaults: { name: application.name, icon: application.baseIcon } })
            }}
          >
            Make a flow template
          </Button>
        </>
      </StyledTab>
    )
  }
}

export default tab
