/* eslint-disable react/prop-types */
import React from 'react'
import classnames from 'classnames'
import { Link } from '@openbox-app-shared'
import CoolButton from './CoolButton'

export default function CoolLinkButton ({ children, to, sameTab = false, className, color, style, download, dontAttachQueryParameters, ...props }) {
  const genericProps = {
    download,
    className: classnames('component--button', className),
    style: {
      textDecoration: 'none',
      width: 'fit-content'
    },
    tabIndex: '-1',
    dontAttachQueryParameters
  }
  if (!to) {
    return (
      <div {...genericProps}>
        <CoolButton {...props} color={color}>{children}</CoolButton>
      </div>
    )
  }
  return (
    <Link to={to} target={sameTab ? undefined : '_blank'} {...genericProps}>
      <CoolButton className={null} {...props} color={color} style={style}>{children}</CoolButton>
    </Link>
  )
}
