import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomHelmet, LinkButton, Button } from '@openbox-app-shared'
import StickerImages from '../../components/StickerImages'
import PartnerPublic from '../../components/PartnerPublic'
import getLogInUrl from '../../getLogInUrl'
import TheForm from './form'
import { log } from '../../log'

const StyledRoute = styled.div`
  position: relative;
  height: 100vh;
  padding: 1rem;
  .partner-error {
    margin-right: 1rem;
    margin-bottom: 1rem;
    p {
      text-align: center;
    }
    .component--button {
      display: block;
      margin: 1rem auto 0 auto;
    }
  }
  .component--partner-public + *, .component--partner-error + * {
    margin-top: 2rem;
  }
  .partner-code {
    margin: 0 auto 1.5rem auto;
    font-size: 1.25rem;
    padding: 0.75rem;
    border-radius: 8px;
  }
  .left-right {
    > section {
      // background-color: orange;
      > div {
        width: calc(100% - 2rem);
        max-width: 350px;
        max-height: calc(100vh - 2rem);
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.5rem 0.5rem 0.5rem 1.5rem;
        transition: opacity 0.3s ease-in-out;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 7px 14px 0 rgb(60 66 87 / 20%), 0 3px 6px 0 rgb(0 0 0 / 20%);
      }
    }
    > aside {
      display: none;
    }
  }
  @media only screen and (min-width: 570px) {
    .left-right {
      > section {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
      }
      > aside {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
      }
    }
  }
`

export default class Route extends Component {
  constructor() {
    super()
    this.state = {
      isShowing: false,
      partner: undefined
    }
  }

  render () {
    const { autoUi } = this.props
    const { isShowing, partner, partnerError } = this.state
    const logInRedirectTo = getLogInUrl()
    const { partnerCode } = this.props.match.params
    log('[Route-logIn] [render] 2', { autoUi, partnerError })

    return (
      <StyledRoute>
        <CustomHelmet
          title='Log in'
        />
        <div className='left-right'>
          <section>
            <div style={{ opacity: isShowing ? 1 : 0 }}>
              {partnerCode && partnerError && <div className='partner-error'>
                <p>{partnerCode} isn't right.</p>
                <LinkButton to={`/log-in${logInRedirectTo ? `?logInRedirectTo=${encodeURIComponent(logInRedirectTo)}` : ''}`} sameTab>
                  Try again →
                </LinkButton>
              </div>}
              <PartnerPublic
                partnerCode={partnerCode}
                onReady={({ partner, error }) => {
                  partner && (() => {
                    this.freeStyle = window.sticky.addStyle(undefined, partner.css)
                  })()
                  this.setState({
                    isShowing: true,
                    partner,
                    partnerError: error
                  })
                }}
              />
              {(partnerCode ? !partnerError : true) && <TheForm
                autoUi={autoUi}
                logInRedirectTo={logInRedirectTo}
                partnerCode={partner && partner.code}
              />}
            </div>
          </section>
          <aside>
            <StickerImages />
          </aside>
        </div>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  autoUi: PropTypes.arrayOf(PropTypes.string),
  match: PropTypes.object
}
