import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

const StyledPartnerCell = styled.div`
  height: 2.5rem;
  background-image: url('/partner-cell.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export default function PartnerCell ({ className }) {
  return (
    <StyledPartnerCell className={classnames('component--partner-cell', className)}></StyledPartnerCell>
  )
}
PartnerCell.propTypes = {
  className: PropTypes.string
}
