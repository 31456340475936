import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomHelmet, Button, LinkButton } from '@openbox-app-shared'

import _ from '../../_'

import { log } from '../../log'
import dashboardIcons from '../../icons'
import PartnerCell from '../../../../../openbox-app-shared/components/PartnerCell'
import getLogOutUrl from '../../getLogOutUrl'

const StyledRoute = styled.div`
  min-height: 100vh;
  section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 1rem;
    width: 100%;
    max-width: 20rem;
    > * {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    > *.full-width {
      width: 100% !important;
    }
    > * + * {
      margin-top: 1rem;
    }
    .component--button > button {
      width: 100%;
    }
  }
`

export default class Route extends Component {
  componentDidMount() {
    log('[Route-view-yeetYeet] [componentDidMount]', {})
  }

  render() {
    const { user, autoUi } = this.props
    return (
      <StyledRoute
        className='level--styled-route'
      >
        <CustomHelmet
          title={window.sticky._('STICKY_PAY')}
        />
        <section>
          <PartnerCell partner={user.partner} />
          <LinkButton sameTab to='/sticky-pay-sticky-pay?autoUi=backToYeetYeet' InlineIcon={dashboardIcons.pos} bigIcon className='openbox--pulsing-1 full-width'>
            {window.sticky._('STICKY_PAY')}
          </LinkButton>
          <LinkButton sameTab to='/to-do?autoUi=backToYeetYeet' InlineIcon={dashboardIcons.livePayments} bigIcon className='full-width'>
            Live payments
          </LinkButton>
          {user.can('show-linear-operator-view') && <LinkButton sameTab to='/to-do-linear' InlineIcon={dashboardIcons.livePaymentsLinear} bigIcon className='full-width'>
            Linear live payments
          </LinkButton>}
          <LinkButton sameTab to='/me/flows?autoUi=backToYeetYeet' InlineIcon={dashboardIcons.application} bigIcon className='full-width'>
            Dashboard
          </LinkButton>
          {autoUi.includes('browserBack') && <Button
            onClick={() => {
              window.sticky.users.logOut()
              window.history.back()
            }}
            backgroundColor='transparent'
            InlineIcon={dashboardIcons.goBack}
          >
            Back
          </Button>}
          {!autoUi.includes('browserBack') && <Button
            onClick={() => {
              window.sticky.users.logOut()
              const logOutRedirectTo = getLogOutUrl()
              window.location = `/log-in${logOutRedirectTo ? `?logInRedirectTo=${encodeURIComponent(logOutRedirectTo)}` : ''}`
            }}
            backgroundColor='transparent'
            InlineIcon={dashboardIcons.logOut}
          >
            Log out
          </Button>}
        </section>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
