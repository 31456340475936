import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Label, Input } from './Input'

const COLOURS = [
  '#1A1F35',
  '#EA2027',
  '#C0392B',
  '#FFD700',
  '#FFA500',
  '#FF8C00',
  '#d35400',
  '#FFEFD5',

  '#EEE8AA',
  '#BDB76B',
  '#FFFF00',
  '#7CFC00',
  '#32CD32',
  '#228B22',
  '#006400',
  '#00FF7F',

  '#6B8E23',
  '#AFEEEE',
  '#40E0D0',
  '#008080',
  '#00FFFF',
  '#00BFFF',
  '#6A5ACD',
  '#483D8B',

  '#EE82EE',
  '#FF00FF',
  '#8A2BE2',
  '#4B0082',
  '#FF69B4',
  '#CD853F',
  '#8B4513',
  '#FF7F50',

  '#FFFFFF',
  '#CFD8DC',
  '#90A4AE',
  '#607D8B',
  '#546E7A',
  '#455A64',
  '#263238',
  '#000000'
]

const StyledColourPicker = styled.div`
  width: fit-content;
  .colours {
  margin-top: 0.5rem;
    position: relative;
    ${props => css`
      width: ${props.$columns * props.$cellSize}rem;
      height: ${(props.$colours.length / props.$columns) * props.$cellSize}rem;
      button {
        width: ${props.$cellSize}rem;
        height: ${props.$cellSize}rem;
      }
    `}
    border-radius: 4px;
    overflow: hidden;
    button {
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: none;
      transition: border-color 0.3s ease-in-out;
      border: 2px solid transparent;
      &:focus {
        outline: 0;
        border: 2px solid white;
        // background-color: yellow;
      }
    }
  }
  .bottom-container {
    margin-top: 0.5rem;
    height: 1.5rem;
    > * {
      float: left;
      height: 1.5rem;
    }
    > * + * {
      margin-left: 0.5rem;
    }
    .component--input {
      width: 4.4rem;
      > input {
        height: 1.5rem;
        padding: 0.25rem;
        font-size: 0.8rem;
      }
    }
    .which-colour {
      width: 1.5rem;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }
`

const ColourPicker = ({ colours = COLOURS, columns = 8, cellSize = 0.8, currentColour, label, onChoose }) => {
  let x = 0
  let y = 0
  return (
    <StyledColourPicker className='component--colour-picker' $cellSize={cellSize} $colours={colours} $columns={columns}>
      <Label>
        {label}
        <div className='colours'>
          {
            colours.map((colour, index) => {
              const style = {backgroundColor: colour, top: `${y * cellSize}rem`, left: `${x * cellSize}rem`, border: currentColour === colour ? '2px dashed #F0F1F3' : undefined}
              if (x === columns - 1) {
                x = 0
                y += 1
              } else {
                x += 1
              }
              return (
                <button key={colour} style={style} onClick={() => onChoose(colour)} />
              )
            })
          }
        </div>
      </Label>
      <div className='bottom-container'>
        <Input
          value={currentColour}
          onChange={(v) => onChoose(v)}
        />
        <div className='which-colour' style={{ backgroundColor: currentColour }} />
      </div>
    </StyledColourPicker>
  )
}

ColourPicker.propTypes = {
  onChoose: PropTypes.func,
  columns: PropTypes.number,
  label: PropTypes.node.isRequired
}
ColourPicker.defaultProps = {
  onChoose: () => {}
}

export default ColourPicker
