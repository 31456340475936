import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { icons, Banner, Form, Input, UploadImage, Price, TimePicker, DayPicker, Dropdown, Switch, Button, Modal, PatchableSetList, ColourPicker } from '@openbox-app-shared'
import { log } from '../../log'
import H2 from '../H2'
import ButtonList from '../ButtonList'
import Box from '../Box'

function FormBox ({ onChange, children, ...props }) {
  return (
    <Box {...props}>
      <Form onChange={onChange}>
        {children}
      </Form>
    </Box>
  )
}
FormBox.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node
}

const VIEWS = [
  {
    id: 'list',
    name: 'List',
    icon: icons.generic.views.list
  },
  {
    id: 'list-description',
    name: 'List + description',
    icon: icons.generic.views.list
  },
  {
    id: 'grid',
    name: 'Grid',
    icon: icons.generic.views.grid
  },
  {
    id: 'grid-name',
    name: 'Grid + name',
    icon: icons.generic.views.gridName
  }
]

const StyledModal = styled.div`
  .component--modal {
    max-width: 640px;
    .component--banner {
      margin-bottom: 1rem;
    }
    > .component--button {
      display: block;
      margin: 2rem auto 0 auto;
      width: auto;
    }
    .component--button-list .component--button {
      font-size: 70%;
      padding: 0.5rem 0rem 0.5rem 0;
      img {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
  .split > * + * {
    margin-top: 1rem;
  }
  .split .component--box > * + * {
    margin-top: 1rem;
  }
  .split .component--box + .component--box {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 570px) {
    .split {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * + * {
        margin-top: 0;
      }
    }
  }
  .split + * {
    margin-top: 1rem;
  }
  .component--time-picker {
    display: inline-block;
    width: auto;
  }
  .component--time-picker + .component--time-picker {
    margin-left: 1rem;
  }
  .component--switch:not(:first-child) {
    margin-top: 0.75rem;
  }
  .products {
    padding: 0.5rem;
    border: 2px solid #F0F1F3;
    border-radius: 4px;
  }
  .pc-description textarea {
    height: 4rem;
  }
  .pc-id {
    margin: 1rem auto 0 auto;
    max-width: 21rem;
  }
`

export default function ThisModal ({ user, productCategory, products, onUpdate, onSave, onCancel }) {
  const [filter, setFilter] = useState('')
  const connectionName = productCategory.connection && user.getConnection(productCategory.connection).name
  const forPs = products
    .filter(p => p.doesMatchFilter(filter, false))
    .map(p => {
      return {
        id: p.id,
        name: p.name,
        tags: [
          {
            name: <Price price={p.price} currency={p.currency} />
          }
        ]
      }
    })
  log('[ThisModal]', { productCategory })
  return (
    <StyledModal>
      <Modal onClose={onCancel}>
        <div className='split'>
          <div>
            <FormBox onChange={onUpdate}>
              <Input
                label='Name'
                name='name'
                value={productCategory.name}
              />
              <Switch
                name='showName'
                checked={productCategory.showName}
              >
                Show name to people
              </Switch>
            </FormBox>
            <FormBox onChange={onUpdate}>
              <ButtonList
                label='Show as'
                turnedOnColor='#a55eea'
                items={VIEWS}
                selected={productCategory.view}
                name='view'
                columns={2}
                bigIcon
              />
            </FormBox>
            <FormBox onChange={onUpdate}>
              <UploadImage
                label='Image'
                value={productCategory.imageUrl}
                show
                color='#a55eea'
                canChangeToUndefined
                onChange={({ url }) => {
                  onUpdate('imageUrl', url)
                }}
              />
              <Dropdown
                label='Show as'
                name='showImageAs'
                items={[
                  {
                    id: 'backgroundImage',
                    name: 'Background image'
                  },
                  {
                    id: 'iconLeft',
                    name: 'Icon (left)'
                  },
                  {
                    id: 'iconRight',
                    name: 'Icon (right)'
                  }
                ]}
                selected={productCategory.showImageAs}
              />
              <Switch
                name='showImageEpos'
                checked={productCategory.showImageEpos}
              >
                Show this image in {window.sticky._('STICKY_PAY')} / Take a payment
              </Switch>
            </FormBox>
          </div>
          <div>
            {connectionName && (<Banner>
              <p>
                <strong>{connectionName} manages this product category.</strong>
              </p>
              <p>
                Updates you make here may be overwritten by {connectionName}.
              </p>
              {productCategory.theirId && <p><strong><code>{productCategory.theirId}</code></strong></p>}
            </Banner>)}
            <FormBox onChange={onUpdate}>
              <Switch
                name='isEnabled'
                checked={productCategory.isEnabled}
              >
                For sale
              </Switch>
              <Switch
                name='alwaysAt'
                checked={productCategory.alwaysAt}
              >
                For sale 24/7
              </Switch>
              {!productCategory.alwaysAt && <>
                <TimePicker
                  name='startAt'
                  label='From'
                  time={productCategory.startAt}
                  timezone={user.timezone}
                  onChange={v => onUpdate('startAt', v)}
                />
                <TimePicker
                  name='endAt'
                  label='To'
                  time={productCategory.endAt}
                  timezone={user.timezone}
                  onChange={v => onUpdate('endAt', v)}
                />
                <DayPicker
                  label='For sale on...'
                  turnedOnColor='#a55eea'
                  days={productCategory.days}
                  onChange={days => {
                    onUpdate('days', days)
                  }}
                />
              </>}
            </FormBox>
            <FormBox onChange={onUpdate}>
              <Input
                type='textarea'
                label='Description'
                name='description'
                value={productCategory.description}
                className='pc-description'
              />
              <ColourPicker
                name='color'
                label='Color'
                currentColour={productCategory.color}
              />
              <ColourPicker
                name='foregroundColor'
                label='Text color'
                currentColour={productCategory.foregroundColor}
              />
            </FormBox>
          </div>
        </div>
        <H2>Products in this category</H2>
        <div className='products'>
          <PatchableSetList
            draggable
            all={forPs}
            set={productCategory.products}
            onUpdate={() => {
              onUpdate({
                products: productCategory.products
              })
            }}
            limitHeight={320}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
        <Button
          icon={icons.inverted.check}
          onClick={onSave}
        >
          Save
        </Button>
        <Input disabled value={productCategory.id} className='pc-id' />
      </Modal>
    </StyledModal>

  )
}

ThisModal.propTypes = {
  user: PropTypes.object.isRequired,
  productCategory: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdate: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}
