import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { log } from '../log'

const StyledComponent = styled.div`
  .partner {
    height: 4rem;
    img {
      display: block;
      margin: 0 auto 0 auto;
      height: 100%;
      max-width: 100%;
      max-height: 4rem;
    }
  }
`

export default class PartnerPublic extends Component {
  constructor (props) {
    super(props)
    this.state = {
      partner: null,
      error: undefined
    }
    log('[PartnerPublic] [constructor]')
  }

  async componentDidMount () {
    let partner
    try {
      partner = await window.sticky.internals.partners.get(this.props.partnerCode)
      log('[PartnerPublic]', { partner })
      this.setState({
        partner
      })
      this.props.onReady && this.props.onReady({ partner, error: false })
    } catch (error) {
      this.props.onReady && this.props.onReady({ partner: null, error: true })
    }
  }

  render () {
    const { partner } = this.state
    if (!partner) {
      return
    }
    return (
      <StyledComponent className='component--partner-public'>
        {partner && (
          <div className='partner'>
            {/* <p>In partnership with</p> */}
            <img src={partner.logoUrl} alt={partner.name} />
          </div>
        )}
      </StyledComponent>
    )
  }
}
PartnerPublic.propTypes = {
  partnerCode: PropTypes.string,
  onReady: PropTypes.func
}
