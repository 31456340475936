const isPrivateKey = v => {
  if (typeof v !== 'string') {
    return false
  }
  const r = [
    v.match(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i),
    v.match(/^private-[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i)
  ].some(e => e) ? true : false
  return r
}

export default isPrivateKey
