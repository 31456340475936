/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { List, ListItem, Loading } from '@openbox-app-shared'

import H2 from '../../components/H2'
import Box from '../../components/Box'
import _ from '../../_'
import { dispatch } from '../../redux'

const StyledReport = styled.div`
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
  .component--h2 + .component--list {
    margin-top: 0.5rem;
  }
`

function ReportChild ({ user, data, onRefresh }) {
  const [stuff, setStuff] = useState()
  useEffect(
    () => {
      async function getStuff () {
        setStuff({
          things: await window.sticky.things.getAll(),
          applications: await window.sticky.applications.getAll()
        })
      }
      !stuff && getStuff()
    },
    []
  )

  const containers = stuff && {
    applications: [...stuff.applications]
      .map(a => {
        const row = data.applications.find(dt => dt.id === a.id)
        return { entity: a, count: row ? row.count : 0 }
      })
      .sort((a, b) => (b.count - a.count))
      .filter(_ => _.count > 0),
    things: [...stuff.things]
      .map(a => {
        const row = data.things.find(dt => dt.id === a.id)
        return { entity: a, count: row ? row.count : 0 }
      })
      .sort((a, b) => (b.count - a.count))
      .filter(_ => _.count > 0)
  }

  return (
    <StyledReport>
      {!stuff && <Loading />}
      {stuff && <div className='grid'>
        <Box>
          <H2>By flow</H2>
          <List emptyText='There were no flow interactions during this time.'>
            {containers.applications.map(e => {
              const { entity, count } = e
              return (
                <ListItem
                  key={entity.id}
                  icon={entity.baseIcon}
                  tags={count > 0 ? [{ name: `${count} interaction${count === 1 ? '' : 's'}`, color: '#1A1F35' }] : []}
                  onAction={() => dispatch('REDIRECT', { to: `/me/flows/${entity.id}` })}
                  actions={
                    [
                      user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced') && 'go-to'
                    ]
                      .filter(_ => _)
                  }
                  goTo={`/analytics/home/flows/${entity.id}`}
                >
                  {entity.name}
                </ListItem>
              )
            })}
          </List>
        </Box>
        <Box>
          <H2>By sticky</H2>
          <List emptyText='There were no sticky interactions during this time.'>
            {containers.things.map(e => {
              const { entity, count } = e
              return (
                <ListItem
                  key={entity.id}
                  icon={entity.designUrl}
                  tags={count > 0 ? [{ name: `${count} tap${count === 1 ? '' : 's'}`, color: '#1A1F35' }] : []}
                  onAction={() => dispatch('REDIRECT', { to: `/me/stickies/${entity.id}` })}
                  actions={
                    [
                      user.federatedUserCan('things') && 'go-to'
                    ]
                      .filter(_ => _)
                  }
                  goTo={`/analytics/home/stickies/${entity.id}`}
                >
                  {entity.name}
                </ListItem>
              )
            })}
          </List>
        </Box>
      </div>}
    </StyledReport>
  )
}

export default ReportChild
