/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Modal } from '@openbox-app-shared'

import _ from '../../../_'
import H1 from '../../../components/H1'
import FederatedUsersGrid from '../../../components/FederatedUsersGrid'
import EnterPinModal from '../../modals/EnterPin'

const StyledModal = styled.div`
  .component--modal {
    max-width: 340px;
  }
  .component--h1 {
    margin-bottom: 2rem;
    text-align: center;
  }
`
function ThisModal ({ federatedUsers, onChoose, canBeClosed, onClose }) {
  return (
    <StyledModal>
      <Modal
        canBeClosed={canBeClosed || false}
        onClose={onClose}
      >
        <H1>{_('CHOOSE_FEDERATED_USER')}</H1>
        <FederatedUsersGrid federatedUsers={federatedUsers} onChoose={onChoose} />
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ why, entity, entities, canBeClosed, dispatch, setState }) => {
    const [federatedUser] = entities.federatedUsers
    if (entities.federatedUsers.length === 1 && federatedUser && federatedUser.pin === '0000') {
      window.sticky.internals.setFederatedUserPrivateKey(federatedUser.privateKey)
      dispatch('CHOOSE_FEDERATED_USER_GOOD', { why, entity, federatedUser })
      return
    }
    if (entities.federatedUsers.length === 1 && federatedUser && federatedUser.pin !== '0000') {
      setState({ CHOOSE_FEDERATED_USER: { why, entity }, CHOOSE_FEDERATED_USER_1: undefined, CHOOSE_FEDERATED_USER_2: federatedUser })
      return
    }
    setState({ CHOOSE_FEDERATED_USER: { why, entity, canBeClosed }, CHOOSE_FEDERATED_USER_1: true })
  },
  render: function ThisAction ({ state, setState, entities, dispatch }) {
    const { CHOOSE_FEDERATED_USER, CHOOSE_FEDERATED_USER_1, CHOOSE_FEDERATED_USER_2 } = state
    const { canBeClosed } = CHOOSE_FEDERATED_USER || {}
    return (
      <>
        {CHOOSE_FEDERATED_USER_1 && <ThisModal
          federatedUsers={entities.federatedUsers}
          canBeClosed={canBeClosed}
          onClose={() => {
            setState({
              CHOOSE_FEDERATED_USER: undefined,
              CHOOSE_FEDERATED_USER_1: undefined
            })
          }}
          onChoose={(federatedUser) => {
            if (federatedUser.pin === '0000') {
              setState({ CHOOSE_FEDERATED_USER_1: undefined })
              const { why, entity } = CHOOSE_FEDERATED_USER
              window.sticky.internals.setFederatedUserPrivateKey(federatedUser.privateKey)
              dispatch('CHOOSE_FEDERATED_USER_GOOD', { why, entity, federatedUser })
              return
            }
            setState({
              CHOOSE_FEDERATED_USER_1: undefined,
              CHOOSE_FEDERATED_USER_2: federatedUser
            })
          }}
        />}
        {CHOOSE_FEDERATED_USER_2 && (
          <EnterPinModal
            goodPin={CHOOSE_FEDERATED_USER_2.pin}
            onGood={() => {
              setState({ CHOOSE_FEDERATED_USER_2: undefined })
              const { why, entity } = CHOOSE_FEDERATED_USER
              window.sticky.internals.setFederatedUserPrivateKey(CHOOSE_FEDERATED_USER_2.privateKey)
              dispatch('CHOOSE_FEDERATED_USER_GOOD', { why, entity, federatedUser: CHOOSE_FEDERATED_USER_2 })
            }}
            onCancel={() => {
              setState({
                CHOOSE_FEDERATED_USER_1: CHOOSE_FEDERATED_USER_2,
                CHOOSE_FEDERATED_USER_2: undefined
              })
            }}
          />
        )}
      </>
    )
  }
}
