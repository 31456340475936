import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DebouncedForm, Input } from '@openbox-app-shared'

const StyledComponent = styled.div`
  form {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    > * {
      margin-top: 0;
    }
  }
`

export default function JsonEditor ({ json, includeKeys, excludeKeys = [], onChange, className }) {
  const keys = (Array.isArray(includeKeys) ? includeKeys : Object.keys(json))
    .filter(k => !excludeKeys.includes(k))
  return (
    <StyledComponent className='component--json-editor'>
      <DebouncedForm
        onChange={(k, v) => {
          onChange && onChange({
            ...json,
            [k]: v
          })
        }}
        className={className}
      >
        {keys.map(k => {
          return (
            <Input
              key={k}
              label={k}
              name={k}
              value={json[k]}
            />
          )
        })}
      </DebouncedForm>
    </StyledComponent>
  )
}
JsonEditor.propTypes = {
  json: PropTypes.object,
  includeKeys: PropTypes.arrayOf(PropTypes.string),
  excludeKeys: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  className: PropTypes.string
}
