import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import { HotImage, UploadImage, Banner, Label } from '@openbox-app-shared'

const StyledLogo = styled.div`
  .component--upload {
    margin-top: 0.5rem;
  }
  .component--banner {
    margin-top: 1rem;
  }
  label + .hot-show {
    margin-top: 0.5rem;
  }
  .hot-show {
    width: fit-content;
    > img {
      display: block;
      max-width: 100%;
    }
  }
`

export default function Logo ({ url, label, backgroundColor, color, onChange, className, svgsAllowed = true }) {
  const isSvg = typeof url === 'string' && url.endsWith('.svg')
  return (
    <StyledLogo className={classnames('component--logo', className)}>
      {label && <Label>{label}</Label>}
      {typeof url === 'string' && <div className='hot-show' style={{ backgroundColor: backgroundColor }}>
        <HotImage src={url} alt='' />
      </div>}
      <UploadImage
        onChange={onChange}
        value={url}
        color={color}
      />
      {!svgsAllowed && isSvg && (
        <Banner>
          <p>
            Your logo is an SVG file which won't show in Gmail.
          </p>
          <p>
            Please upload a PNG instead.
          </p>
        </Banner>
      )}
    </StyledLogo>
  )
}
Logo.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  svgsAllowed: PropTypes.bool
}
