import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import EVENT_TYPES from './EVENT_TYPES'

const StyledEventType = styled.div`
  img, svg, strong {
    display: block;
  }
  img, svg {
    float: left;
    height: 1.5rem;
  }
  svg {
    width: 1.5rem;
  }
  strong {
    line-height: 1.5rem;
  }
  img + strong, svg + strong {
    margin-left: 2rem;
  }
`

export default function EventType ({ event, user }) {
  const { type } = event
  const foundEventType = EVENT_TYPES.find(([t]) => t === type)
  const FoundInlineIcon = foundEventType && foundEventType[1].inlineIcon
  return (
    <StyledEventType className='component--event-type' style={foundEventType ? { color: foundEventType[1].color } : undefined}>
      {foundEventType && <>
        {foundEventType[1].icon && <img src={foundEventType[1].icon({ event, user })} alt='' />}
        {FoundInlineIcon && <FoundInlineIcon />}
        {foundEventType[1].text({ event, user })}
      </>}
      {!foundEventType && <>
        <strong style={{ color: '#6C7A89'}}>Unknown</strong>
      </>}
    </StyledEventType>
  )
}
EventType.propTypes = {
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}
