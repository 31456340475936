import dashboardIcons from '../../../icons'
import TabBar from '../../../components/TabBar'
import ApplicationBlocksList from '../../../components/ApplicationBlocksList'

const EVENT_KEYS = {
  on_load: ['on_load', 'Interact', undefined, dashboardIcons.thing],
  on_pay: ['on_pay', 'Success', undefined, dashboardIcons.check],
  on_pay_fail: ['on_pay_fail', 'Failure', undefined, dashboardIcons.void],
  on_pay_before: ['on_pay_before', 'Before payment', undefined],
  on_change_cart: ['on_change_cart', 'Bag change', undefined],
  on_pay_card: ['on_pay_card', 'Card', undefined],
}

function getWellKnownDefaults (user, application) {
  return {
    dangerousPrivateKey: user.privateKey,
    colour: application.primaryColor,
    colour2: application.backgroundColor
  }
}

const tab = {
  id: 'steps',
  name: (context) => 'Steps',
  inlineIcon: dashboardIcons.application,
  to: (context) => `/me/flows/${context.state.application.id}/steps`,
  child: function Child (context, { applicationBlocks, previewLink }) {
    const { viewSubroot } = context.props.match.params
    const { user } = context.props
    const { application } = context.state
    const eventKeys = Object.keys(EVENT_KEYS).map(e => EVENT_KEYS[e])
    const tabs = eventKeys.map(([ek, name, icon, inlineIcon]) => {
      return {
        id: ek,
        name,
        icon,
        inlineIcon,
        to: `/me/flows/${application.id}/steps/${ek}`,
        child: (
          <ApplicationBlocksList
            aBaseSettingsRender={application.baseSettingsRender}
            allApplicationBlocks={applicationBlocks}
            wellKnownDefaults={getWellKnownDefaults(user, application)}
            events={application.events[ek]}
            eventKey={ek}
            currentEk={viewSubroot}
            previewLink={previewLink}
            onUpdate={events => {
              const toUpdateWith = {
                events: {
                  ...application.events,
                  [ek]: events
                }
              }
              context.onUpdate(toUpdateWith)
            }}
          />
        )
      }
    })
    return <TabBar
      selectedTab={viewSubroot}
      tabs={tabs}
    />
  }
}

export default tab
