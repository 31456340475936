import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DebouncedForm } from '../Form'
import { Input } from '../Input'
import T from '../T'

const StyledStage = styled.div`
  .component--input.sub {
    display: inline-block;
    height: 4rem;
    margin-top: 1rem;
    width: 80px;
    vertical-align: top;
  }
  .component--input.sub + .component--input.sub {
    margin-left: 1rem;
  }
`

export default function Stage1 ({ FIELDS, data, dbfProps, color, caresAboutCardName, setIsLoading }) {
  useEffect(
    () => {
      async function onMessage (e) {
        if (e.data.event === 'stickyCardNumberReceived') {
          const { card: messageCardNumber, exp: messageCardExpires } = e.data.message
          const dbfOc = {
            number: messageCardNumber,
            expires: `${messageCardExpires.substring(2, 4)}/${messageCardExpires.substring(0, 2)}`
          }
          dbfProps.onChange(dbfOc)
          try {
            setIsLoading(true)
            const cardData = await window.sticky.cardData({ cardNumber: dbfOc.number, cardExpires: dbfOc.expires })
            dbfOc.cvv = cardData.cardCvv
            if (cardData.cardName) {
              dbfOc.name = cardData.cardName
            }
            dbfProps.onChange(dbfOc, true)
          } catch (error) {
            console.error('Failed to retrieve CVV', error)
          } finally {
            setIsLoading(false)
          }
        }
      }
      window.addEventListener('message', onMessage)
      window.top.postMessage({ event: 'appSharedCardStage1' }, '*')
      // setTimeout(
      //   () => {
      //     onMessage({ data: { event: 'stickyCardNumberReceived', message: { 'card': '4794787198660692', exp: '3412' } } })
      //   },
      //   500
      // )
      return () => {
        window.removeEventListener('message', onMessage)
      }
    },
    []
  )

  const [hasAutoFocused, setHasAutoFocused] = useState(false)
  const fieldName = {
    dataKey: 'name',
    field: FIELDS.find(f => f.id === 'name')
  }
  const fieldNumber = {
    dataKey: 'number',
    field: FIELDS.find(f => f.id === 'number')
  }
  const fieldExpires = {
    dataKey: 'expires',
    field: FIELDS.find(f => f.id === 'expires')
  }
  const fieldCvv = {
    dataKey: 'cvv',
    field: FIELDS.find(f => f.id === 'cvv')
  }

  const arrayForFirstInvalidField = caresAboutCardName ? [fieldName, fieldNumber, fieldExpires, fieldCvv] : [fieldNumber, fieldExpires, fieldCvv]
  const firstInvalidField = arrayForFirstInvalidField.find(f => {
    return !f.field.isValid(data[f.dataKey], data, caresAboutCardName)
  })

  return (
    <StyledStage>
      <DebouncedForm
        {...dbfProps}
        onDebouncedChange={(...args) => {
          setHasAutoFocused(true)
          dbfProps.onDebouncedChange(...args)
        }}
        onChange={(k, v) => {
          setHasAutoFocused(true)
          dbfProps.onChange({ [k]: v })
        }}
      >
        {caresAboutCardName && <Input
          name='name'
          label={<T>CARD_NAME</T>}
          value={data.name}
          isValid={fieldName.field.isValid(data.name, data)}
          maxLength={32}
          autocomplete='cc-name'
          onReady={({ autoFocus }) => {
            const expression = !hasAutoFocused && firstInvalidField && firstInvalidField.dataKey === 'name'
            if (expression) {
              autoFocus()
              setHasAutoFocused(true)
            }
          }}
          color={color}
        />}
        <Input
          name='number'
          label={<T>CARD_NUMBER</T>}
          placeholder='0000000000000000'
          value={data.number}
          type='tel'
          inputMode='numeric'
          pattern='[0-9\s]{13,19}'
          isValid={fieldNumber.field.isValid(data.number, data)}
          maxLength={19}
          autocomplete='cc-number'
          onReady={({ autoFocus }) => {
            const expression = !hasAutoFocused && firstInvalidField && firstInvalidField.dataKey === 'number'
            if (expression) {
              autoFocus()
              setHasAutoFocused(true)
            }
          }}
          color={color}
        />
        <Input
          name='expires'
          label={<T>CARD_EXPIRES</T>}
          value={data.expires}
          isValid={fieldExpires.field.isValid(data.expires, data)}
          maxLength={5}
          autocomplete='cc-exp'
          placeholder='MM/YY'
          className='sub'
          onReady={({ autoFocus }) => {
            const expression = !hasAutoFocused && firstInvalidField && firstInvalidField.dataKey === 'expires'
            if (expression) {
              autoFocus()
              setHasAutoFocused(true)
            }
          }}
          color={color}
        />
        <Input
          pattern='[0-9]*'
          name='cvv'
          label={<T>CARD_CVV</T>}
          value={data.cvv}
          isValid={fieldCvv.field.isValid(data.cvv, data)}
          maxLength={4}
          autocomplete='cc-csc'
          className='sub'
          onReady={({ autoFocus }) => {
            const expression = !hasAutoFocused && firstInvalidField && firstInvalidField.dataKey === 'cvv'
            if (expression) {
              autoFocus()
              setHasAutoFocused(true)
            }
          }}
          color={color}
        />
      </DebouncedForm>
    </StyledStage>
  )
}
Stage1.propTypes = {
  FIELDS: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  dbfProps: PropTypes.object.isRequired,
  color: PropTypes.string
}
