import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import { Label } from '@openbox-app-shared'

const STYLED_TAG = `
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 2rem;
  padding-right: 0.5rem;
  cursor: default;
  z-index: 0;
  > div {
    position: absolute;
    top: 0;
    left: 16px;
    right: 0;
    bottom: 0;
    z-index: -2;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  > div, > svg {
    opacity: 0.1;
    transition: opacity 0.3s ease-in-out;
  }
  > svg {
    height: 2rem;
  }
  > strong {
    display: inline-block;
    vertical-align: top;
    font-size: 90%;
    color: #1a1f34;
    line-height: 2rem;
    z-index: -1;
    max-width: 12.5rem;
  }
  > strong.as-text {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > img {
    display: inline-block;
    vertical-align: top;
    height: 1.5rem;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    z-index: -1;
  }
  &.has {
   > div, > svg {
      opacity: 1;
    }
  }
  &.can {
    > strong {
      padding-left: 2.125rem;
      background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQyIiB2aWV3Qm94PSIwIDAgNDIgNDIiIHdpZHRoPSI0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMzggMGMyLjIwOTEzOSAwIDQgMS43OTA4NjEgNCA0djM0YzAgMi4yMDkxMzktMS43OTA4NjEgNC00IDRoLTM0Yy0yLjIwOTEzOSAwLTQtMS43OTA4NjEtNC00di0zNGMwLTIuMjA5MTM5IDEuNzkwODYxLTQgNC00em0tMS41IDRoLTMxYy0uODI4NDI3MTIgMC0xLjUuNjcxNTcyODgtMS41IDEuNXYzMWMwIC44Mjg0MjcxLjY3MTU3Mjg4IDEuNSAxLjUgMS41aDMxYy44Mjg0MjcxIDAgMS41LS42NzE1NzI5IDEuNS0xLjV2LTMxYzAtLjgyODQyNzEyLS42NzE1NzI5LTEuNS0xLjUtMS41eiIgZmlsbD0iIzFhMWYzNCIgZmlsbC1vcGFjaXR5PSIuMjUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: 8px 7px;
    }
  }
  &.has {
    > strong {
      color: white;
    }
  }
  &.can.has {
    > strong {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQyIiB2aWV3Qm94PSIwIDAgNDIgNDIiIHdpZHRoPSI0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0zOCAwYzIuMjA5MTM5IDAgNCAxLjc5MDg2MSA0IDR2MzRjMCAyLjIwOTEzOS0xLjc5MDg2MSA0LTQgNGgtMzRjLTIuMjA5MTM5IDAtNC0xLjc5MDg2MS00LTR2LTM0YzAtMi4yMDkxMzkgMS43OTA4NjEtNCA0LTR6bS0xLjUgNGgtMzFjLS44Mjg0MjcxMiAwLTEuNS42NzE1NzI4OC0xLjUgMS41djMxYzAgLjgyODQyNzEuNjcxNTcyODggMS41IDEuNSAxLjVoMzFjLjgyODQyNzEgMCAxLjUtLjY3MTU3MjkgMS41LTEuNXYtMzFjMC0uODI4NDI3MTItLjY3MTU3MjktMS41LTEuNS0xLjV6IiBmaWxsPSIjZmZmIi8+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNS4yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMiAxMSkiPjxwYXRoIGQ9Im0wIDEwLjY0MiA0Ljg1OSA3LjM1OCIvPjxwYXRoIGQ9Im0xOCAwYy0yLjg5OTU2MDcgMy4yNDcwODYxNy01LjA4OTcxMzUgNS44ODQ3NjczMi02LjU3MDQ1ODQgNy45MTMwNDM0Ny0xLjQ4MDc0NDg4IDIuMDI4Mjc2MTUtMy42NzA4OTc3IDUuMzkwNTk1MDMtNi41NzA0NTg0MSAxMC4wODY5NTY1MyIvPjwvZz48L2c+PC9zdmc+');
    }
  }
`

const Styled = {
  LI: styled.li`${STYLED_TAG}`,
  DIV: styled.div`${STYLED_TAG}`
}

const StyledComponent = styled.div`
  label {
    margin-bottom: 0.5rem;
  }
  ul {
    list-style-type: none;
  }
  ul {
    li {
      margin: 0 0.75rem 0.75rem 0;
    }
    li:last-child {
      margin-right: 0;
    }
  }
`

export function Tag ({ element = 'LI', has, can = true, tag, onUpdate }) {
  const WhichElement = Styled[element]
  return (
    <WhichElement
      className={classnames({ has, can })}
      onClick={() => onUpdate && onUpdate(tag.id)}
    >
      <svg height="32" viewBox="0 0 16 32" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m16 0v32c-1.060866 0-2.0782816-.4214274-2.8284271-1.1715729l-12.00000002-12c-1.56209717-1.5620971-1.56209717-4.0947571 0-5.6568542l12.00000002-12.00000002c.7501455-.75014552 1.7675611-1.17157288 2.8284271-1.17157288z" fill={tag.color} fillRule="evenodd"/></svg>
      <div style={{ backgroundColor: tag.color }} />
      <strong className={classnames({ 'as-text': typeof tag.name === 'string' }, 'in-dropdown')}>{tag.name}</strong>
      {tag.icon && <img src={tag.icon} alt='' />}
    </WhichElement>
  )
}
Tag.propTypes = {
  element: PropTypes.string,
  has: PropTypes.bool,
  can: PropTypes.bool,
  tag: PropTypes.object,
  onUpdate: PropTypes.func
}

export function TagChooser ({ className, label, all, set, can, onUpdate }) {
  return (
    <StyledComponent className={classnames('component--tag-chooser', className)}>
      {label && <Label>{label}</Label>}
      <ul>
        {all.map(tag => {
          const has = set.has(tag.id)
          return <Tag key={`patchable-list--${tag.id}`} tag={tag} has={has} can={can} onUpdate={onUpdate} />
        })}
      </ul>
    </StyledComponent>
  )
}
TagChooser.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  all: PropTypes.array,
  set: PropTypes.object,
  onUpdate: PropTypes.func,
  can: PropTypes.bool
}
