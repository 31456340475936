import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isPasswordValid, CustomHelmet, Form, PasswordInput, Button } from '@openbox-app-shared'
import { subscribe, dispatch } from '../../redux'
import StickerImages from '../../components/StickerImages'

import { log } from '../../log'

const StyledRoute = styled.div`
  padding: 1rem;
  height: calc(100vh - 3rem);
  position: relative;
  .left-right {
    > section {
      > form {
        width: calc(100% - 2rem);
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.5rem;
        transition: opacity 0.3s ease-in-out;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 7px 14px 0 rgb(60 66 87 / 20%), 0 3px 6px 0 rgb(0 0 0 / 20%);
      }
    }
    > aside {
      display: none;
    }
  }
  @media only screen and (min-width: 570px) {
    .left-right {
      > section {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
      }
      > aside {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
      }
    }
  }
  .buttons {
    float: right;
    width: auto;
    .component--button {
      display: inline-block;
      vertical-align: top;
    }
  }
`

export default class Route extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newPassword: ''
    }
  }

  async componentDidMount () {
    this.freeStyle = window.sticky.addStyle(
      undefined,
      `
body {
  background-color: #322CBE;
}
      `
    )
    this.subscriptions = [
      subscribe(
        'SHOW_MESSAGE_GOOD',
        ({ why }) => {
          why === 'uberPublic--resetPasswordGo' && (() => {
            dispatch('REDIRECT', { to: '/log-in' })
          })()
        }
      )
    ]
  }
  componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
    this.freeStyle()
  }

  render () {
    const { newPassword, isBusy } = this.state
    const { finiteToken } = this.props.match.params
    const canGoNext = [
      isPasswordValid(newPassword)
    ].every(e => e)
    log('[Route-resetPassword] [render] 2', { newPassword, canGoNext })
    const onDone = () => {
      if (!canGoNext) {
        return
      }
      this.setState(
        {
          isBusy: true
        },
        () => {
          dispatch('TRIGGER', { trigger: 'uberPublic--resetPasswordGo', body: { finiteToken, newPassword } })
        }
      )
    }
    return (
      <StyledRoute>
        <CustomHelmet
          title='Reset password'
        />
        <div className='left-right'>
          <section>
            <Form
              onChange={(k, v) => {
                this.setState({
                  [k]: v
                })
              }}
              onSubmit={onDone}
            >
              <PasswordInput
                name='newPassword'
                key='newPassword'
                label='New password'
                value={newPassword}
                autocomplete='new-password'
                fireIfInvalid
                onReady={({ autoFocus }) => autoFocus()}
              />
              <div className='buttons'>
                <Button
                  disabled={!canGoNext || isBusy}
                  onClick={onDone}
                  className={canGoNext ? 'openbox--pulsing-2' : undefined}
                >
                  Reset →
                </Button>
              </div>
            </Form>
          </section>
          <aside>
            <StickerImages />
          </aside>
        </div>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  match: PropTypes.object
}
