import easy from './files/easy.svg'
import regular from './files/regular.svg'
import hard from './files/hard.svg'
import expert from './files/expert.svg'

export default {
  easy,
  regular,
  hard,
  expert
}
