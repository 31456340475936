import React, { Component, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled from 'styled-components'
import debounce from 'debounce'

const StyledForm = styled.form`
  display: block;
  > video + *, > img + *, > div + *, > details + *,  > section + *, ul + *, > .component--button, > .component--button + * {
    width: 100%;
    margin-top: 1.25rem;
  }
  > * + hr {
    margin-top: 1rem;
  }
  > label + * {
    margin-top: 0.5rem;
    width: 100%;
  }
`

export function Form ({ className, children, onChange, onSubmit, autoFocus }) {
  const [hasAutofocused, setAutoFocus] = useState(false)

  return (
    <StyledForm
      className={classnames('component--form', className)}
      onSubmit={e => e.preventDefault()}
    >
      {React.Children.map(children, (child, i) => {
        if (child === null) {
          return null
        }
        const extraProps = {}
        const isInput = typeof child.props.name === 'string' && child.props.name.length > 0 // this is ugly, but child.type is not the same when minified
        const isDropdown = Array.isArray(child.props.items)
        const isCodeEditor = child.props.type === 'code'
        const isFirstElement = i === 0
        const { setName, set, storageSector, storageSectorName } = child.props
        // console.log('[Form] [React.Children.map]', { cp: child.props, autoFocus, isInput, isDropdown, isCodeEditor, isFirstElement })
        if (autoFocus && isInput && isFirstElement) {
          extraProps.onReady = ({ autoFocus: inputAutofocus }) => {
            if (!hasAutofocused) {
              inputAutofocus()
              setAutoFocus(true)
            }
          }
        }
        if (isInput) {
          if (!isCodeEditor) {
            const f = v => {
              // console.log('[Form] [React.Children.map] [f]', { v, cp: child.props  })
              if (set) {
                // console.log('[Form] [React.Children.map] [f] set')
                set.toggle(child.props.name)
                onChange(setName, set)
              } else if (storageSector) {
                // console.log('[Form] [React.Children.map] [f] storageSector')
                storageSector.writeTo(child.props.name, v)
                onChange(storageSectorName, storageSector)
              } else {
                // console.log('[Form] [React.Children.map] [f] else')
                onChange(child.props.name, v)
              }
            }
            if (onChange) {
              // console.log('[Form] [React.Children.map] [f] assigning extraProps.onChange', { extraProps })
              extraProps.onChange = v => f(v)
            }
            if (child.props.onChoose || isDropdown) {
              // console.log('[Form] [React.Children.map] [f] assigning extraProps.onChoose', { extraProps })
              extraProps.onChoose = v => f(v)
            }
            extraProps.onDone = v => onSubmit()
          }
          if (isCodeEditor) {
            extraProps.onChange = v => onChange(child.props.name, v)
          }
        }
        return cloneElement(
          child,
          extraProps
        )
      })}
    </StyledForm>
  )
}
Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  autoFocus: PropTypes.bool
}
Form.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  autoFocus: false
}

export class DebouncedForm extends Component {
  componentDidMount () {
    const {
      onDebouncedChange,
      debounceTime = 250
    } = this.props
    // console.log('[DebouncedForm] [componentDidMount]', { debounce, onDebouncedChange })
    this.onDebouncedChange = onDebouncedChange && debounce(onDebouncedChange, debounceTime)
  }

  componentWillUnmount () {
    // console.log('[DebouncedForm] [componentWillUnmount]')
    this.onDebouncedChange && this.onDebouncedChange.clear()
  }

  render () {
    return <Form {...this.props} onChange={(...args) => {
      this.props.onChange(...args)
      // console.log('[DebouncedForm] [render]', { onDebouncedChange: this.onDebouncedChange } )
      this.onDebouncedChange && this.onDebouncedChange(...args)
    }} />
  }
}
DebouncedForm.propTypes = {
  ...Form.propTypes,
  onDebouncedChange: PropTypes.func,
  debounceTime: PropTypes.number
}
DebouncedForm.defaultProps = {
  ...Form.defaultProps
}
