import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { tags, Price, Details, Link, ApplicationBlocks, Table, Button } from '@openbox-app-shared'
import H2 from '../H2'
import { dispatch } from '../../redux'

const StyledReport = styled.div`
  .component--details {
    width: 100%;
    max-width: 24rem;
  }
  .component--table {
    width: 100%;
  }
  .component--h2 {
    margin-top: 1rem;
  }
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
  .by-category {
    display: inline-block;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgb(60 66 87 / 12%), 0 1px 1px 0 rgb(0 0 0 / 12%);
    p {
      margin-top: 0.25rem;
    }
    .component--application-blocks ul {
      text-align: left !important;
    }
  }
  .action-buttons {
    margin-top: 2rem;
    .component--button {
      vertical-align: top;
      display: inline-block;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
  .indent-me {
    margin-left: 1rem;
  }
`

export default function Report ({ user, data }) {
  const gateway = window.sticky.Stickypay.getGateway(user.gateway)
  const details1 = new Map(
    [
      ['Total', <><Price key='d--totalTotal' currency={data.currency} price={data.totalTotal} /> ({data.counts.total})</>],
      ...Object.keys(data.gateway).map(k => ([window.sticky.Stickypay.GATEWAYS.find(_ => _.id === k).name, <Price className='indent-me' key={`d--${k}`} price={data.gateway[k]} currency={data.currency} />])),
      ['Refunds', <><Price key='d--totalRefunded' currency={data.currency} price={data.totalRefunded} /> ({data.counts.refunds})</>],
      gateway && gateway.canShowFee && ['Fee', <Price key='d--totalWeGet' currency={data.currency} price={data.totalWeGet} />],
      ['You get', <Price key='d--totalTheyGet' currency={data.currency} price={data.totalTheyGet} />],
      ['Discounts', <><Price key='d--totalDiscount' currency={data.currency} price={data.totalDiscount} /> ({data.counts.discounts})</>],
      ['Service', <><Price key='d--totalTip' currency={data.currency} price={data.totalTip} /> ({data.counts.tips})</>],
      ['Comp', <><Price key='d--totalComp' currency={data.currency} price={data.totalComp} /> ({data.counts.comps})</>]
    ]
      .filter(_ => _)
  )

  const detailsByFederatedUser = new Map(
    Object.keys(data.byFederatedUser)
      .filter(byk => {
        const v = data.byFederatedUser[byk]
        return typeof v.federatedUserName === 'string'
      })
      .map(byk => {
        const v = data.byFederatedUser[byk]
        return [
          <Link key={byk} to={`/me/team/${byk}`}>{v.federatedUserName}</Link>,
          <>
            Payments: <Price currency={data.currency} price={v.total || 0} /> ({v.countTotal || 0})<br /><br />
            Discounts: <Price currency={data.currency} price={v.totalDiscount || 0} /> ({v.countDiscount || 0})<br /><br />
            Service: <Price currency={data.currency} price={v.totalTip || 0} /> ({v.countTip || 0})<br /><br />
            Comps: <Price currency={data.currency} price={v.totalComp || 0} /> ({v.countComp || 0})
          </>
        ]
      })
  )

  const detailsByProduct = Object.keys(data.byProduct).map(byk => {
    const v = data.byProduct[byk]
    return {
      productName: <Link to={`/me/products/${byk}`}>{v.productName}</Link>,
      total: <Price currency={data.currency} price={v.total} />,
      quantity: v.quantity
    }
  })

  const detailsByProductCategory = Object.keys(data.byProductCategory).map(byk => {
    const v = data.byProductCategory[byk]
    return {
      productCategoryName: <Link to={`/me/products/${byk}`}>{v.productCategoryName}</Link>,
      total: <Price currency={data.currency} price={v.total} />,
      quantity: v.quantity
    }
  })

  const detailsByCategory = Object.keys(data.byCategory)
    .map(byk => {
      const v = data.byCategory[byk]
      const foundTagIndex = tags.findIndex(t => t.id === byk)
      const foundTag = tags[foundTagIndex]
      if (!foundTag|| !foundTag.doReport) {
        return
      }
      return {
        foundTagIndex,
        foundTag,
        byk,
        v
      }
    })
    .filter(_ => _)
    .sort((a, b) => {
      if (a.foundTagIndex < b.foundTagIndex) return -1
      if (a.foundTagIndex > b.foundTagIndex) return 1
      return 0
    })
    .map(({ foundTag, byk, v }) => {
      return (
        <div className='by-category' key={byk}>
          <ApplicationBlocks
            applicationBlocks={[
              {
                id: 'tags',
                config: {
                  tags: [foundTag]
                }
              }
            ]}
          />
          <p>
            <Price currency={data.currency} price={v.total} /> ({v.quantity})
          </p>
        </div>
      )
    })
    .filter(e => e)

  return (
    <StyledReport>
      <div className='grid'>
        <div>
          <H2>Summary</H2>
          <Details details={details1} />
          {detailsByFederatedUser.size > 0 && <>
            <H2>By team member</H2>
            <Details details={detailsByFederatedUser} />
          </>}
          <div className='action-buttons'>
            <Button
              isSecondary
              onClick={async () => {
                dispatch('LOADING')
                try {
                  const eventPayload = {
                    type: 'PAYMENTS_REPORT_CAPTURE',
                    data
                  }
                  await window.sticky.session.createEvent(
                    eventPayload,
                    'doesnt-matter'
                  )
                  dispatch('SHOW_MESSAGE', { message: <p>End of day captured.</p>, canBeBadded: '' })
                } catch (e) {
                  dispatch('SHOW_MESSAGE', { message: <><p><strong>Sorry, that didn't work.</strong></p><p>{e.message}</p></>, canBeBadded: '' })
                }
                dispatch('STOP_LOADING')
              }}
            >
              End of day
            </Button>
            {user.can('has-chp') && <Button
              isSecondary
              onClick={async () => {
                dispatch('LOADING')
                try {
                  const chps = await window.sticky.chp.getAll()
                  window.sticky.assert(chps.length > 0, 'You don\'t have any Sticky card machines.')
                  const [chp1] = chps
                  const response = await chp1.endOfDay()
                  const eventPayload = {
                    type: 'CHP_END_OF_DAY',
                    data: response
                  }
                  await window.sticky.session.createEvent(
                    eventPayload,
                    'doesnt-matter'
                  )
                  dispatch('SHOW_MESSAGE', { message: <p style={{ whiteSpace: 'pre' }}>{response}</p>, canBeBadded: '' })
                } catch (e) {
                  dispatch('SHOW_MESSAGE', { message: <><p><strong>Sorry, that didn't work.</strong></p><p>{e.message}</p></>, canBeBadded: '' })
                }
                dispatch('STOP_LOADING')
              }}
            >
              End of day (Sticky card machine)
            </Button>}
          </div>
        </div>
        <div>
          {detailsByCategory.length > 0 && (
            <>
              <H2>By label</H2>
              {detailsByCategory}
            </>
          )}
          {detailsByProduct.length > 0 && (
            <>
              <H2>By product</H2>
              <Table columns={{ productName: 'Product', total: 'Total', quantity: 'Quantity' }} rows={detailsByProduct} />
            </>
          )}
          {detailsByProductCategory.length > 0 && (
            <>
              <H2>By product category</H2>
              <Table columns={{ productCategoryName: 'Product', total: 'Total', quantity: 'Quantity' }} rows={detailsByProductCategory} />
            </>
          )}
        </div>
      </div>
    </StyledReport>
  )
}
Report.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object
}
