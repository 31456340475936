import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { icons, Banner, HotImage, Form, Input, Button, CustomHelmet, Loading } from '@openbox-app-shared'
import { state as reduxHandlerState } from '../../components/ReduxHandler'

import Box from '../../components/Box'
import TabBar from '../../components/TabBar'

import { log } from '../../log'
import _ from '../../_'
import dashboardIcons from '../../icons'
import MainFrame from '../../components/MainFrame'
import User from '../../components/User'

import tabApplication from './tabs/application'
import tabProduct from './tabs/product'
import tabTags from './tabs/tags'
import tabData from './tabs/data'
// import tabConsumers from './tabs/consumers'
import tabLocation from './tabs/location'
import tabAdvanced from './tabs/advanced'
import { dispatch, subscribe } from '../../redux'
import SaveButton from '../../components/SaveButton'
import CopyUrl from '../../components/CopyUrl'
import HeaderBar from '../../components/HeaderBar'

const StyledRoute = styled.div`
  padding: 1rem;
  > .component--banner {
    margin-bottom: 1rem;
  }
  aside {
    hr {
      border-color: #CFD8DC;
    }
    .component--banner {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .buttons {
      height: 2.5rem;
      margin-bottom: 1rem;
      > * {
        float: left;
      }
      > * + * {
        margin-left: 0.5rem;
      }
    }
  }
  .component--box + .component--copy-url {
    margin-top: 1rem;
  }
  .component--box {
    .logo {
      display: block;
      max-width: 128px;
      margin-bottom: 2rem;
    }
    .component--h1 {
      margin-bottom: 0.5rem;
    }
    .component--h2 {
      margin-bottom: 0.5rem;
    }
    .component--details {
      width: 100%;
    }
    .component--form + .component--details, .component--list + .component--details, .component--list + .component--form {
      margin-top: 1rem;
    }
    label + .component--list {
      margin-top: 0.5rem;
    }
  }
  .as-image {
    position: relative;
    height: 192px;
    background-color: #A9B7C6;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    .component--button {
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      width: 1.75rem !important;
      height: 1.75rem !important;
      padding: 0.125rem;
    }
    > img.as-thing {
      display: inline-block;
      margin: 0 auto 0 auto;
      width: 90px;
      height: 120px;
    }
    > img.as-qr {
      display: inline-block;
      margin: 0 auto 0 auto;
      width: 120px;
      height: 120px;
      border: 2px solid black;
      border-radius: 6px;
    }
    p {
      margin-top: 0.5rem;
      height: 2rem;
      strong {
        line-height: 2rem;
        letter-spacing: 0.5px;
      }
    }
  }
`

const tabs = [
  tabApplication,
  tabProduct,
  tabTags,
  tabData,
  // tabConsumers,
  tabLocation,
  tabAdvanced
]

export default class Route extends Component {
  constructor() {
    super()
    this.previewLinkCounter = 0
    this.state = {
      hasMadeUpdate: false
    }
    this.onSave = this.onSave.bind(this)
    this.changeDesign = this.changeDesign.bind(this)

    this.GET_INPUT_GOOD = this.GET_INPUT_GOOD.bind(this)
  }

  getUrls (thingId, includePreviewLinkCounter) {
    this.previewLinkCounter += 1
    const { SESSION_BECOME } = reduxHandlerState
    const sessionId = SESSION_BECOME ? SESSION_BECOME.session.id : undefined
    const extra = [
      includePreviewLinkCounter ? `previewLinkCounter=${this.previewLinkCounter}` : undefined,
      sessionId ? `actingAs=${sessionId}` : undefined
    ]
      .filter(_ => _)
    const extraString = extra.length > 0 ? extra.join('&') : undefined
    return {
      goReal: window.sticky.things.goReal(thingId, sessionId, extraString),
      goTest: window.sticky.things.goTest(thingId, sessionId, undefined, extraString)
    }
  }

  getApplication (thing) {
    if (!thing) {
      return
    }
    const { applications } = this.state
    return typeof thing.applicationId === 'string' && applications.find(a => a.id === thing.applicationId)
  }

  async componentDidMount () {
    const { user } = this.props
    if (!user.federatedUserCan('things')) {
      return window.sticky.applications.blocks.showError('You do not have permission to work on stickies.')
    }

    const {
      thingId,
      view
    } = this.props.match.params
    log('[Route-thing] [componentDidMount]', { thingId, view })

    const thing = await window.sticky.things.get(thingId)
    const federatedUsers = await window.sticky.users.federated.getAll()
    const applications = await window.sticky.applications.getAll()
    const products = await window.sticky.products.getAll()
    const productCategories = await window.sticky.products.categories.getAll()
    this.setState({
      thing,
      applications,
      federatedUsers,
      products,
      productCategories,
      urls: this.getUrls(thingId, true)
    })
    this.subscriptions = [
      subscribe('GET_INPUT_GOOD', this.GET_INPUT_GOOD),
      subscribe(
        'SESSION_BECOME_RESET',
        () => {
          this.setState({
            urls: this.getUrls(thingId, true)
          })
        }
      )
    ]
  }

  async componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  GET_INPUT_GOOD ({ why, string }) {
    log('[Route-thing] [GET_INPUT_GOOD]', { why, string })
    why !== 'copy-link-thing' && this.onUpdate(why, string)
  }

  onUpdate (key, value) {
    log('[Route-thing] [onUpdate]', { key, value })
    let { thing } = this.state
    thing.patch({ [key]: value })
    this.setState({
      thing,
      hasMadeUpdate: true
    })
  }

  unlockSession () {
    window.sticky.things.unlockSession(this.state.thing.id)
      .then(thing => {
        this.setState({
          thing,
          hasMadeUpdate: true
        })
      })
  }

  async onSave ({ toPatchWith } = {}) {
    const { thing } = this.state
    log('[Route-thing] [onSave]', { thing, toPatchWith })
    toPatchWith && thing.patch(toPatchWith)
    return new Promise(resolve => {
      (async () => {
        try {
          await window.sticky.things.save(thing)
          this.setState(
            {
              hasMadeUpdate: false,
              urls: this.getUrls(thing.id, true)
            },
            resolve
          )
        } catch ({ message }) {
          dispatch('SHOW_MESSAGE', { message: <><p><strong>Hmm; that didn't work.</strong></p><p>{message}</p></>, canBeBadded: '' })
          this.setState(
            {
              hasMadeUpdate: false
            },
            resolve
          )
        }
      })()
    })
  }

  changeDesign () {
    log('[Route-thing] [changeDesign]')
    dispatch('GET_INPUT', { why: 'designId', string: this.state.thing.designId || 'unknown', hint: 'Type a new design ID:', selectAll: true })
  }

  render () {
    const {
      user,
      match
    } = this.props
    const {
      thing,
      hasMadeUpdate,
      urls
    } = this.state
    const {
      view
    } = match.params

    const asImage1 = thing && thing.designUrl
    const asImage2 = thing && window.sticky.things.getImage(thing.id)
    const debouncedProps = {
      onChange: (key, value) => this.onUpdate(key, value),
      // onDebouncedChange: this.onSave
    }

    const renderTabs = tabs
      .map(tab => {
        return {
          ...tab,
          name: tab.name && tab.name(this),
          to: tab.to(this),
          child: tab.child(this, { debouncedProps, urls, hasMadeUpdate })
        }
      })
      .filter(tab => tab.child)
    log('[Route-thing] [render]', { user, thing })

    return (
      <StyledRoute>
        <CustomHelmet
          title={thing && thing.name}
        />
        {!thing && <Loading />}
        {thing && <>
          <MainFrame
            user={user}
            autoUi={this.props.autoUi}
            aside={<>
              {thing.lockedSessionId && (
                <Banner mood='very-bad'>
                  <p>
                    This sticky is locked.
                  </p>
                  {thing.session && <Button
                    InlineIcon={dashboardIcons.teamMember}
                    onClick={() => {
                      dispatch('LOADING')
                      window.sticky.session.get(undefined, false, thing.lockedSessionId)
                        .then(session => {
                          dispatch('SESSION', { session })
                          dispatch('STOP_LOADING')
                        })
                        .catch(({ message }) => {
                          window.sticky.applications.blocks.showError(message, true)
                        })
                        .then(() => {
                          dispatch('STOP_LOADING')
                        })
                    }}
                  >
                    {thing.session.identifierUnique}
                  </Button>}
                  <Button
                    InlineIcon={dashboardIcons.void}
                    isSecondary
                    onClick={() => {
                      this.unlockSession()
                    }}
                  >
                    Unlock
                  </Button>
                </Banner>
              )}
              <User user={user} whichPart={match.path} toShow={['semantic-home']} autoUi={this.props.autoUi} />
              <hr />
              <div className='buttons'>
                <SaveButton
                  color='#f7b731'
                  canSave={hasMadeUpdate}
                  onSave={async () => {
                    hasMadeUpdate && await this.onSave()
                  }}
                />
              </div>
              <Box>
                <Form {...debouncedProps}>
                  <Input
                    name='name'
                    label='Name'
                    value={thing.name}
                    autocomplete='off'
                  />
                </Form>
              </Box>
              <Box className='as-image'>
                {!thing.isVirtual && <HotImage className='as-thing' src={asImage1} alt='Sticker image' />}
                {thing.isVirtual && <img className='as-qr' src={asImage2} alt='Sticker image' />}
                <Button
                  icon={icons.generic.edit}
                  isSecondary
                  onClick={this.changeDesign}
                />
                <p>
                  <strong><code>{thing.consumerIdentifierUrl}</code></strong>
                </p>
              </Box>
            </>}
            main={<>
              <HeaderBar text={`Stickies › ${thing.name}`} Icon={dashboardIcons.thing} user={user} />
              <Box>
                <TabBar
                  selectedTab={view}
                  tabs={renderTabs}
                />
              </Box>
            </>}
          />
        </>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
