import generic from './generic'
import inverted from './inverted'
import countries from './countries'
import align from './align'
import complexity from './complexity'

export default {
  generic,
  inverted,
  countries,
  align,
  complexity
}
