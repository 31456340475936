/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Modal } from '@openbox-app-shared'
import Events from '../../Events/Events'
import Event from '../../Events/Event'

const StyledModal = styled.div`
  .component--modal {
    max-width: 560px;
    padding: 2rem 1rem 1rem 1rem !important;
  }
`

function ThisModal ({ user, event, onClose }) {
  return (
    <StyledModal>
      <Modal onClose={onClose}>
        <Events>
          <Event user={user} event={event} allowGoTo={false} />
        </Events>
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, event }) => {
    setState({ EVENT: { event } })
  },
  render: function ThisAction ({ user, state, setState }) {
    const { EVENT } = state
    if (!EVENT) {
      return null
    }
    const { event } = EVENT
    const props = { user, event }
    return (
      <ThisModal
        {...props}
        onClose={() => setState({ EVENT: false })}
      />
    )
  }
}
