import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from './Box'
import H1 from './H1'

import { Details, Price } from '@openbox-app-shared'

const StyledPaymentInvoice = styled.div`
  -webkit-font-smoothing: auto;
  span, strong, strong > span, strong > span > span, .component--banner p {
    font-family: sans-serif;
  }
  .component--h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 200%;
    text-transform: uppercase;
  }
  p {
    white-space: pre;
    font-size: 0.9rem;
    line-height: 1.25rem;
  }
  p + p {
    margin-top: 1rem;
  }
  @media only screen {
    .component--box {
      width: 840px;
      height: 1188px;
    }
  }
  .component--box {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        // background-color: red;
        .component--details {
          width: 100%;
        }
        .component--details tr td {
          font-size: 0.85rem;
          line-height: 1.1rem;
        }
      }
    }
    .summary {
      white-space: pre;
    }
    .grid + .grid, .grid + * {
      margin-top: 1rem;
    }
    .component--details + .component--details {
      margin-top: 1rem;
    }
  }
  .question {
    margin-left: 1rem;
  }
  .answer {
    margin-left: 1rem;
  }
`

const DEFAULT_ADDRESS = `14 William Street
Brighouse
HD6 1HR
United Kingdom`

export default function PaymentInvoice ({
  payment,
  vatNumber = '357117008',
  forceVat = 120,
  dueDate
}) {
  const hasPaid = payment && typeof payment.sessionPaidAt === 'number'
  const details1 = new Map(payment ? [
    ['Invoice', `code:${payment.consumerIdentifier}`],
    ['Date', `date:${payment.originTime}`],
    ['Due', dueDate || (payment.dueDate > 0 ? `date:${payment.dueDate}` : 'On receipt')],
    hasPaid && ['Paid', `date:${payment.sessionPaidAt}`]
  ].filter(a => a) : [])

  let details2
  if (payment) {
    const totalVat = typeof forceVat === 'number' ? Math.floor(payment.total - (payment.total / (forceVat / 100))) : payment.VAT.total // HMRC VAT rules
    const totalExVat = typeof forceVat === 'number' ? payment.total - totalVat : payment.total - payment.VAT.total
    details2 = new Map([
      ['Subtotal', `price:${payment.originalTotal}/${payment.currency}`],
      payment.discount !== 0 && ['- Discount', `price:${payment.discount}/${payment.currency}`],
      vatNumber && ['→ Total ex tax', `price:${totalExVat}/${payment.currency}`],
      ...(vatNumber ? payment.VAT.niceRows.map(nr => [nr[0], `price:${nr[1]}/${payment.currency}`]) : []),
      vatNumber && ['→ Total tax', `price:${totalVat}/${payment.currency}`],
      [!hasPaid ? ' = To pay' : ' = Total', `price:${payment.total}/${payment.currency}`],
    ]
      .filter(a => a))
  }

  return (
    <StyledPaymentInvoice className='component--invoice'>
      <Box>
        <div className='grid'>
          <div>
            <H1>Invoice</H1>
            {payment.rawCart.length > 0 && <div className='summary'>
              {payment.rawCart.map((rc, rci) => {
                const questions = rc.questions || []
                const optionsTotal = window.sticky.sum(
                  questions
                    .map(q => {
                      const foundOptions = Array.isArray(q.answer) ? q.options.filter(o => q.answer.includes(o.name)) : [q.options.find(o => o.name === q.answer)]
                      return window.sticky.sum(foundOptions.map(_ => _.delta))
                    })
                ) * rc.quantity
                const displayTotal = (rc.productPrice * rc.quantity) - optionsTotal
                return (
                  <>
                    <p key={`rc--${rci}`}>
                      <strong>{rc.productName || '(Unknown product)'}</strong>
                      &nbsp;×&nbsp;
                      <strong>{rc.quantity}</strong>
                      &nbsp;=&nbsp;
                      <strong><Price price={displayTotal} currency={rc.productCurrency} /></strong>
                      {questions.length > 0 && <br />}
                      {questions.map((q, qi) => {
                        const foundOptions = Array.isArray(q.answer) ? q.options.filter(o => q.answer.includes(o.name)) : [q.options.find(o => o.name === q.answer)]
                        const extraPrice = window.sticky.sum(foundOptions.map(_ => _.delta))
                        return (
                          <Fragment key={`q--${qi}`}>
                            <strong className='question'>{q.question}</strong> {extraPrice > 0 && <>+<Price price={extraPrice * rc.quantity} currency={rc.productCurrency} /></>}
                            <span className='answer'>{q.answer}</span>
                            <br />
                          </Fragment>
                        )
                      })}
                    </p>
                    {rci < payment.rawCart.length -1 && <hr />}
                  </>
                )
              })}
            </div>}
          </div>
          <div>
            <Details details={details1} />
            <Details className='details-2' details={details2} />
          </div>
        </div>
      </Box>
    </StyledPaymentInvoice>
  )
}

PaymentInvoice.propTypes = {
  user: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  vatNumber: PropTypes.string,
  forceVat: PropTypes.number,
  dueDate: PropTypes.string,
  logo: PropTypes.string,
  bdIban: PropTypes.string,
  bdAccountNumber: PropTypes.string,
  bdSortCode: PropTypes.string,
  bdAddress: PropTypes.string,
  bdNameBank: PropTypes.string,
  fromName: PropTypes.string,
  fromAddress: PropTypes.string,
  fromEmail: PropTypes.string,
  toName: PropTypes.string,
  toAddress: PropTypes.string
}
