import React from 'react'
import PropTypes from 'prop-types'
import { Link, Time, Price } from '@openbox-app-shared'

import _ from '../_'
import ScrollableTable from './ScrollableTable'
import { PaymentSummary } from './payments'

export default function ThingPayments ({ user, payments }) {
  return (
    <ScrollableTable
      columns={{
        createdAt: '',
        total: '',
        for: '',
        status: ''
      }}
      rows={payments.map(tp => {
        return {
          createdAt: (
            <Link
              to={`/me/payments-user-private-key/${tp.id}`}
            >
              <Time
                isByDay
                time={tp.createdAt}
              />
            </Link>
          ),
          total: <strong><Price price={tp.total} currency={tp.currency} /></strong>,
          for: (tp.paymentGatewayId === 'MONTHLY' ? 'Plan' : _('GET_THINGS_MORE')),
          status: <PaymentSummary payment={tp} showGateway={false} />
        }
      })}
    />
  )
}

ThingPayments.propTypes = {
  user: PropTypes.object.isRequired,
  payments: PropTypes.arrayOf(PropTypes.object)
}