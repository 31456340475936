/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import classnames from 'classnames'
import styled from 'styled-components'

import { icons, Modal, UploadCsv, Banner, Button, LinkButton, Price } from '@openbox-app-shared'

import _ from '../../../_'
import { dispatch } from '../../../redux'

import Box from '../../../components/Box'
import H2 from '../../../components/H2'
import ScrollableTable from '../../../components/ScrollableTable'

const StyledComponent = styled.div`
  .component--modal {
    max-width: 768px;
  }
  .grid {
    > * + * {
      margin-top: 2rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
  .component--h2 {
    line-height: 1.5rem;
  }
  .how-to {
    .component--button {
      display: block;
    }
    .component--h2 {
      margin-top: 1rem;
    }
    > p, > ul {
      font-size: 90%;
    }
    > p {
      margin-top: 1.5rem;
    }
    > ul {
      margin-top: 1rem;
    }
  }
  .do-it {
    border: 2px solid #607481;
    .component--h2 {
      margin-bottom: 1.5rem;
    }
  }
  .component--banner {
    margin-bottom: 1rem;
  }
  .component--upload {
    margin-top: 0.5rem;
  }
  .component--table {
    width: 100%;
    margin-top: 0.5rem;
    td:nth-child(1) {
      width: 33%;
    }
    td:nth-child(2) {
      width: 34%;
    }
    td:nth-child(3) {
      width: 33%;
    }
  }
  .product-category + .product-category {
    margin-top: 1rem;
  }
  .not-in-a-category {
    background-color: #fad859;
    border: 2px solid #a48204;
    h2 {
      color: #a48204;
    }
    .component--table tbody td {
      background-color: white;
    }
  }
`

const COLUMNS = {
  name: 'Name',
  price: 'Price',
  description: 'Description',
  stock: 'Stock'
}

function ThisModal ({ user, onDone, onClose }) {
  const [error, setError] = useState()
  const [fromSdk, setFromSdk] = useState()
  const [stage, setStage] = useState(1)
  return (
    <StyledComponent>
      <Modal onClose={onClose}>
        {stage === 1 && (
          <div className='grid'>
            <div className='how-to'>
              <LinkButton to={`${window.sticky.cdn}/product-categories-template.csv`} isSecondary icon={icons.generic.download} download>
                Download CSV template
              </LinkButton>
              <p>
                Remember:
              </p>
              <ul className='plain'>
                <li>Don't change the columns</li>
                <li>
                  Don't use currency symbols in the price column
                  <ul className='plain'>
                    <li>Good: <code>32.00</code></li>
                    <li>Bad: <code>32.00 $</code></li>
                    <li>Bad: <code>£32</code></li>
                  </ul>
                </li>
                <li>The "Product category" column is optional</li>
                <li>The "Your ID" column is optional</li>
              </ul>
            </div>
            <Box className='do-it'>
              <H2>2) Upload</H2>
              {error && <Banner mood='very-bad'>
                <p>That didn't work. {error}.</p>
              </Banner>}
              <UploadCsv
                color='#a55eea'
                onChange={csv => {
                  window.sticky.products.fromCsv(csv)
                    .then(nowFromSdk => {
                      setFromSdk(nowFromSdk)
                      setStage(2)
                    })
                    .catch(e => {
                      setError(e.message)
                    })
                }}
              />
            </Box>
          </div>
        )}

        {stage === 2 && (
          <>
            <Banner mood='good'>
              <p>Do these look right?</p>
              <Button
                icon={icons.inverted.check}
                className='openbox--pulsing-2'
                onClick={() => onDone(fromSdk)}
              >
                Yes, import now
              </Button>
              <Button
                isSecondary
                onClick={() => {
                  setFromSdk(undefined)
                  setStage(1)
                }}
              >
                No, start again
              </Button>
            </Banner>
            {fromSdk.productCategories.map(fs => {
              return (
                <Box key={fs.name} className={classnames('product-category', { 'not-in-a-category': fs.dontCreateCategory })}>
                  <H2>{fs.name}</H2>
                  <ScrollableTable
                    columns={COLUMNS}
                    rows={fs.products.map(p => {
                      return {
                        name: p.name,
                        price: <Price currency={user.currency} price={p.price} />,
                        description: <p>{p.description}</p>,
                        stock: p.stock.toLocaleString()
                      }
                    })}
                  />
                </Box>
              )
            })}
          </>
        )}
      </Modal>
    </StyledComponent>
  )
}

export default {
  trigger: ({ setState, why }) => {
    setState({ PRODUCTS_IMPORT: { why } })
  },
  render: function PRODUCTS_IMPORT ({ user, state, setState }) {
    const { PRODUCTS_IMPORT } = state
    if (!PRODUCTS_IMPORT) {
      return null
    }
    const onClose = () => {
      setState({
        PRODUCTS_IMPORT: undefined
      })
    }
    return (
      <ThisModal
        user={user}
        onDone={fromSdk => {
          onClose()
          dispatch('PRODUCTS_IMPORT_GOOD', { fromSdk })
        }}
        onClose={onClose}
      />
    )
  }
}
