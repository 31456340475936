import React, { useEffect, useRef } from 'react'
const { v4: uuid } = require('uuid')

export default function InlineHtml ({ html }) {
  const ref = useRef()
  useEffect(
    () => {
      const { current: element } = ref
      const id = uuid()
      element.insertAdjacentHTML('afterend', html)
      element.nextSibling.id = id
      return () => {
        const foundElement = document.getElementById(id)
        foundElement && foundElement.remove()
      }
    },
    [html]
  )
  return (
    <div ref={ref} style={{ display: 'none' }}></div>
  )
}
