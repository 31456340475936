/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Button } from '@openbox-app-shared'
import Box from '../../Box'
import dashboardIcons from '../../../icons'

const StyledComponent = styled.div`
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  width: 16.25rem;
  p {
    margin-bottom: 1rem;
  }
`

export default {
  trigger: ({ setState, session }) => {
    setState({ SESSION_BECOME: { session } })
  },
  render: function ThisAction ({ state, setState, dispatch }) {
    const { SESSION_BECOME } = state
    if (!SESSION_BECOME) {
      return null
    }
    const { session } = SESSION_BECOME
    return (
      <StyledComponent>
        <Box>
          <p>
            You are acting as <strong>{session.identifierUnique}</strong>
          </p>
          <Button
            onClick={() => {
              setState({ SESSION_BECOME: undefined })
              dispatch('SESSION_BECOME_RESET')
            }}
            InlineIcon={dashboardIcons.void}
          >
            Stop
          </Button>
        </Box>
      </StyledComponent>
    )
  }
}
