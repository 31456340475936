import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DebouncedForm, Input, Button } from '@openbox-app-shared'
import Box from '../Box'
import H2 from '../H2'
import { dispatch } from '../../redux'

const StyledView = styled.div`
  .component--button.go-back {
    margin-bottom: 1rem;
  }
  .component--box {
    max-width: 420px;
    .component--h2 {
      margin-bottom: 0.5rem;
    }
  }
`

const isAllValid = (masterSettings, settings) => {
  return masterSettings.every(([,,settingValidator], index) => settingValidator(settings[index][1]))
}

export default function StateEdit ({ user, entity, gateway, setView, onSettingsValid, onSave }) {
  const masterSettings = gateway.settings.get(entity)
  let [settings, setSettings] = useState(masterSettings.map(([ settingName, settingValue ]) => ([settingName, settingValue])))

  const onCancel = () => setView('StateView')

  return (
    <StyledView>
      <Button
        onClick={onCancel}
        isSecondary
        className='go-back'
      >
        ← Go back
      </Button>
      <Box>
        <div className='buttons-up'>
          {user === entity && gateway.settings.isApplePayable && !user.stickypayApplePayEnabled && user.applePayPem && (
            <Button
              isSecondary isThin
              onClick={() => {
                onSave({
                  stickypayApplePayEnabled: true
                })
              }}
            >
              Turn on Apple Pay
            </Button>
          )}
          {user === entity && gateway.settings.isGooglePayable && !user.stickypayGooglePayEnabled && (
            <Button
              isSecondary isThin
              onClick={() => {
                onSave({
                  stickypayGooglePayEnabled: true
                })
              }}
            >
              Turn on Google Pay
            </Button>
          )}
          {user === entity && gateway.settings.isApplePayable && user.stickypayApplePayEnabled && (
            <Button
              isSecondary isThin
              onClick={() => {
                onSave({
                  stickypayApplePayEnabled: false
                })
              }}
            >
              Turn off Apple Pay
            </Button>
          )}
          {user === entity && gateway.settings.isApplePayable && user.stickypayApplePayEnabled && (
            <Button
              isSecondary isThin
              onClick={() => dispatch('GET_INPUT', { why: 'gateway--StateEdit--applePaySlug', string: user.applePaySlug, selectAll: true })}
            >
              Change Apple Pay slug
            </Button>
          )}
          {user === entity && gateway.settings.isGooglePayable && user.stickypayGooglePayEnabled && (
            <Button
              isSecondary isThin
              onClick={() => {
                onSave({
                  stickypayGooglePayEnabled: false
                })
              }}
            >
              Turn off Google Pay
            </Button>
          )}
        </div>
        <H2>{gateway.name} settings</H2>
        <DebouncedForm
          onChange={(key, value) => {
            settings = settings.map(s => {
              let [settingName, settingValue] = s
              if (settingName === key) {
                settingValue = value
              }
              return [settingName, settingValue]
            })
            setSettings(settings)
            const isReallyAllValid2 = isAllValid(masterSettings, settings)
            if (isReallyAllValid2) {
              gateway.settings.set(entity, settings)
              onSettingsValid(gateway)
              onSave()
            }
          }}
        >
          {masterSettings.map(([,, settingValidator, isEditable, isCode], index) => {
            const [settingName, settingValue] = settings[index]
            return (
              <Input
                key={settingName}
                name={settingName}
                label={settingName}
                value={settingValue}
                isValid={settingValidator(settingValue)}
                disabled={!isEditable}
                type={!isCode ? 'text': 'code'}
              />
            )
          })}
        </DebouncedForm>
      </Box>
    </StyledView>
  )
}
StateEdit.propTypes = {
  user: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  gateway: PropTypes.object,
  setView: PropTypes.func.isRequired,
  onSettingsValid: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
