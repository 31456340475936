import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@openbox-app-shared'

const StyledComponent = styled.div`
  > * {
    display: inline-block;
    vertical-align: top;
    height: 4.5rem;
    margin: 0;
    font-size: 0.75rem;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    box-shadow: none;
  }
`

export default function Dock ({ items, onChoose }) {
  return (
    <StyledComponent className='component--dock'>
      {items.map(i => {
        return (
          <Button
            onClick={() => onChoose(i.id)}
            key={i.id}
            isSecondary
            icon={i.icon}
            InlineIcon={i.InlineIcon}
            inlineIconString={i.inlineIconString}
            bigIcon
            backgroundColor='transparent'
            color='white'
          >
            {i.name}
          </Button>
        )
      })}
    </StyledComponent>
  )
}
Dock.propTypes = {
  items: PropTypes.array,
  onChoose: PropTypes.func
}
