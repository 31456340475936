import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { icons, LinkButton, Button } from '@openbox-app-shared'
import { dispatch, subscribe } from '../../../redux'
import dashboardIcons from '../../../icons'

import H2 from '../../../components/H2'
import TabBar from '../../../components/TabBar'
import SaveButton from '../../../components/SaveButton'

const StyledComponent = styled.div`
  > .component--h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .component--application-blocks {
    margin-top: 0;
  }
  > .buttons {
    > .component--button {
      vertical-align: top;
      display: inline-block;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
`

import TabSettings from './tabs/settings/settings'
import TabCode from './tabs/code'
import TabMore from './tabs/more'

export default function ApplicationBlock ({ view, user, applicationBlock, onHasMadeUpdate }) {
  const [hasMadeUpdate, setHasMadeUpdate] = useState(false)
  useEffect(() => {
    const subscriptions = [
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why }) => {
          why === `delete--${applicationBlock.id}` && (() => {
            dispatch('LOADING')
            window.sticky.applications.blocks.remove(applicationBlock)
              .then(() => {
                dispatch('STOP_LOADING')
                dispatch('REDIRECT', { to: '/developer/flow-steps' })
              })
              .catch(({ message }) => {
                dispatch('STOP_LOADING')
                window.sticky.applications.blocks.showError(`Sorry; that didn\'t work.\n\n${message}`, true)
              })
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === `application-block-publish--${applicationBlock.id}` && (() => {
            dispatch('LOADING')
            window.sticky.applications.blocks.publish(applicationBlock, string)
              .then(r => {
                dispatch('STOP_LOADING')
                window.sticky.applications.blocks.showMessage(r, undefined, true, true)
                onHasMadeUpdate()
              })
              .catch(e => {
                dispatch('STOP_LOADING')
                window.sticky.applications.blocks.showError(`That didn\'t work. ${e.message}`, true)
              })
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const disabled = !applicationBlock.hasUserId
  const context = {
    applicationBlock,
    disabled
  }

  function onSave () {
    window.sticky.applications.blocks.save(applicationBlock)
      .catch(() => {
        window.sticky.applications.blocks.showError('That didn\'t work.\n\nThis flow step is probably shared between multiple dashboards.', true)
      })
      .then(() => {
        setHasMadeUpdate(false)
      })
  }

  const genericTabProps = {
    context,
    onHasMadeUpdate: (k, v) => {
      applicationBlock.patch({ [k]: v })
      setHasMadeUpdate(true)
      onHasMadeUpdate()
    },
    onSave
  }

  return (
    <StyledComponent className='component--application-block'>
      <div className='buttons'>
        <LinkButton className='go-back' to='/developer/flow-steps' sameTab isSecondary>
          ← Back
        </LinkButton>
        {applicationBlock.hasUserId && <SaveButton
          onSave={onSave}
          canSave={hasMadeUpdate}
        />}
        {applicationBlock.hasUserId && user.partner && <Button
          icon={icons.inverted.upload}
          onClick={() => {
            dispatch('GET_INPUT', { why: `application-block-publish--${applicationBlock.id}`, hint: 'Enter your partner\'s private key:', string: '', doValidate: false })
          }}
        >
          Publish
        </Button>}
        {applicationBlock.hasUserId && <Button
          icon={icons.inverted.delete}
          backgroundColor='#ff3838'
          onClick={() => {
            dispatch('SURE_DELETE', { why: `delete--${applicationBlock.id}` })
          }}
        >
          Delete
        </Button>}
      </div>
      <H2>{applicationBlock.name}</H2>

      <TabBar
        selectedTab={view}
        tabs={[
          {
            id: 'settings',
            name: 'Settings',
            icon: icons.generic.appearance,
            to: `/developer/flow-steps/${applicationBlock.id}/settings`,
            child: <TabSettings {...genericTabProps} />
          },
          {
            id: 'code',
            name: 'Code',
            inlineIcon: dashboardIcons.deploy,
            to: `/developer/flow-steps/${applicationBlock.id}/code`,
            child: <TabCode {...genericTabProps} />
          },
          {
            id: 'more',
            name: '',
            inlineIcon: dashboardIcons.dotDotDot,
            to: `/developer/flow-steps/${applicationBlock.id}/more`,
            child: <TabMore {...genericTabProps} />
          }
        ]}
      />
    </StyledComponent>
  )
}
ApplicationBlock.propTypes = {
  view: PropTypes.string,
  user: PropTypes.object,
  applicationBlock: PropTypes.object,
  onHasMadeUpdate: PropTypes.func.isRequired
}
