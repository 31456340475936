import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

function OnePlayVideo ({ url, posterUrl, onDone }) {
  const [hasPlayed, setHasPlayed] = useState(false)
  const element = useRef()
  return (
    <video
      style={{ backgroundColor: 'black' }}
      ref={element}
      onClick={e => {
        if (hasPlayed) {
          onDone()
        } else {
          setHasPlayed(true)
          e.target.play()
        }
      }}
      playsInline
      loop
      poster={posterUrl}
    >
      <source src={`${url}#t=0.001`} />
    </video>
  )
}
OnePlayVideo.propTypes = {
  url: PropTypes.string,
  posterUrl: PropTypes.string,
  onDone: PropTypes.func
}

const StyledPayment = styled.div`
  .outcome--warning {
    margin: 0 auto 0 auto;
    margin-top: calc((100vh - min(100vh, 100vw)) / 2);
    width: 100vw;
    max-width: 100vh;
    height: 100vh;
    max-height: 100vw;
    line-height: min(100vh, 100vw);
    text-align: center;
    font-size: 2rem;
    background-color: #1A1F35;
    color: white;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .questions {
        .question {
          line-height: 3.25rem;
        }
      }
    }
  }
  .outcome--debug {
    position: absolute;
    top: 0;
    height: 1.5rem;
    width: 100%;
    left: 0;
    padding: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
    > div {
      margin: 0.25rem 0 0 0.25rem;
      width: fit-content;
      height: 1rem;
      line-height: 1rem;
      font-size: 75%;
      color: white;
    }
  }
  .outcome--media.image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .outcome--media.video {
    width: 100%;
    height: 100%;
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`

function getMedia (media, currentFederatedUser) {
  let newMedia = media.filter(ni => ni.showInLinearOperatorView)
  if (currentFederatedUser) {
    newMedia = newMedia.filter(ni => ni.federatedUserId === currentFederatedUser.id || (!ni.federatedUserId || ni.federatedUserId === 'all'))
  } else {
    newMedia = newMedia.filter(ni => !ni.federatedUserId || ni.federatedUserId === 'all')
  }
  return newMedia
}

function getProductMedia (currentCartItem, currentFederatedUser) {
  let newMedia = getMedia(currentCartItem.product.media, currentFederatedUser)
  currentCartItem.questions
    .forEach(question => {
      const foundProductQuestion = currentCartItem.product.questions.find(q => q.question === question.question)
      if (!foundProductQuestion) {
        return
      }
      question.options
        .forEach(option => {
          const foundProductQuestionOption = foundProductQuestion.options.find(o => o.name === option.name && o.name === question.answer)
          if (!foundProductQuestionOption) {
            return
          }
          newMedia = [...newMedia, ...getMedia(foundProductQuestionOption.media, currentFederatedUser)]
        })
    })
  return newMedia
}

export default function Payment ({ userPreferences, currentFederatedUser, payment, onDone }) {
  const [currentCartIndex, setCartIndex] = useState(0)
  const [currentMediaIndex, setImageIndex] = useState(0)
  const [showingThing, setShowingThing] = useState(false)

  const cart = payment.cart.get()
  const currentCartItem = typeof currentCartIndex === 'number' && cart[currentCartIndex]
  if (cart.length === 0) {
    return null
  }

  let allImages = []
  if (currentCartItem) {
    for (let i = 0; i < currentCartItem.quantity; i++) {
      if (currentCartItem.product) {
        allImages = [...allImages, ...getProductMedia(currentCartItem, currentFederatedUser)]
      }
    }
  }
  const currentMedia = typeof currentMediaIndex === 'number' && allImages[currentMediaIndex]

  const doProgress = () => {
    if (showingThing) {
      onDone()
    }
    if (
      (payment.thing && currentMediaIndex === allImages.length - 1 && currentCartIndex === cart.length - 1)
      ||
      (payment.thing && allImages.length === 0)
    ) {
      setImageIndex(false)
      setCartIndex(false)
      setShowingThing(true)
    } else {
      if (currentMediaIndex < allImages.length - 1) {
        setImageIndex(currentMediaIndex + 1)
      } else {
        if (currentCartIndex < cart.length - 1) {
          setImageIndex(0)
          setCartIndex(currentCartIndex + 1)
        } else {
          onDone()
        }
      }
    }
  }

  return (
    <StyledPayment
      className='component--payment'
      data-id={payment.id}
    >
      {/* {!isAppleWatch && <strong
        className='outcome--debug'
      >
        <div>
          <span style={{ color: '#EA2027' }}>{payment.descriptionLive}</span>
          &nbsp;•&nbsp;
          <Price currency={payment.currency} price={payment.total} />
          {typeof currentCartIndex === 'number' && <>&nbsp;•&nbsp;<span>cart item {currentCartIndex + 1}/{cart.length}</span></>}
          {typeof currentMediaIndex === 'number' && allImages.length > 0 && <>&nbsp;•&nbsp;<span>image {currentMediaIndex + 1}/{allImages.length}</span></>}
          {allImages.length === 0 && !showingThing && <span>&nbsp;•&nbsp;no images</span>}
        </div>
      </strong>} */}
      {!currentMedia && !showingThing && (
        <div className='outcome--warning' onClick={doProgress}>
          <p>
            {currentCartItem && currentCartItem.product && currentCartItem.product ? `${currentCartItem.quantity} × ${currentCartItem.product.name}` : 'No media for this product!'}
            <br />
            {Array.isArray(currentCartItem.questions) && currentCartItem.questions.length > 0 && <div className='questions'>
              {currentCartItem.questions
                .filter(ciq => Array.isArray(ciq.answer) ? ciq.answer.length > 0 : ciq.answer)
                .map(ciq => {
                  return (
                    <div className='question' key={ciq.question}>
                      {[true, undefined].includes(userPreferences.toDoShowQuestionQuestions) && <><strong>{ciq.question}</strong><br /></>}
                      {Array.isArray(ciq.answer) ? ciq.answer.join(', ') : ciq.answer}
                    </div>
                  )
                })}
            </div>}
          </p>
        </div>
      )}
      {currentMedia && currentMedia.type === 'image' && !showingThing && (
        <div onClick={doProgress} className='outcome--media image' style={{ backgroundImage: `url('${currentMedia.url}')` }} />
      )}
      {currentMedia && currentMedia.type === 'text' && !showingThing && (
        <div className='outcome--warning' onClick={doProgress}>
          <p>
            {currentMedia.text}
          </p>
        </div>
      )}
      {currentMedia && currentMedia.type === 'video' && !showingThing && (
        <div className='outcome--media video'>
          <OnePlayVideo
            url={currentMedia.url}
            posterUrl={currentMedia.posterUrl}
            onDone={doProgress}
          />
        </div>
      )}
      {showingThing && (
        <div className='outcome--warning' onClick={doProgress}>
          <p>
            {payment.thing.name}
          </p>
        </div>
      )}
    </StyledPayment>
  )
}
Payment.propTypes = {
  userPreferences: PropTypes.object,
  currentFederatedUser: PropTypes.object,
  payment: PropTypes.object.isRequired,
  thing: PropTypes.object,
  onDone: PropTypes.func
}
