import React, { createRef, Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { hotLoader, Button } from '@openbox-app-shared'
import H2 from './H2'
import Box from './Box'
import SaveButton from './SaveButton'
import { log } from '../log'
import dashboardIcons from '../icons'

const StyledComponent = styled.div`
  .controls {
    margin-bottom: 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
    }
    > * + * {
      margin-left: 1rem;
    }
    .component--h2 {
      height: 2.5rem;
      padding-left: 0.5rem;
      line-height: 2.5rem;
    }
    .buttons-ce2 {
      float: right;
      > * {
        display: inline-block;
        vertical-align: top;
      }
      > * + * {
        margin-left: 1rem;
      }
      .component--save-button {
        display: none;
      }
    }
  }
  .component--box {
    background-color: black;
    .component--h2 {
      color: white;
    }
  }
  .pre-container {
    background-color: #282c34;
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgb(60 66 87 / 12%), 0 1px 2px 0 rgb(0 0 0 / 12%);
    min-height: 25rem;
    pre {
      display: none;
      min-height: 24rem;
    }
  }
  &.is-maximized {
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: black;
    .component--box {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      height: 100vh;
      border-radius: 0;
    }
    .pre-container {
      height: calc(100vh - 5.5rem);
      pre {
        height: 100%;
      }
    }
  }
`

const LANGUAGES = new Map([
  [
    'text',
    {
      aceMode: 'ace/mode/text'
    }
  ],
  [
    'html',
    {
      aceMode: 'ace/mode/html'
    }
  ],
  [
    'css',
    {
      aceMode: 'ace/mode/css'
    }
  ],
  [
    'js',
    {
      aceMode: 'ace/mode/typescript'
    }
  ],
  [
    'json',
    {
      aceMode: 'ace/mode/json'
    }
  ]
])

export default class CodeEditor2 extends Component {
  constructor (props) {
    super(props)
    this.scRef = createRef()
    this.aceEditorRef = createRef()
  }

  shouldComponentUpdate (nextProps, nextState) {
    log('[CodeEditor2] [shouldComponentUpdate]', { props: this.props, nextProps, nextState })
    return false
  }

  async componentDidMount () {
    const { language = 'text', onChange, onControl } = this.props
    await hotLoader(`${window.sticky.cdn}/lib/ace/ace.js`)
    await hotLoader(`${window.sticky.cdn}/lib/ace/ext-language_tools.js`)

    const foundLanguage = LANGUAGES.get(language)
    this.aceEditor = ace.edit(
      this.aceEditorRef.current,
      {
        printMargin: 0,
        wrap: true
      }
    )
    this.aceEditor.setTheme('ace/theme/one_dark')
    this.aceEditor.session.setMode(foundLanguage.aceMode)
    this.aceEditor.session.setOptions({
      tabSize: 2,
      useSoftTabs: true
    })

    this.aceEditor.session.on('change', () => {
      const toChangeWith = this.aceEditor.getValue()
      onChange(toChangeWith)
      // log('[CodeEditor2] [componentDidMount] [aceEditor->change]', { toChangeWith })
    })
    this.aceEditor.commands.addCommand({
      name: 'myCommandSave',
      bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
      exec: () => {
        onControl && onControl('save', this.aceEditor.getValue())
      },
      readOnly: true
    })

    this.aceEditorRef.current.style.display = 'block'
  }

  componentWillUnmount () {
    if (!this.aceEditor) {
      return
    }
    this.aceEditor.destroy()
    this.aceEditor.container.remove()
  }

  render () {
    const { code = '', label, className, disabled, controls = ['expand'], onControl } = this.props
    return (
      <StyledComponent
        className={classnames('component--code-editor', className)}
        aria-disabled={disabled}
        ref={this.scRef}
      >
        <Box>
          {(controls.length > 0 || label) && <div className='controls'>
            <H2>{label}</H2>
            <div className='buttons-ce2'>
              {controls.includes('save') && (
                <SaveButton
                  onSave={() => onControl && onControl('save', this.aceEditor.getValue())}
                  color='#282c34'
                  doPulse={false}
                  canSave
                  onReady={({ setDisplay }) => {
                    this.setSaveButtonDisplay = setDisplay
                  }}
                />
              )}
              {controls.includes('expand') && <Button
                title='Maximise'
                backgroundColor='#282c34'
                InlineIcon={dashboardIcons.expand}
                onClick={() => {
                  const isMaximizedNow = this.scRef.current.classList.contains('is-maximized')
                  this.setSaveButtonDisplay && this.setSaveButtonDisplay(!isMaximizedNow ? 'inline-block' : 'none')
                  this.scRef.current.classList.toggle('is-maximized')
                  this.aceEditor.resize()
                }}
              />}
            </div>
          </div>}
          <div className='pre-container'>
            <pre ref={this.aceEditorRef}>{code}</pre>
          </div>
        </Box>
      </StyledComponent>
    )
  }
}
CodeEditor2.propTypes = {
  code: PropTypes.string,
  label: PropTypes.string,
  language: PropTypes.oneOf(['text', 'html', 'css', 'js', 'json']),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  controls: PropTypes.array,
  onControl: PropTypes.func
}
