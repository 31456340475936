import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled, { css } from 'styled-components'

import { Button, Label } from '@openbox-app-shared'

const StyledButtonList = styled.div`
  label {
    margin-bottom: 0.5rem;
  }
  div {
    display: grid;
    ${props => css`
      grid-gap: ${props.padding}rem;
      grid-template-columns: ${new Array(props.columns).fill(true).map(c => '1fr').join(' ')};
    `}
    .component--button {
      width: 100% !important;
    }
  }
`

export default function ButtonList ({ items, turnedOnColor = '#1A1F35', label, className, selected, columns = 3, padding = 1, onChoose, allowHtml = false, autoFocus, disabled, ...props }) {
  const realColumns = Math.min(items.length, columns)
  return (
    <StyledButtonList className={classnames('component--button-list', className)} columns={realColumns} padding={padding}>
      {label && <Label aria-disabled={disabled}>{label}</Label>}
      <div>
        {items.map((c, index) => {
          return (
            <Button
              key={`${c.id}--${c.name}`}
              turnedOn={selected === c.id && turnedOnColor}
              isSecondary
              onClick={() => onChoose(c.id)}
              icon={c.icon}
              InlineIcon={c.InlineIcon}
              inlineIconString={c.inlineIconString}
              className={c.className}
              hint={c.hint}
              backgroundColor={c.backgroundColor}
              {...props}
              disabled={disabled}
              autoFocus={autoFocus && index === 0}
            >
              {allowHtml ? <span dangerouslySetInnerHTML={{ __html: c.name }}></span> : null}
              {!allowHtml && c.name ? c.name : undefined}
            </Button>
          )
        })}
      </div>
    </StyledButtonList>
  )
}
ButtonList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  turnedOnColor: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.string,
  columns: PropTypes.number,
  padding: PropTypes.number,
  onChoose: PropTypes.func,
  allowHtml: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool
}
