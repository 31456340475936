/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { icons, CustomHelmet } from '@openbox-app-shared'
import Connections from '../../components/Connections'

const StyledComponent = styled.div`
  padding-top: 1rem;
`

function RenderChild ({ context, dbfProps }) {
  const { user } = context.props
  const { onChange } = dbfProps
  return (
    <StyledComponent>
      <CustomHelmet title={`Connections | Account | ${user.name}`} />
      <Connections
        allConnections={user.allConnections}
        connectedConnections={user.connections}
        onUpdate={connections => onChange('connections', connections)}
      />
    </StyledComponent>
  )
}

const tab = {
  id: 'connections',
  name: (context) => 'Connections',
  icon: icons.generic.connections,
  to: (context) => '/my/account/connections',
  child: props => <RenderChild {...props} />
}

export default tab
