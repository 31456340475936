import React, { useReducer } from 'react'
import styled from 'styled-components'

import { icons } from '@openbox-app-shared'

import AppearanceStuff from './AppearanceStuff'

const StyledTab = styled.div`
  .component--logo, .component--logo + .component--box, .component--box + div, div + .component--box, .component--banner + .component--patchable, .component--banner + .component--h2 {
    margin-top: 1rem;
  }
  .component--h2 {
    margin-bottom: 0.5rem;
  }
  .new-questions {
    .config--collection-time {
      margin-top: 1rem;
      .component--form {
        margin-top: 0.5rem;
        .times {
          .time {
            padding: 0.5rem;
            background-color: #F0F1F3;
            border-radius: 4px;
            position: relative;
            .component--time-picker {
              display: inline-block;
              width: auto;
            }
            .component--time-picker + .component--time-picker {
              margin-left: 1rem;
            }
            .component--button {
              position: absolute;
              bottom: 0.7rem;
              right: 0.5rem;
              width: 2.25rem;
              height: 2.25rem;
              border: 0;
            }
          }
          .time + .time {
            margin-top: 1rem;
          }
          > .component--button {
            margin-top: 1rem;
            width: fit-content;
          }
        }
        .component--input.type--number input {
          width: 6rem;
        }
        .component--day-picker {
          max-width: unset;
        }
        .component--day-picker + .component--input {
          margin-top: 0.5rem;
        }
      }
    }
  }
`

const tab = {
  id: 'setup',
  name: (context) => 'Setup',
  icon: icons.generic.appearance,
  to: (context) => `/me/flows/${context.state.application.id}/setup`,
  child: function Child (context, extraProps) {
    const { user } = context.props
    const { application } = context.state
    const paRender = AppearanceStuff.find(ap => ap.id === application.baseSettingsRender)
    if (!paRender || !paRender.Render) {
      return null
    }
    return (
      <StyledTab>
        <>
          <paRender.Render context={context} extraProps={extraProps} />
        </>
      </StyledTab>
    )
  }
}

export default tab
