import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Empty } from '@openbox-app-shared'

const StyledComponent = styled.ul`
  list-style-type: none;
  max-width: 640px;
  .component--event + .component--event {
    margin-top: 2rem;
  }
`

export default function Events ({ children }) {
  if (children.length === 0) {
    return (
      <Empty />
    )
  }
  return (
    <StyledComponent className='component--events'>
      {children}
    </StyledComponent>
  )
}
Events.propTypes = {
  children: PropTypes.node
}

