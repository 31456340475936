import React from 'react'
import classnames from 'classnames'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import H1 from './H1'
import H2 from './H2'

const StyledKPIs = styled.ul`
  list-style-type: none;
  width: fit-content;
  padding: 1rem 0 0 1rem;
  // background-color: blue;
  .component--kpi {
    display: inline-block;
    vertical-align: top;
    width: 16rem;
    height: 16rem;
    margin: 0 1rem 1rem 0;
    // background-color: red;
  }
`
export function KPIs ({ children, columns = 2 }) {
  return (
    <StyledKPIs className={classnames('component--kpis', `grid-${columns}`)} columns={columns}>
      {children}
    </StyledKPIs>
  )
}
KPIs.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.number
}

const StyledKPI = styled.li`
  position: relative;
  width: 100%;
  height: 16rem;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  > div {
    margin: 1rem;
    .value, .name {
      display: block;
      width: 100%;
      text-align: center;
    }
    .value {
      color: #1A1F35;
      font-size: 3rem;
      line-height: 8rem;
    }
    .name {
      margin-top: 1rem;
      color: #607481;
      line-height: 1.75rem;
    }
  }
`
export function KPI ({ value, name }) {
  return (
    <StyledKPI className='component--kpi'>
      <div>
        <H1 className='value'>{value}</H1>
        <H2 className='name'>{name}</H2>
      </div>
    </StyledKPI>
  )
}
KPI.propTypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.node.isRequired
}
