import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmailValid, isPhoneNumberValid, Input, DynamicInput, Form } from '@openbox-app-shared'
import { log } from '../../../log'

export default function CustomerDetails({ wizardState, onUpdate, controlCanGoNext, nextComponent }) {
  const canGoNext = o => [
    o.contactName.length > 0,
    isEmailValid(o.email),
    // isPhoneNumberValid(o.phone)
  ].every(e => e)

  useEffect(
    () => {
      canGoNext(wizardState) && controlCanGoNext(true)
    }
  )

  log('[Route-createUser][CustomerDetails] -> render', { canGoNext, wizardState })

  return (
    <Form
      onChange={(key, value) => {
        onUpdate({ [key]: value })
        controlCanGoNext(canGoNext({ ...wizardState, [key]: value }))
      }}
      onSubmit={nextComponent}
      autoFocus
    >
      <Input
        name='contactName'
        key='contactName'
        label='First and last name'
        value={wizardState.contactName}
        autocomplete='name'
        spellCheck={false}
        placeholder='John Smith'
        required
      />
      <Input
        name='email'
        key='email'
        label='Email'
        value={wizardState.email}
        autocomplete='email'
        spellCheck={false}
        placeholder='sales@acme.co'
        required
      />
      <Input
        name='name'
        key='name'
        label='Business name'
        value={wizardState.name}
        spellCheck={false}
        placeholder='Acme Co'
        required
      />
      {/* <DynamicInput
        type='phone'
        label='Phone'
        name='phone'
        value={wizardState.phone}
        placeholder='113 314 1024'
        doValidate={false}
        fireIfInvalid
        required
      /> */}
    </Form>
  )
}

CustomerDetails.propTypes = {
  wizardState: PropTypes.object,
  onUpdate: PropTypes.func,
  controlCanGoNext: PropTypes.func,
  nextComponent: PropTypes.func
}
