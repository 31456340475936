export function scrollTo (to = 0, element = window) {
  try {
    element.scrollTo(0, to)
  } catch (error) {}
}

export function scrollToTop (element) {
  if (typeof window === 'undefined') return
  scrollTo(0, element)
}

export function scrollToBottom (element) {
  if (typeof window === 'undefined') return
  scrollTo(document.body.scrollHeight, element)
}
