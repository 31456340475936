import React from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../../icons'
import _ from '../../../_'
import ChildrenAndInlineLinkFrame from '../../../components/ChildrenAndInlineLinkFrame'
import ProductsLikeAMenu from '../../../components/ProductsLikeAMenu'

const StyledComponent = styled.div`
  padding-top: 1rem;
`

const tab = {
  id: 'product',
  name: () => 'Product',
  inlineIcon: dashboardIcons.product,
  to: (context) => context.state.thing ? `/me/stickies/${context.state.thing.id}/product` : undefined,
  child: function Child(context, { urls }) {
    const { thing, products, productCategories } = context.state
    const { user } = context.props
    const foundApplication = context.getApplication(thing)
    const shouldRender = foundApplication && foundApplication.baseSettingsRender === 'stickyretail-product'
    if (!shouldRender) {
      return null
    }
    return <StyledComponent>
      <ChildrenAndInlineLinkFrame
        inlineLinkFrameProps={{
          link: urls.goTest,
          backgroundColor: foundApplication.backgroundColor
        }}
      >
        <ProductsLikeAMenu
          user={user}
          productCategories={productCategories}
          products={products}
          currentProductId={thing.productId}
          onChangeCurrentProductId={id => context.onUpdate('productId', id)}
          allowNone
        />
      </ChildrenAndInlineLinkFrame>
    </StyledComponent>
  }
}

export default tab
