/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import Stock from '../../../components/Stock'

import _ from '../../../_'
import { log } from '../../../log'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 16rem;
  }
`
function ThisModal ({ stock, stockNotify, onChange, onClose }) {
  const [localStock, setLocalStock] = useState(stock)
  const [localStockNotify, setLocalStockNotify] = useState(stockNotify)
  return (
    <StyledModal>
      <GenericModal
        onClose={onClose}
        goodText='Done'
        onGood={() => {
          onChange({ stock: localStock, stockNotify: localStockNotify })
        }}
      >
        <Stock
          stock={localStock}
          stockNotify={localStockNotify}
          onChange={(k, v) => {
            k === 'stock' && setLocalStock(v)
            k === 'stockNotify' && setLocalStockNotify(v)
          }}
        />
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, entity, stock, stockNotify }) => {
    setState({ STOCK: { why, entity, stock, stockNotify } })
  },
  render: function STOCK ({ state, setState }) {
    const { STOCK } = state
    if (!STOCK) {
      return null
    }

    const { why, entity, stock, stockNotify } = STOCK
    const onDone = (delta) => {
      const toDispatchWith = { why, entity, ...delta }
      log('[STOCK] [Modal->render->onDone] toDispatchWith', toDispatchWith)
      dispatch('STOCK_GOOD', toDispatchWith)
      setState({
        STOCK: undefined
      })
    }
    const onClose = () => {
      setState({
        STOCK: undefined
      })
    }

    return (
      <ThisModal
        stock={stock}
        stockNotify={stockNotify}
        onChange={onDone}
        onClose={onClose}
      />
    )
  }
}
