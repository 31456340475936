/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { icons, Time, TimeDifference, Button, Link, Banner, Table } from '@openbox-app-shared'

import H2 from '../../components/H2'
import Box from '../../components/Box'
import _ from '../../_'
import EventType from '../../components/Events/EventType'

const StyledReport = styled.div`
  .component--h2 + .component--table {
    margin-top: 0.5rem;
  }
  .component--table {
    margin-top: 1rem;
    thead th {
      width: 25%;
    }
    img {
      width: unset;
    }
    a {
      line-height: 1.25rem;
    }
  }
  .component--table + .component--box, .component--box + .component--box {
    margin-top: 1rem;
  }
`

function ReportChild ({ user, data, onRefresh }) {
  const [stuff, setStuff] = useState()
  useEffect(
    () => {
      async function getStuff () {
        setStuff({
          things: await window.sticky.things.getAll(),
          applications: await window.sticky.applications.getAll()
        })
      }
      !stuff && getStuff()
    },
    []
  )

  const COLUMNS_SUMMARY = {
    'session': '',
    'first': 'First',
    'second': 'Last',
    'timeDifference': 'Time between'
  }

  const enrichedData = data.bySession.map(bs => {
    return {
      ...bs,
      session: window.sticky.session.createClass({ crossUserSector: bs.crossUserSector })
    }
  })

  const COLUMNS_BY_SESSION = {
    type: '',
    createdAt: 'Time',
    thing: _('THING'),
    application: window.sticky._('APPLICATION'),
    actions: ''
  }

  return (
    <StyledReport>
      {enrichedData.length === 0 && (
        <Banner mood='bad'>
          <p>
            There were no "{data.eventName}"s during this time.
          </p>
        </Banner>
      )}
      {enrichedData.length > 0 && (
        <>
          <Table
            columns={COLUMNS_SUMMARY}
            rows={enrichedData.map(bs => {
              return {
                session: <a href={`#session--${bs.uniqueId}`}>{bs.session.identifierUnique}</a>,
                first: <Time key='en--1' time={bs.firstEventCreatedAt} />,
                second: typeof bs.lastEventCreatedAt === 'number' ? <Time key='en--2' time={bs.lastEventCreatedAt} /> : <strong style={{ color: '#FF3838' }}>—</strong>,
                timeDifference: <span style={{ color: typeof bs.lastEventCreatedAt !== 'number' ? '#FF3838' : undefined }}>
                  <TimeDifference key='en--3' time={bs.firstEventCreatedAt} timezone={user.timezone} now={bs.lastEventCreatedAt} />
                  {typeof bs.lastEventCreatedAt !== 'number' && <> so far</>}
                </span>
              }
            })}
          />
          {enrichedData.map(d => {
            return (
              <Box key={d.uniqueId} id={`session--${d.uniqueId}`}>
                <H2>{d.session.identifierUnique}</H2>
                <Table
                  columns={COLUMNS_BY_SESSION}
                  rows={d.events.map(e => {
                    const foundThing = stuff && stuff.things.find(t => t.id === e.thingId)
                    const foundApplication = stuff && stuff.applications.find(t => t.id === e.applicationId)
                    return {
                      type: <EventType event={e} user={user} />,
                      createdAt: <Time time={e.createdAt} />,
                      thing: foundThing ? <Link key='d--t-name' to={`/me/stickies/${foundThing.id}`}>{foundThing.name}</Link> : '—',
                      application: foundApplication ? <Link key='d--a-name' to={`/me/flows/${foundApplication.id}`}>{foundApplication.name}</Link> : '—',
                      actions: (
                        <>
                          <Button
                            title='Delete'
                            backgroundColor='#ff3838'
                            icon={icons.inverted.delete}
                            onClick={async () => {
                              await window.sticky.session.events.remove(e.id)
                              onRefresh && onRefresh()
                            }}
                          />
                        </>
                      )
                    }
                  })}
                />
              </Box>
            )
          })}
        </>
      )}
    </StyledReport>
  )
}

export default ReportChild
