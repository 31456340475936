/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Modal, Location } from '@openbox-app-shared'

const StyledModal = styled.div`
  .component--modal {
    max-width: 544px;
    padding: 3rem 1rem 1rem 1rem !important;
  }
`

function ThisModal ({ location, onClose }) {
  return (
    <StyledModal>
      <Modal onClose={onClose}>
        <Location location={location} showYouOnMap={false} isEditable={false} />
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, location }) => {
    setState({ LOCATION: { location } })
  },
  render: function ThisAction ({ state, setState }) {
    const { LOCATION } = state
    if (!LOCATION) {
      return null
    }
    const { location } = LOCATION
    const props = { location }
    return (
      <ThisModal
        {...props}
        onClose={() => setState({ LOCATION: false })}
      />
    )
  }
}
