import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { icons, Button } from '@openbox-app-shared'
import { dispatch } from '../redux'

const WAIT = 1 * 1000

export default function SaveButton ({ canSave, doPulse = true, color, onSave, onReady, handleErrors = false }) {
  const [liveState, setLiveState] = useState()
  const canReallySave = canSave && !liveState
  let timeout
  useEffect(
    () => {
      return () => {
        clearTimeout(timeout)
      }
    },
    []
  )
  return (
    <Button
      icon={icons.inverted.check}
      className={classnames('component--save-button', { 'openbox--pulsing-2': doPulse && canReallySave })}
      disabled={!canReallySave}
      backgroundColor={color}
      title='Save'
      onClick={async () => {
        setLiveState('in-progress')
        if (handleErrors) {
          try {
            await onSave()
          } catch ({ message }) {
            dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
          }
          setLiveState(undefined)
        } else {
          await onSave()
          setLiveState('live')
          timeout = setTimeout(() => setLiveState(undefined), WAIT)
        }
      }}
      onReady={onReady}
    >
      {!liveState && 'Save'}
      {liveState === 'in-progress' && 'Saving...'}
      {liveState === 'live' && 'Saved!'}
    </Button>
  )
}
SaveButton.propTypes = {
  canSave: PropTypes.bool,
  doPulse: PropTypes.bool,
  color: PropTypes.string,
  onSave: PropTypes.func,
  onReady: PropTypes.func,
  handleErrors: PropTypes.bool
}
