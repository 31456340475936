import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@openbox-app-shared'

export default function CoolButton ({ color, backgroundColor, ...props }) {
  const style = {
    borderColor: color
  }
  return (
    <Button {...props} isSecondary backgroundColor={backgroundColor || 'transparent'} color={color} style={style} />
  )
}
CoolButton.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string
}
