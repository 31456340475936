import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@openbox-app-shared'

export default function GtinInput ({ onReady, onInputted }) {
  const [input, setInput] = useState('')
  return (
    <Input
      value={input}
      onChange={(v) => setInput(v)}
      onDone={(v) => {
        onInputted && onInputted(v)
        setInput('')
      }}
      onReady={({ autoFocus }) => {
        onReady(autoFocus)
      }}
      className='component--input-gtin'
    />
  )
}
GtinInput.propTypes = {
  onReady: PropTypes.func,
  onInputted: PropTypes.func
}
