import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@openbox-app-shared'

const StyledComponent = styled.div`
  .buttons {
    text-align: center;
    .component--button {
      display: inline-block;
      font-size: 125%;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      &.is-secondary {
        background-color: transparent;
        border-color: transparent;
      }
      img, svg {
        width: 1.6rem;
        height: 1.6rem;
      }
      strong {
        line-height: 1.6rem;
      }
    }
    .component--button + .component--button {
      margin-left: 1rem;
    }
  }
`

export default function UltimateWizard ({ steps, onChangeStep, onDone }) {
  const [currentStep, setCurrentStep] = useState(0)
  const onPrevious = () => {
    setCurrentStep(currentStep - 1)
    onChangeStep && onChangeStep(currentStep - 1)
  }
  const onNext = () => {
    setCurrentStep(currentStep + 1)
    onChangeStep && onChangeStep(currentStep + 1)
  }
  const { child: CurrentStepChild, canGoNext, canGoPrevious, canGoFinish } = steps[currentStep]
  const howManyStepsNext = canGoNext ? canGoNext() : 0
  const howManyStepsPrevious = canGoPrevious ? canGoPrevious() : 0
  const canReallyGoFinish = canGoFinish && canGoFinish()
  return (
    <StyledComponent className={`component--ultimate-wizard step-${currentStep}`}>
      <CurrentStepChild onNext={onNext} />
      {(canGoNext || canGoPrevious) && (
        <div className='buttons'>
          {canGoPrevious && (
            <Button onClick={onPrevious} isSecondary inlineIconString={window.sticky.internals.icons.get('arrowLeft')} disabled={howManyStepsPrevious === 0}>{_('BACK')}</Button>
          )}
          {canGoNext && !canReallyGoFinish && (
            <Button onClick={onNext} iconToRight inlineIconString={window.sticky.internals.icons.get('arrowRight')} disabled={howManyStepsNext === 0} className={howManyStepsNext > 0 ? 'openbox--pulsing-2' : undefined}>{_('NEXT')}</Button>
          )}
          {canReallyGoFinish && (
            <Button onClick={onDone} inlineIconString={window.sticky.internals.icons.get('check')} className='openbox--pulsing-2'>{_('FINISH')}</Button>
          )}
        </div>
      )}
    </StyledComponent>
  )
}
UltimateWizard.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeStep: PropTypes.func,
  onDone: PropTypes.func.isRequired
}
