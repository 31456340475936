import { log } from '../../log'

// const THINGS = {
//   'things--generic': {
//     'GBP': 'dca0bf89-0b90-412a-9dd0-16ef0b11835a',
//     'USD': 'dca0bf89-0b90-412a-9dd0-16ef0b11835a',
//     'EUR': 'dca0bf89-0b90-412a-9dd0-16ef0b11835a',
//     'ZAR': '8001821d-a233-47b9-84f7-f1dc534e5273',
//     'NGN': 'fc648d96-d165-4b97-a702-d836660c2c4b'
//   },
//   'things--custom': {
//     'GBP': 'e59ad7ed-17fc-4015-9ea3-b40e236847a5',
//     'USD': 'e59ad7ed-17fc-4015-9ea3-b40e236847a5',
//     'EUR': 'e59ad7ed-17fc-4015-9ea3-b40e236847a5',
//     'ZAR': '6598b946-70fa-40ce-8877-46817b24b645',
//     'NGN': '707e67f8-005c-41ed-a319-27673c908410'
//   },
//   'accessories': {
//     'GBP': '9aab92b5-ec8b-4f5d-ae66-a37d6391f34d'
//   }
// }

const THINGS = {
  'GBP': 'b5856730-111c-46a5-a6c9-06f0dd9612db',
  'USD': 'fa0fa4f5-d9f7-48b9-8b5b-594c1c2645b2',
  'EUR': '233633d9-adb5-4216-8d01-ab4ba5833d39'
}

export default async function action (state, { user }) {
  log('[ACTION->GET_THINGS]', { user })
  window.sticky.popUpIframe({
    src: sticky.things.goTest((user.partner && user.partner.recommendedThingId) ? user.partner.recommendedThingId : THINGS[user.currency] || THINGS['GBP'], undefined, user.privateKey),
    maxWidth: '376px',
    maxHeight: '680px'
  })
}
