import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet, Loading } from '@openbox-app-shared'
import PaymentInvoice from '../../components/PaymentInvoice'

import { log } from '../../log'
import _ from '../../_'

const StyledRoute = styled.div`
  padding: 1rem;
`

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      payment: undefined
    }
  }

  async componentDidMount () {
    const {
      paymentId
    } = this.props.match.params
    log('[Route-Stickypay-paymentInvoice] [componentDidMount] 1', { paymentId })

    const payment = await window.sticky.pay.get(paymentId)

    log('[Route-Stickypay-paymentInvoice] [componentDidMount] 2', { paymentId, payment })
    this.setState({
      payment
    })
  }

  render () {
    const {
      user
    } = this.props
    const {
      payment
    } = this.state
    log('[Route-Stickypay-paymentInvoice] [render]', { user, payment })

    return (
      <StyledRoute>
        <CustomHelmet
          title='Invoice'
        />
        {!payment && <Loading />}
        {payment && <PaymentInvoice
          payment={payment}
          vatNumber={user.vat}
          forceVat={null}
        />}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  paymentId: PropTypes.string
}
