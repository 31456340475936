/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import ProductTagsVat from '../../../components/ProductTagsVat'

import _ from '../../../_'
import { log } from '../../../log'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 320px;
  }
`

function ThisModal ({ value, onDone, onClose }) {
  const [managedValue, setManagedValue] = useState(value)
  return (
    <StyledModal>
      <GenericModal
        onGood={() => onDone(managedValue)}
        onClose={onClose}
      >
        <ProductTagsVat
          value={managedValue}
          onUpdate={v => {
            setManagedValue(v)
          }}
        />
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, value }) => {
    setState({ PRODUCT_CHANGE_TAGS_VAT: { why, value } })
  },
  render: function PRODUCT_CHANGE_TAGS_VAT ({ state, setState }) {
    const { PRODUCT_CHANGE_TAGS_VAT } = state
    if (!PRODUCT_CHANGE_TAGS_VAT) {
      return null
    }
    const { why, value } = PRODUCT_CHANGE_TAGS_VAT
    const onDone = value => {
      const toDispatchWith = { why, value }
      log('[PRODUCT_CHANGE_TAGS_VAT] [Modal->render->onDone] toDispatchWith', toDispatchWith)
      dispatch('PRODUCT_CHANGE_TAGS_VAT_GOOD', toDispatchWith)
      setState({
        PRODUCT_CHANGE_TAGS_VAT: undefined
      })
    }
    const onClose = () => {
      setState({
        PRODUCT_CHANGE_TAGS_VAT: undefined
      })
    }
    return (
      <ThisModal
        value={value}
        onDone={onDone}
        onClose={onClose}
      />
    )
  }
}
