/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Modal } from '@openbox-app-shared'

const StyledModal = styled.div`
  .component--modal {
    width: calc(100% - 0.25rem);
    max-width: 480px;
    padding: 2.5rem 0.25rem 0.25rem 0.25rem !important;
    border-radius: 8px;
  }
  .component--modal.is-dark {
    background-color: black;
    .icon--close {
      color: white;
    }
  }
  iframe {
    display: block;
    width: 100%;
    border-radius: 4px;
    height: 612px;
    max-height: calc(100vh - 5.2rem);
  }
`

function ThisModal ({ url, width, isDark, onClose }) {
  return (
    <StyledModal>
      <Modal onClose={onClose} className={isDark && 'is-dark'} style={{ maxWidth: typeof width === 'number' ? `${width}px` : undefined }}>
        <iframe src={url} title='' />
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, url, width, isDark }) => {
    setState({ FRAME: { url, width, isDark } })
  },
  render: function ThisAction ({ state, setState }) {
    const { FRAME } = state
    if (!FRAME) {
      return null
    }
    const { url, width, isDark } = FRAME
    const props = { url, width, isDark }
    return (
      <ThisModal
        {...props}
        onClose={() => setState({ FRAME: false })}
      />
    )
  }
}
