import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TabBar from '../../../../components/TabBar'
import dashboardIcons from '../../../../icons'
import _ from '../../../../_'

import { List, ListItem, Price } from '@openbox-app-shared'

const StyledPayments = styled.div`
  position: relative;
  .component--list {
    margin-top: 1rem;
  }
`

function getTags (p, user) {
  const gateway = window.sticky.Stickypay.getGateway(p.gateway)

  let priceTagPrefix
  let priceTagColor = '#6C7A89'
  let priceTagPrice = p.total
  let showSince = true
  if (typeof p.sessionPaidAt === 'number') {
    priceTagPrefix = 'P '
    priceTagColor = '#40cf7a'
    showSince = false
  }
  if (typeof p.refundedAt === 'number') {
    priceTagPrefix = 'R '
    priceTagColor = '#ff3838'
    priceTagPrice = p.refundedTotal
  }

  const tags = []
  const timeSince = window.sticky.dateTime.getTimeSince(p.time, user.timezone, ['h', 'm'])
  showSince && timeSince.length > 0 && tags.push({ name: timeSince, color: '#A9B7C6' })
  p.newStatusDone === true && tags.push({ name: 'Complete', color: '#322CBE' })
  gateway && gateway.isExternal && gateway.isExternal(user) && tags.push({ id: 'how-paid--gateway', name: gateway.name, color: '#90a4ae' })
  tags.push({ name: <>{priceTagPrefix}<Price price={priceTagPrice} currency={p.currency} /></>, color: priceTagColor })
  return tags
}

const RealList = ({ user, paymentsToRender, currentPayment, onChoosePayment }) => {
  if (paymentsToRender.length === 0) {
    return null
  }
  return (
    <List>
      {paymentsToRender.map(p => {
        const tags = getTags(p, user)
        return (
          <ListItem
            id={p.id}
            key={p.id}
            onChoose={() => onChoosePayment(p)}
            selected={currentPayment === p}
            InlineIcon={dashboardIcons.payment}
            tags={tags}
            tagBuffer
          >
            {p.descriptionPos}
          </ListItem>
        )
      })}
    </List>
  )
}
RealList.propTypes = {
  user: PropTypes.object,
  paymentsToRender: PropTypes.arrayOf(PropTypes.object),
  currentPayment: PropTypes.object,
  onChoosePayment: PropTypes.func
}

export default function Payments ({ things, user, payments, currentPayment, currentThing, onChooseThing, onChoosePayment, selectedTab, onChangeSelectedTab }) {
  const allProps = {
    user,
    currentPayment,
    currentThing,
    onChoosePayment
  }
  const paymentsUnpaid = payments.filter(p => p.isUnpaid && !p.newMaster)
  const paymentsPaid = payments.filter(p => !p.isUnpaid)
  const tabs = [
    paymentsUnpaid.length > 0 && {
      id: 'payments--unpaid',
      name: <span style={{ color: '#FF3838' }}>Unpaid</span>,
      child: <RealList {...allProps} paymentsToRender={paymentsUnpaid} />
    },
    paymentsPaid.length > 0 && {
      id: 'payments--paid',
      name: <span style={{ color: '#40CF7A' }}>Paid</span>,
      child: <RealList {...allProps} paymentsToRender={paymentsPaid} />
    },
    things.length > 0 && {
      id: 'things',
      name: 'By sticky',
      inlineIcon: dashboardIcons.thing,
      child: (() => {
        let listItems = []
        things.forEach(thing => {
          const paymentsUnpaid = payments.filter(p => p.thingId === thing.id && p.isUnpaid && !p.newMaster)
          const totalUnpaid = window.sticky.sum(
            paymentsUnpaid
              .filter(p => !p.newMaster)
              .map(p => p.total)
          )
          const totalUnpaidCurrency = paymentsUnpaid.length ? paymentsUnpaid[0].currency : undefined
          // const paymentsPaid = payments.filter(p => p.thingId === thing.id && !p.isUnpaid)
          // {
          //   name: `${paymentsPaid.length} paid`,
          //   color: '#40CF7A'
          // }
          if (paymentsUnpaid.length === 0) {
            return
          }
          const tags = [
            {
              name: <Price price={totalUnpaid} currency={totalUnpaidCurrency} />,
              color: '#FF3838'
            }
          ]
          const paymentsToShoveOnEnd = currentThing === thing ? paymentsUnpaid.map((p, i) => {
            const iName = p.newMaster ? <>Pay all for <Price price={p.total} currency={p.currency} /></> : (p.name || `Part ${paymentsUnpaid.length - i}`)
            return (
              <ListItem
                key={p.id}
                id={p.id}
                tags={getTags(p, user)}
                onChoose={() => onChoosePayment(currentPayment !== p ? p : undefined)}
                selected={currentPayment === p}
                InlineIcon={dashboardIcons.payment}
                indentationOuter={36}
                tagBuffer
              >
                {iName}
              </ListItem>
            )
          }) : []
          listItems = [
            ...listItems,
            <ListItem
              key={thing.id}
              id={thing.id}
              icon={thing.designUrl}
              onChoose={() => onChooseThing(thing)}
              selected={currentPayment ? false : currentThing === thing}
              tags={tags}
              tagBuffer
            >
              {thing.name}
            </ListItem>,
            ...paymentsToShoveOnEnd
          ]
        })
        return (
          <List>
            {listItems}
          </List>
        )
      })()
    }
  ]
    .filter(e => e)
  return (
    <StyledPayments className='component--epos-payments'>
      <TabBar tabs={tabs} selectedTab={selectedTab} onClick={onChangeSelectedTab} />
    </StyledPayments>
  )
}

Payments.propTypes = {
  things: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object,
  payments: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPayment: PropTypes.object,
  currentThing: PropTypes.object,
  onChooseThing: PropTypes.func,
  onChoosePayment: PropTypes.func,
  selectedTab: PropTypes.string,
  onChangeSelectedTab: PropTypes.func
}
