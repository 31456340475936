/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import TagChooserUser from '../../TagChooserUser'
import GenericModal from '../../modals/generic'

const StyledModal = styled.div`
  .component--modal {
    max-width: 544px;
    padding: 3rem 1rem 1rem 1rem !important;
  }
`

class ThisModal extends React.Component {
  render () {
    const { user, tags, onGood, onClose, chooseOnlyOne } = this.props
    return (
      <StyledModal>
        <GenericModal
          onGood={onGood}
          onClose={onClose}
          badText=''
          goodText={chooseOnlyOne ? null : undefined}
        >
          <TagChooserUser
            user={user}
            tags={tags}
            onUpdate={(id) => {
              if (chooseOnlyOne) {
                if (tags.has(id)) {
                  tags.clear()
                } else {
                  tags.clear()
                  tags.add(id)
                }
                onGood()
              } else {
                tags.toggle(id)
                this.forceUpdate()
              }
            }}
            canManage={false}
          />
        </GenericModal>
      </StyledModal>
    )
  }
}

export default {
  trigger: ({ setState, why, tags, entity, chooseOnlyOne }) => {
    setState({ CHOOSE_TAGS: { why, tags, entity, chooseOnlyOne } })
  },
  render: function ThisAction ({ state, setState, dispatch, user }) {
    const { CHOOSE_TAGS } = state
    if (!CHOOSE_TAGS) {
      return null
    }
    const { why, tags, entity, chooseOnlyOne } = CHOOSE_TAGS
    const props = { user, tags, chooseOnlyOne }
    return (
      <ThisModal
        {...props}
        onGood={() => {
          dispatch('CHOOSE_TAGS_GOOD', { why, tags, entity })
          setState({ CHOOSE_TAGS: false })
        }}
        onClose={() => {
          setState({ CHOOSE_TAGS: false })
        }}
      />
    )
  }
}
