/* eslint-disable react/prop-types */

import React from 'react'
import _ from '../../../_'
import { dispatch } from '../../../redux'

export default {
  trigger: async ({ trigger, showMessage = true, body, forcePublicKey }) => {
    dispatch('LOADING')
    try {
      const r = await window.sticky.internals.trigger(trigger, body, forcePublicKey)
      showMessage && dispatch(
        'SHOW_MESSAGE',
        {
          message: <p>{r}</p>,
          canBeBadded: '',
          why: trigger
        }
      )
      dispatch('TRIGGER_GOOD', { trigger, body })
    } catch (e) {
      const { message } = e
      showMessage && dispatch(
        'SHOW_MESSAGE',
        {
          message: (
            <p>
              {message}
            </p>
          ),
          canBeBadded: '',
          why: trigger
        }
      )
      dispatch('TRIGGER_BAD', { trigger, body, message })
    }
    dispatch('STOP_LOADING')
  },
  render: function TRIGGER () {
    return (<></>)
  }
}
