import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ExpandCollapse } from '@openbox-app-shared'

const CATEGORIES = () => [
  // {
  //   name: 'Seasonal',
  //   items: [
  //     // { id: 'item--seasonal-halloween-pumpkin', name: 'Pumpkin' },
  //     // { id: 'item--seasonal-halloween-ghost', name: 'Ghost' },
  //     // { id: 'item--seasonal-halloween-zombie', name: 'Zombie' },
  //     // { id: 'item--seasonal-fireworks', name: 'Fireworks' },

  //     // { id: 'item--seasonal-christmas-santa', name: 'Santa' },
  //     // { id: 'item--seasonal-christmas-snowperson', name: 'Snowperson' },
  //     // { id: 'item--seasonal-christmas-tree', name: 'Christmas tree' },
  //     // { id: 'item--seasonal-lgbt', name: 'LGBT+' }
  //   ].map(({ id, name }) => ({ type: 'image', name, url: `${window.sticky.cdn}/items/${id}.gif` }))
  // },
  {
    name: 'Items',
    items: [
      { id: 'item--star', name: 'Star' },
      { id: 'item--medal-1', name: 'Gold medal' },
      { id: 'item--medal-2', name: 'Silver medal' },
      { id: 'item--medal-3', name: 'Bronze medal' },

      { id: 'item--dog', name: 'Dog' },
      { id: 'item--monkey', name: 'Monkey' },
      { id: 'item--snake', name: 'Snake' },
      { id: 'item--heart', name: 'Heart' },

      // { id: 'item--fizz-bottle', name: 'Bottle of fizz' },
      { id: 'item--fizz-glasses', name: 'Glass of fizz' },
      { id: 'item--beer', name: 'Beer' },
      { id: 'item--hot-drink', name: 'Hot drink' },
      { id: 'item--ticket', name: 'Ticket' }
      // { id: 'item--strength', name: 'Strength' },
      // { id: 'item--pie', name: 'Pie' },
      // { id: 'item--rocket', name: 'Rocket' }
    ].map(({ id, name }) => ({ type: 'image', name, url: `${window.sticky.cdn}/items/${id}.png` }))
  },
  {
    name: 'Characters',
    items: [
      { id: 'character--superhero-f', name: 'Superhero' },
      { id: 'character--superhero-m', name: 'Superhero' },
      { id: 'character--ninja', name: 'Ninja' },
      { id: 'character--teacher-f', name: 'Teacher' },
      { id: 'character--teacher-m', name: 'Teacher' },
      { id: 'character--santa', name: 'Santa' },
      { id: 'character--surfer-f', name: 'Surfer' },
      { id: 'character--surfer-m', name: 'Surfer' },
      { id: 'character--dancing', name: 'Dancer' }
    ].map(({ id, name }) => ({ type: 'image', name, url: `${window.sticky.cdn}/characters/${id}.png` }))
  },
  {
    name: 'Products',
    items: [
      'product--beef-burger',
      'product--chicken-burger',
      'product--fish-and-chips',
      'product--ham-egg-and-chips',
      'product--asparagus-soup',
      'product--sticky-toffee-pudding',
      'product--amstel',
      'product--red-wine',
      'product--aperol-spritz',
      'product--water',
      'product--popcorn',
      'product--coke'
    ].map(i => ({ type: 'image', name: i.substring('product--'.length), url: `${window.sticky.cdn}/product-images/${i}.jpg` }))
  },
  {
    name: 'Amounts (GBP)',
    items: [
      'gbp-010', 'gbp-020', 'gbp-050', 'gbp-1', 'gbp-2', 'gbp-5', 'gbp-10', 'gbp-20', 'gbp-50', 'gbp-100', 'gbp-200', 'gbp-500'
    ].map(e => ({ type: 'image', url: `${window.sticky.cdn}/amounts/amount-${e}.png` }))
  },
  {
    name: 'Amounts (USD)',
    items: [
      'usd-010', 'usd-020', 'usd-050', 'usd-1', 'usd-2', 'usd-5', 'usd-10', 'usd-20', 'usd-50', 'usd-100', 'usd-200', 'usd-500'
    ].map(e => ({ type: 'image', url: `${window.sticky.cdn}/amounts/amount-${e}.png` }))
  },
  {
    name: 'Amounts (EUR)',
    items: [
      'eur-010', 'eur-020', 'eur-050', 'eur-1', 'eur-2', 'eur-5', 'eur-10', 'eur-20', 'eur-50', 'eur-100', 'eur-200', 'eur-500'
    ].map(e => ({ type: 'image', url: `${window.sticky.cdn}/amounts/amount-${e}.png` }))
  }
]

const StyledComponent = styled.div`
  .component--expand-collapse + .component--expand-collapse {
    margin-top: 1rem;
  }
  .component--expand-collapse > div {
    padding: 0 0 0 1rem;
    > * {
      display: inline-block;
      width: 53px !important;
      height: 53px;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
      padding: 0.25rem;
      background-color: white;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`

export default function ChooseResource ({ type, categories, onChoose }) {
  return (
    <StyledComponent className='component--choose-resource'>
      {CATEGORIES()
        .filter(c => Array.isArray(categories) ? categories.includes(c.name) : true)
        .map((category, ci) => {
          return (
            <ExpandCollapse
              text={category.name}
              key={category.name}
              isCollapsed={ci > 0}
            >
              {category.items
                .filter(spi => (typeof type === 'text') ? spi.type === type : true)
                .map((spi, i) => {
                  return (
                    <Button
                      isSecondary
                      key={`sample-${i}`}
                      onClick={() => {
                        onChoose(spi)
                      }}
                      icon={spi.type === 'image' ? spi.url : undefined}
                    />
                  )
                })}
            </ExpandCollapse>
          )
        })}
    </StyledComponent>
  )
}
ChooseResource.propTypes = {
  type: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
  onChoose: PropTypes.func.isRequired
}
