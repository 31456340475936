import { log } from '../../log'

export default async function action (state, data) {
  log('[ACTION->SAVE_USER_PREFERENCES]', { state, data })
  const entity = await window.sticky.users.getMe()
  entity.preferences.patch({ ...entity.preferences.get(), ...data })
  entity.patch({ preferences: entity.preferences })
  await window.sticky.users.save(undefined, ['preferences'])
  state = {
    ...state
  }
}
