import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled, { css } from 'styled-components'

const StyledComponent = styled.div`
  > * {
    width: 100%;
    height: 4rem;
    overflow: hidden;
    padding: 0.125rem;
    font-size: 140%;
    > * {
      line-height: 1.8rem;
    }
  }
  ${props => css`
    padding: ${props.$padding}rem;
    > * + * {
      margin-top: ${props.$padding}rem;
    }
    @media only screen and (min-width: ${(props.$columns * props.$buttonSize) + (props.$columns - 1)}rem) {
      padding: ${props.$padding}rem 0 0 ${props.$padding}rem;
      > * {
        display: inline-block;
        vertical-align: top;
        margin: 0 ${props.$padding}rem ${props.$padding}rem 0;
        width: ${props.$buttonSize}rem;
        height: ${props.$buttonSize}rem;
      }
      > * + * {
        margin-top: 0;
      }
    }
  `}
`

const ButtonGrid = ({ children, columns = 3, padding = 1, className, buttonSize = 10 }) => {
  return (
    <StyledComponent className={classnames('component--button-grid', className)} $padding={padding} $columns={columns} $buttonSize={buttonSize}>
      {children}
    </StyledComponent>
  )
}

ButtonGrid.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.number,
  padding: PropTypes.number,
  className: PropTypes.string,
  buttonSize: PropTypes.number
}

export default ButtonGrid
