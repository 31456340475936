import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { dispatch, subscribe } from '../redux'

const { v4: uuid } = require('uuid')
import { Button } from '@openbox-app-shared'

export default function SureButton ({ onClick, ...props }) {
  const theId = uuid()
  useEffect(
    () => {
      const subscriptions = [
        subscribe(
          'SURE_DELETE_GOOD',
          ({ why }) => {
            why === theId && onClick()
          }
        )
      ]
      return () => {
        subscriptions.forEach(s => s())
      }
    }
  )
  return (
    <Button
      {...props}
      onClick={() => {
        dispatch('SURE_DELETE', { why: theId })
      }}
    />
  )
}
SureButton.propTypes = {
  onClick: PropTypes.func
}
