import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, PasswordInput, isPasswordValid } from '@openbox-app-shared'
import H2 from '../../../components/H2'

export default function SetPassword({ onUpdate, formDependencies, wizardState, controlCanGoNext }) {
  const { partner } = formDependencies
  const canGoNext = [
    isPasswordValid(wizardState.password)
  ].every(e => e)
  return (
    <Form
      onChange={(key, value) => {
        onUpdate({ [key]: value })
        controlCanGoNext(canGoNext)
      }}
      onSubmit={() => canGoNext && onDone()}
    >
      <H2>Choose a password</H2>
      <PasswordInput
        name='password'
        key='password'
        value={wizardState.password}
        autocomplete='current-password'
        fireIfInvalid
        onReady={({ autoFocus }) => autoFocus()}
      />
      <p className='hint'>
        By choosing "Get started →" you agree to our <Button
          className='terms-and-conditions'
          onClick={() => {
            const AB_PK = 'public-c0b88579-1463-4459-8534-4484ae6028d0'
            window.sticky.setPublicKey(AB_PK)
            window.sticky.applications.blocks.renderInlineEvents(
              [
                {
                  id: '71be5a71-6818-47c7-b1c6-56a639f4b323',
                  config: {
                    name: 'You',
                    subscriptionTerm: 'an indefinite period',
                    subscriptionFrom: 'today',
                    dataProcessors: [partner ? partner.name : undefined, 'Google', 'DigitalOcean']
                      .filter(_ => _)
                      .join('/'),
                    commercials: 'Not yet defined.'
                  }
                },
                {
                  'id': 'a21eddf2-aa86-4b6a-a2af-8ac279b246f7',
                  'config': {
                    'label': 'Close',
                    'action': 'application--inline-destroy~~||~~~~||~~false',
                    'colour': '#1A1F35',
                    'foregroundColour': '#ffffff',
                    'icon': 'cross',
                    'fullWidth': false,
                    'dropShadowAndRoundedCorners': true
                  }
                }
              ],
              {
                maxWidth: 720,
                canBeClosed: true
              }
            )
          }}
        >
          terms and conditions
        </Button>
      </p>
    </Form>
  )
}

SetPassword.propTypes = {
  onUpdate: PropTypes.func,
  formDependencies: PropTypes.object,
  wizardState: PropTypes.object,
  controlCanGoNext: PropTypes.func,
}