import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { dispatch, subscribe } from '../redux'
import { Location, Button, Loading } from '@openbox-app-shared'

const MapContainer = styled.div`
  .find-me {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;

    button {
      margin-right: 1rem;
    }
    div {
      padding: 0;
    }
  }
`

export default function LocationPasteGoogleMapsUrl({ ...props }) {
  const [disableButton, setdisableButton] = useState(false)

  const findLocation = () => {
    setdisableButton(true)

    const callbackSuccess = async (position) => {
      setdisableButton(false)
      props.onChange && props.onChange({ latitude: Number(position.coords.latitude), longitude: Number(position.coords.longitude) })
    }
    const callbackFailure = () => {
      setdisableButton(false)
      window.sticky.applications.blocks.showError('Geolocation is turned off.', true)
    }

    navigator.geolocation.getCurrentPosition(callbackSuccess, callbackFailure)
  }

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === 'route--application--tab--location' && (() => {
            try {
              const parts = string.split('/')
              const longLatPart = parts.find(p => p.startsWith('@') && p.indexOf(',') > 0 && p.endsWith('z'))
              // '@52.7562243,-0.948888,15.7z'
              window.sticky.assert(longLatPart, 'The URL must contain a pair of coordinates.')
              const [longLatPart1, longLatPart2] = longLatPart.substring(1).split(',').slice(0, -1)
              props.onChange && props.onChange({ latitude: Number(longLatPart1), longitude: Number(longLatPart2) })
            } catch (e) {
              dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
            }
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  return (
    <MapContainer>
      <Location
        {...props}
        onPasteGoogleMapsUrl={() => {
          dispatch('GET_INPUT', { selectAll: true, why: 'route--application--tab--location', type: 'url', hint: <>Paste in a Google Maps URL:</>, string: 'https://www.google.co.uk/maps/place/Hampton+by+Hilton+Dortmund+Phoenix+See/@51.497963,7.4856052,14z' })
        }}
      />
      <div className='find-me'>
        <Button
          disabled={disableButton}
          onClick={() => {
            findLocation()
          }}>
          FIND ME
        </Button>
        {disableButton && <Loading />}
      </div>
    </MapContainer>
  )
}
LocationPasteGoogleMapsUrl.propTypes = {
  onChange: PropTypes.func
}