import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledComponent = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.25;
  img {
    display: block;
    width: 100%;
  }
`

function getDesignId (partner, fallback) {
  return (partner && partner.recommendedDesignId) ? `${window.sticky.cdn}/designs/design-${partner.recommendedDesignId}.png` : `${window.sticky.cdn}/frame-v4.png`
}

export default function StickerImages ({ partner }) {
  const designId = getDesignId(partner)
  return <StyledComponent className='component--sticker-images'>
    <img src={designId} />
    <img src={designId} />
    <img src={designId} />
    <img src={designId} />
    <img src={designId} />
    <img src={designId} />
  </StyledComponent>
}
StickerImages.propTypes = {
  partner: PropTypes.object
}
