import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import StateView from './StateView'
import StateEdit from './StateEdit'
import StateChoose1 from './StateChoose1'

import { dispatch, subscribe } from '../../redux'
import { log } from '../../log'

const StyledComponent = styled.div`
  .buttons-up {
    margin-bottom: 0.5rem;
    > * {
      font-size: 90%;
      display: inline-block;
      margin: 0 0.5rem 0.5rem 0;
      vertical-align: top;
      > strong {
        min-height: 1.25rem;
        line-height: 1.25rem;
      }
    }
    > *:last-child {
      margin-right: 0;
    }
  }
`

const VIEWS = {
  'StateView': StateView,
  'StateEdit': StateEdit,
  'StateChoose1': StateChoose1
}

function ChooseGateway ({ user, entity, onSettingsValid, triggerProps = {}, onSave }) {
  const gateways = window.sticky.Stickypay.GATEWAYS
  let [gateway, setGateway] = useState(window.sticky.Stickypay.getGateway(entity.gateway))
  const [view, setView] = useState(gateway ? 'StateView' : 'StateChoose1')

  const interestingProps = ['gateway', 'gatewayEndpoint', 'gatewayId', 'acquirerId']

  const onDisconnect = async () => {
    dispatch('LOADING')
    try {
      const payload = {}
      interestingProps.forEach(p => { payload[p] = null })
      await onSave(
        payload,
        interestingProps
      )
    } catch (e) {}
    dispatch('STOP_LOADING')
  }
  const onChoose = async (newGateway) => {
    log('[paymentProvider] [onChoose]', { newGateway })
    if (entity.gateway) {
      dispatch('SURE_DELETE', { why: 'payment-provider-choose', hint: `Are you sure? You won\'t be able to take payments until you\'ve set up ${newGateway.name} correctly.`, entity: newGateway })
    } else {
      await onChooseDone(newGateway, entity)
    }
  }
  const onChooseDone = async (newGateway, entity) => {
    async function methodOnSave () {
      dispatch('LOADING')
      try {
        await onSave(
          {
            gateway: newGateway.id,
            gatewayEndpoint: null,
            gatewayId: null,
            acquirerId: null
          },
          interestingProps
        )
        const maybeRedirectUrl = entity.customData.readFrom('redirectUrl')
        log('[paymentProvider] [onChooseDone] post onSave', { entity, maybeRedirectUrl })
        if (typeof maybeRedirectUrl === 'string') {
          window.location = maybeRedirectUrl
        } else {
          setGateway(newGateway)
          setView('StateView')
          window.location.reload()
        }
      } catch (e) {
        log('[paymentProvider] [onChooseDone] post onSave caught')
        const payload = {}
        interestingProps.forEach(p => { payload[p] = null })
        await onSave(
          payload,
          interestingProps
        )
      }
      dispatch('STOP_LOADING')
    }

    async function methodApplicationBlocks () {
      const r = await window.sticky.applications.blocks.renderInlineEventsButton(
        newGateway.canOnboardChoose,
        'Continue'
      )
      if (!r) {
        return
      }
      user.preferences.patchDelta({ 'gatewayMethodApplicationBlocks': r })
      onSave({ preferences: user.preferences }, 'preferences')
      await methodOnSave()
    }

    if (newGateway.canOnboardChoose === 'onSave') {
      await methodOnSave()
    }
    if (Array.isArray(newGateway.canOnboardChoose)) {
      await methodApplicationBlocks()
    }
  }

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why, chosen }) => {
          why === 'payment-provider-disconnect' && (async () => {
            log('[paymentProvider] [why -> payment-provider-disconnect]')
            await onDisconnect()
          })()
          why === 'payment-provider-choose' && (async () => {
            log('[paymentProvider] [why -> payment-provider-choose]')
            await onChooseDone(chosen, entity)
          })()
        }
      ),
      subscribe(
        'TRIGGER_GOOD',
        ({ trigger }) => {
          trigger === 'gateway--onboardTrigger' && (() => {
            window.location.reload()
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === 'gateway--StateEdit--applePaySlug' && (() => {
            onSave({
              applePaySlug: string
            })
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  if (view === 'StateView' && !entity.gateway && typeof gateway === 'object') {
    setGateway(undefined)
    setView('StateChoose1')
    return null
  }

  const renderProps = {
    user,
    entity,
    gateways,
    gateway,
    setView,
    onUpdate: newGateway => {
      const realGateway = newGateway || window.sticky.Stickypay.getGateway(entity.gateway)
      log('[paymentProvider] [inline:onUpdate]', { realGateway, newGateway, gateway })
      setGateway(realGateway)
    },
    onSettingsValid,
    onSave,
    onChoose,
    onDisconnect: (doConfirm = true) => {
      doConfirm && dispatch('SURE_DELETE', { why: 'payment-provider-disconnect', hint: 'Are you sure? You won\'t be able to take payments.' })
      !doConfirm && onDisconnect()
    },
    triggerProps
  }
  const VIEW = VIEWS[view]
  return (
    <StyledComponent>
      {<VIEW {...renderProps} />}
    </StyledComponent>
  )
}
ChooseGateway.propTypes = {
  user: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  onSettingsValid: PropTypes.func.isRequired,
  triggerProps: PropTypes.object,
  onSave: PropTypes.func.isRequired
}

export default ChooseGateway
