 

export default {
  trigger: ({ setState }) => {
    setState({ LOADING: false })
  },
  render: function ThisAction ({ state }) {
    return null
  }
}
