/* eslint-disable react/prop-types */

import React from 'react'
import styled, { css } from 'styled-components'
import GenericModal from '../../../components/modals/generic'

import _ from '../../../_'
import { log } from '../../../log'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  ${props => props.css ? css`${props.css}` : ''}
`
function ThisModal ({ message, canBeGooded, canBeBadded, css, onDone }) {
  return (
    <StyledModal css={css}>
      <GenericModal
        onGood={() => onDone(true)}
        onClose={() => onDone(false)}
        goodText={typeof canBeGooded === 'string' ? canBeGooded : ''}
        badText={typeof canBeBadded === 'string' ? canBeBadded : undefined}
      >
        {message}
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, message, css, extra, canBeGooded = 'Done', canBeBadded }) => {
    setState({ SHOW_MESSAGE: { why, message, css, extra, canBeGooded, canBeBadded } })
  },
  render: function SHOW_MESSAGE ({ state, setState }) {
    const { SHOW_MESSAGE } = state
    if (!SHOW_MESSAGE) {
      return null
    }

    const { why, message, css, extra, canBeGooded, canBeBadded } = SHOW_MESSAGE
    const onDone = (good) => {
      const toDispatchWith = { why, good, extra }
      log('[SHOW_MESSAGE] [Modal->render->onDone] toDispatchWith', toDispatchWith)
      dispatch('SHOW_MESSAGE_GOOD', toDispatchWith)
      setState({
        SHOW_MESSAGE: undefined
      })
    }

    return (
      <ThisModal
        message={message}
        canBeGooded={canBeGooded}
        canBeBadded={canBeBadded}
        css={css}
        onDone={onDone}
      />
    )
  }
}
