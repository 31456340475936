import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import { Label } from './Input'

const StyledDatePicker = styled.div`
  label + * {
    margin-top: 0.5rem;
  }
  input {
    width: 10rem;
    height: 2.5rem;
    border-radius: 4px;
    border: 1px solid #e0e1e4;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
    transition: box-shadow 0.3s ease-in-out;
  }
  &:focus-within {
    input {
      box-shadow: #cccbf0 0px 0px 0px 3px;
      outline: 0;
    }
  }
`

function getAttribute(time) {
  const d = new Date(time * 1000)
  return [d.getFullYear(), (d.getMonth() + 1).toString().padStart(2, '0'), d.getDate().toString().padStart(2, '0')].join('-')
}

export default function DatePicker({ color, time, minTime, maxTime, label, className, onChange }) {
  const labelProps = {
    style: {
      color: color ? color : undefined
    }
  }

  return <StyledDatePicker className={classnames('component--date-picker', className)}>
    {label && <Label {...labelProps}>{label}</Label>}
    <input
      type='date'
      value={getAttribute(time)}
      min={minTime ? getAttribute(minTime) : undefined}
      max={maxTime ? getAttribute(maxTime) : undefined}
      onChange={(e) => {
        const val = e.target.valueAsNumber / 1000
        onChange(val)
      }}
      pattern='\d{4}-\d{2}-\d{2}'
    />
  </StyledDatePicker>

}

DatePicker.propTypes = {
  color: PropTypes.string,
  time: PropTypes.number.isRequired,
  minTime: PropTypes.number,
  maxTime: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
}
