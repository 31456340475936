import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { icons, Button, Loading, LinkButton, Modal, ApplicationBlocks } from '@openbox-app-shared'
import H1 from './H1'
import SideChooser from './SideChooser'
import { log } from '../log'

const StyledChooseApplicationBaseOrBaseSet = styled.div`
  .component--h1 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .action-link {
    margin-bottom: 1rem;
  }
  .buttons {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr 1fr;
    .component--button {
      height: 8rem;
    }
  }
  .component--side-chooser {
    margin-top: 1rem;
  }
  .component--input.search {
    max-width: 16rem;
    margin: 0 auto 2rem auto;
  }

  .current-application-base-set {
    width: calc(100% - 1rem);
    max-width: 368px;
    max-height: calc(100%);
    padding: 0 1rem 1rem 1rem;
    .ca-buttons {
      .component--button {
        margin: 0 auto 0 auto;
      }
      .component--button + .component--button {
        margin-top: 1rem;
      }
    }
  }
`

function CategoryChild ({ whats, actionLink, onChoose }) {
  return (
    <>
      <div className='action-link'>
        {actionLink && (
          <LinkButton
            to={actionLink.to}
            onClick={() => onChoose()}
            sameTab
            isSecondary
            isThin
            icon={icons.generic.edit}
          >
            {actionLink.text}
          </LinkButton>
        )}
      </div>
      <div className='buttons'>
        {whats.map(({ what, type }) => {
          return (
            <Button
              key={what.id}
              icon={what.icon}
              bigIcon
              isSecondary
              backgroundColor={what.backgroundColor}
              color={what.foregroundColor}
              onClick={() => onChoose({ what, type })}
            >
              {what.name}
            </Button>
          )
        })}
      </div>
    </>
  )
}
CategoryChild.propTypes = {
  whats: PropTypes.array,
  actionLink: PropTypes.object,
  onChoose: PropTypes.func
}

export default function ChooseApplicationBaseOrBaseSet ({ user, onChoose }) {
  const [stuff, setStuff] = useState()
  const [filter, setFilter] = useState('')
  const [currentApplicationBaseSet, setCurrentApplicationBaseSet] = useState()

  function inlineOnChoose ({ what, type }) {
    log('[ChooseApplicationBaseOrBaseSet] [inlineOnChoose]', { what, type })
    !what && onChoose(undefined)
    what && type === 'ApplicationBase' && (() => {
      what.configApplicationBlocks.length > 0 && (() => {
        window.sticky.applications.blocks.renderInlineEventsButton(
          [...what.configApplicationBlocks],
          'Add'
        )
          .then(config => {
            log('[ChooseApplicationBaseOrBaseSet] [inlineOnChoose]', { type, what, config })
            config && onChoose({ type, what, config })
          })
      })()
      what.configApplicationBlocks.length === 0 && onChoose({ type, what })
    })()
    what && type === 'ApplicationBaseSet' && (() => {
      try {
        what.applicationBases.forEach(({ id: potentialBaseId }) => {
          window.sticky.assert(stuff.bases.find(base => base.id === potentialBaseId), `There is a flow template with ID "${potentialBaseId}" in this flow template set that doesn't exist.`)
        })
        setCurrentApplicationBaseSet({
          applicationBaseSet: {
            ...what
          },
          forCreation: [],
          whichIndex: 0
        })
      } catch ({ message }) {
        window.sticky.applications.blocks.showError(message, true)
      }
    })()
  }
  useEffect(() => {
    async function getStuff () {
      const bases = await window.sticky.applications.bases.getAll()
      const baseSets = await window.sticky.applications.baseSets.getAll(true)
      setStuff({
        bases,
        baseSets
      })
    }
    !stuff && getStuff()
  })
  const categories = stuff ? (() => {
    const theSet = new Set([
      ...stuff.baseSets.map(_ => _.category),
      ...stuff.bases.map(_ => _.category),
    ])
    return Array.from(theSet).map(_ => {
      const whats = [
        ...stuff.baseSets
          .filter(__ => __.category === _ && __.doesMatchFilter(filter))
          .map(what => ({ type: 'ApplicationBaseSet', what })),
        ...stuff.bases
          .filter(__ => __.category === _ && __.doesMatchFilter(filter))
          .map(what => ({ type: 'ApplicationBase', what }))
      ]
      const ccProps = {
        // actionLink: whats.some(base => (typeof base.userId === 'string' || typeof base.partnerId === 'string')) ? { to: '/my/account/flow-templates', text: 'Manage flow templates' } : undefined
      }
      return {
        id: _,
        name: _,
        children: <CategoryChild whats={whats} onChoose={inlineOnChoose} {...ccProps} />
      }
    })
  })() : []

  function doSomething (doSet = true) {
    const config = window.sticky.applications.blocks.getFormData()
    const { id: applicationBaseId, doCreate } = currentApplicationBaseSet.applicationBaseSet.applicationBases[currentApplicationBaseSet.whichIndex]
    const { forCreation } = currentApplicationBaseSet
    if (doCreate) {
      forCreation.push({
        applicationBase: stuff.bases.find(_ => _.id === applicationBaseId),
        config
      })
    }
    const finalD = {
      ...currentApplicationBaseSet,
      whichIndex: currentApplicationBaseSet.whichIndex + 1,
      forCreation
    }
    doSet && setCurrentApplicationBaseSet(finalD)
    return forCreation
  }

  return (
    <StyledChooseApplicationBaseOrBaseSet>
      {currentApplicationBaseSet && (
        <Modal
          onClose={() => setCurrentApplicationBaseSet(undefined)}
          outerStyle={{ padding: 0 }}
          className='current-application-base-set'
        >
          <ApplicationBlocks
            forceReRender
            applicationBlocks={stuff.bases.find(_ => _.id === currentApplicationBaseSet.applicationBaseSet.applicationBases[currentApplicationBaseSet.whichIndex].id).configApplicationBlocks}
          />
          <div className='ca-buttons'>
            {currentApplicationBaseSet.applicationBaseSet.applicationBases[currentApplicationBaseSet.whichIndex].canSkip && <Button
              inlineIconString={window.sticky.internals.icons.get('minus')}
              isSecondary
              onClick={() => {
                currentApplicationBaseSet.applicationBaseSet.applicationBases.splice(currentApplicationBaseSet.whichIndex + 1, 0, {...currentApplicationBaseSet.applicationBaseSet.applicationBases[currentApplicationBaseSet.whichIndex]})
                setCurrentApplicationBaseSet({
                  ...currentApplicationBaseSet,
                  whichIndex: currentApplicationBaseSet.whichIndex + 1
                })
              }}
            >
              Skip
            </Button>}
            {currentApplicationBaseSet.applicationBaseSet.applicationBases[currentApplicationBaseSet.whichIndex].canDuplicate && <Button
              inlineIconString={window.sticky.internals.icons.get('plus')}
              isSecondary
              onClick={() => {
                currentApplicationBaseSet.applicationBaseSet.applicationBases.splice(currentApplicationBaseSet.whichIndex + 1, 0, {...currentApplicationBaseSet.applicationBaseSet.applicationBases[currentApplicationBaseSet.whichIndex]})
                doSomething()
              }}
            >
              Repeat
            </Button>}
            {currentApplicationBaseSet.whichIndex < currentApplicationBaseSet.applicationBaseSet.applicationBases.length - 1 && <Button
              onClick={doSomething}
            >
              Next →
            </Button>}
            {currentApplicationBaseSet.whichIndex === currentApplicationBaseSet.applicationBaseSet.applicationBases.length - 1 && <Button
              icon={icons.inverted.check}
              onClick={() => {
                const config = doSomething(false)
                onChoose({ type: 'ApplicationBaseSet', what: currentApplicationBaseSet.applicationBaseSet, config })
              }}
            >
              Done
            </Button>}
          </div>
        </Modal>
      )}
      {!stuff && <Loading />}
      {stuff && (
        <>
          <H1>{_('CHOOSE_APPLICATION_BASE')}</H1>
          <SideChooser
            items={categories}
          />
        </>
      )}
    </StyledChooseApplicationBaseOrBaseSet>
  )
}
ChooseApplicationBaseOrBaseSet.propTypes = {
  user: PropTypes.object,
  onChoose: PropTypes.func
}
