import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@openbox-app-shared'

const StyledComponent = styled.div`
  font-size: 160%;
  .component--button {
    position: relative;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: 3rem;
    border: 2px solid #ffffff40;
    width: 100%;
    height: 100%;
    > strong {
      margin: 0 auto 0 auto;
      word-break: break-all;
      line-height: 1.25rem;
      text-shadow: 0 1px 0 black;
    }
    .background {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      content: ' ';
      display: block;
      position: absolute;
      left: 5%;
      top: 5%;
      width: 90%;
      height: 90%;
      opacity: 0.4;
      z-index: -1;
    }
  }
  .component--button.selected {
    background-color: rgba(255, 255, 255, 0.15) !important;
    border: 4px solid rgba(255, 255, 255, 1) !important;
    strong {
      opacity: 1;
    }
  }
`

export default function ThingButton ({ thing, className, style, onClick }) {
  return (
    <StyledComponent className='component--thing-button'>
      <Button
        onClick={onClick}
        className={className}
        style={style}
      >
        <div className='background' style={{ backgroundImage: `url('${thing.designUrl}')` }} />
        <span dangerouslySetInnerHTML={{ __html: thing.name.replaceAll(' ', '<br />') }} />
      </Button>
    </StyledComponent>
  )  
}
ThingButton.propTypes = {
  thing: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
}
