import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import H1 from './H1'
import { InlineHtml } from '@openbox-app-shared'

const StyledComponent = styled.div`
  color: white;
  > .component--h1 {
    padding-top: 0.75rem;
    padding-bottom: 1rem;
    font-size: 120%;
    color: inherit;
    > strong {
      > * {
        display: inline-block;
        vertical-align: top;
        height: 2rem;
      }
      > img, > svg {
        margin-top: 0.25rem;
      }
      > img {
        height: 1.5rem;
      }
      > svg {
        width: 1.5rem;
        height: 1.5rem;
      }
      > img + span, > svg + span {
        margin-left: 0.5rem;
      }
    }
    span {
      line-height: 2rem;
    }
  }
`

export default function HeaderBar ({ text, Icon, InlineIcon, IconHtml }) {
  return (
    <StyledComponent className='component--header-bar'>
      <H1>
        {Icon && <Icon />}
        {InlineIcon && <InlineIcon />}
        {IconHtml && <InlineHtml html={IconHtml} />}
        <span>{text}</span>
      </H1>
    </StyledComponent>
  )
}
HeaderBar.propTypes = {
  text: PropTypes.node,
  InlineIcon: PropTypes.node,
  Icon: PropTypes.func,
  IconHtml: PropTypes.string
}
