import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from './Box'
import H2 from './H2'

import { Button, Input } from '@openbox-app-shared'
import dashboardIcons from '../icons'

const StyledComponent = styled.div`
  .component--box {
    background-color: #1A1F35;
    > * {
      margin-top: 0 !important;
      text-align: center;
    }
    .component--h2 {
      margin-bottom: 1rem;
      color: white;
      line-height: 1.5rem;
    }
    .component--input {
      margin-right: 3.125rem;
      font-size: 100%;
      input {
        height: 2.5rem;
      }
    }
    .component--button {
      width: 2.5rem !important;
      height: 2.5rem;
      float: right;
    }
  }
`
export default function CopyUrl ({ url, children }) {
  return (
    <StyledComponent className='component--copy-url'>
      <Box>
        {children && <H2>{children}</H2>}
        <Button
          isSecondary
          InlineIcon={dashboardIcons.copy}
          onClick={() => navigator.clipboard.writeText(url)}
        />
        <Input value={url} selectAll />
      </Box>
    </StyledComponent>
  )
}
CopyUrl.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node
}
