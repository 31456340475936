import SAVE_USER_PREFERENCES from './actions/SAVE_USER_PREFERENCES'
import SAVE_FEDERATED_USER from './actions/SAVE_FEDERATED_USER'
import LOADING_WHAT from './actions/LOADING_WHAT'
import GET_THINGS from './actions/GET_THINGS'
const actions = new Map([
  ['SAVE_USER_PREFERENCES', SAVE_USER_PREFERENCES],
  ['SAVE_FEDERATED_USER', SAVE_FEDERATED_USER],
  ['LOADING_WHAT', LOADING_WHAT],
  ['GET_THINGS', GET_THINGS]
])

let state = {}
console.log('[redux] state', state)

const element = document.createElement('SPAN')

export async function dispatchMany (array) {
  for (let index = 0; index < array.length; index++) {
    try {
      state = await dispatch(...array[index])
    } catch (error) {
      break
    }
  }
}

export function dispatch (name, data = {}, pleaseThrow = false) {
  const foundAction = actions.get(name)
  if (typeof foundAction !== 'function') {
    console.warn(`[state] action ${name} is not understood! state is only working as an event bus, not redux`)
    element.dispatchEvent(new window.CustomEvent(`redux-dispatch-${name}`, { detail: data }))
    return Promise.resolve(state)
  }
  return foundAction(state, data)
    .then(newState => {
      state = newState
      element.dispatchEvent(new window.CustomEvent(`redux-dispatch-${name}`, { detail: state }))
      return state
    })
    .catch(error => {
      console.error('[state] action execution failed!', error)
      // const errorMessage = (() => {
      //   if (error.message === 'Network Error') {
      //     return 'Our API looks to be down. Are you connected to the Internet?'
      //   } else if (error.hasOwnProperty('response')) {
      //     return `That didn&rsquo;t work (${error.response.data.message}).`
      //   } else if (error.hasOwnProperty('message')) {
      //     return `That didn&rsquo;t work (${error.message}).`
      //   } else {
      //     return 'That didn&rsquo;t work. That&rsquo;s all we know.'
      //   }
      // })()
      // alert(errorMessage)
      if (pleaseThrow) {
        throw error
      }
    })
}

export function subscribe (name, handler) {
  const logic = e => handler(e.detail)
  element.addEventListener(`redux-dispatch-${name}`, logic, false)
  return () => element.removeEventListener(`redux-dispatch-${name}`, logic)
}
