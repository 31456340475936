import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

const CustomHelmet = ({ title }) => {
  const realTitle = title ? `${title}` : ''
  return (
    <Helmet>
      <title>{realTitle}</title>
    </Helmet>
  )
}

CustomHelmet.propTypes = {
  title: PropTypes.string
}

export default CustomHelmet
