import React from 'react'
import styled from 'styled-components'
import { icons, Loading } from '@openbox-app-shared'
import Box from '../../../components/Box'
import H2 from '../../../components/H2'
import KeyValuePairs from '../../../components/KeyValuePairs'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
`

const tab = {
  id: 'memory',
  name: () => 'Memory',
  icon: icons.generic.storeData,
  to: (context) => context.state.thing ? `/me/stickies/${context.state.thing.id}/memory` : undefined,
  child: function Child (context, extraProps) {
    const { thing } = context.state
    const { user } = context.props
    return <StyledComponent className='tab'>
      {!thing && <Loading />}
      {thing && (
        <div className='grid'>
          <Box>
            <H2>Public</H2>
            <KeyValuePairs
              user={user}
              object={thing.customData.get()}
              excludeKeys={['paymentId']}
              onUpdate={({ name, value }) => {
                if (value === null) {
                  thing.customData.delete(name)
                } else {
                  thing.customData.writeTo(name, value)
                }
                context.onUpdate('customData', thing.customData)
              }}
            />
          </Box>
          <Box>
            <H2>Private</H2>
            <KeyValuePairs
              user={user}
              object={thing.customDataPrivate.get()}
              excludeKeys={['paymentId']}
              onUpdate={({ name, value }) => {
                if (value === null) {
                  thing.customDataPrivate.delete(name)
                } else {
                  thing.customDataPrivate.writeTo(name, value)
                }
                context.onUpdate('customDataPrivate', thing.customDataPrivate)
              }}
            />
          </Box>
        </div>
      )}
    </StyledComponent>
  }
}

export default tab
