import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomHelmet, Button } from '@openbox-app-shared'
import StickerImages from '../../components/StickerImages'

import { log } from '../../log'
import PartnerPublic from '../../components/PartnerPublic'

import TheWizard from './wizard'
import CustomerDetails from './steps/CustomerDetails'
import LocationFinder from './steps/LocationFinder'
import SetLocation from './steps/SetLocation'
import SetPassword from './steps/SetPassword'
import { dispatch, subscribe } from '../../redux'
import getLogInUrl from '../../getLogInUrl'

const AB_PK = 'public-c0b88579-1463-4459-8534-4484ae6028d0'

const StyledRoute = styled.div`
  position: relative;
  height: 100vh;
  padding: 1rem;
  .component--partner-public + * {
    margin-top: 2rem;
  }
  .partner-code {
    margin: 0 auto 1.5rem auto;
    font-size: 1.25rem;
    padding: 0.75rem;
    border-radius: 8px;
  }
  .left-right {
    > section {
      // background-color: orange;
      > div {
        width: calc(100% - 2rem);
        max-height: calc(100vh - 2rem);
        overflow: scroll;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.5rem 1.5rem 0.5rem 1.5rem;
        transition: opacity 0.3s ease-in-out;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 7px 14px 0 rgb(60 66 87 / 20%), 0 3px 6px 0 rgb(0 0 0 / 20%);
      }
    }
    > aside {
      display: none;
    }
  }
  @media only screen and (min-width: 570px) {
    .left-right {
      > section {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
      }
      > aside {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
      }
    }
  }
  .component--modal {
    max-width: 420px;
    p + p {
      margin-top: 2rem;
    }
    .component--button {
      margin: 0 auto 0 auto;
    }
    .component--button + .component--button {
      margin-top: 1rem;
    }
  }
`

export default class Route extends Component {
  constructor() {
    super()
    this.state = {
      isShowing: false,
      isWorking: false,
      partner: undefined,
      wizardState: {
        contactName: ''
      }
    }
    this.onCreateUser = this.onCreateUser.bind(this)
    this.setWizardState = this.setWizardState.bind(this)
  }

  componentDidMount () {
    this.subscriptions = [
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why, entity }) => {
          why === 'createUser-existingUser' && this.doCreateUser(entity)
        }
      ),
      subscribe(
        'SURE_DELETE_BAD',
        () => {
          const { partner } = this.state
          dispatch('REDIRECT', { to: `/log-in${partner ? `/${partner.code}` : ''}` })
        }
      )
    ]
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach(s => s())
    this.freeStyle && this.freeStyle()
  }

  async doCreateUser(user) {
    const { partner } = this.state
    log('[Route-createUser] [onCreateUser]', { user, partner })
    const start = () => {
      log('[Route-createUser] [onCreateUser] [start]')
      return new Promise(resolve => this.setState({ isWorking: true }, resolve))
    }
    const end = (extraState = {}) => {
      log('[Route-createUser] [onCreateUser] [end]')
      return new Promise(resolve => this.setState({ ...extraState, isWorking: false }, resolve))
    }
    await start()
    try {
      await window.sticky.users.create({
        partnerId: partner ? partner.id : undefined,
        ...user
      })
      const logInRedirectTo = getLogInUrl()
      window.location = logInRedirectTo ? `${logInRedirectTo}?logOutRedirectTo=${logInRedirectTo}` : '/me/flows'
    } catch (error) {
      log('[Route-createUser] [onCreateUser] caught error', error)
      await end()
      dispatch(
        'SHOW_MESSAGE',
        {
          message: (
            <>
              <p>
                {error.message}
              </p>
            </>
          ),
          canBeBadded: ''
        }
      )
    }
  }

  async onCreateUser(user) {
    const existingUsers = await window.sticky.users.getByEmail(user.email, true)
    if (existingUsers.length > 0) {
      dispatch('SURE_DELETE', { entity: user, why: 'createUser-existingUser', hint: <><p>There's already a dashboard for {user.email}.</p><p>Are you sure you want to create another dashboard?</p></>, canBeBadded: '' })
      return
    } else {
      this.doCreateUser(user)
    }
  }

  setWizardState (newState) {
    const { wizardState } = this.state
    this.setState({
      wizardState: {
        ...wizardState,
        ...newState
      }
    })
  }

  render() {
    const {
      isShowing,
      partner,
      wizardState
    } = this.state
    log('[Route-createUser] [render]', { wizardState })
    return (
      <StyledRoute>
        <CustomHelmet
          title='Sign up'
        />
        <div className='left-right'>
          <section>
            <div style={{ opacity: isShowing ? 1 : 0 }}>
              <PartnerPublic
                fallbackLogo={false}
                partnerCode={this.props.match.params.partnerCode}
                onReady={({ partner }) => {
                  partner && (() => {
                    this.freeStyle = window.sticky.addStyle(undefined, partner.css)
                  })()
                  this.setState({
                    isShowing: true,
                    partner,
                    wizardState: {
                      ...wizardState,
                      country: partner ? partner.country : 'GBR'
                    }
                  })
                }}
              />
              {!partner && (
                <>
                  <Button
                    className='partner-code'
                    onClick={() => {
                      ;(async () => {
                        window.sticky.setPublicKey(AB_PK)
                        const { Code } = await window.sticky.applications.blocks.getInput(undefined, 'Code', '', 'Text', 'Continue →', '')
                        window.location = `/sign-up/${Code}`
                      })()
                    }}
                  >
                    Got a code? →
                  </Button>
                  <hr />
                </>
              )}
              <TheWizard
                wizardState={wizardState}
                setWizardState={this.setWizardState}
                onSubmit={this.onCreateUser}
                components={[
                  {
                    component: CustomerDetails
                  },
                  {
                    component: LocationFinder,
                    useComponentButtons: true
                  },
                  {
                    component: SetLocation,
                    canGoNext: true
                  },
                  {
                    component: SetPassword
                  }
                ]}
                formDependencies={{ partner }}
              />
            </div>
          </section>
          <aside style={{ opacity: isShowing ? 1 : 0 }}>
            <StickerImages partner={partner} />
          </aside>
        </div>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  match: PropTypes.object
}
