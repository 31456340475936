import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Modal, Price } from '@openbox-app-shared'

import _ from '../../_'
import NumberGrid from '../NumberGrid'
import H1 from '../../components/H1'

const StyledModal = styled.div`
  .component--modal {
    padding: 3rem 1rem 1rem 1rem !important;
    max-width: calc(248px + 4rem);
  }
  .component--h1 {
    text-align: center;
  }
  .component--h1.hint {
    font-size: 120%;
    line-height: 1.5rem;
    > strong > strong {
      display: block;
    }
    > strong > strong + strong {
      margin-top: 1rem;
    }
  }
  .component--h1.price {
    font-size: 2rem;
    line-height: 3rem;
  }
  .component--h1 + .component--h1 {
    margin-top: 1rem;
  }
  .component--button-grid {
    margin-top: 0.5rem;
  }
`

export default function ThisModal ({ hint, price, currency, onGood, onCancel }) {
  const [localPrice, setLocalPrice] = useState(price || 0)

  return (
    <StyledModal>
      <Modal onClose={onCancel}>
        {hint && <H1 className='hint'>{hint}</H1>}
        <H1 className='price'><Price price={localPrice} currency={currency} /></H1>
        <NumberGrid
          onChange={(newNumber) => {
            const newPrice = parseInt(localPrice.toString() + newNumber, 10)
            setLocalPrice(newPrice)
          }}
          onDone={() => onGood(localPrice)}
          onGoBack={() => {
            const asString = localPrice.toString()
            if (asString.length === 1) {
              setLocalPrice(0)
              return
            }
            const newPrice = parseInt(asString.substring(0, asString.length - 1), 10)
            setLocalPrice(newPrice)
          }}
        />
      </Modal>
    </StyledModal>
  )
}

ThisModal.propTypes = {
  hint: PropTypes.string,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string,
  onGood: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
