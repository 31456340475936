import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Form, Input, Label, Banner } from '@openbox-app-shared'
import { dispatch } from '../redux'
import GenericModal from './modals/generic'

const StyledComponent = styled.div`
  .component--modal {
    max-width: 480px;
    padding-left: 2rem;
    padding-right: 2rem;
    .upload-p12 {
      margin-top: 1rem;
      label + input {
        margin-top: 0.5rem;
      }
      input {
        display: block;
        width: 100%;
      }
    }
  }
`

export default function ApplePay ({ user, onBad }) {
  const [payload, setPayload] = useState({
    mid: user.applePayMid || '',
    fqdn: user.applePayFqdn || '',
    p12: ''
  })
  const [isWorking, setIsWorking] = useState(false)

  const isPayloadValid = () => [
    payload.mid.startsWith('merchant.com.'),
    payload.fqdn.length > 0,
    payload.p12.length > 0
  ]
    .every(e => e)
  const doIt = () => {
    setIsWorking(true)
    dispatch('TRIGGER', { trigger: 'applePay', body: payload })
  }

  return (
    <StyledComponent className='onboard-trigger'>
      <GenericModal
        goodText='Activate'
        badText=''
        onGood={() => {
          isPayloadValid() && doIt()
        }}
        buttonProps={{ disabled: (!isPayloadValid() || isWorking) }}
        onClose={onBad}
      >
        <Form
          onChange={(f, newValue) => {
            setPayload({
              ...payload,
              [f]: newValue
            })
          }}
          onSubmit={() => {
            isPayloadValid() && doIt()
          }}
        >
          <Input
            label='Merchant ID'
            value={payload.mid}
            name='mid'
          />
          <Input
            type='code'
            label='Domain verification'
            value={payload.fqdn}
            name='fqdn'
          />
          <Banner><p>{user.applePaySlug}.sticky.to</p></Banner>
        </Form>

        <div className='upload-p12'>
          <Label>Upload P12</Label>
          <input
            type='file'
            accept='application/x-pkcs12,.p12'
            onChange={async v => {
              const [file] = v.target.files
              if (!file) {
                return
              }
              var reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = function () {
                const p12 = reader.result.substring('data:application/x-pkcs12;base64,'.length)
                setPayload({
                  ...payload,
                  p12
                })
              }
            }}
          />
        </div>
      </GenericModal>
    </StyledComponent>
  )
}
ApplePay.propTypes = {
  user: PropTypes.object.isRequired,
  onBad: PropTypes.func
}
