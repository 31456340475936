import React from 'react'
import styled from 'styled-components'
import { Loading, List, ListItem, Banner } from '@openbox-app-shared'
import dashboardIcons from '../../../icons'
import _ from '../../../_'
import { dispatch } from '../../../redux'
import ChildrenAndInlineLinkFrame from '../../../components/ChildrenAndInlineLinkFrame'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--banner {
    margin-bottom: 1rem;
  }
  .cant-change-application {
    .component--list-item {
      cursor: not-allowed;
    }
  }
`

const tab = {
  id: 'flow',
  name: () => window.sticky._('WHICH_APPLICATION'),
  inlineIcon: dashboardIcons.application,
  to: (context) => context.state.thing ? `/me/stickies/${context.state.thing.id}/flow` : undefined,
  child: function Child (context, { urls, hasMadeUpdate }) {
    const { user } = context.props
    const { thing, applications } = context.state
    const foundApplication = context.getApplication(thing)
    const hasNoApplicationId = thing && typeof thing.applicationId !== 'string'
    return <StyledComponent>
      {!thing && <Loading />}
      {thing && (
        <>
          {hasNoApplicationId && (
            <Banner><p>{window.sticky._('APPLICATION_MISSING')}</p></Banner>
          )}
          {!hasNoApplicationId && !foundApplication && (
            <Banner><p>{window.sticky._('APPLICATION_DELETED')}</p></Banner>
          )}
          <ChildrenAndInlineLinkFrame
            inlineLinkFrameProps={(foundApplication && !hasMadeUpdate) ? {
              link: urls.goTest,
              backgroundColor: foundApplication.backgroundColor
            } : undefined}
          >
            <List
              emptyText={window.sticky._('NO_APPLICATIONS')}
              className={thing.canChangeApplication ? 'can-change-application' : 'cant-change-application'}
            >
              {applications.map(a => {
                return (
                  <ListItem
                    selected={thing.applicationId === a.id}
                    onChoose={(id) => {
                      thing.canChangeApplication && context.onUpdate('applicationId', id)
                    }}
                    disabled={!thing.canChangeApplication}
                    doHover={thing.canChangeApplication}
                    onAction={() => dispatch('REDIRECT', { to: `/me/flows/${a.id}` })}
                    key={a.id}
                    id={a.id}
                    icon={a.baseIcon}
                    actions={
                      [
                        user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced') && 'go-to'
                      ]
                        .filter(_ => _)
                    }
                    indentationOuter={a.indentation * 48}
                  >
                    {a.name}
                  </ListItem>
                )
              })}
            </List>
          </ChildrenAndInlineLinkFrame>
        </>
      )}
    </StyledComponent>
  }
}

export default tab
