import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Empty, Link } from '@openbox-app-shared'
import FederatedUser from './FederatedUser'

const StyledComponent = styled.ul`
  list-style-type: none;
  li {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 6px;
  }
  li + li {
    margin-top: 1rem;
  }
  li.selected {
    box-shadow: 0 0 0 2px rgb(38, 222, 129);
    outline: 0;
  }
  a {
    text-decoration: none;
  }
`

export default function FederatedUsersList ({ federatedUsers, onChoose, selected, linkOut = true }) {
  return (
    <>
      {federatedUsers.length === 0 && (
        <Empty>You don't have any team members yet.</Empty>
      )}
      {federatedUsers.length > 0 && <StyledComponent className='component--federated-users component--federated-users-list'>
        {federatedUsers.map(federatedUser => {
          const genericProps = {
            federatedUser
          }
          return (
            <li key={federatedUser.id} id={federatedUser.id} role='button' className={selected === federatedUser.id ? 'selected' : undefined} onClick={() => onChoose && onChoose(federatedUser)}>
              {linkOut && <Link to={`/me/team/${federatedUser.id}`}>
                <FederatedUser {...genericProps} />
              </Link>}
              {!linkOut && <FederatedUser {...genericProps} />}
            </li>
          )
        })}
      </StyledComponent>}
    </>
  )
}
FederatedUsersList.propTypes = {
  federatedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  onChoose: PropTypes.func,
  linkOut: PropTypes.bool
}
