/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet, DebouncedForm, Input, DynamicInput, LinkButton } from '@openbox-app-shared'

import Box from '../../components/Box'
import Logo from '../../components/Logo'
import MainFrame from '../../components/MainFrame'
import User from '../../components/User'
import H2 from '../../components/H2'
import dashboardIcons from '../../icons'

import _ from '../../_'

import { log } from '../../log'
import { dispatch } from '../../redux'
import HeaderBar from '../../components/HeaderBar'

const StyledRoute = styled.div`
  padding: 1rem;
  .buttons {
    margin-bottom: 1rem;
  }
  .component--h2 {
    margin-bottom: 0.5rem;
  }
  .component--form + .component--logo {
    margin-top: 1rem;
  }
  .grid {
    > * {
      > * + * {
        margin-top: 1rem;
      }
    }
    > * + * {
      margin-top: 1rem;
    }
  }
  .quick-actions {
    > * {
      display: block;
    }
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
`

export default class Route extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  // const things = await window.sticky.things.getAll()
  // const applications = await window.sticky.applications.getAll()
  // const products = await window.sticky.products.getAll()
  // const productCategories = await window.sticky.products.categories.getAll()

  render () {
    const { onUpdateUser, onSaveUser } = this.props

    const { user, match } = this.props
    log('[Route-getStarted] [render]', { user })
    return (
      <StyledRoute>
        <CustomHelmet title={`Home | ${user.name}`} />
        <MainFrame
          user={user}
          autoUi={this.props.autoUi}
          aside={<>
            <User user={user} whichPart={match.path} autoUi={this.props.autoUi} />
          </>}
          main={<>
            <HeaderBar text='Home' InlineIcon={dashboardIcons.home} user={user} />
            <div className='grid'>
              <div>
                <Box>
                  <H2>Quick actions</H2>
                  <div className='quick-actions'>
                    {user.federatedUserCan('applications') && <LinkButton
                      to='/me/flows'
                      sameTab
                      InlineIcon={dashboardIcons.application}
                      backgroundColor='#26de81'
                      onClick={() => {
                        dispatch('BLINK', { ids: ['NEW_APPLICATION'] })
                      }}
                    >
                      {window.sticky._('NEW_APPLICATION')}
                    </LinkButton>}
                    {user.federatedUserCan('things') && (user.partner ? user.partner.recommendedThingId : true) && <LinkButton
                      to='/me/stickies'
                      sameTab
                      InlineIcon={dashboardIcons.thing}
                      backgroundColor='#f7b731'
                      onClick={() => {
                        dispatch('BLINK', { ids: ['NEW_THING_1'] })
                      }}
                    >
                      Get stickies
                    </LinkButton>}
                  </div>
                </Box>
              </div>
            </div>
          </>}
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
