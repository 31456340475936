import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import Empty from './Empty'

const StyledTable = styled.table`
  border: 2px solid #F4F5F5;
  border-spacing: 0;
  border-radius: 6px;
  a {
    display: block;
    color: #EA2027;
  }
  a + a {
    margin-top: 1rem;
  }
  thead {
    tr > * {
      background-color: #F4F5F5;
      border-bottom: 2px solid #F4F5F5;
    }
  }
  tbody {
    > tr:not(:last-child) > * {
      border-bottom: 2px solid #F4F5F5;
    }
    td {
      img {
        display: block;
        width: 100%;
        margin: 0 auto 0 auto;
      }
      .component--tags + .component--tags {
        margin-top: 0.5rem;
      }
      .component--button {
        display: inline-block;
        padding: 0.125rem;
        width: 1.75rem !important;
        height: 1.75rem !important;
      }
      .component--button + .component--button {
        margin-left: 0.5rem;
      }
    }
  }
  tbody, thead {
    > tr {
      > * {
        padding: 0.75rem;
        font-size: 0.9rem;
        vertical-align: top;
        text-align: left;
      }
      > *:not(:last-child) {
        border-right: 2px solid #F4F5F5;
      }
    }
  }
`

const Table = ({ columns, rows, className, emptyText, ...rest }) => {
  const columnKeys = Object.keys(columns)
  const classNames = classnames('component--table', className)
  const allEmptyColumnValues = Object.values(columns).every(e => e.length === 0)
  if (rows.length === 0) {
    return <Empty className={className}>{emptyText || 'There\'s nothing to show here yet.'}</Empty>
  }
  return (
    <StyledTable className={classNames} {...rest}>
      {!allEmptyColumnValues && <thead>
        <tr>
          {columnKeys.map(ck => {
            return (
              <th key={ck}><strong>{columns[ck]}</strong></th>
            )
          })}
        </tr>
      </thead>}
      <tbody>
        {rows.map((r, i) => {
          return (
            <tr key={`tr--${i}`}>
              {columnKeys.map(ck => {
                return (
                  <td key={ck}>{r[ck]}</td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

Table.propTypes = {
  columns: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  emptyText: PropTypes.string
}

export default Table
