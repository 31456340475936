import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import { Input, Label } from './Input'

const timeToHour = (time) => {
  const i = (time - (time % 60)) / 60
  return i.toString().padStart(2, '0')
}

const timeToMinute = (time) => {
  const i = (time % 60)
  return i.toString().padStart(2, '0')
}

const hourMinuteToTime = (hour, minute, timezone) => {
  const [iHour, iMinute] = [hour, minute].map(v => parseInt(v, 10))
  return ((iHour * 60) + iMinute) - (timezone / 60)
}

const isValid = (v, upperLimit) => {
  if (typeof upperLimit !== 'number') {
    return true
  }
  // console.log('[TimePicker] [isValid]', { v, upperLimit })
  const asInt = parseInt(v, 10)
  return v.length === 2 && !isNaN(asInt) && asInt >= 0 && asInt < upperLimit
}

const StyledTimePicker = styled.div`
  label + * {
    margin-top: 0.5rem;
    // background-color: green;
  }
  .inputs {
    height: 2.5rem;
    .component--input, .divider {
      float: left;
    }
    .component--input {
      width: 42px;
    }
    .divider {
      height: 2.5rem;
      line-height: 2.5rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      // background-color: red;
    }
  }
`

export default function TimePicker ({ time, hourMax = 24, timezone, label, className, onChange, onIsValid }) {
  const timezoneAdjustedTime = time + (timezone / 60)
  const [hour, setHour] = useState(timeToHour(timezoneAdjustedTime))
  const [minute, setMinute] = useState(timeToMinute(timezoneAdjustedTime))
  const isHourValid = isValid(hour, hourMax)
  const isMinuteValid = isValid(minute, 60)
  console.log('[TimePicker]', { time, hourMax, timezoneAdjustedTime, hour, minute, isHourValid, isMinuteValid })
  return (
    <StyledTimePicker className={classnames('component--time-picker', className)}>
      {label && <Label>{label}</Label>}
      <div className='inputs'>
        <Input
          value={hour}
          isValid={isHourValid}
          onChange={value => {
            setHour(value)
            const isItValid = isValid(value, hourMax)
            if (isItValid) {
              const valueToFireWith = hourMinuteToTime(value, minute, timezone)
              console.log('[TimePicker] [onChange] hour', { value, valueToFireWith })
              onChange && onChange(valueToFireWith)
            }
            onIsValid && onIsValid(isItValid && isMinuteValid)
          }}
          maxLength={typeof hourMax === 'number' ? hourMax.toString().length : undefined}
        />
        <span className='divider'>:</span>
        <Input
          value={minute}
          isValid={isMinuteValid}
          onChange={value => {
            setMinute(value)
            const isItValid = isValid(value, 60)
            if (isItValid) {
              const valueToFireWith = hourMinuteToTime(hour, value, timezone)
              console.log('[TimePicker] [onChange] minute', { value, valueToFireWith })
              onChange && onChange(valueToFireWith)
            }
            onIsValid && onIsValid(isItValid && isHourValid)
          }}
          maxLength={2}
        />
      </div>
    </StyledTimePicker>
  )
}
TimePicker.propTypes = {
  time: PropTypes.number.isRequired,
  hourMax: PropTypes.number,
  timezone: PropTypes.number.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onIsValid: PropTypes.func
}
