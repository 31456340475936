import center from './files/center.svg'
import justify from './files/justify.svg'
import left from './files/left.svg'
import right from './files/right.svg'

export default {
  center,
  justify,
  left,
  right
}
