import React from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../../icons'
import TagChooserUser from '../../../components/TagChooserUser'

const StyledTab = styled.div`

`

const tab = {
  id: 'labels',
  name: (context) => 'Labels',
  inlineIcon: dashboardIcons.tags,
  to: (context) => `/me/flows/${context.state.application.id}/labels`,
  child: function Child (context, extraProps) {
    const { user } = context.props
    const { application } = context.state
    return (
      <StyledTab>
        <TagChooserUser
          user={user}
          tags={application.tags}
          onUpdate={(id) => {
            application.tags.toggle(id)
            context.onUpdate('tags', application.tags)
          }}
        />
      </StyledTab>
    )
  }
}

export default tab
