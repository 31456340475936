import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import ColourPickers from '../../components/ColourPickers'
import {
  Button,
  ColourPicker,
  Dropdown,
  Form,
  icons,
  Input,
  Modal,
  Switch,
  UploadImage,
  UrlInput,
} from '@openbox-app-shared'
import ButtonList from '../ButtonList'
import Box from '../Box'
import BankDetails from '../BankDetails'
import JsonEditor from '../JsonEditor'

const StyledModal = styled.div`
  .component--modal {
    max-width: 468px;
  }
  .component--box {
    margin-top: 2rem;
  }
  .component--input.type--number,
  .cost-per-sticker-per-day,
  .cost-per-month {
    input {
      width: 6rem;
    }
  }
  .component--button.save {
    display: block;
    margin: 2rem auto 0 auto;
    width: auto;
  }
  .component--json-editor {
    margin-top: 1rem;
  }
`

const extractGradientColors = (css) => {
  const cleanCss = css.replace(/;\s*;/g, ';').replace(/\s+/g, ' ').trim()
  const bodyRegex = /body\s*{[^}]*background(-image|-color)\s*:[^}]+}/gi
  const matches = cleanCss.match(bodyRegex)

  if (matches) {
    let startColor = null
    let endColor = null

    for (const match of matches) {
      // Check for background-color
      const bgColorMatch = match.match(
        /background-color\s*:\s*(#[A-Fa-f0-9]{3,6}|rgba?\([^)]+\))/i,
      )
      if (bgColorMatch && !startColor) {
        startColor = bgColorMatch[1]
      }

      // Check for linear or radial gradient
      const gradientMatch = match.match(/(linear|radial)-gradient\(([^)]+)\)/i)
      if (gradientMatch) {
        const colorStops = gradientMatch[2]
          .split(',')
          .map((stop) => stop.trim())
        // Extract colors, ignoring position values
        const colors = colorStops
          .map((stop) => {
            const colorMatch = stop.match(/(#[A-Fa-f0-9]{3,6}|rgba?\([^)]+\))/)
            return colorMatch ? colorMatch[1] : null
          })
          .filter(Boolean)

        // If there are more than 2 colors, return null
        if (colors.length > 2) {
          return null
        }

        if (colors.length === 2) {
          startColor = colors[0]
          endColor = colors[1]
        }
      }
    }

    if (startColor || endColor) {
      return {
        start: startColor,
        end: endColor || startColor,
      }
    }
  }

  return null
}

const extractBackgroundImageUrl = (css) => {
  const regex =
    /\.component--partner-cell\s*{\s*[^}]*background(?:-image)?\s*:\s*url\(\s*['"]?([^'"\)]+)['"]?\s*\)[^;}]*;/i
  const match = css.match(regex)
  return match ? match[1] : ''
}

export default function ThisModal({ partner, onUpdate, onSave, onCancel }) {
  const initialGradient = extractGradientColors(partner.css)
  const [gradientStart, setGradientStartColor] = useState(
    initialGradient?.start,
  )
  const [gradientEnd, setGradientEndColor] = useState(initialGradient?.end)

  const [stickyPayUrl, setStickyPayUrl] = useState(
    extractBackgroundImageUrl(partner.css),
  )

  useEffect(() => {
    let css = partner.css

    if (!gradientStart && !gradientEnd) {
      return
    }

    const newBackgroundImage = `linear-gradient(to bottom, ${gradientStart ?? gradientEnd} 0%, ${gradientEnd ?? gradientStart} 100%)`
    const bodyRegex = /(body\s*{[^}]*})/i

    if (bodyRegex.test(css)) {
      css = css.replace(bodyRegex, (match) => {
        let updatedContents = match

        if (/background-image\s*:/.test(updatedContents)) {
          updatedContents = updatedContents.replace(
            /(background-image\s*:)[^;]+;/,
            `$1 ${newBackgroundImage};`,
          )
        } else {
          updatedContents = updatedContents.replace(
            /body\s*{/,
            `body {\n  background-image: ${newBackgroundImage};`,
          )
        }

        return updatedContents
      })
    } else {
      css += `\nbody {\n  background-image: ${newBackgroundImage};\n}`
    }

    onUpdate('css', css)
  }, [gradientStart, gradientEnd])

  useEffect(() => {
    let css = partner.css
    const partnerCellRegex = /(\.component--partner-cell\s*{[^}]*})/i

    if (partnerCellRegex.test(css)) {
      css = css.replace(partnerCellRegex, (match) => {
        if (stickyPayUrl === '') {
          // Remove the background-image property if stickyPayUrl is empty
          return match.replace(/\s*background-image\s*:[^;]+;/, '')
        } else {
          // Update or add the background-image property
          if (/background-image\s*:/.test(match)) {
            return match.replace(
              /(background-image\s*:)[^;]+;/,
              `$1 url('${stickyPayUrl}') !important;`,
            )
          } else {
            return match.replace(
              /}/,
              `  background-image: url('${stickyPayUrl}') !important;\n}`,
            )
          }
        }
      })
    } else if (stickyPayUrl !== '') {
      // Add new rule if stickyPayUrl is not empty
      css += `\n.component--partner-cell {\n  background-image: url('${stickyPayUrl}') !important;\n}`
    }

    onUpdate('css', css)
  }, [stickyPayUrl])

  return (
    <StyledModal>
      <Modal onClose={onCancel}>
        <Form onChange={onUpdate} onSubmit={onSave} autoFocus>
          <Input
            label="Name"
            name="name"
            value={partner.name}
            isValid={partner.name.length > 0}
          />
          <UploadImage
            label="Logo"
            show
            value={partner.logoUrl}
            onChange={({ url }) => onUpdate('logoUrl', url)}
          />
          <Input
            label="Code"
            name="code"
            value={partner.code}
            isValid={partner.code.length > 0}
          />
          <hr />
          <Dropdown
            name="status"
            label="Status"
            items={partner.constructor.STATUSES.map((s) => ({
              id: s[0],
              name: s[1][0],
            }))}
            selected={partner.status}
          />
          <Input name="css" value={partner.css} type="code" label="CSS" />
          <ColourPickers>
            <ColourPicker
              name="gradientStart"
              label="Gradient Top Color"
              currentColour={gradientStart}
              onChoose={setGradientStartColor}
            />
            <ColourPicker
              name="gradientEnd"
              label="Gradient Bottom Color"
              currentColour={gradientEnd}
              onChoose={setGradientEndColor}
            />
          </ColourPickers>
          <UrlInput
            value={stickyPayUrl}
            label="Sticky Pay Image URL"
            onChange={setStickyPayUrl}
            doValidate={false}
          />
          <hr />
          <ButtonList
            label="Choose a currency"
            items={window.sticky.Stickypay.CURRENCIES.filter(
              (c) => c.userCreatable,
            ).map((c) => ({ id: c.id, name: c.userCreatable }))}
            selected={partner.currency}
            columns={3}
            onChoose={(id) => onUpdate('currency', id)}
          />
          <Dropdown
            name="country"
            label="Default region"
            selected={partner.country}
            items={[
              {
                id: '',
                name: '(None)',
              },
              ...window.sticky.internals.ISO_COUNTRIES.filter(
                (c) => c[1].userCreatable,
              ).map((ic) => ({
                id: ic[0],
                name: ic[1].name,
              })),
            ]}
          />
          <hr />
          <Dropdown
            name="gatewayId"
            label="Enforced payment provider"
            items={[
              {
                id: '',
                name: '(None)',
              },
              ...window.sticky.Stickypay.GATEWAYS.filter(
                (g) => !g.isExternal || !g.isExternal(),
              ).map((s) => ({
                id: s.id,
                name: s.name,
              })),
            ]}
            selected={partner.gatewayId}
          />
          <Input
            label="Default plan"
            name="defaultPlan"
            value={partner.defaultPlan}
          />
          <Input
            label="Recommended Sticky Store sticky ID"
            name="recommendedThingId"
            value={partner.recommendedThingId}
          />
          <Switch
            name="useExclusiveApplicationBases"
            checked={partner.useExclusiveApplicationBases}
          >
            Allow partner users to see only partner-level flow templates
          </Switch>
          <Switch
            name="createApplicationAndThing"
            checked={partner.createApplicationAndThing}
          >
            Create default flow and sticky
          </Switch>
        </Form>
        {partner.can('hide-billing') && (
          <Box>
            <BankDetails
              bankDetails={partner.bankDetails}
              onChange={() => onUpdate('bankDetails', partner.bankDetails)}
            />
          </Box>
        )}
        <JsonEditor
          name="underscoreExtension"
          json={partner.underscoreExtension.get()}
          includeKeys={[
            'STICKY_PAY',
            'NEW_APPLICATION',
            'APPLICATIONS',
            'APPLICATION',
            'NO_APPLICATIONS',
            'APPLICATION_MISSING',
            'APPLICATION_DELETED',
            'CHOOSE_APPLICATION',
            'CHOOSE_APPLICATIONS',
            'WHICH_APPLICATION',
            'CHOOSE_APPLICATION_BASE'
          ]}
          onChange={(newJson) => {
            partner.underscoreExtension.set(newJson)
            onUpdate('underscoreExtension', partner.underscoreExtension)
          }}
        />
        <Button
          className="save"
          icon={icons.inverted.check}
          onClick={onSave}
          disabled={!partner.isValid}
        >
          Done
        </Button>
      </Modal>
    </StyledModal>
  )
}

ThisModal.propTypes = {
  partner: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}
