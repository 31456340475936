import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, Modal, Button } from '@openbox-app-shared'
import H1 from '../H1'

const StyledModal = styled.div`
  .component--modal {
    max-width: 12rem;
    padding: 1rem;
    .component--h1 {
      text-align: center;
    }
    .component--h1 + .component--button {
      margin-top: 1rem;
    }
    .component--button {
      width: 10rem !important;
      height: 10rem !important;
      border-radius: 6px;
      img {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
      }
    }
  }
`

export default function ModalYes ({ text = 'Accept?', className, onGood }) {
  return (
    <StyledModal className={className}>
      <Modal canBeClosed={false}>
        {text && <H1>{text}</H1>}
        <Button onClick={onGood} icon={icons.inverted.check} backgroundColor='#40CF7A' />
      </Modal>
    </StyledModal>
  )
}

ModalYes.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onGood: PropTypes.func
}
