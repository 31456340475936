import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Button } from '@openbox-app-shared'
import styled from 'styled-components'
import classnames from 'classnames'

const WizardComponent = styled.div`
  margin-bottom:10px;

  .component--form > * + * {
    margin-top: 1.5rem;
  }
  .buttons {
    padding: 1rem 0 0;
    display: flex;
    justify-content: flex-end;
    .component--button {
      margin-left: 1rem;
      &.is-secondary {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  .component--form > p, .component--form > svg {
    text-align: center;
  }
  .component--form > svg {
    display: block;
    margin: 0 auto 0 auto;
    width: 6rem;
  }
  .component--h2 {
    text-align: center;
    line-height: 1.5rem;
  }
  .component--loading + .component--h2 {
    margin-bottom: 1.5rem;
  }
  .component--button-list.v-fatter {
    .component--button {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
  .component--button-list {
    margin-top: 1rem;
    > div {
      grid-gap: 0.5rem;
    }
    .component--button {
      padding: 0.25rem 0.25rem 0.125rem 0.25rem;
      > strong {
        font-size: 0.8rem;
        line-height: 1rem;
        min-height: 1rem;
      }
      > svg {
        margin-bottom: 0.5rem;
      }
    }
  }
  .component--button-list + .component--button-list {
    width: 100%;
    margin-top: 0.5rem;
  }
  .hint {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    .component--button {
      display: inline-block;
      margin-top: 0.125rem;
      padding: 0 0.25rem 0 0.25rem;
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.25);
      color: rgba(0, 0, 0, 0.5);
      border-width: 1px;
      strong {
        min-height: 1rem;
        line-height: 1rem;
      }
    }
  }
  .buttons + .hint {
    margin-top: 5.5rem;
    margin-bottom: 1.5rem;
  }
  a.switch-state {
    height: 2.5rem;
    line-height: 2.5rem;
    text-decoration: none;
    color: #1A1F35;
    outline: 0;
    border-radius: 4px;
  }
  a.switch-state:focus {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 2px 5px 0 rgb(60 66 87 / 12%), 0 1px 1px 0 rgb(0 0 0 / 12%), #cccbf0 0px 0px 0px 3px;
  }
`

export default function WizardContainer({ wizardState = {}, formDependencies, components, setWizardState, onSubmit }) {
  const [currentComponent, setCurrentComponent] = useState(0)
  const { component: Component, useComponentButtons, getHowManyStepsToGoNext, getHowManyStepsToGoPrevious, canGoNext: overrideCanGoNext } = components[currentComponent]

  const [canGoNext, setCanGoNext] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const { partner } = formDependencies

  const controlCanGoNext = (value) => {
    setCanGoNext(value)
  }

  const prevComponent = (prev = 1) => {
    if (currentComponent > 0) {
      setCurrentComponent(currentComponent - prev)
    }
  }

  const canReallyGoNext = typeof overrideCanGoNext === 'boolean' ? overrideCanGoNext : canGoNext
  const nextComponent = (next = 1) => {
    if (currentComponent < components.length - next && canReallyGoNext) {
      setCurrentComponent(currentComponent + next)
      controlCanGoNext(false)
    }
  }


  return (
    <WizardComponent>
      {
        <Component onUpdate={setWizardState} prevComponent={prevComponent} nextComponent={nextComponent} wizardState={wizardState} controlCanGoNext={controlCanGoNext} formDependencies={formDependencies} />
      }
      <div className="buttons">
        {!useComponentButtons && currentComponent === 0 && <Link sameTab className='switch-state' to={`/log-in${partner ? `/${partner.code}` : ''}`} style={{ color: partner ? partner.color : undefined }}>Log in</Link>}
        {!useComponentButtons && currentComponent > 0 && <Button className='is-secondary' onClick={() => {
          prevComponent(getHowManyStepsToGoPrevious ? getHowManyStepsToGoPrevious({ wizardState, formDependencies }) : 1)
        }}>
          ← Back
        </Button>}
        {!useComponentButtons && currentComponent < components.length - 1 && <Button className={classnames({ 'openbox--blinking': canReallyGoNext })} disabled={!canReallyGoNext} onClick={() => {
          nextComponent(getHowManyStepsToGoNext ? getHowManyStepsToGoNext({ wizardState, formDependencies }) : 1)
        }}>
          Next →
        </Button>}
        {!useComponentButtons && currentComponent === components.length - 1 &&
          <Button
            className={classnames({ 'openbox--blinking': canReallyGoNext })}
            disabled={!canReallyGoNext || disabledSubmit}
            onClick={async () => {
              setDisabledSubmit(true)
              try {
                await onSubmit(wizardState)
              } finally {
                setDisabledSubmit(false)
              }
            }}
          >
            Get started →
          </Button>
        }
      </div>
    </WizardComponent>
  )
}

WizardContainer.propTypes = {
  wizardState: PropTypes.object,
  formDependencies: PropTypes.object,
  components: PropTypes.array,
  setWizardState: PropTypes.func,
  onSubmit: PropTypes.func
}
