import React, { useRef, cloneElement } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import classnames from 'classnames'

const StyledExpandCollapse = styled.details`
  overflow: hidden;
  border-radius: 6px;
  transition: box-shadow 0.3s ease-in-out;
  ${props => css`
    background-color: ${props.backgroundColor || 'white'};
    border: 2px solid ${props.color || '#f4f5f5'};
    > div > p {
      padding: 0 0.5rem 0.5rem 0.5rem;
      color: ${props.color || '#f4f5f5'};
    }
  `}
  &:focus-within {
    box-shadow: #cccbf0 0px 0px 0px 3px;
    summary {
      outline: 0;
    }
  }
  &[open] {
    summary:before {
      transform: rotate(90deg);
    }
  }
  summary {
    padding: 1rem;
    display: block;
    padding-left: 2.2rem;
    position: relative;
    cursor: pointer;
    ${props => css`
      color: ${props.color || '#607481'};
    `}
  }
  summary:before {
    content: '';
    border-width: 0.5rem;
    border-style: solid;
    position: absolute;
    top: 1rem;
    left: 1rem;
    transform: rotate(0);
    transform-origin: 0.2rem 50%;
    transition: .25s transform ease;
    ${props => css`
      border-color: transparent transparent transparent ${props.color || '#607481'};
    `}
  }
  summary::-webkit-details-marker {
    display: none;
  }
  > div {
    padding: 1rem;
  }
  &.drag-here {
    border-color: black;
    border-style: dashed;
    summary {
      color: black;
    }
    summary:before {
      border-color: transparent transparent transparent black;
    }
  }
`

export function ExpandCollapse ({ isCollapsed, onToggle, className, color, backgroundColor, text, children, draggable }) {
  return (
    <StyledExpandCollapse
      className={classnames('component--expand-collapse', className)}
      color={color}
      backgroundColor={backgroundColor}
      open={typeof isCollapsed === 'boolean' ? !isCollapsed : undefined}
      draggable={draggable ? true : undefined}
      onToggle={onToggle}
      {...draggable}
    >
      <summary>{text}</summary>
      <div>
        {children}
      </div>
    </StyledExpandCollapse>
  )
}
ExpandCollapse.propTypes = {
  isCollapsed: PropTypes.bool,
  onToggle: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  draggable: PropTypes.object
}

const StyledExpandCollapseContainer = styled.div`
  > * + * {
    margin-top: 1rem;
  }
`

export function ExpandCollapseContainer ({ draggable = false, onDrag, children }) {
  let draggingI
  const refList = useRef()
  function binOff () {
    Array.from(refList.current.querySelectorAll('.component--expand-collapse')).forEach(el => {
      el.classList.remove('drag-here')
    })
  }
  return (
    <StyledExpandCollapseContainer ref={refList} className='component--expand-collapse-container'>
      {React.Children.map(children, (child, i) => {
        const extraProps = {
          draggable: draggable ? {
            onDragStart: e => {
              e.dataTransfer.effectAllowed = 'copyMove'
            },
            onDragStartCapture: e => {
              draggingI = i
            },
            onDragEndCapture: () => {
              draggingI = undefined
              binOff()
            },
            onDragOverCapture: e => {
              e.preventDefault()
              const t = (e.nativeEvent.path || (e.nativeEvent.composedPath && e.nativeEvent.composedPath())).find(el => el.nodeName === 'DETAILS' && el.classList.contains('component--expand-collapse'))
              if (!t) {
                return
              }
              binOff()
              t.classList.add('drag-here')
              t.focus()
            },
            onDropCapture: e => {
              e.preventDefault()
              binOff()
              onDrag && draggingI !== i && onDrag(draggingI, i)
            }
          } : undefined
        }
        return cloneElement(
          child,
          extraProps
        )
      })}
    </StyledExpandCollapseContainer>
  )
}
ExpandCollapseContainer.propTypes = {
  draggable: PropTypes.bool,
  onDrag: PropTypes.func,
  children: PropTypes.node
}