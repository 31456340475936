import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled from 'styled-components'

const StyledEmpty = styled.div`
  p {
    margin: 2rem auto 2rem auto;
    text-align: center;
    opacity: 0.75;
    font-size: 90%;
  }
`

export default function Empty ({ className, style = {}, children }) {
  return (
    <StyledEmpty className={classnames('component--empty', className)} style={style}>
      <p>
        {children}
        {!children && <span>There&rsquo;s nothing here.</span>}
      </p>
    </StyledEmpty>
  )
}
Empty.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
}
