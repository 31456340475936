import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet } from '@openbox-app-shared'
import dashboardIcons from '../../icons'

import Box from '../../components/Box'
import MainFrame from '../../components/MainFrame'
import User from '../../components/User'
import TabBar from '../../components/TabBar'

import _ from '../../_'
import HeaderBar from '../../components/HeaderBar'

import RenderChildHome from './RenderChildHome'
import RenderChildApplicationBlocks from './RenderChildApplicationBlocks'

const StyledRoute = styled.div`
  padding: 1rem;
  .tab-container {
    margin-top: 1rem;
  }
  .component--application-block-chooser {
    max-width: 498px;
  }
  .buttons {
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
  }
`

export default class Route extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.reload = this.reload.bind(this)
    this.onHasMadeUpdate = this.onHasMadeUpdate.bind(this)
  }

  async reload () {
    const { user } = this.props
    const applicationBlocks = await window.sticky.applications.blocks.getAll(user)
    this.setState({
      applicationBlocks
    })
  }

  async componentDidMount () {
    const { user } = this.props
    if (!user.can('develop')) {
      return window.sticky.applications.blocks.showError('You do not have permission to use this.')
    }
    await this.reload()
  }

  onHasMadeUpdate () {
    this.forceUpdate()
  }

  render () {
    const { user, match } = this.props
    return (
      <StyledRoute>
        <CustomHelmet title={`Developer | ${user.name}`} />
        <MainFrame
          user={user}
          autoUi={this.props.autoUi}
          aside={<>
            <User user={user} whichPart={match.path} autoUi={this.props.autoUi} />
          </>}
          main={
            <>
              <HeaderBar text='Developer' Icon={dashboardIcons.developer} user={user} />
              <Box>
                <TabBar
                  selectedTab={match.params.view}
                  tabs={[
                    {
                      id: 'about',
                      name: '',
                      inlineIcon: dashboardIcons.home,
                      to: '/developer/about',
                      child: <RenderChildHome context={this} />
                    },
                    {
                      id: 'flow-steps',
                      name: 'Flow steps',
                      inlineIcon: dashboardIcons.application,
                      to: '/developer/flow-steps',
                      child: <RenderChildApplicationBlocks context={this} />
                    }
                  ]}
                />
              </Box>
            </>
          }
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
