import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CustomHelmet, Loading } from '@openbox-app-shared'

import { log } from '../../log'
import ThingPayments from '../../components/ThingPayments'
import Box from '../../components/Box'
import HeaderBar from '../../components/HeaderBar'
import dashboardIcons from '../../icons'

const StyledRoute = styled.div`
  padding: 1rem;
`

export default class Route extends Component {
  constructor () {
    super()
    this.state = {}
  }

  async getPayments () {
    const { partnerPrivateKey, userPrivateKey } = window.sticky.getPayload()
    window.sticky.pay.setQuery(`userPrivateKey=${userPrivateKey}&partnerPrivateKey=${partnerPrivateKey}`)
    const payments = [...(await window.sticky.pay.getAll())].reverse()
    window.sticky.pay.resetQuery()
    const user = await window.sticky.users.getMe(true)
    log('[Route-partner-billing] [getPayments]', { user, payments, partnerPrivateKey, userPrivateKey })
    this.setState({
      user,
      payments
    })
  }

  async componentDidMount () {
    log('[Route-partner-billing] [componentDidMount]')
    this.getPayments()
  }

  render () {
    const { user, payments } = this.state
    const isReady = Array.isArray(payments)
    log('[Route-partner-billing] [render]', { payments })

    return (
      <StyledRoute>
        <CustomHelmet
          title='Billing'
        />
        {!isReady && <Loading />}
        {isReady && (
          <>
            <HeaderBar text={`Billing for ${user.name}`} Icon={dashboardIcons.payment} user={user} />
            <Box>
              <ThingPayments user={user} payments={payments} />
            </Box>
          </>
        )}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  match: PropTypes.object
}
