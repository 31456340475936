import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, SearchInput } from '@openbox-app-shared'
import dashboardIcons from '../icons'
import Box from './Box'
import TagChooserUser from './TagChooserUser'
import { log } from '../log'

const StyledComponent = styled.div`
  padding: 0.25rem;
  .component--input.search {
    width: 15rem;
  }
  .component--box {
    margin-top: 0.5rem;
  }
  .fb-buttons {
    float: right;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    > * {
      display: inline-block;
      vertical-align: top;
    }
    > * + * {
      margin-left: 0.5rem;
    }
  }
`

const doesFilterObjectHaveAnythingInIt = fo => fo.text.length > 0 || fo.tags.size > 0
const load = (k) => window.sticky.internals.readFromLocalStorage(`dashboard--filter-object--${k}`)
const save = (k, v) => window.sticky.internals.writeToLocalStorage(`dashboard--filter-object--${k}`, v)

function getNewFilterObject () {
  return {
    text: '',
    tags: window.sticky.newPatchableSet([]),
    hasSomethingInIt: false
  }
}

export default class FilterButton extends Component {
  constructor (props) {
    super(props)
    const { memoryKey, onChange } = props

    const maybeFilterObject = load(memoryKey)

    const finalFilterObject = maybeFilterObject ? {
      ...maybeFilterObject,
      tags: window.sticky.newPatchableSet(maybeFilterObject.tags)
    } : getNewFilterObject()
    setTimeout(() => onChange(finalFilterObject), 0)

    this.state = {
      filterObject: finalFilterObject
    }
  }

  render () {
    const { user, memoryKey, turnedOnColor, filterByTags = true, onChange } = this.props
    const { filterObject } = this.state
  
    const reallySetFilterObject = (newFilterObject) => {
      save(memoryKey, {
        ...newFilterObject,
        tags: newFilterObject.tags.toArray()
      })
      this.setState({ filterObject: newFilterObject })
      onChange(newFilterObject)
    }
  
    function onSearchChange (text) {
      const { text: oldText } = filterObject
      const newFilterObject = {
        ...filterObject,
        text: typeof text === 'string' ? text : oldText
      }
      newFilterObject.hasSomethingInIt = doesFilterObjectHaveAnythingInIt(newFilterObject)
      reallySetFilterObject(newFilterObject)
    }

    return (
      <Button
        title='Filter'
        isSecondary
        InlineIcon={dashboardIcons.filter}
        turnedOn={doesFilterObjectHaveAnythingInIt(filterObject) && (turnedOnColor || '#1a1f34')}
        onClick={(listItemsOpen) => {
          !listItemsOpen && this.searchInputAutoFocus && this.searchInputAutoFocus()
        }}
        onReady={({ doCollapse }) => {
          this.howToHide = doCollapse
        }}
        dropdownWidth={256}
        DropdownElement={(
          <StyledComponent>
            <SearchInput
              value={filterObject ? filterObject.text : undefined}
              onChange={onSearchChange}
              onReady={({ autoFocus }) => {
                this.searchInputAutoFocus = () => autoFocus(true)
              }}
              onDone={() => {
                onSearchChange()
                this.howToHide && this.howToHide()
              }}
            />
            {filterByTags && filterObject && user.tags.get().length > 0 && <>
              <Box>
                <TagChooserUser
                  user={user}
                  tags={filterObject.tags}
                  onUpdate={(id) => {
                    filterObject.tags.toggle(id)
                    const newFilterObject = {...filterObject}
                    newFilterObject.hasSomethingInIt = doesFilterObjectHaveAnythingInIt(newFilterObject)
                    reallySetFilterObject(newFilterObject)
                  }}
                />
              </Box>
            </>}
            <div className='fb-buttons'>
              <Button
                className='done'
                isThin
                InlineIcon={dashboardIcons.check}
                onClick={() => {
                  this.howToHide && this.howToHide()
                }}
              >
                Done
              </Button>
              <Button
                className='clear'
                isSecondary
                isThin
                InlineIcon={dashboardIcons.void}
                onClick={() => {
                  const newFilterObject = getNewFilterObject()
                  reallySetFilterObject(newFilterObject)
                }}
              >
                Clear
              </Button>
            </div>
          </StyledComponent>
        )}
      />
    )
  }
}

FilterButton.propTypes = {
  user: PropTypes.object,
  memoryKey: PropTypes.string,
  turnedOnColor: PropTypes.string,
  onChange: PropTypes.func,
  filterByTags: PropTypes.bool
}
