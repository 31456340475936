import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import classnames from 'classnames'

import { SearchInput, Button, LinkButton, Price } from '@openbox-app-shared'
import H2 from './H2'

const StyledComponent = styled.div`
  .component--input.search {
    max-width: 16rem;
    margin: 0 auto 1rem auto;
  }
  section {
    padding: 0.75rem 0 0 1rem;
    border-radius: 4px;
    border: 1px solid #A9B7C6;
    h2 {
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
    }
  }
  section + section {
    margin-top: 1rem;
  }
  .buttons {
    .component--button {
      vertical-align: top;
      display: inline-block;
      width: 6.5rem !important;
      height: 6.5rem;
      margin: 0 1rem 1rem 0;
      background-size: 100% 100%;
      img + * {
        margin-top: 0.5rem;
      }
      strong {
        line-height: 0.9rem;
        font-size: 0.9rem;
      }
      .component--price, .has-bought {
        display: block;
        margin-top: 4px;
        color: #D4AF37;
      }
    }
    ${props => props.goTo && css`
      > .component--button button {
        width: 100%;
        height: 100%;
        padding: 0.1rem;
      }
      > .component--button.show-price button {
        border: 2px solid #D4AF37;
      }
      > .component--button.has-bought button {
        border: 2px solid #D4AF37;
      }
    `}
    ${props => !props.goTo && css`
      .component--button {
        padding: 0.1rem;
        &.show-price {
          border: 2px solid #D4AF37;
        }
        &.has-bought {
          border: 2px solid #D4AF37;
        }
      }
    `}
  }
`

const CATEGORIES = [
  {
    id: 'user',
    doesMatch: ({ ab, user }) => ab.hasUserId && !ab.hasPartnerId,
    name: ({ user }) => user.name
  },
  {
    id: 'partner',
    doesMatch: ({ ab, user }) => ab.hasPartnerId && !ab.hasUserId,
    name: ({ user }) => user.partner ? `From ${user.partner.name}` : 'From my partner'
  },
  ...['Look and feel', 'Form', 'Community', 'Logic', 'Products and payments', 'Metaverse', 'Physical spaces', 'Consumer memory', 'Sticky memory', 'Button actions', 'Advanced'].map(_ => ({
    id: _,
    doesMatch: ({ ab, user }) => !ab.hasUserId && !ab.hasPartnerId && ab.tag === _, // && ab.price[user.currency] === 0
    name: ({ user }) => _
  }))
]

function doStringsMatch (string1, string2) {
  return string1.toLowerCase().includes(string2.toLowerCase())
}

export default function ApplicationBlockChooser ({ user, applicationBlocks, onChoose, goTo, doDisableIterator }) {
  const [filter, setFilter] = useState('')
  const WhichButtonComponent = goTo ? LinkButton : Button
  return (
    <StyledComponent goTo={goTo} className='component--application-block-chooser'>
      <SearchInput
        onChange={setFilter}
        autoFocus
      />
      {CATEGORIES.map(category => {
        const name = category.name({ user })
        const doesNameMatch = doStringsMatch(name, filter)
        const applicableAbs = applicationBlocks.filter(ab => {
          const matchesTags = category.doesMatch({ ab, user })
          const matchesFilter = filter.length === 0 ? true : doStringsMatch(ab.name, filter)
          return [matchesTags, doesNameMatch ? true : matchesFilter].every(e => e)
        })
        const doesAbsMatch = applicableAbs.length > 0
        if (!doesAbsMatch) {
          return null
        }
        return (
          <section key={category.id} style={{ borderColor: category.color }}>
            <H2 color={category.color}>{name}</H2>
            <div className='buttons'>
              {applicableAbs.map((applicationBlock, i) => {
                let isDisabled = false
                if (doDisableIterator) {
                  isDisabled = doDisableIterator(applicationBlock)
                }
                const price = applicationBlock.price[user.currency]
                const hasBought = user.ownApplicationBlockIds.has(applicationBlock.id)
                const showPrice = price > 0 && !hasBought
                return (
                  <WhichButtonComponent
                    key={`ab-${i}`}
                    icon={applicationBlock.calculatedIcon}
                    bigIcon
                    isSecondary
                    onClick={() => onChoose && onChoose(applicationBlock)}
                    to={goTo ? goTo(applicationBlock.id) : undefined}
                    sameTab
                    disabled={isDisabled}
                    className={classnames({ 'show-price': showPrice, 'has-bought': hasBought })}
                  >
                    {applicationBlock.name}
                    {showPrice && <><br /><Price price={price} currency={user.currency} /></>}
                    {hasBought && <><br /><span className='has-bought'>✓</span></>}
                  </WhichButtonComponent>
                )
              })}
            </div>
          </section>
        )
      })}
    </StyledComponent>
  )
}
ApplicationBlockChooser.propTypes = {
  user: PropTypes.object.isRequired,
  applicationBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChoose: PropTypes.func,
  goTo: PropTypes.func,
  doDisableIterator: PropTypes.func
}
