import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form } from '@openbox-app-shared'

import CodeEditor2 from '../../../../components/CodeEditor2'

const StyledComponent = styled.div`
  .component--code-editor + .component--code-editor {
    margin-top: 1rem;
  }
  .component--code-editor.is-maximized {
    margin-top: 0;
  }
`

export default function TabCode ({ context, onHasMadeUpdate, onSave }) {
  const { applicationBlock, disabled } = context
  const genericCeProps = {
    disabled,
    controls: ['expand', 'save']
  }
  function onControl (whichControl, whichAbPart, whichAbValue) {
    whichControl === 'save' && (() => {
      onHasMadeUpdate(whichAbPart, whichAbValue)
      onSave()
    })()
  }
  return (
    <StyledComponent>
      <Form onChange={onHasMadeUpdate}>
        <CodeEditor2 {...genericCeProps} onControl={(whichControl, whichAbValue) => onControl(whichControl, 'html', whichAbValue)}    name='html'    language='html' label='Content'         code={applicationBlock.html} />
        <CodeEditor2 {...genericCeProps} onControl={(whichControl, whichAbValue) => onControl(whichControl, 'css', whichAbValue)}     name='css'     language='css'  label='Appearance'      code={applicationBlock.css} />
        <CodeEditor2 {...genericCeProps} onControl={(whichControl, whichAbValue) => onControl(whichControl, 'js', whichAbValue)}      name='js'      language='js'   label='Logic'           code={applicationBlock.js} />
        <CodeEditor2 {...genericCeProps} onControl={(whichControl, whichAbValue) => onControl(whichControl, 'jsAsync', whichAbValue)} name='jsAsync' language='js'   label='Scheduled logic' code={applicationBlock.jsAsync} />
      </Form>
    </StyledComponent>
  )
}
TabCode.propTypes = {
  context: PropTypes.object,
  onHasMadeUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
