import { error } from './log'

const beepTime = (x) => {
  if (x.target.currentTime > 19) {
    x.target.currentTime = 10
  }
}

const beep = (soundFileName = 'none') => {
  let beeper
  if (soundFileName !== 'none') {
    try {
      beeper = new Audio()
      beeper.src = `${window.sticky.cdn}/${soundFileName}.m4a`
      beeper.addEventListener('timeupdate', beepTime)
    } catch (e) {
      error('[beep] cannot create beeper', e)
    }
  }

  return {
    start: () => {
      if (!beeper) {
        error('[beep] [start] !beeper')
        return
      }
      try {
        beeper.play()
          .catch(e => {
            error('[beep] [start] beeper.play failed 1', e)
          })
      } catch (e) {
        error('[beep] [start] beeper.play failed 2', e)
      }
    },
    beep: () => {
      if (!beeper) {
        error('[beep] [beep] !beeper')
        return
      }
      beeper.currentTime = 0
    },
    unbeep: () => {
      if (!beeper) {
        error('[beep] [unbeep] !beeper')
        return
      }
      beeper.removeEventListener('timeupdate', beepTime)
      beeper = undefined
    }
  }
}

export default beep
