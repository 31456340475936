import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import EVENT_TYPES from '../../components/Events/EVENT_TYPES'
import { CustomHelmet, Button, LinkButton, Loading } from '@openbox-app-shared'

import { log } from '../../log'

const StyledRoute = styled.div`
  padding: 1rem;
  .buttons {
    margin-bottom: 1rem;
    > * {
      display: inline-block;
      margin: 0 1rem 0.5rem 0;
      vertical-align: top;
    }
  }
`

export default class Route extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {
    const { eventId } = this.props.match.params
    const event = await window.sticky.session.events.get(eventId)
    const eventType = EVENT_TYPES.find(([type]) => type === event.type)[1]
    this.setState({
      event,
      eventType
    })
  }

  render () {
    const { user } = this.props
    const { event, eventType } = this.state
    log('[Route-event] [render]', { user, event, eventType })

    return (
      <StyledRoute>
        {!typeof event === 'object' && <Loading />}
        {typeof event === 'object' && (
          <>
            <CustomHelmet
              title={`Event: ${eventType.textRaw()}`}
            />
            <div className='print-hide buttons'>
              <LinkButton to='/my/account/more' sameTab>
                ← Back to dashboard
              </LinkButton>
              <Button isSecondary onClick={() => window.print()}>
                Print
              </Button>
            </div>
            <div className='rendered'>
              {eventType.toFull(user, event)}
            </div>
          </>
        )}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object
}
