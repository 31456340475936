import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Loading, Link, LinkButton } from '@openbox-app-shared'

import { PaymentSummary } from './payments'
import ScrollableTable from './ScrollableTable'

const COLUMNS = {
  link: '',
  status: '',
  application: 'Flow',
  thing: 'Sticky',
  // total: 'Total',
  // '-': '',
  // weGet: 'Fee',
  // '=': '',
  // theyGet: 'You get'
}

const StyledComponent = styled.div`
  > .component--h1 {
    margin-bottom: 0.75rem;
  }
  .component--button {
    margin-bottom: 1rem;
  }
  .component--table {
    width: 100%;
    tr > *:nth-child(1) {
      min-width: 9rem;
      > * {
        display: block;
      }
      > * + * {
        margin-top: 0.5rem;
      }
    }
    tr > *:nth-child(2) {
    }
    tr > *:nth-child(3) {
      min-width: 10rem; max-width: 10rem; width: 10rem;
    }
    tr > *:nth-child(4) {
      min-width: 10rem; max-width: 10rem; width: 10rem;
    }
  }
  .column-debug {
    font-size: 110%;
    letter-spacing: 1px;
    text-decoration: none;
    code {
      font-weight: bold;
    }
  }
  a.column-debug {
    color: #322CBE;
  }

  .component--button.column-entity {
    display: inline-block;
    vertical-align: top;
    margin: 0 0.5rem 0.5rem 0;
    width: auto !important;
    height: auto !important;
    button {
      padding: 0.25rem;
    }
    &.thing img {
      width: 15px;
    }
  }
`

export default function AbstractedPayments ({ user, payments, onSomethingClicked }) {
  const columns = {
    ...COLUMNS
  }
  const rows = Array.isArray(payments)
    ?
    (payments.map(p => {
      return {
        link: <>
          {p.userPaymentId && <strong><code>{p.userPaymentId}</code></strong>}
          {p.name && <strong>{p.name}</strong>}
          {user.federatedUserCan('payments') && <Link onClick={onSomethingClicked} className='column-debug' to={`/me/payments/${p.id}`}><code>{p.consumerIdentifier}</code> →</Link>}
          {!user.federatedUserCan('payments') && <strong className='column-debug'><code>{p.consumerIdentifier}</code></strong>}
        </>,
        status: <PaymentSummary payment={p} />,
        application: p.application ? <LinkButton className='column-entity' isSecondary sameTab disabled={!(user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced'))} onClick={onSomethingClicked} to={`/me/flows/${p.application.id}`} icon={p.application.baseIcon}>{p.application.name}</LinkButton> : null,
        thing: p.thing ? <LinkButton className='column-entity thing' isSecondary sameTab disabled={!user.federatedUserCan('things')} onClick={onSomethingClicked} to={`/me/stickies/${p.thing.id}`} icon={p.thing.designUrl}>{p.thing.name}</LinkButton> : null
      }
    }))
    :
    undefined

  if (rows && !rows.some(e => e.thing)) {
    delete columns.thing
  }

  return (
    <StyledComponent className='component--abstracted-payments'>
      <>
        {!rows && <Loading />}
        {rows && (
          <ScrollableTable
            rows={rows}
            columns={columns}
          />
        )}
      </>

    </StyledComponent>
  )
}
AbstractedPayments.propTypes = {
  user: PropTypes.object.isRequired,
  payments: PropTypes.arrayOf(PropTypes.object),
  onSomethingClicked: PropTypes.func
}
