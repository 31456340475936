/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { icons, CustomHelmet, Input, Button } from '@openbox-app-shared'
import dashboardIcons from '../../icons'
import ScrollableTable from '../../components/ScrollableTable'
import { dispatch } from '../../redux'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--scrollable-table + .component--input, .component--input + .component--button {
    margin-top: 1rem;
  }
`

const COLUMNS = {
  'meta': '',
  'howToConfig': 'URL',
  'config': 'Configuration'
}

function RenderChild ({ context, dbfProps }) {
  const { user } = context.props
  const { onChange } = dbfProps
  return (
    <StyledComponent>
      <CustomHelmet title={`Hardware | Account | ${user.name}`} />
      <ScrollableTable
        columns={COLUMNS}
        rows={user.hardwareRows.map(r => (
          {
            meta: <p><strong>{r.name}</strong><br /><br />{r.prettyType}</p>,
            prettyType: r.prettyType,
            howToConfig: <code>{r.howToConfig({ user })}</code>,
            config: <code>{r.prettyConfig}</code>
          }
        ))}
      />
      <Input
        type='code'
        value={user.hardware.toJson()}
        name='hardware'
        onChange={v => {
          user.hardware.patch(JSON.parse(v))
          onChange('hardware', user.hardware)
        }}
      />
      <Button
        icon={icons.inverted.delete}
        onClick={() => dispatch('TRIGGER', { trigger: 'deleteAllHardwareJobs' })}
      >Delete all jobs</Button>
    </StyledComponent>
  )
}

const tab = {
  id: 'hardware',
  name: (context) => 'Hardware',
  inlineIcon: dashboardIcons.printer,
  to: (context) => '/my/account/hardware',
  child: props => <RenderChild {...props} />
}

export default tab
