import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@openbox-app-shared'
import ButtonList from '../../../components/ButtonList'
import H2 from '../../../components/H2'

export default function SetLocation({ onUpdate, wizardState, controlCanGoNext }) {
  return (
    <Form>
      <H2>Where are you based?</H2>
      <ButtonList
        items={[
          ...window.sticky.internals.ISO_COUNTRIES
            .filter(c => c[1].userCreatable)
            .map(c => ({ id: c[0], name: c[1].name, inlineIconString: c[1].icon }))
        ]}
        selected={wizardState.country}
        columns={2}
        bigIcon
        onChoose={v => {
          const delta = {
            'country': v,
            'currency': window.sticky.internals.ISO_COUNTRIES.find(c => c[0] === v)[1].defaultCurrency,
            'timezone': window.sticky.internals.ISO_COUNTRIES.find(c => c[0] === v)[1].defaultTimezone
          }
          onUpdate(delta)
          controlCanGoNext(true)
        }}
      />
      <p className='hint'>
        Not in the list? Choose United Kingdom.
      </p>
    </Form>
  )
}

SetLocation.propTypes = {
  onUpdate: PropTypes.func,
  wizardState: PropTypes.object,
  controlCanGoNext: PropTypes.func,
}