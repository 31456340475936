import React from 'react'
import PropTypes from 'prop-types'
import { icons, Button, CustomHelmet, Loading } from '@openbox-app-shared'

import { log } from '../../log'

import ApplicationBlockChooser from '../../components/ApplicationBlockChooser'
import ApplicationBlock from './ApplicationBlock/ApplicationBlock'

export default function RenderChildApplicationBlocks ({ context }) {
  const { user, match } = context.props
  const { applicationBlocks } = context.state
  const { entityId, viewSubroot } = match.params
  log('[Route-developer] [RenderChildApplicationBlocks]', { entityId, viewSubroot })
  if (!applicationBlocks) {
    return <Loading />
  }
  if (entityId) {
    const applicationBlock = applicationBlocks.find(ab => ab.id === entityId)
    return <ApplicationBlock view={viewSubroot} user={user} applicationBlock={applicationBlock} onHasMadeUpdate={context.onHasMadeUpdate} />
  }
  return (
    <>
      <CustomHelmet title={`Flow Steps | Developer | ${user.name}`} />
      <div className='buttons'>
        <Button
          backgroundColor='#26de81'
          icon={icons.inverted.add}
          onClick={async () => {
            const r = await window.sticky.applications.blocks.renderInlineEventsButton(
              [
                {
                  'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                  'config': {
                    'label': 'Name',
                    'labelForm': 'name',
                    'type': 'Text',
                    'value': 'My flow step',
                    'disabled': false,
                    'required': true,
                    'stashUser': false,
                    'isHidden': false
                  }
                },
                {
                  'id': '4e6f993d-ddc9-43ea-aadb-7080bee647bc',
                  'config': {
                    'label': 'Icon',
                    'labelForm': 'icon',
                    'buttonText': 'Upload',
                    'required': true,
                    'stashUser': false,
                    'customValue': `uploadImage:${window.sticky.cdn}/application-blocks/new.svg`
                  }
                },
                {
                  'id': '100ada2b-1375-42c0-958a-49e7187a7d73',
                  'config': {
                    'label': 'Create a wrapping container',
                    'labelForm': 'rendersAContainer',
                    'stashUser': false,
                    'value': true
                  }
                }
              ],
              'Add'
            )
            if (!r) {
              return
            }
            const payload = {
              name: r.name,
              icon: r.icon,
              rendersAContainer: r.rendersAContainer === 'Yes'
            }
            await window.sticky.applications.blocks.create(user, payload)
            context.reload()
          }}
        >
          Add
        </Button>
      </div>
      <ApplicationBlockChooser user={user} applicationBlocks={applicationBlocks} goTo={(id) => `/developer/flow-steps/${id}`} />
    </>
  )
}
RenderChildApplicationBlocks.propTypes = {
  context: PropTypes.object
}