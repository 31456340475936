import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem } from '@openbox-app-shared'
import _ from '../_'

export default function ThingChooser ({ applicableEntities, selected, allowNone, onChange }) {
  return (
    <List limitHeight={284} emptyText={_('NO_THINGS')}>
      {[
        allowNone && {
          id: '',
          key: '',
          name: '(All)'
        },
        ...applicableEntities
      ]
        .filter(e => e)
        .map(li => {
          return (
            <ListItem
              id={li.id}
              key={li.key}
              icon={li.designUrl}
              onChoose={onChange}
              indentationOuter={li.indentation * 32}
              selected={li.id === selected}
            >
              {li.name}
            </ListItem>
          )
        })
      }
    </List>
  )
}
ThingChooser.propTypes = {
  applicableEntities: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
  allowNone: PropTypes.bool,
  onChange: PropTypes.func
}
