import React from 'react'
import styled from 'styled-components'
import LocationPasteGoogleMapsUrl from '../../../components/LocationPasteGoogleMapsUrl'
import { icons, Loading } from '@openbox-app-shared'

const StyledComponent = styled.div`
  padding-top: 1rem;
`

const tab = {
  id: 'location',
  name: (context) => 'Place',
  icon: icons.generic.location,
  to: (context) => context.state.thing ? `/me/stickies/${context.state.thing.id}/location` : undefined,
  child: function Child (context, extraProps) {
    const { thing } = context.state
    return <StyledComponent>
      {!thing && <Loading />}
      {thing && (
        <LocationPasteGoogleMapsUrl
          name='location'
          location={thing.location}
          label={thing.name}
          onChange={(props) => context.onUpdate('location', props)}
          showYouOnMap
        />
      )}
    </StyledComponent>
  }
}

export default tab
