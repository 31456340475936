import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import InlineLinkFrame from './InlineLinkFrame'

const StyledComponent = styled.div`
  > * + * {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 1130px) {
    > * + * {
      margin-top: 0;
    }
    > * {
      vertical-align: top;
    }
    .component--inline-link-frame {
      display: inline-block;
    }
    ${props => css`
      &.has-inline-link-frame {
        .children {
          display: inline-block;
          width: calc(100% - 376px - ${props.distance}px);
        }
        .component--inline-link-frame {
          position: sticky;
          top: 1rem;
          width: 376px;
          margin-left: ${props.distance}px;
        }
      }
    `}
  }
`

export default function ChildrenAndInlineLinkFrame ({ children, distance = 16, inlineLinkFrameProps }) {
  return (
    <StyledComponent className={classnames('component--children-and-inline-link-frame', { 'has-inline-link-frame': inlineLinkFrameProps })} distance={distance}>
      <div className='children'>{children}</div>
      {inlineLinkFrameProps && <InlineLinkFrame {...inlineLinkFrameProps} />}
    </StyledComponent>
  )
}
ChildrenAndInlineLinkFrame.propTypes = {
  children: PropTypes.node,
  distance: PropTypes.number,
  inlineLinkFrameProps: PropTypes.object
}
