import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TagChooser } from './TagChooser'

import { icons, LinkButton } from '@openbox-app-shared'

const StyledComponent = styled.div`
  position: relative;
`

export default function TagChooserUser ({ user, tags, can, onUpdate, canManage = true, showAll = true }) {
  const userTags = showAll ? user.tags.get() : user.tags.get().filter(_ => tags.has(_.id))
  return (
    <StyledComponent className='component--tag-chooser-user'>
      <TagChooser
        all={userTags}
        set={tags}
        onUpdate={onUpdate}
        can={can}
      />
      {canManage && <LinkButton
        to='/my/account/labels'
        sameTab
        isSecondary
        isThin
        icon={icons.generic.edit}
      >
        Manage
      </LinkButton>}
    </StyledComponent>
  )
}
TagChooserUser.propTypes = {
  user: PropTypes.object,
  tags: PropTypes.object,
  onUpdate: PropTypes.func,
  can: PropTypes.bool,
  canManage: PropTypes.bool,
  showAll: PropTypes.bool
}
