import React from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../../icons'
import { Loading } from '@openbox-app-shared'
import TagChooserUser from '../../../components/TagChooserUser'

const StyledTab = styled.div`
  padding-top: 1rem;
`

const tab = {
  id: 'labels',
  name: (context) => 'Labels',
  inlineIcon: dashboardIcons.tags,
  to: (context) => context.state.thing ? `/me/stickies/${context.state.thing.id}/labels` : undefined,
  child: function Child (context, extraProps) {
    const { user } = context.props
    const { thing } = context.state
    return (
      <StyledTab>
        {!thing && <Loading />}
        {thing && (
          <TagChooserUser
            user={user}
            tags={thing.tags}
            onUpdate={(id) => {
              thing.tags.toggle(id)
              context.onUpdate('tags', thing.tags)
            }}
          />
        )}
      </StyledTab>
    )
  }
}

export default tab
