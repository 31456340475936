import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { icons, Modal, Input, Button } from '@openbox-app-shared'

const StyledModal = styled.div`
  p {
    line-height: 1.25rem;
    text-align: center;
  }
  p + * {
    margin-top: 1rem;
  }
  .buttons {
    width: fit-content;
    margin: 2rem auto 0 auto;
    padding-left: 1rem;
    .component--button {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
  }
`

export default function ThisModal ({ hint, onDone, onCancel }) {
  return (
    <Modal onClose={onCancel}>
      <StyledModal>
        <p><strong>{hint || 'Are you sure? There is no undo.'}</strong></p>
        <div className='buttons'>
          <Button
            backgroundColor='#FF3838'
            icon={icons.inverted.check}
            onClick={onDone}
          >
            Yes
          </Button>
          <Button
            isSecondary
            onClick={onCancel}
          >
            No
          </Button>
        </div>
      </StyledModal>
    </Modal>
  )
}
ThisModal.propTypes = {
  hint: PropTypes.string,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
}
