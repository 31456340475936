import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { icons, Modal, Input, Button } from '@openbox-app-shared'

const StyledModal = styled.div`
  p {
    line-height: 1.25rem;
  }
  p + * {
    margin-top: 1rem;
  }
  .hint {
    color: #FF3838;
  }
  .component--button {
    display: block;
    margin: 2rem auto 0 auto;
    width: auto;
  }
`

export default function ThisModal ({ mustTypeString, hint, onDone, onCancel }) {
  const [whatTheyHaveTyped, setWhatTheyHaveTyped] = useState('')
  const isValid = mustTypeString.toLowerCase() === whatTheyHaveTyped.toLowerCase()
  return (
    <Modal onClose={onCancel}>
      <StyledModal>
        {hint && <p className='hint'><strong>{hint}</strong></p>}
        <p>Please type "<strong>{mustTypeString}</strong>":</p>
        <Input
          name='mustTypeString'
          value={whatTheyHaveTyped}
          onChange={(v) => setWhatTheyHaveTyped(v)}
          onDone={() => {
            isValid && onDone()
          }}
          autoFocus
        />
        <Button
          icon={icons.inverted.check}
          onClick={onDone}
          disabled={!isValid}
        >
          Done
        </Button>
      </StyledModal>
    </Modal>
  )
}
ThisModal.propTypes = {
  mustTypeString: PropTypes.string.isRequired,
  hint: PropTypes.string,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
}
