/* eslint-disable react/prop-types */

import React from 'react'
import classnames from 'classnames'

import { Modal } from '@openbox-app-shared'
import H1 from '../../../components/H1'
import _ from '../../../_'
import ButtonGrid from '../../../components/ButtonGrid'
import ThingButton from '../../ThingButton'

export default {
  trigger: ({ setState, thisState, dispatch, why, thingIds, highlightedThingIds = [], hint, miscEntity, allowNone }) => {
    const applicableEntities = thisState.entities.things.filter(t => {
      return (Array.isArray(thingIds) ? thingIds.includes(t.id) : true)
    })
    if (applicableEntities.length === 0) {
      dispatch('CHOOSE_THING_GOOD', { thing: undefined, why, miscEntity })
      return
    }
    if (applicableEntities.length === 1) {
      dispatch('CHOOSE_THING_GOOD', { thing: applicableEntities[0], why, miscEntity })
      return
    }
    setState({
      CHOOSE_THING: {
        applicableEntities,
        why,
        hint,
        highlightedThingIds,
        miscEntity,
        allowNone,
        filter: ''
      }
    })
  },
  render: function ThisAction ({ state, setState, dispatch }) {
    const { CHOOSE_THING } = state
    if (!CHOOSE_THING) {
      return null
    }
    const { why, hint, highlightedThingIds, applicableEntities, miscEntity, allowNone, filter } = CHOOSE_THING
    const onDone = ({ thing }) => dispatch('CHOOSE_THING_GOOD', { thing, why, miscEntity })
    return (
      <>
        <Modal
          className='choose-entity grid'
          onClose={() => setState({ CHOOSE_THING: undefined })}
          closeColor='white'
          outerStyle={{ zIndex: 8 }}
        >
          <H1>{hint || _('CHOOSE_THING')}</H1>
          {/* <SearchInput
            onChange={_ => {
              setState({ CHOOSE_THING: {
                ...CHOOSE_THING,
                filter: _
              } })
            }}
            value={filter}
          /> */}
          <ButtonGrid buttonSize={6} padding={0.25}>
            {allowNone && <ThingButton
              thing={{ name: allowNone, designUrl: 'none' }}
              onClick={() => {
                onDone({ thing: undefined })
                setState({ CHOOSE_THING: undefined })
              }}
            >
              {allowNone}
            </ThingButton>}
            {applicableEntities
              .filter(t => {
                return filter ? t.doesMatchFilter({ text: filter, tags: window.sticky.newPatchableSet() }) : true
              })
              .map(t => {
                return (
                  <ThingButton
                    key={`thing--${t.id}`}
                    thing={t}
                    className={classnames({ selected: highlightedThingIds.includes(t.id) })}
                    onClick={() => {
                      onDone({ thing: t })
                      setState({ CHOOSE_THING: undefined })
                    }}
                  >
                    {t.name}
                  </ThingButton>
                )
              })}
          </ButtonGrid>
        </Modal>
      </>
    )
  }
}
