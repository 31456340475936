/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Dropdown, Switch } from '@openbox-app-shared'
import GenericModal from '../../../components/modals/generic'

import _ from '../../../_'
import { log } from '../../../log'

const StyledModal = styled.div`
  .component--dropdown + .component--switch {
    margin-top: 1rem;
  }
`
function ThisModal ({ applications, settings, onUpdate, onDone }) {
  return (
    <StyledModal>
      <GenericModal
        onGood={() => onDone(settings)}
        onClose={() => onDone()}
      >
        <Dropdown
          label={window.sticky._('APPLICATION')}
          selected={settings.applicationId}
          items={applications}
          onChoose={(applicationId) => {
            onUpdate({
              ...settings,
              applicationId
            })
          }}
        />
        <Switch
          checked={settings.queryByApplicationId}
          onChange={queryByApplicationId => {
            onUpdate({
              ...settings,
              queryByApplicationId
            })
          }}
        >
          Filter payments by this flow
        </Switch>
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, settings }) => {
    setState({ OPERATOR_VIEW_SETTINGS: settings })
  },
  render: function OPERATOR_VIEW_SETTINGS ({ state, setState, entities, dispatch }) {
    const { OPERATOR_VIEW_SETTINGS } = state
    return (
      <>
        {OPERATOR_VIEW_SETTINGS && <ThisModal
          applications={entities.applications}
          settings={OPERATOR_VIEW_SETTINGS}
          onUpdate={settings => {
            setState({ OPERATOR_VIEW_SETTINGS: settings })
          }}
          onDone={settings => {
            log('[OPERATOR_VIEW_SETTINGS] [Modal->onDone]', { settings })
            if (settings) {
              dispatch('OPERATOR_VIEW_SETTINGS_GOOD', { settings })
              dispatch('SAVE_USER_PREFERENCES', settings)
            }
            setState({
              OPERATOR_VIEW_SETTINGS: undefined
            })
          }}
        />}
      </>
    )
  }
}
