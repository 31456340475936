import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, ColourPicker, Switch } from '@openbox-app-shared'
import Box from '../../../components/Box'
import ColourPickers from '../../../components/ColourPickers'

const StyledComponent = styled.div`
  .config {
    max-width: 19rem;
    .component--colour-pickers {
      margin-top: 1rem;
    }
  }
  > * + * {
    margin-top: 1rem;
  }
`

function styleObjectToString (object) {
  return Object.keys(object)
    .map(k => `${k}:${object[k]};`)
    .join('')
}

function generateCode (url, loadingColor, config) {
  const freshId = window.sticky.uuid()
  const styleButton = styleObjectToString({
    display: 'block',
    position: 'fixed',
    bottom: '12px',
    right: '8px',
    height: '56px',
    'font': '18px -apple-system,BlinkMacSystemFont,\'Segoe UI\',\'Roboto\',sans-serif',
    'font-weight': 'bold',
    'padding': `0 16px 0 ${config.showSymbol ? 56 : 20}px`,
    'border-radius': '5000px',
    'background-color': config.backgroundColor,
    'color': config.foregroundColor,
    'z-index': '1000',
    'border': '0',
    'box-shadow': '0 7px 14px 0 rgb(60 66 87 / 20%),0 3px 6px 0 rgb(0 0 0 / 20%)',
    'background-image': config.showSymbol ? `url('${window.sticky.cdn}/symbol-deploy.svg')` : 'none',
    'background-position': '16px 8px',
    'background-repeat': 'no-repeat',
    'background-size': '29px 40px'
  })
  const styleIframe = styleObjectToString({
    display: 'block',
    position: 'fixed',
    bottom: '80px',
    right: '8px',
    width: '360px',
    height: '640px',
    display: 'none',
    'max-width': 'calc(100vw - 16px)',
    'max-height': 'calc(100vh - 88px)',
    'border-radius': '6px',
    'box-shadow': '0 7px 14px 0 rgb(60 66 87 / 20%),0 3px 6px 0 rgb(0 0 0 / 20%)',
    'z-index': '1000',
    'border': '0',
    'background-color': loadingColor
  })

  return `<button style="${styleButton}" onclick="window['ss-${freshId}']=!window['ss-${freshId}']; document.getElementById('i-${freshId}').style.display=window['ss-${freshId}'] ? 'block' : 'none';"><strong style="vertical-align:2px;">${config.embedText}</strong></button><iframe id="i-${freshId}" src="${url}" style="${styleIframe}"></iframe>`
}

export default function Embedder ({ application, previewLink, onUpdate }) {
  const embedConfig = {
    'embedText': (typeof application.stickyretail.readFrom('embedText') === 'string') ? application.stickyretail.readFrom('embedText') : 'How can we help?',
    'showSymbol': (typeof application.stickyretail.readFrom('showSymbol') === 'boolean') ? application.stickyretail.readFrom('showSymbol') : false,
    'backgroundColor': (typeof application.stickyretail.readFrom('backgroundColor') === 'string') ? application.stickyretail.readFrom('backgroundColor') : '#FFFFFF',
    'foregroundColor': (typeof application.stickyretail.readFrom('foregroundColor') === 'string') ? application.stickyretail.readFrom('foregroundColor') : '#1A1F35'
  }
  const code = generateCode(previewLink, application.backgroundColor, embedConfig)
  const formProps = {
    onChange: (k, v) => {
      application.stickyretail.writeTo(k, v)
      onUpdate(application)
    }
  }
  return (
    <StyledComponent>
      <Box className='config'>
        <Form {...formProps}>
          <Input name='embedText' label='Button text' value={embedConfig.embedText} />
          <Switch name='showSymbol' checked={embedConfig.showSymbol}>Show symbol</Switch>
        </Form>
        <ColourPickers>
          <Form {...formProps}>
            <ColourPicker
              name='backgroundColor'
              label='Background color'
              currentColour={embedConfig.backgroundColor}
            />
            <ColourPicker
              name='foregroundColor'
              label='Text color'
              currentColour={embedConfig.foregroundColor}
            />
          </Form>
        </ColourPickers>
      </Box>
      <Input label='Paste this code into your website' type='code' className='embed' value={code} />
    </StyledComponent>
  )
}
Embedder.propTypes = {
  application: PropTypes.object,
  previewLink: PropTypes.string,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func
}
