import icons from './icons'
import { hotLoader, hotLoaderSdk } from './hotLoader'
import { scrollTo, scrollToTop, scrollToBottom } from './scrollTo'
import isEmailValid from './isEmailValid/isEmailValid'
import isPasswordValid from './isPasswordValid/isPasswordValid'
import isPhoneNumberValid from './isPhoneNumberValid/isPhoneNumberValid'
import isUuid from './isUuid/isUuid'
import isPrivateKey from './isPrivateKey/isPrivateKey'
import isPublicKey from './isPublicKey/isPublicKey'
import _ from './_'
import { sessionKeysAndHowToValidateThem, sessionKeysAndHowToValidateThemByKey } from './sessionKeysAndHowToValidateThem'

import { List, PatchableSetList, ListItem } from './components/List'
import CustomHelmet from './components/CustomHelmet'
import Price from './components/Price'
import Banner from './components/Banner'
import { ExpandCollapse, ExpandCollapseContainer } from './components/ExpandCollapse'
import { Time, TimeDifference } from './components/Time'
import TimePicker from './components/TimePicker'
import DatePicker from './components/DatePicker'
import DayPicker from './components/DayPicker'
import Details from './components/Details'
import Button from './components/Button'
import InlineHtml from './components/InlineHtml'
import { Label, Input, DynamicInput, NumberInput, PriceInput, SlugInput, LatLongInput, BDSortCodeInput, BDAccountNumberInput, EmailInput, UrlInput, PhoneNumberInput, PasswordInput, SearchInput } from './components/Input'
import Link from './components/Link'
import LinkButton from './components/LinkButton'
import Loading from './components/Loading'
import Modal from './components/Modal'
import { Form, DebouncedForm } from './components/Form'
import Redirect from './components/Redirect'
import Switch from './components/Switch'
import Empty from './components/Empty'
import Bar from './components/Bar'
import Dropdown from './components/Dropdown'
import ColourPicker from './components/ColourPicker'
import Question from './components/Question'
import Card from './components/Card/Card'
import Table from './components/Table'
import { UploadDynamic, UploadImage, UploadVideo, UploadDoc, UploadCsv } from './components/Upload'

import Location from './components/Location'
import SessionCrossUserSector from './components/SessionCrossUserSector'

import HotImage from './components/HotImage'
import CoolVideo from './components/CoolVideo'
import CoolDropdown from './components/CoolDropdown'
import { Tag, Tags, tags } from './components/Tag'
import T from './components/T'

import ApplicationBlocks from './components/ApplicationBlocks'
import PartnerCell from './components/PartnerCell'

function addStyle (deduplicateKey, string) {
  if (typeof window === 'undefined') return
  const style = document.createElement('style')
  style.textContent = string
  document.head.append(style)
  return () => {
    style.remove()
  }
}

addStyle(
  undefined,
  `
@keyframes openbox--pulsing-1 {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.03); }
  100% { transform: scale(1); }
}
.openbox--pulsing-1 {
  animation: openbox--pulsing-1 1250ms ease-in-out infinite;
}

@keyframes openbox--pulsing-2 {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.07); }
  100% { transform: scale(1); }
}
.openbox--pulsing-2 {
  animation: openbox--pulsing-2 1250ms ease-in-out infinite;
}

@keyframes openbox--blinking { to { opacity: 0; } }
.openbox--blinking {
  animation: openbox--blinking 0.4s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes openbox--bouncing {
  0% { transform: scaleX(1); }
  2%, 4% {
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
  6%, 10%, 14%, 18% {
    transform: scale3d(1.05, 1.05, 1.05) rotate(3deg);
  }
  8%, 12%, 16% {
    transform: scale3d(1.05, 1.05, 1.05) rotate(-3deg);
  }
  18% { transform: scaleX(1); }
  to  { transform: scaleX(1); }
}
.openbox--bouncing {
  animation: openbox--bouncing 10s;
  animation-iteration-count: infinite;
  z-index: 1;
}
  `
)

export {
  _,
  icons,
  hotLoader,
  hotLoaderSdk,
  scrollTo,
  scrollToTop,
  scrollToBottom,
  isEmailValid,
  isPasswordValid,
  isPhoneNumberValid,
  isUuid,
  isPrivateKey,
  isPublicKey,
  sessionKeysAndHowToValidateThem,
  sessionKeysAndHowToValidateThemByKey,
  List,
  PatchableSetList,
  ListItem,
  CustomHelmet,
  Price,
  Banner,
  ExpandCollapse,
  ExpandCollapseContainer,
  Time,
  TimeDifference,
  Details,
  Button,
  InlineHtml,
  Label,

  Input,
  DynamicInput,
  NumberInput,
  BDSortCodeInput,
  BDAccountNumberInput,
  PriceInput,
  SlugInput,
  LatLongInput,
  EmailInput,
  UrlInput,
  PhoneNumberInput,
  PasswordInput,
  SearchInput,
  TimePicker,
  DatePicker,
  DayPicker,

  Link,
  LinkButton,
  Loading,
  Modal,
  Form,
  DebouncedForm,
  Redirect,
  Switch,
  Empty,
  Bar,
  Dropdown,
  ColourPicker,
  Question,
  Card,
  Table,
  UploadDynamic,
  UploadImage,
  UploadVideo,
  UploadDoc,
  UploadCsv,
  Location,
  SessionCrossUserSector,

  HotImage,
  CoolVideo,
  CoolDropdown,
  Tag, Tags, tags,
  T,

  ApplicationBlocks,
  PartnerCell
}
