/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'
import { icons, Button, Loading, List, ListItem, isPrivateKey, CustomHelmet } from '@openbox-app-shared'
import _ from '../../../_'
import dashboardIcons from '../../../icons'
import FilterButton from '../../../components/FilterButton'
import { dispatch, subscribe } from '../../../redux'
import TagChooserUser from '../../../components/TagChooserUser'

function RenderChild ({ context }) {
  const { user } = context.props
  const [selectMode, setSelectMode] = useState()
  let [selectedIndices, setSelectedIndices] = useState([])
  // let [collapsedApplications, setCollapsedApplications] = useState([])
  const [pFilter, setPFilter] = useState()
  const { applications } = context.state

  useEffect(
    () => {
      if (!user.federatedUserCan('applications')) {
        window.sticky.applications.blocks.showError('You do not have permission to work on flows.')
        return
      }
    },
    []
  )
  useEffect(() => {
    const subscriptions = [
      subscribe('REDUX_HANDLER_READY', () => {
        const { user, userPreferences } = context.props
        if (user.partnerCan('onboard') && !user.onboarded) {
          dispatch('ONBOARDING')
        }
      }),
      subscribe('ONBOARDING_GOOD', () => {
        ;(async () => {
          await context.refreshEverything()
          const { onUpdateUser, onSaveUser } = context.props
          onUpdateUser({ onboarded: true })
          await onSaveUser(['onboarded'])
        })()
      }),
      subscribe(
        'GET_INPUT_GOOD',
        async ({ why, string }) => {
          why === 'copy-applications' && await (async () => {
            const privateKey = string.trim()
            if (!privateKey) {
              return
            }
            if (!isPrivateKey(privateKey)) {
              dispatch('SHOW_MESSAGE', { message: <p>You didn't enter a private key!</p>, canBeBadded: '' })
              return
            }
            const applicationIds = selectedIndices.map(si => applications[si].id)
            dispatch('LOADING')
            try {
              await window.sticky.internals.trigger('copy-applications', { privateKey, applicationIds, isLoggedInAsPartner: user.isLoggedInAsPartner })
            } catch (e) {
              dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
            }
            setSelectedIndices([])
            dispatch('STOP_LOADING')
          })()
        }
      ),
      subscribe(
        'CHOOSE_TAGS_GOOD',
        async ({ why, tags }) => {
          why === 'application--change--tags' && await (async () => {
            dispatch('LOADING')
            for (let i = 0; i < selectedIndices.length; i++) {
              const si = selectedIndices[i]
              const application = applications[si]
              application.tags = window.sticky.newPatchableSet([...tags])
              await window.sticky.applications.save(application, ['tags'])
            }
            setSelectedIndices([])
            dispatch('STOP_LOADING')
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  async function indent (delta) {
    dispatch('LOADING')
    for (let i = 0; i < selectedIndices.length; i++) {
      const si = selectedIndices[i]
      const application = applications[si]
      application.indentation += delta
      if (application.indentation < 0) {
        application.indentation = 0
      }
      if (application.indentation > 10) {
        application.indentation = 10
      }
      await window.sticky.applications.save(application, ['indentation'])
    }
    setSelectedIndices([])
    dispatch('STOP_LOADING')
  }

  const calculatedApplications = Array.isArray(applications) ? applications.filter(t => (selectMode ? true : t.doesMatchFilter(pFilter))) : []
  return (
    <div className='tab'>
      <CustomHelmet title={`${window.sticky._('APPLICATIONS')} | ${user.name}`} />
      {!Array.isArray(applications) && <Loading />}
      {Array.isArray(applications) && (
        <>
          <div className='buttons'>
            {!selectMode && <>
              <Button
                backgroundColor='#26de81'
                icon={icons.inverted.add}
                className={applications.length === 0 ? 'openbox--pulsing-1' : undefined}
                onClick={context.createApplication}
                id='NEW_APPLICATION'
              >
                {window.sticky._('NEW_APPLICATION')}
              </Button>
            </>}
            {user.federatedUserCan('applications-advanced') && applications.length > 0 && !selectMode && <Button
              onClick={() => {
                setSelectMode('select')
                setSelectedIndices([])
              }}
              isSecondary
              title='Select'
              InlineIcon={dashboardIcons.select}
            >Select</Button>}
            {user.federatedUserCan('applications-advanced') && !selectMode && Array.isArray(applications) && applications.length > 0 && (
              <FilterButton
                user={user}
                onChange={setPFilter}
                turnedOnColor='#26de81'
                memoryKey='applications'
              />
            )}
            {selectedIndices.length > 0 && <>
              {user.tags.get().length > 0 && <Button
                isSecondary
                InlineIcon={dashboardIcons.tags}
                onClick={() => dispatch(
                  'CHOOSE_TAGS',
                  {
                    why: 'application--change--tags',
                    tags: (() => {
                      const r = []
                      context.state.applications
                        .filter((_, i) => selectedIndices.includes(i))
                        .forEach(a => {
                          a.tags.forEach(t => r.push(t))
                        })
                      return window.sticky.newPatchableSet(r)
                    })()
                  }
                )}
              >
                Label
              </Button>}
              <Button
                icon={icons.generic.indentOut}
                title='Indent out'
                isSecondary
                onClick={() => indent(-1)}
              />
              <Button
                icon={icons.generic.indentIn}
                title='Indent in'
                isSecondary
                onClick={() => indent(1)}
              />
              <Button
                isSecondary
                icon={icons.generic.move}
                onClick={() => {
                  dispatch('GET_INPUT', { why: 'copy-applications', hint: 'Enter the private key of the destination dashboard:', string: '', doValidate: false })
                }}
              >
                Copy to dashboard
              </Button>
            </>}
            {applications.length > 0 && selectMode && applications.length !== selectedIndices.length && <Button
              onClick={() => {
                const newIndices = []
                for (let i = 0; i < applications.length; i++) newIndices.push(i)
                setSelectedIndices(newIndices)
              }}
              isSecondary
              InlineIcon={dashboardIcons.selectAll}
            >
              Select all
            </Button>}
            {applications.length > 0 && selectMode && <Button
              onClick={() => {
                setSelectMode(undefined)
                setSelectedIndices([])
              }}
              isSecondary
            >
              Cancel
            </Button>}
          </div>
          <div>
            <List
              emptyText={(pFilter && pFilter.hasSomethingInIt) ? 'No flows match your filter.' : null}
              className='main-dish-2 applications'
              draggable
              onDrag={async (from, to) => {
                dispatch('LOADING')
                const now = Math.floor(window.sticky.dateTime.getNowUtcLegacy() / 1000)
                const containers = [...applications]
                window.sticky.swapArrayElements(containers, from, to)
                for (let i = 0; i < containers.length; i++) {
                  const si = containers[i]
                  si.createdAt = now + i
                  await window.sticky.applications.save(si, ['createdAt'])
                }
                await context.refreshApplications()
                dispatch('STOP_LOADING')
              }}
            >
              {calculatedApplications.map((a, aIndex) => {
                // const itemsToExpandCollapse = []
                // for (let i = aIndex + 1; i < calculatedApplications.length; i++) {
                //   const _ = calculatedApplications[i]
                //   if (_.indentation <= a.indentation) {
                //     break
                //   }
                //   itemsToExpandCollapse.push(_)
                // }
                // const actions = [itemsToExpandCollapse.length > 0 && (collapsedApplications.includes(a.id) ? 'move-upmost' : 'move-downmost'), 'copy', 'delete'].filter(_ => _)
                const rightSideChildren = a.tags.hasAny && <TagChooserUser can={false} user={user} tags={a.tags} canManage={false} showAll={false} />
                return (
                  <ListItem
                    doBoxShadow
                    actions={
                      [
                        user.federatedUserCan('applications-advanced') && 'rename',
                        user.federatedUserCan('applications-advanced') && 'copy',
                        'delete'
                      ]
                        .filter(_ => _)
                    }
                    onAction={context.onApplicationAction}
                    // onAction={(id, action) => {
                    //   if (action === 'move-downmost') {
                    //     collapsedApplications = [
                    //       ...collapsedApplications,
                    //       id
                    //     ]
                    //     setCollapsedApplications(collapsedApplications)
                    //   } else if (action === 'move-upmost') {
                    //     collapsedApplications = collapsedApplications
                    //       .filter(_ => _ !== id)
                    //     setCollapsedApplications(collapsedApplications)
                    //   } else {
                    //     context.onApplicationAction(id, action)
                    //   }
                    // }}
                    key={a.id}
                    id={a.id}
                    icon={selectMode ? (selectedIndices.includes(aIndex) ? icons.generic.check : icons.generic.uncheck) : a.baseIcon}
                    goTo={user.federatedUserCan('applications-advanced') && !selectMode && `/me/flows/${a.id}`}
                    indentationOuter={a.indentation * 48}
                    rightSideChildren={rightSideChildren}
                    onChoose={(id) => {
                      if (!selectMode) {
                        return
                      }
                      if (selectedIndices.includes(aIndex)) {
                        selectedIndices = selectedIndices.filter(si => si !== aIndex)
                      } else {
                        selectedIndices.push(aIndex)
                      }
                      setSelectedIndices([...selectedIndices])
                    }}
                  >
                    {a.name}
                  </ListItem>
                )
              })}
            </List>
          </div>
        </>
      )}
    </div>
  )
}

const tab = {
  id: 'flows',
  name: (context) => window.sticky._('APPLICATIONS'),
  inlineIcon: dashboardIcons.application,
  to: (context) => context.props.user.federatedUserCan('applications') && '/me/flows',
  child: function Child (props) {
    return <RenderChild {...props} />
  }
}

export default tab
