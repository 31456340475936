import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const StyledH2 = styled.h2`
  font-size: 1.25rem;
  line-height: 2.25rem;
  color: #1A1F35;
`

export default function H2 ({ color, children, className }) {
  return (
    <StyledH2 className={classnames('component--h2', className)} style={{ color: color }}><strong>{children}</strong></StyledH2>
  )
}
H2.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
}
