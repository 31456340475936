import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { icons, Table, SearchInput, Button } from '@openbox-app-shared'
import { log } from '../log'

const StyledComponent = styled.div`
  .controls {
    height: 2.5rem;
    margin-bottom: 1rem;
    > * {
      float: left;
    }
    > * + * {
      margin-left: 1rem;
    }
    .component--input {
      width: 12rem;
    }
  }
  .table {
    width: 100%;
    .component--table {
      width: 100%;
    }
  ${props => {
    return css`
      overflow: ${props.$rowsLength > 0 ? 'scroll' : 'unset'};
    `
  }};
  }
`

export default function ScrollableTable ({ controls = [], filterableKey, onControl, rows, ...props }) {
  const showControls = controls.some(e => e) || filterableKey
  const [filter, setFilter] = useState('')
  // log('[ScrollableTable] filterableKey', filterableKey)
  // log('[ScrollableTable] rows', rows)
  // log('[ScrollableTable] filter', filter)
  const realRows = rows
    .filter(r => filterableKey && filter.length > 0 ? r[filterableKey].toLowerCase().indexOf(filter.toLowerCase()) !== -1 : true)
  return (
    <StyledComponent className='component--scrollable-table' $rowsLength={rows.length}>
      {showControls && (
        <div className='controls'>
          {controls.includes('add') && <Button onClick={() => onControl('add')} icon={icons.inverted.add}>Add</Button>}
          {filterableKey && (
            <SearchInput
              onChange={setFilter}
            />
          )}
        </div>
      )}
      <div className='table'>
        <Table rows={realRows} {...props} />
      </div>
    </StyledComponent>
  )
}
ScrollableTable.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.string),
  filterableKey: PropTypes.string,
  onControl: PropTypes.func,
  columns: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
}
