import React from 'react'
import PropTypes from 'prop-types'
import { Tags, Tag } from '@openbox-app-shared'

export default function UserBillingTags ({ user }) {
  return (
    <Tags>
      <Tag tag={{ id: 'tag-1', name: user.currentPlan.name }} key='tag-1' />
      {user.is('sandbox') && <Tag tag={{ id: 'tag-2', name: 'Sandbox', color: '#fad859' }} key='tag-2' />}
    </Tags>
  )
}
UserBillingTags.propTypes = {
  user: PropTypes.object.isRequired
}
