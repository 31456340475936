import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled, { css } from 'styled-components'
import { Label } from './Input'

const { v4: uuid } = require('uuid')

const StyledCoolDropdown = styled.div`
  position: relative;
  .top {
    width: 148px;
    height: 1.5rem;
    background-color: #6C7A89;
    border-radius: 5000px;
    border-radius: 5000px;
    .currently-selected {
      display: block;
      width: calc(100% - 1.5rem);
      height: 1.5rem;
      line-height: 1.5rem;
      padding-left: 0.6rem;
      font-size: 75%;
      color: white;
      // background-color: purple;
    }
    .arrow {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 1.75rem;
      height: 1.5rem;
      background-color: transparent;
      outline: 0;
      svg {
        display: block;
        width: 40%;
        margin-left: 0.4rem;
        // background-color: pink;
      }
    }
  }
  .bottom {
    width: 172px;
    position: absolute;
    top: 2rem;
    right: 0;
    list-style-type: none;
    border: 2px solid #6C7A89;
    border-radius: 8px;
    z-index: 2;
    li:first-child {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }
    li:last-child {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
    li:nth-child(odd) {
      background-color: white;
    }
    li:nth-child(even) {
      background-color: #f4f5f5;
    }
    li {
      height: 2rem;
      > span {
        padding-left: 0.5rem;
        line-height: 2rem;
      }
    }
    li.selected {
      background-color: #6C7A89;
      color: white;
    }
  }
`

export default function CoolDropdown ({ label, selected, items, className, color, onChoose }) {
  console.log('[CoolDropdown]', { selected })
  const classNames = classnames('component--dropdown', className)
  const myUuid = uuid()
  return (
    <StyledCoolDropdown className={classNames} color={color}>
      {label && <Label style={{ color }} id={myUuid}>{label}</Label>}

      <div className='top'>
        <strong className='currently-selected'>Hello world</strong>
        <button className='arrow'>
          <svg
            viewBox="0 0 10 5"
            /* change to real dom attr post react */
            fillRule="evenodd"
            fill="#fff"
          >
            <title>Open drop down</title>
            <path d="M10 0L5 5 0 0z"></path>
          </svg>
        </button>
      </div>
      <ul className='bottom'>
          <li><span>Item 1</span></li>
          <li><span>Item 2</span></li>
          <li className='selected'><span>Item 3</span></li>
          <li><span>Item 4</span></li>
          <li><span>Item 5</span></li>
        </ul>
      {/* <select value={selected} onChange={(event) => onChoose && onChoose(event.target.value)} style={selectStyle}>
        {items.map(i => {
          return (
            <option value={i.id} key={`select--option--${i.id}`}>{i.name}</option>
          )
        })}
      </select> */}
    </StyledCoolDropdown>
  )
}
CoolDropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.any,
  color: PropTypes.string,
  onChoose: PropTypes.func
}
