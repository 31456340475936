import React from 'react'

import LocationPasteGoogleMapsUrl from '../../../components/LocationPasteGoogleMapsUrl'
import { icons } from '@openbox-app-shared'

const tab = {
  id: 'location',
  name: (context) => 'Place',
  icon: icons.generic.location,
  to: (context) => `/me/flows/${context.state.application.id}/location`,
  child: function Child (context, extraProps) {
    return (
      <>
        <LocationPasteGoogleMapsUrl
          name='location'
          location={context.state.application.location}
          label={context.state.application.name}
          onChange={(props) => context.onUpdate({ 'location': props })}
          showYouOnMap={false}
        />
      </>
    )
  }
}

export default tab
