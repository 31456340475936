import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, HotImage } from '@openbox-app-shared'

import ScrollableTable from './ScrollableTable'
import UserBillingTags from './UserBillingTags'

const COLUMNS = {
  logo: '',
  name: '',
  plan: '',
  actions: ''
}

const StyledComponent = styled.div`
  /* logo */
    .component--table td:nth-child(1) {
      min-width: 4rem; max-width: 4rem;
    }
  /* name */
    .component--table td:nth-child(2) {
      min-width: 16rem;
    }
  /* plan */
    .component--table td:nth-child(3) {
    }
  /* actions */
    .component--table td:nth-child(4) {
      min-width: 8rem;
    }
`

export default function PartnerUsers ({ users }) {
  const rows = users ? users.map(({ user, partner })=> {
    return {
      logo: <HotImage src={user.logoUrl} alt={user.name} />,
      name: <Link dontAttachQueryParameters to={`/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}&partnerPrivateKey=${partner.privateKey}`} target='_blank'>{user.name}</Link>,
      plan: <>
        <UserBillingTags user={user} />
      </>,
      actions: <>
        <Link dontAttachQueryParameters to={`/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`} target='_blank'>Log in as user →</Link>
        <Link dontAttachQueryParameters to={`/partners/${partner.id}/users/${user.id}/billing?partnerPrivateKey=${partner.privateKey}&userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`} target='_blank'>Go to billing →</Link>
      </>
    }
  }) : []

  return (
    <StyledComponent>
      <ScrollableTable columns={COLUMNS} rows={rows} />
    </StyledComponent>
  )
}
PartnerUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object)
}
