import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, Modal, Button } from '@openbox-app-shared'

const StyledModal = styled.div`
  .component--modal {
    max-width: 400px;
    padding: 1rem;
    > p {
      text-align: center;
      line-height: 1.25rem;
      white-space: pre-wrap;
    }
    p + p {
      margin-top: 1rem;
    }
    p + .buttons {
      margin-top: 2rem;
    }
    .buttons {
      width: fit-content;
      margin: 2rem auto 0 auto;
      padding-left: 1rem;
      .component--button {
        display: inline-block;
        vertical-align: top;
        margin: 0 1rem 1rem 0;
      }
    }
  }
`

export default function GenericModal ({ className, onGood, onClose, children, goodText = 'Done', badText = 'Cancel', buttonProps = {} }) {
  const canBeGooded = typeof goodText === 'string' && goodText.length > 0
  const canBeBadded = typeof badText === 'string' && badText.length > 0
  return (
    <StyledModal className={className}>
      <Modal onClose={onClose || onGood}>
        {children}
        {(canBeGooded || canBeBadded) && (<div className='buttons'>
          {canBeGooded && (
            <Button onClick={onGood} {...buttonProps} icon={icons.inverted.check}>
              {goodText}
            </Button>
          )}
          {canBeBadded && (
            <Button onClick={onClose} {...buttonProps} isSecondary>
              {badText}
            </Button>
          )}
        </div>)}
      </Modal>
    </StyledModal>
  )
}

GenericModal.propTypes = {
  className: PropTypes.string,
  onGood: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  goodText: PropTypes.string,
  badText: PropTypes.string,
  buttonProps: PropTypes.object
}
