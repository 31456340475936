import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import { Form, Input, PriceInput, ApplicationBlocks, Empty, UploadImage, Dropdown, Switch } from '@openbox-app-shared'
import H2 from '../../../../components/H2'
import Box from '../../../../components/Box'

const StyledComponent = styled.div`
  .component--dropdown select {
    max-width: 128px;
  }
  .component--box.price {
    padding-bottom: 0;
    > div > * {
      display: inline-block;
      margin: 0 1rem 1rem 0;
    }
  }
  .component--input.type--price + .component--input.type--price {
    margin-top: 1rem;
  }
  .component--input.type--price input {
    max-width: 128px;
  }
  &.disabled .component--box h2 {
    opacity: 0.5;
  }
  .component--box > * + * {
    margin-top: 1rem;
  }
`

export default function TabMore ({ context, onHasMadeUpdate }) {
  const { applicationBlock, disabled } = context
  const inputProps = {
    disabled
  }
  const ind = window.sticky.applications.blocks.INDENT
  return (
    <StyledComponent className={classnames({ disabled })}>
      <Form onChange={onHasMadeUpdate}>
        <Input
          {...inputProps}
          name='configName'
          label='Display in the edit pop up'
          value={applicationBlock.configName}
          className='limit-width-1'
        />
        <Input
          {...inputProps}
          name='tag'
          label='Section'
          value={applicationBlock.tag}
          className='limit-width-1'
        />
        <Dropdown
          {...inputProps}
          name='indent'
          label='Indentation'
          items={Object.keys(ind).map(k => ({ id: ind[k], name: k }))}
          selected={applicationBlock.indent}
        />
        <Switch
          {...inputProps}
          name='rendersAContainer'
          checked={applicationBlock.rendersAContainer}
        >
          Create a wrapping container
        </Switch>

        <Box>
          <UploadImage
            label='Icon'
            onChange={v => onHasMadeUpdate('icon', v.url)}
            value={applicationBlock.icon}
            show
            {...inputProps}
          />
          <Input
            className='limit-width-1'
            disabled
            name='stickystoreApplicationId'
            label={<code>stickystoreApplicationId</code>}
            value={applicationBlock.stickystoreApplicationId}
          />
        </Box>

        <Box className='price'>
          <H2>Price</H2>
          <div>
            {Object.keys(applicationBlock.price).map(k => {
              return (
                <PriceInput
                  key={k}
                  disabled
                  name='price'
                  label={k}
                  value={applicationBlock.price[k]}
                />
              )
            })}
          </div>
        </Box>
      </Form>
    </StyledComponent>
  )
}
TabMore.propTypes = {
  context: PropTypes.object,
  onHasMadeUpdate: PropTypes.func
}
