import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Modal, Input } from '@openbox-app-shared'

import _ from '../../_'
import NumberGrid from '../NumberGrid'
import H1 from '../../components/H1'

const StyledModal = styled.div`
  .component--modal {
    padding: 2rem 1rem 1rem 1rem;
    max-width: calc(248px + 4rem);
  }
  .component--h1 {
    text-align: center;
  }
  .component--button-grid {
    margin-top: 1.5rem;
  }
`

export default function ThisModal ({ goodPin, onGood, onCancel }) {
  const [currentPin, setCurrentPin] = useState('')
  console.warn('[EnterPin] pin', goodPin)
  const abstractedCurrentPin = currentPin.split('').map(_ => '*').join('')

  return (
    <StyledModal>
      <Modal onClose={onCancel}>
        <H1>{abstractedCurrentPin || 'Enter your PIN'}</H1>
        <NumberGrid
          onChange={(newNumber) => {
            const newPin = currentPin + newNumber
            if (newPin === goodPin) {
              onGood()
              return
            }
            // if (currentPin.length === goodPin.length - 1) {
            //   setCurrentPin('')
            //   return
            // }
            setCurrentPin(newPin)
          }}
          onGoBack={() => {
            if (currentPin.length === 0) {
              return
            }
            const newPin = currentPin.substring(0, currentPin.length - 1)
            setCurrentPin(newPin)
          }}
        />
      </Modal>
    </StyledModal>
  )
}

ThisModal.propTypes = {
  goodPin: PropTypes.string.isRequired,
  onGood: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
