import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled from 'styled-components'

import { Time } from './Time'
import Price from './Price'
import InlineHtml from './InlineHtml'

const StyledDetails = styled.table`
  border: 2px solid black;
  border-spacing: 0;
  border-radius: 0.4rem;
  a {
    color: #EA2027;
  }
  code + .component--button {
    margin-top: 0.5rem;
  }
  tbody {
    > tr {
      p {
        line-height: 1rem;
      }
      td {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        font-size: 0.8rem;
        vertical-align: top;
      }
      td:nth-child(1) {
        width: 30%;
        text-align: right;
        border-right: 2px solid black;
      }
      td:nth-child(2) {
        position: relative;
        width: 70%;
        padding-left: 0.75rem;
        line-height: 1rem;
        white-space: break-spaces;
        word-break: break-all;
        img {
          display: block;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    > tr:not(:last-child) > td {
      border-bottom: 2px solid black;
    }
    > tr:first-child > td:first-child {
      border-top-left-radius: 0.3rem;
    }
    > tr:first-child > td:last-child {
      border-top-right-radius: 0.3rem;
    }
    > tr:last-child > td:first-child {
      border-bottom-left-radius: 0.3rem;
    }
    > tr:last-child > td:last-child {
      border-bottom-right-radius: 0.3rem;
    }
  }
`

const Details = ({ details, user, className, borderColor = '#F4F5F5' }) => {
  const classNames = classnames('component--details', className)
  if (!details || details.size === 0) {
    return null
  }
  return (
    <StyledDetails className={classNames} style={{ borderColor: borderColor }}>
      <tbody>
        {
          Array.from(details, ([key, v], index) => {
            const asDeserialized = window.sticky.getDeserializedValue(v, user)
            const isTime = (typeof v === 'string' && v.startsWith('time:'))
            const maybeParsedTime = isTime ? parseInt(v.substring('____:'.length), 10) : NaN
            const isTimeButShowNever = isNaN(maybeParsedTime)

            let isPrice
            if (typeof v === 'string' && v.startsWith('price:')) {
              const [price, currency] = v.substring('price:'.length).split('/')
              isPrice = { price: parseInt(price, 10), currency }
            }

            const isHandledDifferently = asDeserialized !== undefined || isTime || isPrice
            const Contents = () => <>
              {!isHandledDifferently && v}
              {asDeserialized !== undefined && <InlineHtml html={asDeserialized} />}
              {isTime && !isTimeButShowNever && <Time time={maybeParsedTime} />}
              {isTime && isTimeButShowNever && 'Never'}
              {isPrice && <Price {...isPrice} />}
            </>

            return (
              <tr key={`${key}--${index}`}>
                <td style={{ borderColor: borderColor }}><p><strong>{key}</strong></p></td>
                <td style={{ borderColor: borderColor }}>
                  {typeof v !== 'object' && <Contents />}
                  {typeof v === 'object' && <Contents />}
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </StyledDetails>
  )
}

Details.propTypes = {
  details: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string,
  borderColor: PropTypes.string
}

export default Details
