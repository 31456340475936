import React from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../../icons'
import _ from '../../../_'
import ChildrenAndInlineLinkFrame from '../../../components/ChildrenAndInlineLinkFrame'
import ProductsLikeAMenu from '../../../components/ProductsLikeAMenu'

const StyledComponent = styled.div`
  padding-top: 1rem;
`

const tab = {
  id: 'product',
  name: () => 'Product',
  inlineIcon: dashboardIcons.product,
  to: (context) => context.state.application ? `/me/flows/${context.state.application.id}/product` : undefined,
  child: function Child(context, { previewLink }) {
    const { application, products, productCategories } = context.state
    const { user } = context.props
    const shouldRender = application.baseSettingsRender === 'stickyretail-product'
    if (!shouldRender) {
      return null
    }
    return <StyledComponent>
      <ChildrenAndInlineLinkFrame
        inlineLinkFrameProps={{
          link: previewLink,
          backgroundColor: application.backgroundColor
        }}
      >
        <ProductsLikeAMenu
          user={user}
          productCategories={productCategories}
          products={products}
          currentProductId={application.productId}
          onChangeCurrentProductId={id => context.onUpdate({ 'productId': id })}
          allowNone
        />
      </ChildrenAndInlineLinkFrame>
    </StyledComponent>
  }
}

export default tab
