import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, sessionKeysAndHowToValidateThemByKey, isEmailValid, isPhoneNumberValid, ApplicationBlocks, Empty, Button, LinkButton, Details, Form, Input, Switch, CustomHelmet, Loading, Price, Link, T } from '@openbox-app-shared'

import { log } from '../../log'
import _ from '../../_'
import dashboardIcons from '../../icons'

import Box from '../../components/Box'
import TabBar from '../../components/TabBar'
import EposCart from '../views/opsManager/components/Cart'
import EventExplorer from '../../components/Events/EventExplorer'
import MainFrame from '../../components/MainFrame'
import { PaymentExtra, PaymentSummary } from '../../components/payments'
import User from '../../components/User'
import CoolButton from '../../components/CoolButton'
import CoolLinkButton from '../../components/CoolLinkButton'
import { dispatch, subscribe } from '../../redux'
import CopyUrl from '../../components/CopyUrl'
import { PaymentHeadlines } from '../../components/payments'
import HeaderBar from '../../components/HeaderBar'
import TagChooserUser from '../../components/TagChooserUser'
import CoolCart from '../../components/CoolCart'

const StyledRoute = styled.div`
  padding: 1rem;
  aside {
    hr {
      border-color: #CFD8DC;
    }
    .buttons {
      margin-bottom: 0.5rem;
      > * {
        display: inline-block;
        margin: 0 0.5rem 0.5rem 0;
        vertical-align: top;
      }
    }
    .buttons + .buttons {
      margin-top: 1rem;
    }
    .component--copy-url {
      margin-bottom: 1rem;
    }
    .component--box {
      .component--payment-summary {
        margin-bottom: 1rem;
      }
      .component--details + .component--details, .component--form + .component--details {
        margin-top: 1rem;
      }
      .component--details td > strong, .component--details td > code {
        display: block;
      }
      .component--details {
        width: 100%;
        strong + code, code + code {
          margin-top: 0.5rem;
        }
      }
    }
  }
  .component--tabbar {
    .tab {
      > * {
        margin-top: 1rem;
      }
    }
    .tab.cart {
      .cruddy-container, .component--payment-extra {
        margin-top: 1rem;
      }
    }
  }
  .payment--gateway img {
    display: block;
    width: 4rem !important;
    height: auto !important;
  }
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0;
      }
    }
  }
`

const TABS = [
  {
    id: 'cart',
    name: () => 'Bag',
    inlineIcon: dashboardIcons.buy,
    to: (context) => context.state.payment ? `/me/payments/${context.state.payment.id}/cart` : undefined,
    child: function Child (context) {
      const { user } = context.props
      const { payment } = context.state

      const hasAnythingCruddy = payment && [
        payment.companyName,
        isEmailValid(payment.email),
        isPhoneNumberValid(payment.phone),
        payment.address,
        payment.userPrivateKey
      ].some(e => e)

      const applicationBlocks = [
        payment && isPhoneNumberValid(payment.phone) && {
          id: 'button',
          config: {
            action: `phone~~||~~${payment.phone}~~||~~false`,
            label: payment.phone,
            colour: '#f0f1f3',
            foregroundColour: '#1A1F35',
            icon: 'phone'
          }
        },
        payment && isEmailValid(payment.email) > 0 && {
          id: 'button',
          config: {
            action: `email~~||~~${payment.email}~~||~~false`,
            label: payment.email,
            colour: '#f0f1f3',
            foregroundColour: '#1A1F35',
            icon: 'email'
          }
        }
      ]
        .filter(e => e)

      return (
        <div className='tab cart'>
          {!payment && <Loading />}
          {payment && (
            <div className='grid'>
              <div>
                {payment.rawCart.length > 0 && <CoolCart>
                  {<EposCart
                    cart={payment.rawCart}
                    canVoid={false}
                  />}
                </CoolCart>}
                {payment.rawCart.length === 0 && (
                  <Empty>
                    This person didn't buy anything.
                  </Empty>
                )}
              </div>
              <div>
                <Box>
                  <PaymentHeadlines user={user} entity={payment} />
                  {payment.extra && <PaymentExtra>{payment.extra}</PaymentExtra>}
                  {hasAnythingCruddy && <Form className='cruddy-container'>
                    {applicationBlocks.length > 0 && <ApplicationBlocks
                      applicationBlocks={applicationBlocks}
                    />}
                    {payment.address ? <Input
                      disabled
                      label={sessionKeysAndHowToValidateThemByKey.address.text}
                      type='textarea'
                      name='address'
                      value={sessionKeysAndHowToValidateThemByKey.address.getValue(payment.address)}
                    /> : null}
                    {payment.companyName ? <Input
                      disabled
                      name='companyName'
                      label={sessionKeysAndHowToValidateThemByKey.companyName.text}
                      value={sessionKeysAndHowToValidateThemByKey.companyName.getValue(payment.companyName)}
                    /> : null}
                    {payment.userPrivateKey ? <Input
                      disabled
                      label={sessionKeysAndHowToValidateThemByKey.userPrivateKey.text}
                      name='userPrivateKey'
                      value={sessionKeysAndHowToValidateThemByKey.userPrivateKey.getValue(payment.userPrivateKey)}
                    /> : null}
                  </Form>}
                </Box>
              </div>
            </div>
          )}
        </div>
      )
    }
  },
  {
    id: 'activity',
    name: ({ props: { user } }) => user.can('view-activity') && 'Activity',
    inlineIcon: dashboardIcons.events,
    to: (context) => context.state.payment ? `/me/payments/${context.state.payment.id}/activity` : undefined,
    child: function Child (context) {
      return (
        <div className='tab'>
          {!context.state.payment && <Loading />}
          {context.state.payment && (
            <EventExplorer
              queryId='payment'
              queryObject={{ paymentId: context.state.payment.id }}
            />
          )}
        </div>
      )
    }
  }
]

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      payment: undefined,
      hasMadeUpdate: false
    }
    this.PAYMENT_REFUND_GOOD = this.PAYMENT_REFUND_GOOD.bind(this)
  }

  async componentDidMount () {
    const { user } = this.props
    if (!user.federatedUserCan('payments') || !user.federatedUserCan('payment-get')) {
      window.sticky.applications.blocks.showMessage('Sorry; you do not have permission to see payments.', undefined, false, false)
      return
    }
    const {
      paymentId,
      view
    } = this.props.match.params

    this.subscriptions = [
      subscribe('PAYMENT_REFUND_GOOD', this.PAYMENT_REFUND_GOOD),
      subscribe(
        'TRIGGER_GOOD',
        () => {
          const { payment } = this.state
          payment.cancelSubscription()
          this.forceUpdate()
        }
      )
    ]

    const payment = await window.sticky.pay.get(paymentId)
    const applications = await window.sticky.applications.getAll()
    const things = await window.sticky.things.getAll()
    const federatedUsers = await window.sticky.users.federated.getAll()
    log('[Route-Stickypay-payment] [componentDidMount]', { paymentId, payment, view })
    this.setState({
      payment,
      applications,
      things,
      federatedUsers
    })
  }

  componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  PAYMENT_REFUND_GOOD ({ why }) {
    log('[Route-Stickypay-payment] [PAYMENT_REFUND_GOOD]', { why })
    why === 'stickypay--payment' && (() => {
      this.forceUpdate()
    })()
  }

  render () {
    const {
      user,
      match
    } = this.props
    const {
      payment,
      federatedUsers
    } = this.state
    const {
      view
    } = match.params
    const renderTabs = TABS
      .filter(t => t.name(this))
      .map(tab => ({
        ...tab,
        name: tab.name(this),
        to: tab.to(this),
        child: tab.child(this)
      }))
    log('[Route-Stickypay-payment] [render]', { user, payment, refundedAt: payment && payment.refundedAt })
    const entityDetails1 = new Map(
      [
        payment && payment.isSubscription && ['Subscription', payment && <><Price price={payment.totalCpa} currency={payment.currency} />/<T>{payment.cpaPeriod}</T></>],
        payment && payment.discount !== 0 && ['Discount', <Price key='d--discount' price={payment.discount} currency={payment.currency} />],
        user.vat && ['Tax', payment && <Price price={payment.VAT.total} currency={payment.currency} />]
      ].filter(e => e)
    )

    const gateway = payment && window.sticky.Stickypay.getGateway(payment.gateway)
    const foundFederatedUser = payment && payment.lastFederatedUserId && federatedUsers.find(fu => fu.id === payment.lastFederatedUserId)

    const entityDetails2 = payment && new Map(
      [
        payment.userPaymentId && [
          'Your ID',
          (
            <span key='d--userPaymentId'>{payment.userPaymentId}</span>
          )
        ],
        payment.tip > 0 && ['Service', <Price key='d--tip' price={payment.tip} currency={payment.stickypayPerTxCurrency} />],
        [
          `${gateway ? gateway.name : 'Payment provider'} cost`,
          (
            <span key='d--stickypayPPBS'>{(payment.stickypayMerchantPercentage / 100)}% + <Price price={payment.stickypayPerTx} currency={payment.stickypayPerTxCurrency} /></span>
          )
        ],
        payment.paymentGatewayExtra && [
          'Extra info',
          (
            <span key='d--paymentGatewayExtra' dangerouslySetInnerHTML={{ __html: payment.paymentGatewayExtra }} />
          )
        ],
        foundFederatedUser && ['Last team member', <Link key='d--t-x' to={`/me/team/${foundFederatedUser.id}`}>{foundFederatedUser.name}</Link>],
        [
          'ID',
          (
            <>
              <strong>{payment.consumerIdentifier}</strong>
              <code>{payment.id}</code>
              {payment.paymentGatewayCpaId && <code>{payment.paymentGatewayCpaId}</code>}
            </>
          )
        ],
        payment.paymentGatewayId && [
          `${gateway ? gateway.name : 'Payment provider'} ID`,
          (
            <>
              <code>{payment.paymentGatewayId}</code>
            </>
          )
        ],
        gateway && [
          'Payment provider',
          <div className='payment--gateway' key='payment--gateway'>
            <img src={gateway.logo || gateway.icon} />
            {/* <strong>{gateway.name}</strong> */}
            {/* <code>{gateway.id}</code> */}
          </div>
        ],
        // ['Created', `time:${payment.createdAt}`],
        // ['Updated', `time:${payment.updatedAt}`]
      ]
        .filter(e => e)
    )
    const title = payment ? `Payment ${payment.consumerIdentifier}` + (payment.name ? ` (${payment.name})` : '') : undefined

    return (
      <StyledRoute>
        <CustomHelmet
          title={title}
        />
        {!payment && <Loading />}
        {payment && <>
          <MainFrame
            user={user}
            autoUi={this.props.autoUi}
            aside={<>
              <User user={user} whichPart={match.path} toShow={['semantic-home']} autoUi={this.props.autoUi} />
              <hr />
              <div className='buttons'>
                <LinkButton
                  to='/analytics/payments'
                  sameTab
                  backgroundColor='transparent'
                >
                  ← Payments
                </LinkButton>
                {user.can('has-printer') && (
                  <>
                    <CoolButton
                      color='white' InlineIcon={dashboardIcons.printer}
                      onClick={() => dispatch('TRIGGER', { trigger: 'printPayment', body: { paymentId: payment.id } })}
                    >
                      Print
                    </CoolButton>
                  </>
                )}
                <CoolButton
                  InlineIcon={dashboardIcons.teamMember}
                  color='white'
                  onClick={() => {
                    const { sessionId } = payment
                    window.sticky.session.get(undefined, false, sessionId)
                      .then(session => {
                        dispatch('SESSION', { session, why: 'Payment' })
                        dispatch('STOP_LOADING')
                      })
                  }}
                >
                  Person
                </CoolButton>
                {payment.canBePaidForLater && (
                  <CoolButton
                    InlineIcon={payment.isUnpaid ? dashboardIcons.payment : undefined}
                    color={!payment.isUnpaid ? '#40cf7a' : 'white'}
                    disabled={!payment.isUnpaid}
                    onClick={async () => {
                      payment.sessionPaidAt = Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000)
                      await window.sticky.pay.save(payment, false)
                      this.forceUpdate()
                    }}
                  >
                    {!payment.isUnpaid ? 'Paid' : 'Pay'}
                  </CoolButton>
                )}
                <CoolButton
                  InlineIcon={payment.newStatusDone ? dashboardIcons.void : dashboardIcons.check}
                  color='white'
                  onClick={async () => {
                    payment.newStatusDone = !payment.newStatusDone
                    await window.sticky.pay.save(payment, false)
                    this.forceUpdate()
                  }}
                >
                  {payment.newStatusDone ? 'Uncomplete' : 'Complete'}
                </CoolButton>
                {payment.canRefund && <CoolButton
                  InlineIcon={dashboardIcons.goBack}
                  color='white'
                  disabled={!user.federatedUserCan('payment-refund')}
                  onClick={() => dispatch('PAYMENT_REFUND', { user, why: 'stickypay--payment', payment })}
                >
                  Refund
                </CoolButton>}
                <CoolLinkButton
                  to={`/me/payments/${payment.id}/invoice`}
                  color='white'
                >
                  Invoice
                </CoolLinkButton>
                {isEmailValid(payment.email) && (<CoolButton
                  color='white' InlineIcon={dashboardIcons.email}
                  onClick={() => dispatch('TRIGGER', { trigger: 'public--emailPayment', forcePublicKey: true, body: { paymentId: payment.id, sessionId: payment.sessionId } })}
                >
                  Receipt
                </CoolButton>)}
                {payment.isSubscription && !payment.isUnpaid && (<CoolLinkButton
                  color='white' InlineIcon={dashboardIcons.void}
                  onClick={() => dispatch('TRIGGER', { trigger: 'public--cancel-subscription', body: { paymentId: payment.id, paymentGatewayCpaId: payment.paymentGatewayCpaId }, forcePublicKey: true })}
                >
                  Cancel subscription
                </CoolLinkButton>)}
              </div>
              <Box>
                <PaymentSummary key='d--payment-summary' user={user} payment={payment} />
                <TagChooserUser
                  user={user}
                  tags={payment.tags}
                  can={false}
                  canManage={false}
                  showAll={false}
                />
                <Details details={entityDetails1} />
                <Details details={entityDetails2} />
                <Details details={payment.customDataPublic.toMap()} />
              </Box>
              {payment.isSubscription && payment.isUnpaid && (
                <CopyUrl url={window.sticky.applications.test(payment.application.id, `paymentId=${payment.id}`)}>Copy this</CopyUrl>
              )}
            </>}
            main={<>
              <HeaderBar text={`Payments › ${payment.consumerIdentifier}`} Icon={dashboardIcons.payment} user={user} />
              <Box>
                <TabBar
                  selectedTab={view}
                  tabs={renderTabs}
                />
              </Box>
            </>}
          />
        </>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
