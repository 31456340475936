import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const StyledBox = styled.section`
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  &.is-dark-mode {
    background-color: black;
    .component--h2 {
      color: white;
    }
    .component--list {
      background-color: white;
    }
    .component--button.is-secondary {
      background-color: #282c34;
      border-color: #282c34;
      color: white;
    }
  }
`

function Box ({ className, isDarkMode, children, ...props }) {
  return (
    <StyledBox className={classnames('component--box', { 'is-dark-mode': isDarkMode }, className)} {...props}>
      {children}
    </StyledBox>
  )
}

Box.propTypes = {
  className: PropTypes.string,
  isDarkMode: PropTypes.bool,
  children: PropTypes.node
}

export default Box
