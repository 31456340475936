/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, Button, Dropdown, DatePicker, Input, NumberInput, List, ListItem, DynamicInput } from '@openbox-app-shared'

const StyledComponent = styled.div`
  .component--input + .component--input,
  .component--input + .component--date-picker,
  .component--dropdown + .component--input,
  .component--input + .component--list
  {
    margin-top: 1rem;
  }
  .component--dropdown.type {
    width: 10rem;
  }
  .component--input {
    max-width: 16rem;
  }
  > div + .buttons {
    margin-top: 1rem;
  }
`

const TYPES = [
  {
    type: 'email',
    name: 'Email',
    defaultName: '(...) → email',
    defaultValue: 'email:',
    Component: ({ value, canSave, onUpdate, onDone, whichToAutoFocus, hasAutoFocused, setHasAutoFocused }) => {
      const asExposedValue = value.substring('email:'.length)
      return (
        <DynamicInput type='email'
          label='Email'
          value={asExposedValue}
          onChange={newValue => {
            onUpdate(`email:${newValue}`)
          }}
        />
      )
    }
  },
  {
    type: 'phone',
    name: 'Phone',
    defaultName: '(...) → phone',
    defaultValue: 'phone:',
    Component: ({ value, canSave, onUpdate, onDone, whichToAutoFocus, hasAutoFocused, setHasAutoFocused }) => {
      const asExposedValue = value.substring('phone:'.length)
      return (
        <DynamicInput type='phone'
          label='Phone number'
          value={asExposedValue}
          onChange={newValue => {
            onUpdate(`phone:${newValue}`)
          }}
        />
      )
    }
  },
  { type: '---', name: '──────────', disabled: true },
  {
    type: 'string',
    name: 'Text',
    defaultValue: '',
    Component: ({ value, canSave, onUpdate, onDone, whichToAutoFocus, hasAutoFocused, setHasAutoFocused }) => (
      <Input
        name='value'
        label='Value'
        selectAll
        value={value}
        onChange={onUpdate}
        onReady={({ autoFocus }) => {
          if (whichToAutoFocus !== 'value') return
          !hasAutoFocused && autoFocus()
          setHasAutoFocused(true)
        }}
        onDone={() => {
          canSave && onDone(value)
        }}
      />
    )
  },
  {
    type: 'date',
    name: 'Date',
    defaultValue: 'date:1672531200',
    Component: ({ value, canSave, onUpdate, onDone, whichToAutoFocus, hasAutoFocused, setHasAutoFocused }) => {
      const asExposedValue = parseInt(value.substring('date:'.length), 10)
      return (
        <DatePicker
          label='Date'
          time={asExposedValue}
          onChange={newAsInteger => {
            onUpdate(`date:${newAsInteger}`)
          }}
        />
      )
    }
  },
  {
    type: 'number',
    name: 'Number',
    defaultValue: 1,
    Component: ({ value, canSave, onUpdate, onDone, whichToAutoFocus, hasAutoFocused, setHasAutoFocused }) => {
      return (
        <NumberInput
          name='value'
          label='Value'
          selectAll
          value={value}
          onChange={onUpdate}
          onReady={({ autoFocus }) => {
            if (whichToAutoFocus !== 'value') return
            !hasAutoFocused && autoFocus()
            setHasAutoFocused(true)
          }}
          onDone={() => {
            canSave && onDone(value)
          }}
        />
      )
    }
  },
  {
    type: 'array',
    name: 'List',
    defaultValue: [],
    Component: ({ value, canSave, onUpdate, onDone, whichToAutoFocus, hasAutoFocused, setHasAutoFocused }) => {
      return (
        <List>
          {value.map((l, i) => {
            return (
              <ListItem
                id={l}
                key={l}
                icon={icons.generic.listItem}
              >
                <code>{l}</code>
              </ListItem>
            )
          })}
        </List>
      )
    }
  }
]

export default function SerializableValue({ name, value, whichToAutoFocus, onUpdate, onDone, onCancel }) {
  const [hasAutoFocused, setHasAutoFocused] = useState(false)
  const canSave = name.length > 0
  const type = window.sticky.getDeserializedType(value)
  const { Component } = TYPES.find(t => t.type === type) || TYPES.find(t => t.type === 'string')
  return (
    <StyledComponent className='component--serializable-value'>
      <div>
        <Dropdown
          className='type'
          label='Type'
          items={TYPES.map(t => ({ id: t.type, name: t.name, disabled: t.disabled }))}
          onChoose={v => {
            const chosenType = TYPES.find(t => t.type === v)
            onUpdate(chosenType.defaultName || 'Song', chosenType.defaultValue)
          }}
          selected={type}
        />
        <Input
          name='name'
          label='Name'
          value={name}
          onChange={v => onUpdate(v, value)}
          onReady={({ autoFocus }) => {
            if (whichToAutoFocus !== 'name') return
            !hasAutoFocused && autoFocus()
            setHasAutoFocused(true)
          }}
          selectAll
          isValid={name.length > 0}
          onDone={() => {
            canSave && onDone(name, value)
          }}
        />
        <Component
          value={value}
          onUpdate={v => onUpdate(name, v)}
          whichToAutoFocus={whichToAutoFocus}
          hasAutoFocused={hasAutoFocused}
          setHasAutoFocused={setHasAutoFocused}
          canSave={canSave}
          onDone={value => onDone(name, value)}
        />
      </div>
      <div className='buttons'>
        <Button
          icon={icons.inverted.check}
          onClick={() => onDone(name, value)}
          disabled={!canSave}
        >
          Done
        </Button>
        <Button
          onClick={onCancel}
          isSecondary
        >
          Cancel
        </Button>
      </div>
    </StyledComponent>
  )
}
SerializableValue.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  whichToAutoFocus: PropTypes.string,
  object: PropTypes.object,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
}
