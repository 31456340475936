import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Details, Price } from '@openbox-app-shared'
import Box from '../../components/Box'
import H2 from '../../components/H2'
import dashboardIcons from '../../icons'
import { KPIs, KPI } from '../../components/kpis'
import HeaderBar from '../HeaderBar'

const StyledReport = styled.div`
  .component--box.kpis {
    padding: 0;
  }
  .component--details {
    width: 100%;
  }
  .component--h2 {
    margin-bottom: 0.5rem;
  }
  .component--box {
    margin-top: 1rem;
  }
  .component--kpis {
    margin: 0 auto 0 auto;
  }
  .component--header-bar .component--h1 {
    color: unset;
  }
`

export default function Report ({ user, data }) {
  const detailsByDevice = new Map(
    Object.keys(data.device).map(t => {
      const v = data.device[t]
      return [t, v]
    })
  )

  const detailsByLocation = new Map(
    Object.keys(data.location).map(t => {
      const v = data.location[t]
      return [t, v]
    })
  )

  return (
    <StyledReport>
      <HeaderBar text={data.entityName} Icon={dashboardIcons[data.entityIcon]} user={user} />
      <Box className='kpis'>
        <KPIs>
          <KPI
            value={data.type.SESSION_READ || 0}
            name={`Interaction${data.type.SESSION_READ !== 1 ? 's' : ''}`}
          />
          <KPI
            value={data.type.SESSION_CART_PAY || 0}
            name={<>Payment{data.paymentsTotal !== 1 ? 's' : ''} (<Price price={data.paymentsTotal} currency={data.paymentsCurrency} />)</>}
          />
          <KPI
            value={data.paymentsTips || 0}
            name={<>Service (<Price price={data.paymentsTotalTips} currency={data.paymentsCurrency} />)</>}
          />
        </KPIs>
      </Box>

      {detailsByDevice.size > 0 && <Box>
        <H2>Interactions by location</H2>
        <Details details={detailsByLocation} />
      </Box>}

      {detailsByLocation.size > 0 && <Box>
        <H2>Interactions by device</H2>
        <Details details={detailsByDevice} />
      </Box>}
    </StyledReport>
  )
}
Report.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object
}
