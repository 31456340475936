import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, EmailInput, Switch } from '@openbox-app-shared'

export default function BankDetails ({ bankDetails, onChange, onSave }) {
  return (
    <Form
      onChange={(key, value) => {
        bankDetails.writeTo(key, value)
        onChange(bankDetails)
      }}
    >
      <Input
        name='invoiceName'
        label='Name'
        value={bankDetails.readFrom('invoiceName') || ''}
        isValid={(bankDetails.readFrom('invoiceName') || '').length > 0}
      />
      <EmailInput
        name='invoiceEmail'
        label='Email'
        value={bankDetails.readFrom('invoiceEmail') || ''}
      />
    </Form>
  )
}
BankDetails.propTypes = {
  bankDetails: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func
}
