import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NumberInput, Button } from '@openbox-app-shared'
import { dispatch, subscribe } from '../redux'

const StyledComponent = styled.div`
  .component--stock-buttons {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
`

export default function Stock ({ name, stock, stockNotify, onChange }) {
  useEffect(() => {
    const subscriptions = [
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === 'component--stock-in' && (() => {
            onChange('stock', stock + parseInt(string, 10))
          })()
          why === 'component--stock-out' && (() => {
            onChange('stock', stock - parseInt(string, 10))
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })
  return (
    <StyledComponent className='component--stock' name={name}>
      <NumberInput
        label='Stock'
        value={stock}
        onChange={v => onChange('stock', v)}
      />
      <div className='component--stock-buttons'>
        <Button
          inlineIconString={window.sticky.internals.icons.get('plus')}
          onClick={() => dispatch('GET_INPUT', { type: 'number', string: 1, hint: <>How many have come in?</>, why: 'component--stock-in', selectAll: true })}
        >
          In
        </Button>
        <Button
          inlineIconString={window.sticky.internals.icons.get('minus')}
          onClick={() => dispatch('GET_INPUT', { type: 'number', string: 1, hint: <>How many have gone out?</>, why: 'component--stock-out', selectAll: true })}
        >
          Out
        </Button>
      </div>
      <NumberInput
        label='Email me when for sale and stock is below'
        value={stockNotify}
        onChange={v => onChange('stockNotify', v)}
      />
    </StyledComponent>
  )
}
Stock.propTypes = {
  name: PropTypes.string,
  stock: PropTypes.number,
  stockNotify: PropTypes.number,
  onChange: PropTypes.func
}
