import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, List, ListItem } from '@openbox-app-shared'

const StyledSideChooser = styled.div`
  .component--list + .current-item {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 576px) {
    min-height: 280px;
    .component--list {
      float: left;
      width: 192px;
    }
    .current-item {
      margin-left: 208px;
    }
  }
`

function SideChooser ({ items }) {
  const [currentItemIndex, setCurrentItemIndex] = useState(items.length > 0 ? 0 : undefined)
  const whichReallyItem = typeof currentItemIndex === 'number' && (items[currentItemIndex] || items[0])
  return (
    <StyledSideChooser className='component--side-chooser'>
      <List>
        {items.map((l, i) => {
          return (
            <ListItem
              id={l.id}
              icon={icons.generic.listItem}
              key={l.id}
              selected={currentItemIndex === i}
              onChoose={id => setCurrentItemIndex(i)}
            >
              {l.name}
            </ListItem>
          )
        })}
      </List>
      {whichReallyItem && <div className='current-item'>{whichReallyItem.children}</div>}
    </StyledSideChooser>
  )
}
SideChooser.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.node
    })
  )
}

export default SideChooser
