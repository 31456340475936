import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, Button, Price, Label } from '@openbox-app-shared'

import { dispatch, subscribe } from '../../../../redux'
import { log } from '../../../../log'
import CardCash from './CardCash'
import dashboardIcons from '../../../../icons'
import Box from '../../../../components/Box'

const StyledSummary = styled.div`
  .grid {
    display: grid;
    grid-gap: 1rem;
  }
  .big-fat-button {
    border-radius: 8px;
  }
  .grid.grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid.grid-4 {
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .component--button {
      padding-left: 0;
      padding-right: 0;
      > strong {
        line-height: 1.25rem;
        min-height: 1.25rem;
      }
      > img + strong {
        margin-left: 0.25rem;
      }
    }
  }
  .grid.grid-2-1 {
    grid-template-columns: 1fr 2fr;
  }
  .buttons {
    > * {
      display: inline-block;
      margin: 0 1rem 0 0;
      vertical-align: top;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
  .grid + .grid, .grid + div, div + .grid, div + .buttons {
    margin-top: 1rem;
  }
  .headline {
    .total {
      display: block;
      text-align: center;
      font-size: 2.5rem;
      height: 56px;
      line-height: 56px;
      color: #1A1F35;
    }
  }
  .method {
    .component--button {
      width: 100%;
      height: 104px;
      border-radius: 6px;
      > strong {
        margin-top: 0.5rem;
      }
    }
  }
  .action {
    .component--button {
      width: 100%;
      height: 96px;
      border-radius: 6px;
      > strong {
        margin-top: 0.5rem;
      }
    }
  }
  .action-small {
    .component--button {
      width: 100%;
      font-size: 80%;
      padding: 0.25rem 0.1rem 0.25rem 0.1rem;
    }
  }
  .live-payments {
    margin-top: 1rem;
    label {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
  .live-payments + .grid {
    margin-top: 1rem;
  }
`

export default function Summary ({
  user,
  userPreferences,
  currentThing,
  payment,
  onAction,
  canMove,
  hasThings,
  applicationsMoto,
  applicationsNotMoto,
  onSetCustomTotal,
  onDiscount,
  onAddPayment,
  addLanguage = 'New payment',
  willPayAll,
  willAddCustomer
}) {
  log('[EposSummary]', { applicationsMoto, applicationsNotMoto })
  let [lastPayThing, setLastPayThing] = useState()
  const [cardCash, setCardCash] = useState()

  const { name: paymentName, extra: paymentExtra, currency, total, thingId, sessionPaidAt } = payment || {}

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'PAY_GO_TO_ON_CHANGE',
        ({ k, v }) => {
          onAction('patch', { k, v }, false)
        }
      ),
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing, why }) => {
          if (!thing) {
            return
          }
          why === 'epos--pay--thing' && (() => {
            lastPayThing = thing
            setLastPayThing(lastPayThing)
            dispatch('CHOOSE_APPLICATIONS', { why: 'epos--pay--thing', applicationIds: applicationsNotMoto.map(_ => _.id) })
          })()
          why === 'epos--move' && (() => {
            onAction('move', { thing })
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          why === 'epos--extra' && onAction('extra', { string })
          why === 'epos--rename' && onAction('rename', { string })
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        ({ why, applications }) => {
          log('[CHOOSE_APPLICATIONS_GOOD]', { why, applications, lastPayThing })
          const newApplicationId = applications[0].id
          why === 'epos--onLink' && (() => {
            payment.application = applications[0]
            onAction('patch', { k: 'newStatusDoneDontSet', v: true }, false)
            dispatch('PAY_GO_TO', { why: 'epos', payment, userPreferences })
          })()
          why === 'epos--pay--thing' && (() => {
            onAction('pay', { toDo: 'sticky', thing: lastPayThing, newApplicationId })
          })()
          why === 'epos--moto' && (() => {
            dispatch('APPLICATION_FOR_PAY', { why: 'epos--moto', paymentId: payment.id, applicationId: newApplicationId })
          })()
        }
      ),
      subscribe(
        'APPLICATION_FOR_PAY_GOOD',
        ({ why, extra, paymentId }) => {
          why === 'epos--moto' && (() => {
            onAction('moto')
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const onLink = () => {
    log('[onLink]')
    dispatch('CHOOSE_APPLICATIONS', { why: 'epos--onLink', applicationIds: applicationsNotMoto.map(_ => _.id) })
  }
  const onCashCard = () => {
    let arg
    if (willPayAll) {
      arg = {
        ...willPayAll
      }
    } else {
      arg = {
        total: payment.total,
        currency: payment.currency
      }
    }
    log('[onCashCard]', { willPayAll, arg })
    setCardCash(arg)
  }
  const onPayThing = () => {
    dispatch('CHOOSE_THING', { why: 'epos--pay--thing', hint: 'Which sticky should take the payment?' })
  }
  const onMove = () => {
    dispatch('CHOOSE_THING', { why: 'epos--move', hint: 'Which sticky should this consumer be assigned to?' })
  }
  const onExtra = () => {
    dispatch('GET_INPUT', { why: 'epos--extra', entity: payment, string: paymentExtra || '', doValidate: false, hint: 'Note:', selectAll: true, type: 'textarea' })
  }
  const onRename = () => {
    dispatch('GET_INPUT', { why: 'epos--rename', entity: payment, string: paymentName || '', doValidate: false, hint: paymentName ? `Rename "${paymentName}":` : 'Assign a name:', selectAll: true })
  }

  const paymentCart = payment && payment.cart.get()

  const { posCashCard = true } = userPreferences

  log('[Epos->Summary]', { paymentCart })
  return (
    <StyledSummary>
      {cardCash && <CardCash
        {...cardCash}
        user={user}
        onGood={toDo => {
          setCardCash(undefined)
          onAction('pay', { toDo })
        }}
        onBad={() => setCardCash(undefined)}
      />}
      <div className='headline'>
        {(payment && !willPayAll) && <Price className='total total-1' currency={currency} price={total} />}
        {(!payment && willPayAll) && <Price className='total total-2' currency={willPayAll.currency} price={willPayAll.total} />}
        {(!payment && !willPayAll) && <span className='total total-3'></span>}
      </div>
      {willAddCustomer && (
        <div className='grid grid-1'>
          <Button className='big-fat-button' icon={icons.inverted.add} bigIcon onClick={() => onAddPayment(true)}>New payment</Button>
        </div>
      )}
      <div className='grid grid-3'>
        <div className='method'>
          <Button
            onClick={() => onAddPayment(false)}
            bigIcon
            icon={willPayAll ? icons.generic.add : (payment ? icons.generic.add : icons.inverted.add)}
            isSecondary={willPayAll ? true : (payment ? true : false)}
            disabled={payment && payment.newMaster}
            className={!(willPayAll ? true : (payment ? true : false)) ? 'openbox--pulsing-1' : undefined}
          >
            {addLanguage}
          </Button>
        </div>
        <div className='method'>
          <Button
            onClick={onSetCustomTotal}
            bigIcon
            InlineIcon={dashboardIcons.customTotal}
            isSecondary={(() => {
              if (sessionPaidAt) {
                return true
              }
              return (!payment || (total > 0 || paymentCart.length > 0)) ? true : false
            })()}
            className={(() => {
              if (sessionPaidAt) {
                return undefined
              }
              return !((!payment || (total > 0 || paymentCart.length > 0))) ? 'openbox--pulsing-1' : undefined
            })()}
            disabled={!payment || (payment && !payment.isUnpaid) || (payment && payment.newMaster)}
          >
            Add to total
          </Button>
        </div>
        <div className='method sticky'>
          <Button
            bigIcon
            InlineIcon={hasThings ? dashboardIcons.stickypay : dashboardIcons.link}
            isSecondary={(() => {
              if (user.can('has-chp')) {
                return true
              }
              if ((!payment || (payment && !payment.isUnpaid))) {
                return true
              }
              return !(payment && (total > 0 || paymentCart.length > 0)) ? true : false
            })()}
            className={(() => {
              if (user.can('has-chp')) {
                return undefined
              }
              if ((!payment || (payment && !payment.isUnpaid))) {
                return undefined
              }
              if ((payment && (total > 0 || paymentCart.length > 0))) {
                return 'openbox--pulsing-1'
              }
            })()}
            onClick={() => {
              if (applicationsNotMoto.length === 0) {
                dispatch('SHOW_MESSAGE', { message: <><p>You need a flow with this template:</p><p><strong>Take a payment</strong></p></>, canBeBadded: '' })
                return
              }
              if (!hasThings) {
                onLink()
              } else {
                onPayThing()
              }
            }}
            disabled={willPayAll ? false : (!payment || (payment && !payment.isUnpaid))}
          >
            {hasThings ? 'Pay by sticky' : 'Pay by link'}
          </Button>
        </div>
      </div>
      <div className='grid grid-4'>
        <div className='action-small change-sticky'>
          <Button
            disabled={(() => {
              if (!payment) {
                return true
              }
              if (willPayAll) {
                return false
              }
              return !canMove || (payment && !payment.isUnpaid) || (payment && payment.newMaster)
            })()}
            icon={icons.generic.move} isSecondary onClick={onMove}>{willPayAll ? 'Move' : (payment && payment.thing ? 'Move' : 'Assign')}</Button>
        </div>
        <div className='action-small rename'>
          <Button icon={icons.generic.edit} isSecondary onClick={onRename} disabled={!payment || (payment && payment.newMaster)}>Name</Button>
        </div>
        <div className='action-small extra'>
          <Button InlineIcon={dashboardIcons.note} isSecondary onClick={onExtra} disabled={!payment || (payment && payment.newMaster)}>Note</Button>
        </div>
        <div className='action-small link'>
          <Button
            isSecondary
            onClick={() => {
              if (applicationsNotMoto.length === 0) {
                dispatch('SHOW_MESSAGE', { message: <><p>You need a flow with this template:</p><p><strong>Take a payment</strong></p></>, canBeBadded: '' })
                return
              }
              onLink()
            }}
            InlineIcon={dashboardIcons.link}
            disabled={(() => {
              if (!payment) {
                return true
              }
              if (willPayAll) {
                return false
              }
              return !payment.isUnpaid
            })()}
          >
            Link
          </Button>
        </div>
        <div className='action-small GATEWAY_CARD'>
          <Button
            onClick={onCashCard}
            isSecondary={(() => {
              if (!user.can('has-chp')) {
                return true
              }
              if ((!payment || (payment && !payment.isUnpaid))) {
                return true
              }
              return !(payment && (total > 0 || paymentCart.length > 0)) ? true : false
            })()}
            className={(() => {
              if (!user.can('has-chp')) {
                return undefined
              }
              if ((!payment || (payment && !payment.isUnpaid))) {
                return undefined
              }
              if ((payment && (total > 0 || paymentCart.length > 0))) {
                return 'openbox--pulsing-1'
              }
            })()}
            disabled={!posCashCard || (willPayAll ? false : (!payment || (payment && !payment.isUnpaid)))}
          >
            {window.sticky.Stickypay.GATEWAYS.find(_ => _.id === 'GATEWAY_CASH').name}/{window.sticky.Stickypay.GATEWAYS.find(_ => _.id === 'GATEWAY_CARD').name}
          </Button>
        </div>
        <div className='action-small discount'>
          <Button disabled={!payment || total === 0} isSecondary onClick={onDiscount}>Discount</Button>
        </div>
        <div className='action-small email'>
          <Button isSecondary onClick={() => onAction('email')} disabled={(!payment || (payment && payment.isUnpaid))}>Receipt</Button>
        </div>
        <div className='action-small void'>
          <Button
            isSecondary
            disabled={(() => {
              if (!user.federatedUserCan('payment-delete')) {
                return true
              }
              return payment ? false : !currentThing
            })()}
            onClick={() => onAction('void')}
          >
            {payment ? 'Void' : 'Clear'}
          </Button>
        </div>
      </div>
      <Box className='live-payments'>
        <Label>Live payments</Label>
        <div className='grid grid-2'>
          <Button
            disabled={!payment || (payment && typeof payment.newStatusDone === 'boolean') || (payment && payment.newMaster)}
            icon={icons.inverted.add}
            onClick={() => {
              onAction('to-to-do')
            }}
          >
            Add
          </Button>
          <Button
            isSecondary
            disabled={!payment || (payment && typeof payment.newStatusDone === 'boolean') || (payment && payment.newMaster)}
            icon={icons.generic.check}
            onClick={() => {
              onAction('done')
            }}
          >
            Skip
          </Button>
        </div>
      </Box>
      {user.can('has-printer') && (
        <div className='grid'>
          <Button disabled={!payment} InlineIcon={dashboardIcons.printer} isSecondary onClick={() => onAction('print')}>Print</Button>
        </div>
      )}
    </StyledSummary>
  )
}

Summary.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  currentThing: PropTypes.object,
  payment: PropTypes.object,
  onAction: PropTypes.func.isRequired,
  canMove: PropTypes.bool,
  hasThings: PropTypes.bool,
  applicationsMoto: PropTypes.array,
  applicationsNotMoto: PropTypes.array,
  onSetCustomTotal: PropTypes.func,
  onDiscount: PropTypes.func,
  onAddPayment: PropTypes.func,
  addLanguage: PropTypes.string,

  willPayAll: PropTypes.object,
  willAddCustomer: PropTypes.bool
}