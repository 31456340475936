import React from 'react'
import styled from 'styled-components'
import { icons, Form, Input, DynamicInput, Switch, Button, LinkButton, Dropdown, Banner, NumberInput } from '@openbox-app-shared'
import dashboardIcons from '../../icons'
import Box from '../../components/Box'
import H2 from '../../components/H2'
import { dispatch } from '../../redux'
import Logo from '../../components/Logo'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--form + .component--form {
    margin-top: 1.25rem;
  }
  .component--partner {
    margin-bottom: 1rem;
  }
  .component--copy-url {
    margin-top: 1rem;
  }
  .buttons {
    margin-top: 2rem;
    margin-bottom: 0 !important;
  }
  > * + * {
    margin-top: 1rem;
  }
  .grid {
    @media only screen and (min-width: 1024px) {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * + * {
        margin-top: 0 !important;
      }
    }
  }
  .component--h2 {
    margin-bottom: 0.5rem;
  }
`

const tab = {
  id: 'about',
  inlineIcon: dashboardIcons.home,
  to: context => '/my/account/about',
  child: ({ context, dbfProps }) => {
    // const { federatedUsers } = context.state
    // const mainContact = federatedUsers.find(_ => _.roles.has('contact')) || federatedUsers[0]
    // const changeMainContact = federatedUserId => {
    //   const newMainContact = federatedUsers.find(_ => _.id === federatedUserId)
    //   federatedUsers
    //     .filter(_ => _.roles.has('contact'))
    //     .forEach(_ => {
    //       _.roles.delete('contact')
    //       dispatch('UPDATE_FEDERATED_USER', { entity: _, delta: { roles: _.roles } })
    //     })
    //   newMainContact.roles.add('contact')
    //   dispatch('UPDATE_FEDERATED_USER', { entity: newMainContact, delta: { roles: newMainContact.roles } })
    // }

    const { user } = context.props

    const abCommercialsPayments = [
      user.stickypayMerchantPercentage > 0 && `${user.stickypayMerchantPercentage / 100}%`,
      user.stickypayPerTx > 0 && `${window.sticky.Stickypay.formatPrice(undefined, user.stickypayPerTx, 2)} `
    ]
      .filter(_ => _)
      .join(' + ')
    const abCommercials = [
      abCommercialsPayments && `Payments: ${abCommercialsPayments}`
    ]
      .filter(_ => _)
      .join('\n\n')

    return (
      <StyledComponent>
        <div className='grid'>
          <Box>
            <Form {...dbfProps}>
              <Input
                name='name'
                label='Name'
                style={{ maxWidth: '16rem' }}
                value={user.name}
                isValid={user.name.length > 0}
              />
              <DynamicInput type='email'
                name='email'
                label='Email'
                style={{ maxWidth: '16rem' }}
                value={user.email}
              />
              {/* <DynamicInput type='phone'
                name='phone'
                label='Phone'
                style={{ maxWidth: '16rem' }}
                value={user.phone}
                doValidate={false}
              />
              <hr />
              <Dropdown
                label='Main contact'
                style={{ maxWidth: '16rem' }}
                selected={mainContact.id}
                items={federatedUsers}
                onChoose={(v) => { changeMainContact(v) }}
              /> */}
              <hr />
              <Dropdown
                name='currency'
                style={{ maxWidth: '12rem' }}
                label='Currency'
                selected={user.currency}
                items={window.sticky.Stickypay.CURRENCIES.filter(c => c.userCreatable).map(ic => ({
                  id: ic.id,
                  name: ic.userCreatable
                }))}
              />
              <Dropdown
                name='country'
                style={{ maxWidth: '12rem' }}
                label='Region'
                selected={user.country}
                items={window.sticky.internals.ISO_COUNTRIES.filter(c => c[1].userCreatable).map(ic => ({
                  id: ic[0],
                  name: ic[1].name
                }))}
              />
              <Dropdown
                name='language'
                style={{ maxWidth: '12rem' }}
                label='Language'
                selected={user.language}
                items={window.sticky.internals.languages.getDashboardable().map(id => ({
                  id,
                  name: window.sticky.internals.languages.getName(id)
                }))}
              />
              <Input
                name='vat'
                label='Tax/VAT number'
                style={{ maxWidth: '16rem' }}
                value={user.vat}
                isValid={typeof user.vat === 'string' && user.vat.length > 0}
              />
            </Form>
            <Form
              onChange={(_, value) => {
                user.timezone = value * 3600
                dbfProps.onChange('timezone', user.timezone)
              }}
            >
              <NumberInput
                name='timezone'
                label='Time zone (UTC offset in hours)'
                value={user.timezone / 3600}
                style={{ width: '6rem' }}
                min={-12}
                max={14}
              />
              <Banner mood='good'>
                <p>
                  The time zone is right if your watch says <strong>{window.sticky.dateTime.getFormattedTime(undefined, undefined, user.timezone)}</strong>.
                </p>
              </Banner>
            </Form>
          </Box>
          <div>
            <Box>
              <Logo
                name='logoUrl'
                label='Logo'
                url={user.logoUrl}
                onChange={({ url: logoUrl }) => {
                  dbfProps.onChange('logoUrl', logoUrl)
                }}
                svgsAllowed={false}
              />
            </Box>
            <Box>
              <H2>Email notifications</H2>
              <Form onChange={(k, v) => {
                user.whenEmail.toggle(k)
                dbfProps.onChange('whenEmail', user.whenEmail)
              }}>
                <Switch checked={user.whenEmail.has('payment-success')} name='payment-success'>
                  Successful payment
                </Switch>
                <Switch checked={user.whenEmail.has('payment-failure')} name='payment-failure'>
                  Failed payment
                </Switch>
                <Switch checked={user.whenEmail.has('payment-failure-connection')} name='payment-failure-connection'>
                  Failed payment because of a connection
                </Switch>
                <Switch checked={user.whenEmail.has('submit-form')} name='submit-form'>
                  Form submission
                </Switch>
                <Switch checked={user.whenEmail.has('check-in')} name='check-in'>
                  Check in
                </Switch>
              </Form>
            </Box>
          </div>
        </div>
        <div className='buttons'>
          <Button
            onClick={() => {
              dispatch('USER_DELETE', { otherUser: user })
            }}
            icon={icons.inverted.delete}
            backgroundColor='#ff3838'
          >
            Delete my dashboard
          </Button>
          {user.federatedUser && <Button
            onClick={() => dispatch('GET_INPUT', { why: 'federatedUser', type: 'password', string: '', entity: user.federatedUser, hint: <>Choose a new password:</> })}
            isSecondary
          >
            Change password
          </Button>}
          {user.partnerCan('onboard') && <Button
            onClick={() => dispatch('ONBOARDING')}
            isSecondary
          >
            Onboard
          </Button>}
          <LinkButton
            to={window.sticky.applications.test('32be32a0-925a-4684-95ae-2311a8bc1e5f', `ab_name=${encodeURIComponent(user.name)}&ab_dataProcessors=${encodeURIComponent([user.partner ? user.partner.name : undefined, 'Google', 'DigitalOcean'].filter(_ => _).join('/'))}&ab_commercials=${encodeURIComponent(abCommercials)}`)}
            isSecondary
          >
            My contract
          </LinkButton>
        </div>
      </StyledComponent>
    )
  }
}

export default tab
