import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import classnames from 'classnames'
const { v4: uuid } = require('uuid')

const StyledComponent = styled.div`
  > input {
    display: none;
  }
  &.disabled {
    opacity: 0.5;
    &, > * {
      cursor: not-allowed;
    }
  }
  > label.fake {
    float: left;
    height: 2rem;
  }
  label.real {
    height: 2rem;
    margin-left: 3.9rem;
    line-height: 0.9rem;
    font-size: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${props => css`
      color: ${props.color};
    `}
  }
  > input + label {
    display: block;
    position: relative;
    // off color background
    background-color: #ecf0f1;
    // off color border
    box-shadow: inset 0 0 0px 2px #1A1F35;
    text-indent: -5000px;
    width: calc(2rem + (2rem * (2 / 3)));
    border-radius: 1rem;
  }
  > input + label:before {
    content: "";
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    top: 0;
    left: 0;
    border-radius: 1rem;
    transition: 0.3s ease-in-out;
  }
  > input + label:after {
    content: "";
    position: absolute;
    display: block;
    height: 2rem;
    width: 2rem;
    top: 0;
    left: 0;
    border-radius: 1rem;
    background-color: white;
    // off color border
    box-shadow: inset 0 0 0 2px #1A1F35;
    transition: 0.3s ease-in-out;
  }
  > input:checked + label:before {
    width: calc(2rem + (2rem * (2 / 3)));
    // on color background
    background-color: #4cd963;
  }
  > input:checked + label:after {
    left: calc(2rem * (2 / 3));
    // on color nub
    background-color: white;
    box-shadow: inset 0 0 0 2px #1A1F35;
  }
`

const Switch = ({ children, color, checked = false, disabled = false, onChange = () => {}, className }) => {
  const id = uuid()

  const handleOnChange = () => {
    return onChange(!checked)
  }

  const classNames = classnames('component--switch', className, { disabled })
  return (
    <StyledComponent className={classNames} color={color}>
      <input type='checkbox' id={id} disabled={disabled} checked={checked} onChange={handleOnChange} />
      <label className='fake' htmlFor={id} />
      <label className='real' htmlFor={id}>{children}</label>
    </StyledComponent>
  )
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default Switch
