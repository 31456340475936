import germany from './files/germany.svg'
import ireland from './files/ireland.svg'
import spain from './files/spain.svg'
import unitedKingdom from './files/unitedKingdom.svg'
import unitedStates from './files/unitedStates.svg'

export default {
  germany,
  ireland,
  spain,
  unitedKingdom,
  unitedStates
}
