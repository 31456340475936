import React, { useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Box from './Box'
import PropTypes from 'prop-types'
import { Button } from '@openbox-app-shared'

const StyledComponent = styled.div`
  width: 100%;
  .component--box {
    padding: 0.25rem;
    border-radius: 8px;
    position: relative;
    background-color: black;
    z-index: 4;
    > .headline {
      height: 2.25rem;
      line-height: 2.25rem;
      color: white;
      padding: 0 0.75rem 0 0.75rem;
      display: block;
    }
    > .headline + .component--ops-manager-cart {
      margin-top: 0.25rem;
    }
    > .buttons {
      position: absolute;
      top: 0.5rem;
      right: 2.75rem;
      > * {
        display: inline-block;
      }
      > * + * {
        margin-left: 0.5rem;
      }
    }
    > .expand-collapse {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      width: 2.25rem;
      height: 2.25rem;
      padding: 0 !important;
      border: 0;
      box-shadow: none;
      background-color: transparent;
      font-size: 200%;
      border-radius: 0;
      strong {
        display: inline-block;
        vertical-align: -5px;
        color: white;
      }
      &:not(.collapsed) {
        transform: scaleY(-1);
      }
    }
    .component--ops-manager-cart ul {
      li:first-child {
        > *:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        .void {
          border-top-right-radius: 4px;
        }
      }
      li:last-child {
        > *:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .void {
          border-bottom-right-radius: 4px;
        }
      }
    }
    .component--tag-chooser {
      width: fit-content;
      margin-top: 0.5rem;
      margin-left: 0.25rem;
      ul li {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
`

export default function CoolCart ({ headline, headlineColor, buttons, style, isCollapsedByDefault = false, children }) {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault)
  return (
    <StyledComponent className={classnames('component--cool-cart', { 'has-headline': headline })} style={style}>
      <Box style={{ backgroundColor: headlineColor }}>
        {headline && <>
          <Button
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
            className={`expand-collapse ${isCollapsed ? 'collapsed' : 'not-collapsed'}`}
          >
            &#8963;
          </Button>
          <strong className='headline'>{headline}</strong>
        </>}
        {buttons && <div className='buttons'>{buttons}</div>}
        {!isCollapsed && children}
      </Box>
    </StyledComponent>
  )
}
CoolCart.propTypes = {
  headline: PropTypes.node,
  buttons: PropTypes.node,
  headlineColor: PropTypes.string,
  style: PropTypes.object,
  isCollapsedByDefault: PropTypes.bool,
  children: PropTypes.node
}
