/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from '@openbox-app-shared'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    width: calc(100% - 0.5rem);
    max-width: 480px;
    padding: 2.5rem 0.25rem 0.25rem 0.25rem !important;
  }
  iframe {
    display: block;
    width: 100%;
    border: 2px solid #F0F1F3;
    border-radius: 6px;
    height: 680px;
    max-height: calc(100vh - 5.5rem);
  }
`

function ThisModal ({ user, applicationId, currency, total, totalCpa, cpaPeriod, onDone, paymentId }) {
  const query = [
    typeof currency == 'string' && `currency=${currency}`,
    typeof total === 'number' && `total=${total}`,
    paymentId && `paymentId=${paymentId}`
  ]
  useEffect(
    () => {
      const windowListen = (e) => {
        e.data.type === 'STICKY_PAYMENT_SUCCESS' && onDone(true, e.data.paymentId)
      }
      window.addEventListener('message', windowListen)
      return () => {
        window.removeEventListener('message', windowListen)
      }
    }
  )
  typeof totalCpa === 'number' && query.push(`totalCpa=${totalCpa}`)
  typeof cpaPeriod === 'string' && query.push(`cpaPeriod=${cpaPeriod}`)
  const applicationLink = window.sticky.applications.test(applicationId, query.join('&'))
  return (
    <StyledModal>
      <Modal onClose={() => onDone(false)}>
        {/* <p>Currency: {currency}</p>
        <p>Total: {total}</p>
        <p>Total CPA: {totalCpa}</p>
        <p>Total CPA period: {cpaPeriod}</p> */}
        <iframe id='ReduxHandler--PAY' src={applicationLink} title={`Pay ${total} ${currency}`} allow='payment' />
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, extra, applicationId, currency, total, totalCpa, cpaPeriod, paymentId }) => {
    setState({ PAY: { why, extra, applicationId, currency, total, totalCpa, cpaPeriod, paymentId } })
  },
  render: function ThisAction ({ user, state, setState }) {
    const { PAY } = state
    if (!PAY) {
      return null
    }
    const { why, extra, applicationId, currency, total, totalCpa, cpaPeriod, paymentId } = PAY
    const props = {
      user,
      applicationId,
      currency,
      total,
      totalCpa,
      cpaPeriod,
      paymentId
    }
    return (
      <ThisModal
        {...props}
        onDone={(isGood, paymentId) => {
          isGood && setTimeout(
            () => {
              setState({ PAY: false })
              dispatch('APPLICATION_FOR_PAY_GOOD', { why, extra, paymentId })
            },
            2 * 1000
          )
          !isGood && setState({ PAY: false })
        }}
      />
    )
  }
}
