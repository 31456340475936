import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Loading, CustomHelmet } from '@openbox-app-shared'
import _ from '../../../../_'
import dashboardIcons from '../../../../icons'
import ProductsLikeAMenu from '../../../../components/ProductsLikeAMenu'
import { dispatch } from '../../../../redux'

const StyledComponent = styled.div`
`

function TabProductsCategoriesRenderChild({ context }) {
  return (
    <StyledComponent>
      <CustomHelmet title={`Products | ${context.props.user.name}`} />
      {!(Array.isArray(context.state.products) && context.props.user) && <Loading />}
      {(Array.isArray(context.state.products) && context.props.user) && (
        <ProductsLikeAMenu
          user={context.props.user}
          className='main-dish-2 products'
          productCategories={context.state.productCategories}
          products={context.state.products}
          showTags
          showActions
          onProductCategoryEdit={context.onProductCategoryEdit}
          onProductCategoryAction={context.onProductCategoryAction}
          onProductAction={context.onProductAction}
          canGoToProduct
          masterActions={['add', 'search', 'stock-control', 'import']}
          onMasterAction={a => {
            ({
              'add': () => context.createProductCategory()
            })[a]()
          }}
          onDrag={async (from, to) => {
            dispatch('LOADING')
            const now = Math.floor(window.sticky.dateTime.getNowUtcLegacy() / 1000)
            const containers = [...context.state.productCategories]
            window.sticky.swapArrayElements(containers, from, to)
            for (let i = 0; i < containers.length; i++) {
              const si = containers[i]
              si.createdAt = now + i
              await window.sticky.products.categories.save(si, ['createdAt'])
            }
            await context.refreshProductCategories()
            dispatch('STOP_LOADING')
          }}
        />
      )}
    </StyledComponent>
  )
}
TabProductsCategoriesRenderChild.propTypes = {
  context: PropTypes.object
}

const tabProductsCategories = {
  id: 'products',
  name: (context) => 'Products',
  inlineIcon: dashboardIcons.product,
  to: (context) => '/me/products',
  child: function Child({ context }) {
    return <TabProductsCategoriesRenderChild context={context} />
  }
}

export default tabProductsCategories
