import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import UserCircle from './UserCircle'
import { Empty } from '@openbox-app-shared'

const StyledComponent = styled.div`
  list-style-type: none;
  display: grid;
  grid-gap: 1rem;
  ${props => css`grid-template-columns: ${new Array(props.columns).fill(true).map(_ => '1fr').join(' ')};`}
  > * {
    width: 100%;
    > * {
      margin: 0 auto 0 auto;
    }
  }
`

export default function FederatedUsersGrid ({ federatedUsers, chosenFederatedUserIds = [], onChoose, showEmpty = true }) {
  const columns = Math.min(federatedUsers.length, 3)
  return (
    <>
      {showEmpty && federatedUsers.length === 0 && (
        <Empty>You don't have any team members yet.</Empty>
      )}
      {federatedUsers.length > 0 && <StyledComponent className='component--federated-users component--federated-users-grid' columns={columns}>
        {federatedUsers.map(fu => (
          <div key={fu.id}>
            <UserCircle isChosen={chosenFederatedUserIds.includes(fu.id)} name={fu.name} color={fu.color} onClick={() => onChoose(fu)} photoUrl={fu.photoUrl} />
          </div>
        ))}
      </StyledComponent>}
    </>
  )
}
FederatedUsersGrid.propTypes = {
  federatedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  chosenFederatedUserIds: PropTypes.arrayOf(PropTypes.string),
  onChoose: PropTypes.func.isRequired,
  showEmpty: PropTypes.bool
}
