/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Loading } from '@openbox-app-shared'

const StyledComponent = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(0.5rem);
  .component--loading {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    opacity: 1;
  }
`

export default {
  trigger: ({ setState, loadingWhat }) => {
    setState({ LOADING: { loadingWhat } })
  },
  render: function ThisAction ({ state }) {
    const { LOADING } = state
    if (!LOADING) {
      return null
    }
    const { loadingWhat } = LOADING
    return (
      <StyledComponent>
        <Loading loadingWhat={loadingWhat} />
      </StyledComponent>
    )
  }
}
