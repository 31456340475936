import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledComponent = styled.div`
  > .contents {
    padding: 0.25rem;
    background-color: #1A1F35;
    border-radius: 8px;
    box-shadow: 0 7px 14px 0 rgb(60 66 87 / 20%), 0 3px 6px 0 rgb(0 0 0 / 20%);
    > iframe {
      display: block;
      width: 100%;
      height: 672px;
      border-radius: 4px;
    }
    > iframe[disabled] {
      pointer-events: none;
      opacity: 0.75;
    }
  }
`

export default function InlineLinkFrame ({ link, disabled, backgroundColor }) {
  return (
    <StyledComponent className='component--inline-link-frame'>
      <div className='contents'><iframe disabled={disabled} src={link} style={{ backgroundColor: backgroundColor || 'white' }} /></div>
    </StyledComponent>
  )
}
InlineLinkFrame.propTypes = {
  link: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string
}
