import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { HashLink } from 'react-router-hash-link'

const Link = forwardRef(({ to, dontAttachQueryParameters, children, ...extra }, ref) => {
  const isRouteExternal = (
    to.indexOf('http://') === 0 ||
    to.indexOf('https://') === 0 ||
    to.indexOf('mailto:') === 0 ||
    to.indexOf('tel:') === 0 ||
    to.indexOf('data:') === 0
  )

  if (isRouteExternal) {
    return <a ref={ref} href={to} {...extra}>{children}</a>
  }
  const hashPart = to.split('#')[1]
  if (typeof hashPart === 'string') {
    to = to.substring(0, to.indexOf('#'))
  }

  const searchCharacter = to.split('/').pop().indexOf('?') >= 0 ? '&' : '?'

  const actualTo = dontAttachQueryParameters ? to : typeof window !== 'undefined' && `${to}${searchCharacter}${window.location.search.substring(1)}${typeof hashPart === 'string' ? `#${hashPart}` : ''}`
  return <HashLink ref={ref} {...extra} to={actualTo}>{children}</HashLink>
})

Link.propTypes = {
  to: PropTypes.string,
  dontAttachQueryParameters: PropTypes.bool,
  children: PropTypes.node
}

export default Link
