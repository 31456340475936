import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import { Form, Input } from '@openbox-app-shared'
import EditConfiguration from './EditConfiguration'
import Box from '../../../../../components/Box'

const StyledComponent = styled.div`
  &.disabled .component--box {
    h2, .component--scrollable-table {
      opacity: 0.5;
    }
  }
`

export default function TabSettings ({ context, onHasMadeUpdate }) {
  const { applicationBlock, disabled } = context
  const inputProps = {
    disabled
  }
  return (
    <StyledComponent className={classnames({ disabled })}>
      <Form onChange={onHasMadeUpdate}>
        <Input
          {...inputProps}
          name='name'
          label='Name'
          value={applicationBlock.name}
          className='limit-width-1'
        />
        <Input
          {...inputProps}
          name='renderName'
          label='Display in the list of flow steps'
          value={applicationBlock.renderName}
          className='limit-width-1'
        />
        <Input
          {...inputProps}
          className='limit-width-2'
          type='textarea'
          name='description'
          label='Help text'
          value={applicationBlock.description}
        />

        <Box>
          <EditConfiguration
            name='defaultConfig'
            defaultConfig={applicationBlock.defaultConfig}
            disabled={disabled}
            onChange={v => {
              onHasMadeUpdate('defaultConfig', v)
            }}
          />
        </Box>

        <Input
          label='ID'
          value={applicationBlock.id}
          disabled
        />
      </Form>
    </StyledComponent>
  )
}
TabSettings.propTypes = {
  context: PropTypes.object,
  onHasMadeUpdate: PropTypes.func.isRequired
}
