import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledComponent = styled.div`
  height: 118px;
  .component--colour-picker {
    display: inline-block;
    float: left;
    width: auto;
    margin: 0;
  }
  .component--colour-picker + .component--colour-picker {
    margin-left: 1rem;
  }
`

export default function ColourPickers ({ children }) {
  return (
    <StyledComponent className='component--colour-pickers'>
      {children}
    </StyledComponent>
  )
}
ColourPickers.propTypes = {
  children: PropTypes.node
}
