import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Loading, CustomHelmet, Button, LinkButton, Price, Banner } from '@openbox-app-shared'

import Box from '../components/Box'
import H2 from '../components/H2'
import MainFrame from '../components/MainFrame'
import User from '../components/User'
import TabBar from '../components/TabBar'
import ThingPayments from '../components/ThingPayments'
import SaveButton from '../components/SaveButton'
import _ from '../_'
import dashboardIcons from '../icons'
import { dispatch, dispatchMany, subscribe } from '../redux'

import tabHome from './account/home'
import tabPaymentProvider from './account/paymentProvider'
import tabLocation from './account/location'
import tabConnections from './account/connections'
import tabApplicatonBases from './account/applicationBases'
// import tabApplicatonBaseSets from './account/applicationBaseSets'
import tabExecutableCodes from './account/executableCodes'
import tabShortLinks from './account/shortLinks'
import tabCustomData from './account/customData'
import tabHardware from './account/hardware'
import tabTags from './account/tags'
import tabAdvanced from './account/advanced'
import HeaderBar from '../components/HeaderBar'

const StyledRoute = styled.div`
  padding: 1rem;
  .component--box + .component--banner,
  .component--box + .component--box,
  .component--logo + .component--box {
    margin-top: 1rem;
  }
  .limit-width {
    margin-top: 1rem;
    max-width: 320px;
  }
  .user--billing {
    margin-top: 3rem;
    .component--scrollable-table {
      width: 100%;
      margin-top: 0.5rem;
    }
    .component--table tr td {
      padding-bottom: 0.5rem;
    }
  }
  .buttons {
    margin-bottom: 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
  }
  // .component--tabbar > .component--bar ul li.tab-v2-flow-template-sets > a,
  // .component--tabbar > .component--bar ul li.tab-v2-flow-templates > a
  // {
  //   color: #26de81;
  //   &.active {
  //     border-color: #26de81;
  //   }
  // }
`

export default class Route extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReady: false,
      toDispatch: []
    }
  }

  async componentDidMount() {
    const { user, onUpdateUser } = this.props
    const applications = await window.sticky.applications.getAll()
    this.subscriptions = [
      subscribe(
        'SHOW_MESSAGE_GOOD',
        ({ why }) => {
          why && ['applePay'].some(v => why.startsWith(v)) && (() => {
            window.location.reload()
          })()
          why === 'exit-sandbox' && (() => {
            user.permissions.delete('sandbox')
            onUpdateUser({ 'permissions': user.permissions })
          })()
          ;(why && (why.startsWith('mungeAllApplicationsGateway--') || why.startsWith('acquirer--'))) && (() => {
            window.location.reload()
          })()
        }
      ),
      subscribe(
        'UPDATE_FEDERATED_USER',
        ({ entity, delta }) => {
          entity.patch(delta)
          // remove any existing SAVE_FEDERATED_USER events
          let { toDispatch } = this.state
          toDispatch = toDispatch.filter(_ => {
            return !(_[0] === 'SAVE_FEDERATED_USER' && _[1].entity.id === entity.id)
          })
          toDispatch.push(['SAVE_FEDERATED_USER', { entity, props: Object.keys(delta) }])
          this.setState({
            hasMadeUpdate: true,
            toDispatch
          })
        }
      )
    ]

    const federatedUsers = await window.sticky.users.federated.getAll()

    const baseSetState = {
      isReady: true,
      applications,
      federatedUsers
    }

    if (user.can('hide-billing') ? user.isLoggedInAsPartner : true) {
      window.sticky.pay.setQuery(`userPrivateKey=${user.privateKey}`)
      const thingPayments = [...(await window.sticky.pay.getAll())].reverse()
      window.sticky.pay.resetQuery()
      this.setState({
        ...baseSetState,
        thingPayments
      })
    } else {
      this.setState(baseSetState)
    }
  }
  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  render() {
    const { isReady, hasMadeUpdate } = this.state
    const { user, match, onUpdateUser, autoUi, onSaveUser } = this.props
    const dbfProps = {
      onChange: (key, value) => {
        onUpdateUser({ [key]: value })
        this.setState({
          hasMadeUpdate: true
        })
      }
    }

    return (
      <StyledRoute>
        <CustomHelmet
          title={`Account | ${user.name}`}
        />
        <MainFrame
          user={user}
          autoUi={autoUi}
          aside={<>
            <User user={user} whichPart={match.path} autoUi={autoUi} />
          </>}
          main={
            <>
              <HeaderBar text='Account' Icon={dashboardIcons.teamMember} user={user} />
              <Box>
                {!isReady && <Loading />}
                {isReady && <>
                  <div className='buttons'>
                    <SaveButton
                      onSave={async () => {
                        await dispatchMany(this.state.toDispatch)
                        await onSaveUser()
                        this.setState({
                          hasMadeUpdate: false,
                          toDispatch: []
                        })
                      }}
                      color={hasMadeUpdate ? '#FF3838' : undefined}
                      canSave={hasMadeUpdate}
                    />
                    {autoUi.includes('logOut') && <Button
                      InlineIcon={dashboardIcons.logOut}
                      onClick={() => {
                        window.parent.postMessage({ event: 'autoUiLogOut' })
                      }}
                    >
                      Log out
                    </Button>}
                  </div>
                  <TabBar
                    selectedTab={match.params.entity}
                    tabs={[
                      {
                        ...tabHome,
                        to: tabHome.to(this),
                        child: tabHome.child({ context: this, dbfProps })
                      },
                      {
                        ...tabPaymentProvider,
                        to: tabPaymentProvider.to(this),
                        child: tabPaymentProvider.child({ context: this, dbfProps })
                      },
                      {
                        ...tabTags,
                        to: tabTags.to(this),
                        child: tabTags.child({ context: this, dbfProps })
                      },
                      // {
                      //   ...tabApplicatonBaseSets,
                      //   to: tabApplicatonBaseSets.to(this),
                      //   child: tabApplicatonBaseSets.child({ context: this, dbfProps })
                      // },
                      user.can('connections') && {
                        ...tabConnections,
                        to: tabConnections.to(this),
                        child: tabConnections.child({ context: this, dbfProps })
                      },
                      {
                        ...tabExecutableCodes,
                        to: tabExecutableCodes.to(this),
                        child: tabExecutableCodes.child({ context: this, dbfProps })
                      },
                      (user.can('hide-billing') ? user.isLoggedInAsPartner : true) && {
                        id: 'invoices',
                        name: 'Invoices',
                        to: '/my/account/invoices',
                        inlineIcon: dashboardIcons.payment,
                        child: <div className='user--billing'>
                          <CustomHelmet title={`Invoices | Account | ${user.name}`} />
                          {user.is('sandbox') && (
                            <Banner className='limit-width'>
                              <p>People will see that you have a sandbox dashboard. You won't be billed.</p>
                              <Button
                                onClick={() => {
                                  dispatch('TRIGGER', { trigger: 'exit-sandbox' })
                                }}
                              >
                                Exit sandbox and start billing
                              </Button>
                            </Banner>
                          )}
                          {!Array.isArray(this.state.thingPayments) && <Loading />}
                          {Array.isArray(this.state.thingPayments) && (
                            <ThingPayments user={user} payments={this.state.thingPayments} />
                          )}
                        </div>
                      },
                      {
                        ...tabHardware,
                        to: tabHardware.to(this),
                        child: tabHardware.child({ context: this, dbfProps })
                      },
                      {
                        ...tabApplicatonBases,
                        to: tabApplicatonBases.to(this),
                        child: tabApplicatonBases.child({ context: this, dbfProps })
                      },
                      {
                        ...tabShortLinks,
                        to: tabShortLinks.to(this),
                        child: tabShortLinks.child({ context: this, dbfProps })
                      },
                      {
                        ...tabCustomData,
                        to: tabCustomData.to(this),
                        child: tabCustomData.child({ context: this, dbfProps })
                      },
                      {
                        ...tabLocation,
                        to: tabLocation.to(this),
                        child: tabLocation.child({ context: this, dbfProps })
                      },
                      {
                        ...tabAdvanced,
                        to: tabAdvanced.to(this),
                        child: tabAdvanced.child({ context: this, dbfProps })
                      }
                    ]
                      .filter(t => t)
                      .map(t => {
                        return {
                          ...t,
                          name: typeof t.name === 'function' ? t.name(this) : t.name
                        }
                      })
                    }
                  />
                </>}
              </Box>
            </>
          }
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  onUpdateUser: PropTypes.func,
  onSaveUser: PropTypes.func,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
