import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, CustomHelmet, Loading, LinkButton } from '@openbox-app-shared'

import Users from '../components/Users'
import { log } from '../log'
import { subscribe } from '../redux'
import Box from '../components/Box'
import TabBar from '../components/TabBar'
import dashboardIcons from '../icons'

const StyledRoute = styled.div`
  padding: 1rem;
  > .component--button {
    display: block;
    margin-bottom: 1rem;
  }
  .component--scrollable-table {
    margin-top: 1rem;
  }
  .component--tabbar > .component--bar ul li.tab-v2-active > div {
    color: #26de81;
    &.active {
      border-color: #26de81;
    }
  }
  .component--tabbar > .component--bar ul li.tab-v2-archived > div {
    color: #ff3838;
    &.active {
      border-color: #ff3838;
    }
  }
`

export default class Route extends Component {
  constructor () {
    super()
    this.state = {}
  }

  async getUsers () {
    log('[Route-users] [getUsers]')
    let partners = []
    try {
      partners = await window.sticky.internals.partners.getAll()
    } catch (error) {
    }
    const usersFromSdkCall = await window.sticky.users.getAll(true)
    const users = await window.sticky.map(usersFromSdkCall, async (user) => {
      const partner = partners.find(p => p.id === user.partnerId)
      return { user, partner }
    })
    log('[Route-users] [getUsers]', { usersFromSdkCall, users })
    this.setState({
      users
    })
  }

  async componentDidMount () {
    log('[Route-users] [componentDidMount]')
    this.subscriptions = [
      subscribe(
        'USER_EDIT_GOOD',
        () => {
          this.getUsers()
        }
      )
    ]
    this.getUsers()
  }

  componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  render () {
    const { user: authenticatedUser } = this.props
    const {
      users
    } = this.state

    log('[Route-users] [render]', { users })

    const tabs = users ? [
      {
        id: 'active',
        name: 'Active',
        inlineIcon: dashboardIcons.teamMember,
        child: (() => {
          const filteredUsers = users.filter(_ => !_.user.tagsInternal.has('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98'))
          return <Users users={filteredUsers} authenticatedUser={authenticatedUser} />
        })()
      },
      {
        id: 'archived',
        name: 'Archived',
        inlineIcon: dashboardIcons.teamMember,
        child: (() => {
          const filteredUsers = users.filter(_ => _.user.tagsInternal.has('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98'))
          return <Users users={filteredUsers} authenticatedUser={authenticatedUser} />
        })()
      }
    ] : []

    return (
      <StyledRoute>
        <CustomHelmet
          title='Dashboard'
        />
        <LinkButton
          to='/sign-up'
          icon={icons.inverted.add}
          dontAttachQueryParameters
        >
          Add dashboard
        </LinkButton>
        {!users && <Loading />}
        {users && <>
          <Box>
            <TabBar
              tabs={tabs}
            />
          </Box>
        </>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object
}
