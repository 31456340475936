import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AbstractedPayments from './AbstractedPayments'

export default function AbstractAbstractPayments ({ user, query, onSomethingClicked, onReady }) {
  const [payments, setPayments] = useState()
  useEffect(() => {
    async function doIt (force) {
      window.sticky.pay.setQuery(query)
      const payments = [...(await window.sticky.pay.getAll(force))].reverse()
      window.sticky.pay.resetQuery()
      setPayments(payments)
    }
    !Array.isArray(payments) && doIt(false)
    onReady && onReady({
      reRender: () => doIt(true)
    })
  })
  return (
    <AbstractedPayments user={user} payments={payments} onSomethingClicked={onSomethingClicked} />
  )
}
AbstractAbstractPayments.propTypes = {
  user: PropTypes.object,
  query: PropTypes.string,
  onSomethingClicked: PropTypes.func,
  onReady: PropTypes.func
}
