const NAME = 'openbox-app-customer-dashboard'

let startTime = Date.now()

export function log (message, ...stuff) {
  const currentTime = Date.now()
  const timeDifference = (currentTime - startTime).toString().padEnd(5)
  console.log(`\x1b[31m${timeDifference} \x1b[30m[${NAME}] ${message}`, ...stuff)
}

export function error (message, ...stuff) {
  const currentTime = Date.now()
  const timeDifference = (currentTime - startTime).toString().padEnd(5)
  console.error(`\x1b[31m${timeDifference} \x1b[30m[${NAME}] ${message}`, ...stuff)
}

export function reset () {
  startTime = Date.now()
}
