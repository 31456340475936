import React from 'react'
import classnames from 'classnames'

import Button from './Button'
import Link from './Link'

export default function LinkButton ({ children, to, sameTab = false, className, style = {}, download, dontAttachQueryParameters, disabled, ...props }) {
  const genericProps = {
    download,
    className: classnames('component--button', className),
    style: {
      textDecoration: 'none',
      width: 'fit-content',
      ...style
    },
    tabIndex: '-1',
    dontAttachQueryParameters
  }
  const canGoTo = !disabled ? to : undefined
  if (!canGoTo) {
    return (
      <div {...genericProps}>
        <Button {...props} disabled={disabled}>{children}</Button>
      </div>
    )
  }
  return (
    <Link to={to} target={sameTab ? undefined : '_blank'} {...genericProps}>
      <Button className={null} {...props} disabled={disabled}>{children}</Button>
    </Link>
  )
}
