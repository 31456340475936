import add from './files/add.svg'
import check from './files/check.svg'
import cross from './files/cross.svg'
import edit from './files/edit.svg'
import importDel from './files/delete.svg'
import download from './files/download.svg'
import upload from './files/upload.svg'

import actionGoTo from './files/action-go-to.svg'
import actionCopy from './files/action-copy.svg'
import actionEdit from './files/action-edit.svg'
import actionDelete from './files/action-delete.svg'
import actionMoveUpmost from './files/action-move-upmost.svg'
import actionMoveDownmost from './files/action-move-downmost.svg'

export default {
  add,
  check,
  cross,
  edit,
  delete: importDel,
  download,
  upload,

  actions: {
    goTo: actionGoTo,
    copy: actionCopy,
    edit: actionEdit,
    delete: actionDelete,
    move: {
      upmost: actionMoveUpmost,
      downmost: actionMoveDownmost
    }
  }
}
