/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import styled, { css } from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import { Button, LinkButton, Price } from '@openbox-app-shared'

import _ from '../../../_'
import { log } from '../../../log'
import Box from '../../Box'
import H2 from '../../H2'

const StyledModal = styled.div`
  .component--modal {
    max-width: 44.5rem;
  }
  p.status {
    margin-bottom: 2rem;
    text-align: center;
  }
  p.description {
    height: 3rem;
  }
  .component--button {
    display: block;
    margin: 2rem auto 0 auto;
  }
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  .plan {
    .component--h2, p {
      text-align: center;
    }
    p {
      margin-top: 1rem;
      line-height: 1.25rem;
    }
  }
  .plan.is-current {
    background-color: #f7f8f9;
  }
  @media only screen and (min-width: 59rem) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      ${props => css`
        grid-template-columns: ${new Array(props.howManyPlans).fill(true).map(_ => '1fr').join(' ')};
      `}
      > * {
        margin-top: 0;
      }
    }
  }
`

function Plan ({ plan, user, onChoose, canChoose, suggestMe }) {
  const { currentPlan, currency } = user
  const { name, description, price } = plan
  const bClassNames = classnames({ 'openbox--pulsing-2': suggestMe })
  return (
    <Box className={classnames('plan', { 'is-current': currentPlan.id === plan.id })}>
      <H2>{name}</H2>
      <p className='description'>{description(user)}</p>
      <p>
        <strong>
          {typeof user.billingMonthOverride === 'number' && <><Price price={user.billingMonthOverride} currency={currency} />/month</>}
          {typeof user.billingMonthOverride !== 'number' && price && price[currency] !== 0 && <><Price price={price[currency]} currency={currency} />/month</>}
          {typeof user.billingMonthOverride !== 'number' && price && price[currency] === 0 && 'Free forever'}
          {typeof user.billingMonthOverride !== 'number' && !price && 'Contact us'}
        </strong>
      </p>
      {currentPlan.id === plan.id && <Button disabled isSecondary>Your current plan</Button>}
      {price && currentPlan.id !== plan.id && <Button
        onClick={() => onChoose(plan)}
        disabled={!canChoose}
        isSecondary={!canChoose}
        className={bClassNames}
      >
        {canChoose && 'Choose →'}
        {!canChoose && 'You can\'t choose this plan'}
      </Button>}
      {!price && <LinkButton to={window.sticky.internals.urls.SITE} className={bClassNames}>Get in touch →</LinkButton>}
    </Box>
  )
}

function ThisModal ({ user, message, onCancel, limitValues, dispatch, subscribe, tryingToExceed }) {
  const { currentPlan, currency } = user
  useEffect(() => {
    const subscriptions = [
      subscribe(
        'TRIGGER_GOOD',
        ({ trigger }) => {
          trigger === 'change-plan' && (() => {
            onCancel()
            dispatch(
              'SHOW_MESSAGE',
              {
                why: 'change-plan',
                message: <>
                  <p><strong>Your plan has been changed.</strong></p>
                </>,
                canBeBadded: ''
              }
            )
          })()
        }
      ),
      subscribe(
        'TRIGGER_BAD',
        ({ trigger, message }) => {
          trigger === 'change-plan' && (() => {
            onCancel()
            dispatch(
              'SHOW_MESSAGE',
              {
                message: <>
                  <p><strong>Your plan couldn't be changed.</strong></p>
                  <p>{message}</p>
                </>,
                canBeBadded: ''
              }
            )
          })()
        }
      ),
      subscribe(
        'APPLICATION_FOR_PAY_GOOD',
        ({ why, extra, paymentId }) => {
          why === 'change-plan' && (() => {
            dispatch('TRIGGER', { trigger: 'change-plan', showMessage: false, body: { id: extra.planId, paymentId } })
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const canChooses = window.sticky.internals.PLANS.map(plan => {
    if (plan.id === user.currentPlan.id) {
      return false
    }
    const howManyHasNow = limitValues.applications
    const howManyCanHave = plan.limitApplications(user)
    if (tryingToExceed) {
      return typeof howManyCanHave === 'number' ? howManyHasNow < howManyCanHave : true
    } else {
      return typeof howManyCanHave === 'number' ? howManyHasNow <= howManyCanHave : true
    }
  })
  const firstCanChooseI = canChooses.findIndex(_ => _)

  return (
    <StyledModal howManyPlans={window.sticky.internals.PLANS.length}>
      <GenericModal
        onClose={onCancel}
        goodText=''
        badText=''
      >
        <p className='status'><strong>You're on the {currentPlan.name} plan. {message}</strong></p>
        <div className='grid'>
          {window.sticky.internals.PLANS.map((plan, i) => {
            return (
              <Plan
                plan={plan}
                limitValues={limitValues}
                user={user}
                key={plan.id}
                canChoose={canChooses[i]}
                suggestMe={firstCanChooseI === i}
                onChoose={plan => {
                  // const oldCpa = typeof user.billingMonthOverride === 'number' ? user.billingMonthOverride : currentPlan.price[currency]
                  const newCpa = typeof user.billingMonthOverride === 'number' ? user.billingMonthOverride : plan.price[currency]
                  if (newCpa === 0) {
                    dispatch('TRIGGER', { trigger: 'change-plan', showMessage: false, body: { id: plan.id } })
                  } else {
                    const proRata = v => Math.ceil(v * window.sticky.dateTime.getMonthProRataUntilEnd())
                    let total = proRata(newCpa)
                    if (total < 0) {
                      total = 0
                    }
                    dispatch('APPLICATION_FOR_PAY', { why: 'change-plan', extra: { planId: plan.id }, applicationId: plan.applicationId, currency, total, totalCpa: newCpa, cpaPeriod: 'MONTH' })
                  }
                }}
              />
            )
          })}
        </div>
      </GenericModal>
    </StyledModal>
  )
}

function RenderChild ({ ...props }) {
  const [limitValues, setLimitValues] = useState()
  useEffect(() => {
    async function getLimitValues () {
      setLimitValues({
        applications: (await window.sticky.applications.getAll()).length
      })
    }
    !limitValues && getLimitValues()
  })
  if (!limitValues) {
    return null
  }
  return (
    <ThisModal
      {...props}
      limitValues={limitValues}
    />
  )
}

export default {
  trigger: ({ setState, user, message }) => {
    setState({ LETS_GET_THIS_BREAD: { user, message } })
  },
  render: function LETS_GET_THIS_BREAD ({ state, setState, dispatch, subscribe }) {
    const { LETS_GET_THIS_BREAD } = state
    if (!LETS_GET_THIS_BREAD) {
      return null
    }
    const { user, message } = LETS_GET_THIS_BREAD
    const onCancel = (good) => {
      log('[LETS_GET_THIS_BREAD] [Modal->render->onCancel]')
      dispatch('LETS_GET_THIS_BREAD_GOOD')
      setState({
        LETS_GET_THIS_BREAD: undefined
      })
    }
    const rcProps = {
      user,
      message,
      onCancel,
      dispatch,
      subscribe
    }
    return (
      <>
        {LETS_GET_THIS_BREAD && <RenderChild {...rcProps} />}
      </>
    )
  }
}
