/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { log } from '../../log'
import dashboardIcons from '../../icons'
import { CustomHelmet } from '@openbox-app-shared'
import ChooseGateway from '../../components/ChooseGateway/ChooseGateway'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .buttons {
    margin-bottom: 0 !important;
  }
  .component--get-signature {
    margin-top: 1rem;
  }
`

const tab = {
  id: 'payment-provider',
  name: () => 'Payments',
  inlineIcon: dashboardIcons.payment,
  to: (context) => '/my/account/payment-provider',
  child: ({ context }) => {
    const { user } = context.props
    const onSave = async (body, props) => {
      log('[paymentProvider] [onSave]', { body, props })
      context.props.onUpdateUser(body)
      await context.props.onSaveUser(props)
    }
    return <StyledComponent>
      <CustomHelmet title={`Payments | Account | ${user.name}`} />
      <ChooseGateway
        user={user}
        entity={user}
        onSettingsValid={(gateway) => {
          log('[paymentProvider] [onSettingsValid]', { gateway })
          context.props.onUpdateUser({
            gateway: gateway.id
          })
        }}
        triggerProps={{ userId: user.id }}
        onSave={onSave}
      />
    </StyledComponent>
  }
}

export default tab
