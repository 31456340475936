/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import { DebouncedForm, PriceInput, Input, Switch } from '@openbox-app-shared'

import _ from '../../../_'
import { log } from '../../../log'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 320px;
  }
`

const PERMISSIONS = [
  {
    permission: 'show-footer-logo',
    name: 'Sticky branding'
  },
  {
    permission: 'show-footer-buttons',
    name: 'Flow footer buttons'
  },
  {
    permission: 'has-printer',
    name: 'Printer'
  }
]

function ThisModal ({ otherUser, onDone, onClose }) {
  const [billingMonthOverride, setBillingMonthOverride] = useState(typeof otherUser.billingMonthOverride === 'number')
  const [reactForceUpdateValueSorry, reactForceUpdateSorry] = useState(0)
  const [isArchived, setIsArchived] = useState(otherUser.tagsInternal.has('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98'))
  const forceUpdate = () => reactForceUpdateSorry(reactForceUpdateValueSorry + 1)

  return (
    <StyledModal>
      <GenericModal
        onGood={() => onDone(otherUser)}
        onClose={onClose}
      >
        <DebouncedForm
          onChange={(key, value) => {
            if (key === 'plan') {
              otherUser.plan = value
              forceUpdate()
              return
            }
            if (key === 'setBillingMonthOverride') {
              otherUser.billingMonthOverride = value ? 50 * 100 : undefined
              setBillingMonthOverride(!billingMonthOverride)
              return
            }
            if (key === 'setIsArchived') {
              value && otherUser.tagsInternal.add('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98')
              !value && otherUser.tagsInternal.delete('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98')
              setIsArchived(value)
              return
            }
            if (key.startsWith('permission--')) {
              const whichPermission = key.substring('permission--'.length)
              otherUser.permissions.toggle(whichPermission)
              forceUpdate()
              return
            }
          }}
        >
          <Input
            label='Plan'
            name='plan'
            value={otherUser.plan}
          />
          <hr />
          <Switch
            checked={billingMonthOverride}
            name='setBillingMonthOverride'
          >
            Custom price
          </Switch>
          {billingMonthOverride && <PriceInput
            name='billingMonthOverride'
            value={otherUser.billingMonthOverride}
          />}
          <Switch
            checked={isArchived}
            name='setIsArchived'
          >
            Archived
          </Switch>
          <hr />
          {PERMISSIONS.map(p => (
            <Switch
              key={p.permission}
              checked={otherUser.can(p.permission)}
              name={`permission--${p.permission}`}
            >
              {p.name}
            </Switch>
          ))}
        </DebouncedForm>
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, otherUser }) => {
    setState({ USER_EDIT: { why, otherUser } })
  },
  render: function USER_EDIT ({ state, setState }) {
    const { USER_EDIT } = state
    if (!USER_EDIT) {
      return null
    }
    const { why, otherUser } = USER_EDIT
    const onDone = async user => {
      const whatHappened = { why, user }
      log('[USER_EDIT] [Modal->render->onDone] whatHappened', whatHappened)
      dispatch('LOADING')
      try {
        await window.sticky.users.save(user, ['plan', 'billingMonthOverride', 'tagsInternal', 'permissions'])
      } catch (e) {
        dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
      }
      dispatch('STOP_LOADING')
      dispatch('USER_EDIT_GOOD')
      setState({
        USER_EDIT: undefined
      })
    }
    const onClose = () => {
      setState({
        USER_EDIT: undefined
      })
    }
    return (
      <ThisModal
        otherUser={otherUser}
        onDone={onDone}
        onClose={onClose}
      />
    )
  }
}
