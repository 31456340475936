import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { icons, Details, Button, Modal } from '@openbox-app-shared'

const StyledModal = styled.div`
  .component--modal {
    max-width: 640px;
  }
  .component--details {
    width: 100%;
  }
  .component--details + .component--details {
    margin-top: 1rem;
  }
  .component--button.save {
    display: block;
    margin: 2rem auto 0 auto;
  }
`

export default function ThisModal ({ detailss, canEdit = true, onSave, onCancel }) {
  const renderableDetailss = detailss.filter(d => d.size > 0)
  return (
    <StyledModal>
      <Modal onClose={onCancel}>
        {renderableDetailss.map((d, i) => {
          return (
            <Details
              key={`details--${i}`}
              details={d}
            />
          )
        })}
        <Button
          icon={icons.inverted.check}
          className='save'
          onClick={onSave}
        >
          Done
        </Button>
      </Modal>
    </StyledModal>
  )
}

ThisModal.propTypes = {
  detailss: PropTypes.arrayOf(PropTypes.object).isRequired,
  canEdit: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}
