import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const hasSrc = (src) => typeof src === 'string' && src.length > 0

export default class HotImage extends Component {
  constructor (props) {
    // console.log('[HotImage] [constructor]')
    super(props)
    this.state = {
      src: undefined
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    // console.log('[HotImage] [shouldComponentUpdate]', { props: this.props, nextProps, nextState })
    if (typeof nextProps.src === 'string' && nextProps.src !== this.props.src) {
      this.tryToLoad(nextProps.src)
      return true
    }
    if (this.state.src !== nextState.src) {
      return true
    }
    return false
  }

  tryToLoad (src) {
    // console.log('[HotImage] [tryToLoad]', { src })
    const element = new Image()
    let hasFreed = false

    const localOnLoad = () => {
      // console.log('[HotImage] [tryToLoad] [localOnLoad]')
      this.onLoad()
      free()
    }
    const localOnError = () => {
      // console.log('[HotImage] [tryToLoad] [localOnError]')
      this.onError()
      free()
    }

    const free = () => {
      // console.log('[HotImage] [tryToLoad] [free]', { hasFreed })
      if (!hasFreed) {
        element.removeEventListener('load', localOnLoad)
        element.removeEventListener('error', localOnError)
      }
      hasFreed = true
    }
    element.addEventListener('load', localOnLoad)
    element.addEventListener('error', localOnError)
    element.src = src
    return free
  }

  componentDidMount () {
    // console.log('[HotImage] [componentDidMount]')
    this.free = this.tryToLoad(this.props.src)
  }

  componentWillUnmount () {
    // console.log('[HotImage] [componentWillUnmount]')
    this.free()
  }

  onLoad () {
    const { src } = this.props
    // console.log('[HotImage] [onLoad]', { src })
    this.setState({ src })
  }

  onError () {
    // console.log('[HotImage] [onError]')
    this.setState({ src: `${window.sticky.cdn}/no-image.svg` })
  }

  render () {
    const { src } = this.state
    const { mr, className, ...rest } = this.props
    // console.log('[HotImage] [render]', { src, mr })
    if (!hasSrc(src)) {
      return null
    }
    const finalSrc = mr ? `${src}?mrHotImage=${Math.random()}` : src
    return (
      <img
        {...rest}
        className={classnames('hot', className)}
        src={finalSrc}
      />
    )
  }
}

HotImage.propTypes = {
  mr: PropTypes.bool
}
