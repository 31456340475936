/* eslint-disable react/prop-types */

import React from 'react'

import LocationPasteGoogleMapsUrl from '../../components/LocationPasteGoogleMapsUrl'
import { icons, CustomHelmet } from '@openbox-app-shared'

function RenderChild({ context, dbfProps }) {
  const { user } = context.props

  return (
    <>
      <CustomHelmet title={`Place | Account | ${user.name}`} />
      <LocationPasteGoogleMapsUrl
        name='location'
        location={user.location}
        label={user.name}
        onChange={(location) => {
          console.log('Danesh LOCATION : ', location)
          user.patch({ location })
          dbfProps.onChange('location', location)
        }}
        showYouOnMap={false}
      />

    </>
  )
}

const tab = {
  id: 'place',
  name: () => 'Place',
  icon: icons.generic.location,
  to: () => '/my/account/place',
  child: function Child(props) {
    return <RenderChild {...props} />
  }
}

export default tab
