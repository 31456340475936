import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const StyledComponent = styled.div`
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 50%;
  text-align: center;
  color: white;
  cursor: default;
  position: relative;
  aspect-ratio: 1 / 1;
  > strong {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  ${props => css`
    width: ${typeof props.$fixedWidthHeight === 'number' ? `${props.$fixedWidthHeight}rem` : '100%'};
    height: ${typeof props.$fixedWidthHeight === 'number' ? `${props.$fixedWidthHeight}rem` : '100%'};
    ${props.isChosen ? 'border: 2px solid white;' : ''}
    box-shadow: ${props.isChosen ? '0 2px 5px 0 rgb(60 66 87 / 50%), 0 2px 2px 0 rgb(0 0 0 / 50%)' : '0 2px 5px 0 rgb(60 66 87 / 12%), 0 1px 1px 0 rgb(0 0 0 / 12%)'};
  `}
`

function nameToInitials(name) {
  const nameComponents = name
    .split(' ')
    .filter(s => s.length > 0)
  const nameInitials = (nameComponents.length >= 2 ? `${nameComponents[0].substring(0, 1)}${nameComponents[1].substring(0, 1)}` : name.substring(0, 2))
    .toUpperCase()
  return nameInitials
}

export default function UserCircle({ name, color = '#6C7A89', fixedWidthHeight, isChosen, onClick, photoUrl }) {
  const ref = useRef()
  useEffect(
    () => {
      const e = ref.current
      const rectRef = e.getBoundingClientRect()
      let fontSize = Math.floor((rectRef.width / 48) * 18)
      fontSize = (fontSize < 12) ? 12 : fontSize
      e.style.fontSize = `${fontSize}px`
    },
    []
  )
  const nameInitials = nameToInitials(name)
  return (
    <StyledComponent ref={ref} $fixedWidthHeight={fixedWidthHeight} isChosen={isChosen} role={onClick ? 'button' : undefined} onClick={onClick} title={name} className='component--user-circle' style={{ backgroundColor: color, backgroundImage: photoUrl ? `url('${photoUrl}')` : undefined }}>
      {!photoUrl && <strong>{nameInitials}</strong>}
    </StyledComponent>
  )
}
UserCircle.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  fixedWidthHeight: PropTypes.number,
  isChosen: PropTypes.bool,
  onClick: PropTypes.func,
  photoUrl: PropTypes.string
}
