/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from '@openbox-app-shared'
import Session from '../../Session'

const StyledModal = styled.div`
  .component--modal {
    max-width: 960px;
    padding: 2rem 1rem 1rem 1rem !important;
  }
`

function ThisModal({ user, session, onUpdate, onClose }) {
  const [stuff, setStuff] = useState()
  useEffect(() => {
    async function getStuff() {
      const applications = await window.sticky.applications.getAll()
      setStuff({
        applications,
      })
    }
    !stuff && getStuff()
  }, [])

  if (!stuff) {
    return null
  }

  return (
    <StyledModal>
      <Modal onClose={onClose}>
        <Session
          user={user}
          onUpdate={onUpdate}
          session={session}
          onClose={onClose}
          applications={stuff.applications}
        />
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, session, why }) => {
    setState({ SESSION: { session, why } })
  },
  render: function ThisAction({ user, state, setState, dispatch }) {
    const { SESSION } = state
    if (!SESSION) {
      return null
    }
    const { session, why, didUpdate } = SESSION
    const props = { user, session }
    return (
      <ThisModal
        {...props}
        onUpdate={() => {
          setState({ SESSION: { ...SESSION, didUpdate: true } })
        }}
        onClose={() => {
          setState({ SESSION: false })
          didUpdate && dispatch('SESSION_UPDATE', { why })
        }}
      />
    )
  },
}
