import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { LatLongInput } from './Input'
import Button from './Button'
import icons from '../icons'

const { v4: uuid } = require('uuid')

const StyledLocation = styled.div`
  max-width: 512px;
  .map {
    width: 100%;
    height: 100%;
    max-height: 512px;
  }
  .inputs {
    margin-top: 1rem;
    > * {
      display: inline-block;
    }
    .component--input {
      width: 8rem;
    }
    > * + * {
      margin-left: 1rem;
    }
  }
  .inputs + .component--button {
    margin-top: 0.5rem;
  }
`

export default function Location ({ location, onChange, onPasteGoogleMapsUrl, label = 'Location', isEditable = true, showYouOnMap = true }) {
  console.log('[Location]', { location, isEditable, showYouOnMap })
  const id = uuid()
  const domId = `component--location-map-${id}`
  const {
    latitude,
    longitude
  } = location
  let element, map
  useEffect(
    () => {
      element = document.getElementById(domId)
      ;(async () => {
        map = await window.sticky.Stickymaps(
          element,
          latitude,
          longitude,
          {
            showYouOnMap,
            onLocationChange: ({ latitude, longitude }) => onChange({
              latitude: Number(latitude.toFixed(8)),
              longitude: Number(longitude.toFixed(8))
            })
          }
        )
        map.addMarker(label, latitude, longitude, { draggable: isEditable })
      })()
      return () => {
        map && map.destroy()
        map = undefined
      }
    },
    [location.latitude, location.longitude]
  )
  const handleOnChange = (key, value) => {
    onChange && onChange({
      ...location,
      [key]: value
    })
  }
  const styleBump = 4.75 + (onPasteGoogleMapsUrl ? 3 : 0)
  const style = {
    height: `calc(512px + ${styleBump}rem)`,
    maxHeight: `calc(512px + ${styleBump}rem)`
  }
  return (
    <StyledLocation className='component--location' style={style}>
      <div id={domId} className='map' />
      <div className='inputs'>
        <LatLongInput disabled={!isEditable} label='Latitude' value={latitude} onChange={(v) => handleOnChange('latitude', v)} />
        <LatLongInput disabled={!isEditable} label='Longitude' value={longitude} onChange={(v) => handleOnChange('longitude', v)} />
      </div>
      {onPasteGoogleMapsUrl && <Button
        isSecondary
        onClick={onPasteGoogleMapsUrl}
      >
        Paste from Maps
      </Button>}
    </StyledLocation>
  )
}

Location.propTypes = {
  location: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onPasteGoogleMapsUrl: PropTypes.func,
  label: PropTypes.string,
  isEditable: PropTypes.bool
}
