import { log } from '../../log'
import { dispatch } from '../index' 

export default async function action (state, { entity, props }) {
  log('[ACTION->SAVE_FEDERATED_USER]', { entity, props })
  try {
    await window.sticky.users.federated.save(entity, props)
    state = {
      ...state
    }
  } catch ({ message }) {
    dispatch('SHOW_MESSAGE', { message: <><p><strong>Hmm; that didn't work.</strong></p><p>{message}</p></>, canBeBadded: '' })
  } finally {
    dispatch('SAVED_FEDERATED_USER')
  }
}
