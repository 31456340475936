import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const hasSrc = (src) => typeof src === 'string' && src.length > 0

export default class CoolVideo extends Component {
  constructor (props) {
    console.log('[CoolVideo] [constructor]')
    super(props)
    this.state = {}
    this.elementRef = createRef()
  }

  // componentDidMount () {
  //   const element = this.elementRef && this.elementRef.current
  //   if (!element) {
  //     return
  //   }
  //   let hasFreed = false
  //   const free = () => {
  //     console.log('[CoolVideo] [componentDidMount] [free]', { hasFreed })
  //     if (!hasFreed) {
  //       element.removeEventListener('load', this.onLoad)
  //       element.removeEventListener('error', this.onError)
  //     }
  //     hasFreed = true
  //   }
  //   this.free = free

  //   element.addEventListener('load', () => {
  //     console.log('[CoolVideo] [componentDidMount] [addEventListener->load]')
  //     free()
  //   })
  //   element.addEventListener('error', () => {
  //     console.log('[CoolVideo] [componentDidMount] [addEventListener->error]')
  //     free()
  //   })
  // }

  // componentWillUnmount () {
  //   console.log('[CoolVideo] [componentWillUnmount]')
  //   this.free()
  // }

  componentDidUpdate (prevProps) {
    if (this.props.src !== prevProps.src) {
      this.elementRef.current.load()
    }
  }

  render () {
    const { src, poster, className, autoPlay = false, ...rest } = this.props
    if (!hasSrc(src)) {
      return null
    }
    console.log('[CoolVideo] [render]', { src, poster })
    return (
      <video className={classnames('component--cool-video', className)} poster={poster} ref={this.elementRef} controls playsInline loop autoPlay={autoPlay} {...rest}>
        <source src={src} />
      </video>
    )
  }
}

CoolVideo.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
  className: PropTypes.any,
  autoPlay: PropTypes.bool
}
