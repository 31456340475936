import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled from 'styled-components'
import { Label } from './Input'

const StyledDropdown = styled.div`
  &:focus-within {
    label {
    }
    select {
      box-shadow: #cccbf0 0px 0px 0px 3px;
      outline: 0;
    }
  }
  label + select {
    margin-top: 0.5rem;
  }
  select {
    display: block;
    height: 2.5rem;
    line-height: 2rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    width: 100%;
    transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
    border-radius: 4px;
    border: 1px solid #e0e1e4;
    background-color: white;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 2rem 100%;
    font-size: 95%;
    -webkit-appearance: none;
  }
  &[disabled] {
    label, select {
      opacity: 0.5;
    }
    select {
      cursor: not-allowed;
    }
  }
`

export default function Dropdown ({ label, selected, items, className, style = {}, color, onChoose, disabled }) {
  // console.log('[Dropdown]', { selected })
  const classNames = classnames('component--dropdown', className)
  const labelStyle = { color }
  const selectStyle = { ...style }
  return (
    <StyledDropdown className={classNames} color={color} disabled={disabled}>
      {label && <Label style={labelStyle}>{label}</Label>}
      <select
        value={selected}
        onChange={(event) => onChoose && (() => {
          const fv = typeof selected === 'number' ? parseInt(event.target.value, 10) : event.target.value
          onChoose(fv)
        })()}
        style={selectStyle}
        disabled={disabled}
      >
        {items.map(i => {
          return (
            <option value={i.id} key={`select--option--${i.id}`} disabled={i.disabled}>{i.name}</option>
          )
        })}
      </select>
    </StyledDropdown>
  )
}
Dropdown.propTypes = {
  label: PropTypes.node,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.any,
  style: PropTypes.object,
  color: PropTypes.string,
  onChoose: PropTypes.func,
  disabled: PropTypes.bool
}

