import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from './Button'
import Banner from './Banner'
import Loading from './Loading'
import HotImage from './HotImage'
import CoolVideo from './CoolVideo'
import { UrlInput, Label } from './Input'
import ApplicationBlocks from './ApplicationBlocks'
import icons from '../icons'

const StyledUpload = styled.div`
  .component--banner {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  label {
    margin-bottom: 0.5rem;
  }
  .buttons {
    height: 2.5rem;
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
    > * {
      display: inline-block;
      vertical-align: top;
    }
    > * + * {
      margin-left: 0.5rem;
    }
  }
  .buttons + * {
    margin-top: 1rem;
  }
  form {
    > * + * {
      margin-top: 0.5rem;
    }
    input {
      display: block;
      width: 100%;
      transition: box-shadow 0.3s ease-in-out;
    }
    input:focus {
      box-shadow: #cccbf0 0px 0px 0px 3px;
      outline: 0;
    }
    input[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .component--loading {
    width: 2.5rem;
    margin-bottom: 0.5rem;
    padding: 0;
  }
`

const showStyle = {
  display: 'block',
  maxWidth: '128px',
  marginTop: '1rem'
}

export function UploadDynamic ({ type, ...props }) {
  return ({
    'image': () => <UploadImage {...props} />,
    'video': () => <UploadVideo {...props} />,
    'audio': () => <UploadAudio {...props} />,
    'document': () => <UploadDoc {...props} />,
    'csv': () => <UploadCsv {...props} />
  })[type]()
}

export function UploadImage ({ value, disabled, label, show = false, onChange, color, ...props }) {
  return (
    <>
      <Upload
        accept={[
          '.svg', 'image/svg+xml', 'image/xml',
          '.png', 'image/png',
          '.gif', 'image/gif',
          '.bmp', 'image/bmp',
          '.jpg', '.jpeg', 'image/jpeg'
        ]}
        acceptAsString={['svg', 'png', 'gif', 'bmp', 'jpg', 'jpeg']}
        onChange={onChange}
        label={label}
        value={value}
        disabled={disabled}
        color={color}
        {...props}
      />
      {show && value && (
        <HotImage
          src={value}
          style={showStyle}
        />
      )}
    </>
  )
}

export function UploadVideo ({ value, label, show = false, onChange, color, ...props }) {
  return (
    <>
      <Upload
        accept={[
          '.mp4', 'video/mp4',
          '.webm', 'video/webm',
          '.mov', 'video/quicktime'
        ]}
        acceptAsString={['mp4', 'webm', 'mov']}
        onChange={onChange}
        label={label}
        value={value}
        color={color}
        {...props}
      />
      {show && value && (
        <CoolVideo
          src={value}
          style={showStyle}
        />
      )}
    </>
  )
}

export function UploadAudio ({ value, label, show = false, onChange, color, abProps = {}, ...props }) {
  return (
    <>
      <Upload
        accept={[
          '.mp3', 'audio/mp3'
        ]}
        acceptAsString={['mp3']}
        onChange={onChange}
        label={label}
        value={value}
        color={color}
        {...props}
      />
      {show && <ApplicationBlocks
        applicationBlocks={[
          {
            id: 'audio',
            config: {
              url: value,
              autoplay: false,
              loop: false,
              ...abProps
            }
          }
        ]}
      />}
    </>
  )
}

export function UploadDoc ({ value, label, onChange, color, ...props }) {
  return (
    <div>
      <Upload
        accept={[
          '.png', 'image/png',
          '.pdf', 'image/pdf',
          '.vcf', '.vcard', 'text/vcard',
          '.jpg', '.jpeg', 'image/jpeg',
          '.tiff', '.tif', 'image/tiff',
          '.docx', '.doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword',
          '.pptx', '.ppt', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint',
          '.xlsx', '.xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'
        ]}
        acceptAsString={['png', 'pdf', 'vcf', 'vcard', 'jpg', 'jpeg', 'tiff', 'tif', 'docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls']}
        onChange={onChange}
        label={label}
        value={value}
        color={color}
        {...props}
      />
      {value && (
        <p style={{ marginTop: '1rem' }}>
          Current file type: {value.split('.').pop().toUpperCase()}
        </p>
      )}
    </div>
  )
}

export function UploadCsv ({ value, label, onChange, color, resolveLocally = true }) {
  return (
    <Upload
        accept={[
            '.csv', 'text/csv'
        ]}
        acceptAsString={['csv']}
        onChange={file => {
            if (resolveLocally) {
                file.text()
                    .then(onChange)
                    .catch(e => {
                    })
            } else {
                onChange(file)
            }
        }}
        label={label}
        value={value}
        color={color}
        allowUseUrl={false}
        resolveLocally={resolveLocally}
    />
  )
}

const isReallyValid = v => typeof v === 'string' && (v.startsWith('http://') || v.startsWith('https://'))

// 100000000 bytes = 100 megabytes
export function Upload ({ value, disabled, accept, acceptAsString, label, sizeLimit = 100000000, onChange, canChangeToUndefined, allowUseUrl = true, resolveLocally = false, color }) {
  const [url, setUrl] = useState()
  const [isValid, setIsValid] = useState(isReallyValid(url))
  const [isWorking, setIsWorking] = useState(false)
  const [error, setError] = useState(false)
  const inputElement = useRef()

  const onError = (e) => {
    setError(e)
    setIsWorking(false)
    setIsValid(false)
    if (inputElement.current) inputElement.current.value = ''
  }

  const onDone = () => {
    setError(undefined)
    setIsWorking(false)
    setIsValid(false)
    setUrl(undefined)
    if (inputElement.current) inputElement.current.value = ''
  }

  const onSubmit = async (e) => {
    e && e.preventDefault && e.preventDefault()

    if (typeof url === 'string') {
      if (isValid) {
        onDone()
        onChange({ name: 'URL', url })
      }
      return
    }

    const file = inputElement.current.files[0]
    console.log('[Upload]', { file, resolveLocally })

    if (resolveLocally) {
      onChange(file)
      return
    }

    if (file.size > sizeLimit) {
      onError('Sorry, that file is too big. It must be 100mb or less.')
      return
    }
    setIsWorking(true)

    try {
      const r = await window.sticky.upload(file)
      console.log('[Upload] result of .upload call', r)
      onDone()
      onChange(r)
    } catch (e) {
      console.error('[Upload] error', e)
      onError('Sorry, that didn\'t work.')
      return
    }
  }

  return (
    <StyledUpload className='component--upload' aria-disabled={disabled}>
      {error && (
        <Banner mood='bad'>
          <p>{error}</p>
          {Array.isArray(acceptAsString) && <p>Please upload a file of type {acceptAsString.join(' / ')}.</p>}
        </Banner>
      )}
      {isWorking && (
        <Loading />
      )}
      {typeof url === 'string' && (
        <div className='url'>
          <UrlInput
            label='URL'
            autoFocus
            onIsValid={setIsValid}
            onChange={v => setUrl(v)}
            onDone={onSubmit}
          />
          <div className='buttons'>
            <Button onClick={onSubmit} backgroundColor={color} disabled={!isValid}>Save</Button>
            <Button isSecondary onClick={() => setUrl(undefined)}>Cancel</Button>
          </div>
        </div>
      )}
      {typeof url !== 'string' && <>
        {label && <Label aria-disabled={disabled}>{label}</Label>}
        <form>
          <input
            accept={Array.isArray(accept) ? accept.join(',') : undefined}
            type='file'
            ref={inputElement}
            disabled={isWorking || disabled}
            onChange={e => {
              setIsValid(e.target.value ? true : false)
            }}
          />
        </form>
        <div className='buttons'>
          <Button icon={icons.inverted.upload} onClick={onSubmit} backgroundColor={color} disabled={isWorking || !isValid || disabled}>Upload</Button>
          {allowUseUrl && <Button
            isSecondary
            onClick={() => {
              setUrl('')
              onError()
            }}
            disabled={disabled}
          >
            URL
          </Button>}
          {(!isWorking && isReallyValid(value) && canChangeToUndefined) && <Button
            isSecondary
            onClick={() => {
              onChange({ url: null })
              onDone()
            }}
            title='Delete'
            backgroundColor='#ff3838'
            icon={icons.inverted.delete}
          />}
        </div>
      </>}
    </StyledUpload>
  )
}
Upload.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  acceptAsString: PropTypes.arrayOf(PropTypes.string),
  sizeLimit: PropTypes.number,
  onChange: PropTypes.func,
  canChangeToUndefined: PropTypes.bool,
  allowUseUrl: PropTypes.bool,
  resolveLocally: PropTypes.bool,
  disabled: PropTypes.bool
}
