import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { icons, Modal, DynamicInput, Button } from '@openbox-app-shared'

const StyledModal = styled.div`
  p + .component--input, p + .component--banner {
    margin-top: 1rem;
  }
  .component--button {
    display: block;
    margin: 2rem auto 0 auto;
    width: auto;
  }
`

export default function ThisModal ({ type = 'generic', string = '', hint, selectAll = false, doValidate = true, onDone, onCancel }) {
  const [typedString, setTypedString] = useState(string)
  const [isValid, setIsValid] = useState((() => {
    if (type !== 'generic' || typeof string === 'number') {
      return true
    }
    return (doValidate ? (typedString.length > 0) : true)
  })())
  return (
    <Modal onClose={onCancel}>
      <StyledModal>
        {hint && <p>{hint}</p>}
        <DynamicInput
          type={type}
          value={typedString}
          selectAll={selectAll}
          onIsValid={answer => doValidate && setIsValid(answer)}
          onChange={(v) => setTypedString(v)}
          onDone={() => {
            isValid && onDone(typedString)
          }}
          autoFocus
        />
        <Button
          icon={icons.inverted.check}
          onClick={() => onDone(typedString)}
          disabled={!isValid}
        >
          Done
        </Button>
      </StyledModal>
    </Modal>
  )
}

ThisModal.propTypes = {
  type: PropTypes.string,
  string: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hint: PropTypes.node,
  selectAll: PropTypes.bool,
  doValidate: PropTypes.bool,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
}
