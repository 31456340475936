/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../icons'
import { dispatch } from '../../redux'

import { CustomHelmet, List, ListItem, Loading } from '@openbox-app-shared'

const StyledComponent = styled.div`
  padding-top: 1rem;
`

function RenderChild ({ context }) {
  const { user } = context.props
  const [shortLinks, setShortLinks] = useState()

  function onAction (id, action) {
    const entity = shortLinks.find(_ => _.id === id)
    action === 'copy' && (() => {
      window.sticky.shortLinks.share(entity.getUrl)
    })()
  }

  async function getShortLinks () {
    const _ = (await window.sticky.shortLinks.getAll())
    setShortLinks(_)
  }

  useEffect(
    () => {
      const subscriptions = []
      ;(async () => {
        try {
          await getShortLinks()
        } catch (e) {
          dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
        }
      })()
      return () => {
        subscriptions.forEach(s => s())
      }
    },
    []
  )

  return (
    <StyledComponent>
      <CustomHelmet title={`Links | Account | ${user.name}`} />
      {!shortLinks && (
        <Loading />
      )}
      {shortLinks && (
        <>
          <List>
            {shortLinks.map(_ => {
              const tags = []
              _.application && tags.push({ name: _.application.name, color: '#F0F1F3', foregroundColor: '#1A1F35', icon: _.application.baseIcon })
              return (
                <ListItem
                  id={_.id}
                  key={_.id}
                  InlineIcon={dashboardIcons.link}
                  actions={['copy']}
                  onAction={onAction}
                  tags={tags}
                  goTo={_.getUrl}
                >
                  {_.name} ［ <code>{_.customSlug}</code> ］
                </ListItem>
              )
            })}
          </List>
        </>
      )}
    </StyledComponent>
  )
}

const tab = {
  id: 'short-links',
  name: (context) => 'Links',
  inlineIcon: dashboardIcons.link,
  to: (context) => '/my/account/short-links',
  child: props => <RenderChild {...props} />
}

export default tab
