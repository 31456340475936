import React from 'react'
import PropTypes from 'prop-types'

import { tags, Dropdown } from '@openbox-app-shared'

export default function ProductTagsVat ({ value, onUpdate }) {
  return (
    <Dropdown
      className='component--product-tags-vat'
      label='Tax'
      items={[
        {
          id: '---',
          name: '(Not set)'
        },
        ...tags.filter(t => t.id.startsWith('vat--'))
      ]}
      onChoose={v => {
        if (v === '---') {
          onUpdate(undefined)
        } else {
          onUpdate(v)
        }
      }}
      selected={value}
    />
  )
}
ProductTagsVat.propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func
}
