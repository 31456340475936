import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import debounce from 'debounce'

import { log } from '../log'
import { Dropdown, DatePicker, Loading } from '@openbox-app-shared'

import reportPaymentsLight from './reports/paymentsLight'
import reportEvents from './reports/events'
import reportSessionReadSessionPay from './reports/SESSION_READ--SESSION_CART_PAY'
import reportThingsApplications from './reports/thingsApplications'
import reportUserSessions from './reports/userSessions'
import _ from '../_'

const REPORTS = [
  {
    id: 'paymentsLight',
    RenderComponent: reportPaymentsLight
  },
  {
    id: 'events',
    RenderComponent: reportEvents
  },
  {
    id: 'things',
    RenderComponent: reportSessionReadSessionPay
  },
  {
    id: 'applications',
    RenderComponent: reportSessionReadSessionPay
  },
  {
    id: 'thingsApplications',
    RenderComponent: reportThingsApplications
  },
  {
    id: 'userSessions',
    RenderComponent: reportUserSessions
  }
]

// PLEASE DONT COPY THIS CODE WHICH HAS ALREADY BEEN COPIED
// INTO "DatePickerBadAss"; I FOUND IT TOO HARD TO REFACTOR
// THIS FILE TO USE THE NEW COMPONENT (I AM SORRY)

const dateToUnixTime = date => date.getTime() / 1000

function getDaysInMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

const TIME_PERIOD_TEMPLATE = {
  formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
}

const TIME_PERIODS = [
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'today',
    name: 'Today (midnight → midnight)',
    startTime: dateToUnixTime((() => {
      const date = new Date()
      date.setHours(0, 0, 0, 0)
      return date
    })()),
    timeLength: 86400,
    timeDelta: 86400 / 24,
    formatUnixTime: _ => ((d) => `${d.getHours().toString().padStart(2, '0')}:00`)(new Date(_))
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'yesterday',
    name: 'Yesterday (midnight → midnight)',
    startTime: dateToUnixTime((() => {
      const date = new Date()
      date.setDate(date.getDate() - 1)
      date.setHours(0, 0, 0, 0)
      return date
    })()),
    timeLength: 86400,
    timeDelta: 86400 / 24,
    formatUnixTime: _ => ((d) => `${d.getHours().toString().padStart(2, '0')}:00`)(new Date(_))
  },
  {
    id: '---1',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'this-month',
    name: 'This month (1st → now)',
    ...(() => {
      const date = new Date()
      date.setDate(1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * getDaysInMonth(date)
      }
    })(),
    timeDelta: 86400,
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'long', day: 'numeric' })
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'last-month',
    name: 'Last month',
    ...(() => {
      const date = new Date()
      date.setMonth(date.getMonth() - 1)
      date.setDate(1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * getDaysInMonth(date)
      }
    })(),
    timeDelta: 86400,
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'long', day: 'numeric' })
  },
  {
    id: '---2',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'this-year',
    name: 'This year (1st → now)',
    ...(() => {
      const date = new Date(new Date().getFullYear(), 0, 1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * 365
      }
    })(),
    timeDelta: Math.floor(86400 * 30.437),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'short' })
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'last-year',
    name: 'Last year',
    ...(() => {
      const date = new Date(new Date().getFullYear() - 1, 0, 1)
      date.setHours(0, 0, 0, 0)
      return {
        startTime: dateToUnixTime(date),
        timeLength: 86400 * 365
      }
    })(),
    timeDelta: Math.floor(86400 * 30.437),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { month: 'short' })
  },
  {
    id: '---3',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'all-time',
    name: 'All time',
    startTime: undefined,
    timeLength: undefined,
    timeDelta: Math.floor(86400 * 30.437),
    formatUnixTime: _ => (new Date(_)).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })
  },
  {
    id: '---4',
    name: '──────────',
    disabled: true
  },
  {
    ...TIME_PERIOD_TEMPLATE,
    id: 'custom',
    name: 'Custom',
    startTime: undefined,
    timeLength: undefined,
    timeDelta: 86400 * 7
  }
]

const StyledReports = styled.div`
  .settings {
    margin-top: 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    > .component--dropdown {
      width: 296px;
    }
  }
  hr.at-top {
    margin-top: 0;
  }
`

const k = (keyArray) => `Reports--${keyArray.join('---')}`
const r = (keyArray, ...args) => window.sticky.internals.readFromLocalStorage(k(keyArray), ...args)
const w = (keyArray, ...args) => window.sticky.internals.writeToLocalStorage(k(keyArray), ...args)

export default class Reports extends PureComponent {
  constructor(props) {
    super(props)
    let { reportId, timePeriodId, query = {} } = this.props
    const hasReportId = typeof reportId === 'string'
    reportId = hasReportId ? reportId : REPORTS[0].id
    timePeriodId = r([reportId, 'timePeriodId']) || timePeriodId
    const startOfToday = window.sticky.dateTime.getStartOfToday(undefined)
    const state = {
      reportId,
      query,
      customQuery: {},
      timePeriodId: timePeriodId || TIME_PERIODS[0].id,
      canChooseReport: !hasReportId,
      startOfToday,
      customStartTime: startOfToday,
      customEndTime: window.sticky.dateTime.getEndOfToday(undefined) - 1
    }
    log('[Reports] [constructor]', { state })
    this.state = state
    this.loadReport = this.loadReport.bind(this)
    this.onRefresh = this.onRefresh.bind(this)
    this.getData = this.getData.bind(this)
    this.setCustomQuery = this.setCustomQuery.bind(this)
    this.setCustomDataGetData = debounce(this.setCustomDataGetData, 500)
  }

  async componentDidMount() {
    this.setState(
      {
        applications: await window.sticky.applications.getAll()
      },
      this.loadReport
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const shouldSetState = nextProps.reportId !== this.props.reportId
    console.log('[Reports] [UNSAFE_componentWillReceiveProps]', { nextProps, shouldSetState })
    if (shouldSetState) {
      this.setState(
        {
          reportId: nextProps.reportId,
          query: nextProps.query || {},
          data: undefined
        },
        this.loadReport
      )
    }
  }

  changeTimePeriod(timePeriodId) {
    log('[Reports] [changeTimePeriod]', { timePeriodId })
    w([this.state.reportId, 'timePeriodId'], timePeriodId)
    this.setState({ timePeriodId }, this.loadReport)
  }

  changeApplicationId(applicationId) {
    log('[Reports] [changeApplicationId]', { applicationId })
    this.setState({ applicationId: applicationId !== 'all' ? applicationId : undefined }, this.loadReport)
  }

  async getData(extra = {}) {
    const { timeWiggle } = this.props
    const { reportId, query, customQuery, timePeriodId, applicationId, customStartTime, customEndTime } = this.state
    log('[Reports] [loadReport] 1', { extra, reportId, query, timePeriodId, customStartTime, customEndTime })

    const foundReport = REPORTS.find(r => r.id === reportId)
    const timePeriod = TIME_PERIODS.find(tp => tp.id === timePeriodId)

    let { startTime, timeLength, timeDelta } = timePeriod

    if (timePeriod.id === 'custom') {
      startTime = customStartTime
      timeLength = customEndTime - customStartTime
    }

    const args = { startTime, timeLength, applicationId, timeDelta, ...query, ...customQuery, ...extra }
    if (typeof timeWiggle === 'number' && typeof args.startTime === 'number') {
      args.startTime += timeWiggle
    }
    if (typeof timeWiggle === 'number' && typeof args.timeLength === 'number') {
      args.timeLength += timeWiggle
    }
    log('[Reports] [loadReport] 2', { reportId, timePeriodId, foundReport, timePeriod, args })

    const data = await window.sticky.reports.load(foundReport.id, args)
    return data
  }

  async loadReport({ extra } = {}) {
    const startLoadReport = () => {
      return new Promise(resolve => this.setState({ data: undefined }, resolve))
    }
    await startLoadReport()
    const data = await this.getData(extra)
    this.setState({ data })
    const { onReady } = this.props
    onReady && onReady({
      onRefresh: this.onRefresh
    })
  }

  onRefresh(extra) {
    log('[Reports] [onRefresh]', extra)
    this.loadReport({ extra })
  }

  async setCustomQuery(customQuery) {
    this.setState({ customQuery })
    await this.setCustomDataGetData(customQuery)
  }

  async setCustomDataGetData(customQuery) {
    const data = await this.getData(customQuery)
    this.setState({ data })
  }

  render() {
    const { user, canChooseApplicationId, showControlsBelow, showTimePeriodChooser = true, actions, onAction } = this.props
    const { query, reportId, timePeriodId, data, applications, applicationId, customQuery } = this.state
    let { customStartTime, customEndTime, startOfToday } = this.state
    const timePeriod = TIME_PERIODS.find(tp => tp.id === timePeriodId)
    const foundReport = REPORTS.find(r => r.id === reportId)
    log('[Reports] [render]', { foundReport, data })
    const { RenderComponent } = foundReport

    const ConfigBs = (
      <div className='settings'>
        {showTimePeriodChooser && <Dropdown
          label='Time period'
          selected={timePeriod.id}
          items={TIME_PERIODS}
          onChoose={v => this.changeTimePeriod(v)}
        />}
        {timePeriod.id === 'custom' && (
          <>
            <DatePicker
              time={customStartTime}
              label='From'
              onChange={customStartTime => {
                customStartTime = window.sticky.dateTime.getStartOfToday(customStartTime, user.timezone)
                if (customEndTime < customStartTime) {
                  customEndTime = window.sticky.dateTime.getEndOfToday(customStartTime) - 1
                }
                this.setState({ customStartTime, customEndTime }, this.loadReport)
              }}
              maxTime={startOfToday}
            />
            <DatePicker
              time={customEndTime}
              label='To'
              onChange={customEndTime => {
                customEndTime = window.sticky.dateTime.getEndOfToday(customEndTime) - 1
                this.setState({ customEndTime }, this.loadReport)
              }}
              minTime={customStartTime}
            />
          </>
        )}
        {typeof data === 'object' && canChooseApplicationId && <Dropdown
          label={window.sticky._('APPLICATION')}
          selected={applicationId}
          items={[
            {
              id: 'all',
              name: 'All'
            },
            ...applications.map(a => {
              return {
                id: a.id,
                name: a.name
              }
            })
          ]}
          onChoose={v => this.changeApplicationId(v)}
        />}
      </div>
    )

    return (
      <StyledReports className='component--reports'>
        {typeof data !== 'object' && <Loading />}
        {typeof data === 'object' && (
          <>
            {!showControlsBelow && (
              <>
                {ConfigBs}
                {(showTimePeriodChooser || canChooseApplicationId) && <hr className='at-top' />}
              </>
            )}
            <div className={`report id--${reportId}`}>
              <RenderComponent actions={actions} onAction={onAction} query={query} timePeriod={timePeriod} user={user} data={data} customQuery={customQuery} setCustomQuery={this.setCustomQuery} onRefresh={this.onRefresh} getData={this.getData} />
            </div>
            {showControlsBelow && (
              <>
                {ConfigBs}
              </>
            )}
          </>
        )}
      </StyledReports>
    )
  }
}
Reports.propTypes = {
  user: PropTypes.object,
  reportId: PropTypes.string,
  timePeriodId: PropTypes.string,
  canChooseApplicationId: PropTypes.bool,
  query: PropTypes.object,
  timeWiggle: PropTypes.number,
  showControlsBelow: PropTypes.bool,
  showTimePeriodChooser: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.string),
  onAction: PropTypes.func,
  onReady: PropTypes.func
}
