import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import 'moment/locale/en-gb'

moment.locale('en-gb')

export function Time ({ time, isByDay = false, justTime = false, showTimeAsFixed = false, ...props }) {
  const adjustedTime = time * 1000
  const momentObject = moment(adjustedTime)

  if (showTimeAsFixed) {
    return momentObject.format('DD/MM/YY HH:mm:ss') 
  }

  const timeAsFixed = momentObject.format('Do MMMM YYYY, HH:mm:ss')
  const timeAsFixedShort = momentObject.format('Do MMMM YYYY')
  const timeAsTime = momentObject.format('HH:mm')
  const niceLookingTime = momentObject.calendar()
  const finalTime = justTime ? timeAsTime : (isByDay ? timeAsFixedShort : niceLookingTime)
  return (
    <span className='component--time' style={{ cursor: 'help' }} title={timeAsFixed} {...props}>
      {finalTime}
    </span>
  )
}
Time.propTypes = {
  time: PropTypes.number,
  isByDay: PropTypes.bool,
  justTime: PropTypes.bool,
  showTimeAsFixed: PropTypes.bool
}

export function TimeDifference ({ time, timezone, now, fallbackToJustNow = false, ...props }) {
  const toReturn = window.sticky.dateTime.getTimeSince(time, timezone, ['d', 'h', 'm'], now) || (fallbackToJustNow ? 'Just now' : undefined)
  return (
    <span className='component--time' {...props}>
      {toReturn}
    </span>
  )
}
TimeDifference.propTypes = {
  time: PropTypes.number,
  timezone: PropTypes.number,
  now: PropTypes.number,
  fallbackToJustNow: PropTypes.bool
}
