import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Loading, InlineHtml, Button } from '@openbox-app-shared'
import H2 from '../../../components/H2'

export default function LocationFinder({ formDependencies, onUpdate, prevComponent, nextComponent, controlCanGoNext }) {
  const [isLoading, setIsLoading] = useState(true)
  const [countryData, setCountryData] = useState(null)

  useEffect(
    () => {
      const callbackSuccess = position => {
        const { latitude, longitude } = position.coords
        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
          .then(res => res.json())
          .then(data => {
            const matchingCountry = window.sticky.internals.ISO_COUNTRIES.find(_ => {
              return _[1].iso2 === data.countryCode && _[1].userCreatable
            })
            matchingCountry && setCountryData(matchingCountry)

            controlCanGoNext(true)
            setIsLoading(false)
          })
          .catch(callbackFailure)
          .finally(() => {
            clearTimeout(stHandler)
          })
      }
      const callbackFailure = () => {
        isLoading && (() => {
          controlCanGoNext(true)
          setIsLoading(false)
        })()
      }

      const stHandler = setTimeout(
        () => {
          callbackFailure()
        },
        5 * 1000
      )
      navigator.geolocation.getCurrentPosition(callbackSuccess, callbackFailure)
    },
    []
  )

  if (isLoading) {
    return <div>
      <Loading />
      <H2>Finding your location...</H2>
    </div>
  }

  if (!countryData) {
    nextComponent()
    return null
  }

  return <Form>
    <InlineHtml html={countryData[1].icon} />
    <p>{countryData[1].name}</p>
    <H2>Is this correct?</H2>
    <div className='buttons'>
      <Button
        onClick={() => {
          prevComponent()
        }}
        isSecondary>
        ← Back
      </Button>
      <Button
        onClick={() => {
          nextComponent()
        }}
      >
        No
      </Button>
      <Button
        onClick={() => {
          onUpdate({
            'country': countryData[0],
            'currency': countryData[1].defaultCurrency,
            'timezone': countryData[1].defaultTimezone
          })
          nextComponent(2)
        }}
      >
        Yes →
      </Button>
    </div>
  </Form>
}

LocationFinder.propTypes = {
  formDependencies: PropTypes.object,
  onUpdate: PropTypes.func,
  prevComponent: PropTypes.func,
  nextComponent: PropTypes.func,
  controlCanGoNext: PropTypes.func,
}
