import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link, Bar } from '@openbox-app-shared'

const StyledTabBar = styled.div`
`

function TabBar ({ tabs, selectedTab, onClick }) {
  const getFirstTab = () => tabs.length > 0 && tabs[0].id
  let [whichTab, setWhichTab] = useState(getFirstTab())
  let actuallyWhichTab = selectedTab || whichTab

  const handleOnClick = (e, tabId) => {
    // log('[TabBar] [handleOnClick]', { tabId })
    e.preventDefault()
    setWhichTab(tabId)
    onClick && onClick(tabId)
  }
  const getCurrentTab = (actuallyWhichTab) => tabs.find(tab => tab.id === actuallyWhichTab)
  let currentTab = getCurrentTab(actuallyWhichTab)
  if (!currentTab) {
    actuallyWhichTab = getFirstTab()
    currentTab = getCurrentTab(actuallyWhichTab)
  }
  // log('[TabBar]', { actuallyWhichTab, selectedTab, whichTab, currentTab })

  return (
    <StyledTabBar className='component--tabbar'>
      <Bar>
        {tabs.filter(t => !t.hide).map(tab => {
          const realChildren = <>
            {tab.icon && <img className='icon' src={tab.icon} alt='' />}
            {tab.inlineIcon && <tab.inlineIcon />}
            {tab.name && <strong className='name'>{tab.name}</strong>}
          </>
          const isSelected = actuallyWhichTab === tab.id
          // log('[TabBar] [tab-map]', { actuallyWhichTab, tab, isSelected })
          return (
            <li key={`tab-${tab.id}`} className={`tab-v2-${tab.id}`}>
              {tab.to && (
                <Link
                  to={tab.to}
                  className={isSelected ? 'active' : undefined}
                >{realChildren}</Link>
              )}
              {!tab.to && (
                <div
                  onClick={!tab.to ? (e) => handleOnClick(e, tab.id) : undefined}
                  className={isSelected ? 'active' : undefined}
                >
                  {realChildren}
                </div>
              )}
            </li>
          )
        })}
      </Bar>
      <div className='tab-container'>
        {currentTab && currentTab.child}
      </div>
    </StyledTabBar>
  )
}

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.node,
      icon: PropTypes.string,
      child: PropTypes.object
    })
  ),
  selectedTab: PropTypes.string,
  onClick: PropTypes.func
}

export default TabBar
