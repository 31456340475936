/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

import { Modal, Price, Button } from '@openbox-app-shared'
import { dispatch, subscribe } from '../../../../redux'

const StyledModal = styled.div`
  .component--modal {
    ${props => css`max-width: ${2 + (props.howManyButtons * 7.5) + ((props.howManyButtons - 1) * 1)}rem;`}
    padding-left: 1rem;
    padding-bottom: 0;
    padding-right: 0;
    p {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 120%;
      line-height: 1.5rem;
    }
    .buttons {
      text-align: center;
      > * {
        width: 7.5rem;
        height: 7.5rem;
        margin: 0 1rem 1rem 0 !important;
      }
    }
  }
`

export default function CardCash ({ user, total, currency, onGood, onBad }) {
  useEffect(
    () => {
      // log('[CardCash] 0 useEffect', { total, currency })
      const subscriptions = [
        subscribe(
          'GET_PRICE_GOOD',
          ({ why, price }) => {
            // log('[CardCash] 1 GET_PRICE_GOOD; what is why?', { user })
            why.startsWith('CHOOSE_CARD_CASH--') && (() => {
              const delta = price - total
              if (delta === 0) {
                why = why.substring('CHOOSE_CARD_CASH--'.length)
                onGood(why)
                return
              }
              // log('[CardCash] 2 why===CHOOSE_CARD_CASH', { user })
              dispatch(
                'SHOW_MESSAGE',
                {
                  message: <p className='openbox--pulsing-2'>Now give <strong><Price price={delta} currency={currency} /></strong></p>,
                  canBeBadded: '← Go back',
                  why,
                  css: `
                    p {
                      font-size: 120%;
                      line-height: 1.5rem;
                    }
                  `
                }
              )
            })()
          }
        ),
        subscribe(
          'SHOW_MESSAGE_GOOD',
          ({ good, why }) => {
            // log('[CardCash] 3 sub SHOW_MESSAGE_GOOD', { good, why })
            good && why.startsWith('CHOOSE_CARD_CASH--') && (() => {
              why = why.substring('CHOOSE_CARD_CASH--'.length)
              // log('[CardCash] 4 why===CHOOSE_CARD_CASH')
              onGood(why)
            })()
            good && why.startsWith('gwDoConfirm') && (() => {
              why = why.substring('gwDoConfirm--'.length)
              onGood(why)
            })()
          }
        )
      ]
      return () => {
        // log('[CardCash] 5 freeing subs')
        subscriptions.forEach(s => s())
      }
    }
  )

  const gws = window.sticky.Stickypay.GATEWAYS.filter(gw => gw.isEposable && gw.isEposable(user))
  return (
    <StyledModal howManyButtons={gws.length}>
      <Modal onClose={onBad}>
        <p className='openbox--pulsing-2'>
          Take <strong><Price price={total} currency={currency} /></strong>
        </p>
        <p>
          How do they want to pay?
        </p>
        <div className='buttons'>
          {gws.map(gw => {
            return (
              <Button
                key={gw.id}
                icon={gw.icon}
                isSecondary
                bigIcon
                onClick={() => {
                  if (gw.doConfirm) {
                    dispatch('SHOW_MESSAGE', { why: `gwDoConfirm--${gw.id}`, message: <><p><strong>The total is <Price price={total} currency={currency} /></strong></p><p>Was the payment successful?</p></>, canBeGooded: 'Yes', canBeBadded: '← Go back' })
                    return
                  }
                  if (gw.isTenderable) {
                    dispatch('GET_PRICE', { why: `CHOOSE_CARD_CASH--${gw.id}`, hint: <><strong>The total is <Price price={total} currency={currency} /></strong><strong>How much have you taken?</strong></>, price: 0 })
                    return
                  }
                  onGood(gw.id)
                }}
              >
                {gw.name}
              </Button>
            )
          })}
        </div>
      </Modal>
    </StyledModal>
  )
}
