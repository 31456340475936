import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, Button } from '@openbox-app-shared'

export default function ApplicationChooser ({ applicableEntities, selected = [], limit = 1, allowNone, onChange }) {
  return (
    <List limitHeight={284}>
      {[
        allowNone && {
          id: '',
          key: '',
          name: allowNone
        },
        ...applicableEntities
      ]
        .filter(e => e)
        .map(li => {
          return (
            <ListItem
              id={li.id}
              key={li.id}
              icon={li.baseIcon}
              onChoose={id => {
                if (selected.includes(id)) {
                  selected = selected.filter(_ => _ !== id)
                } else {
                  selected.unshift(id)
                }
                if (typeof limit === 'number') {
                  selected = selected.slice(0, limit)
                }
                onChange(selected)
              }}
              indentationOuter={li.indentation * 32}
              selected={selected.includes(li.id)}
            >
              {li.name}
            </ListItem>
          )
        })
      }
    </List>
  )
}
ApplicationChooser.propTypes = {
  applicableEntities: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  allowNone: PropTypes.string,
  onChange: PropTypes.func
}
