import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import { Label } from './Input'
import Button from './Button'

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const StyledDayPicker = styled.div`
  .buttons {
    margin-top: 0.5rem;
    .component--button {
      display: inline-block;
      margin: 0 0.5rem 0.5rem 0;
      width: fit-content;
      font-size: 90%;
    }
  }
`

export default function DayPicker ({ days, label, turnedOnColor = '#322CBE', className, onChange }) {
  console.log('[DayPicker]', { days })
  return (
    <StyledDayPicker className={classnames('component--day-picker', className)}>
      {label && <Label>{label}</Label>}
      <div className='buttons'>
        {DAYS.map((day, dayIndex) => {
          return (
            <Button
              isSecondary
              key={`day--${dayIndex}`}
              turnedOn={days.includes(dayIndex) && turnedOnColor}
              onClick={() => {
                if (days.includes(dayIndex)) {
                  days = days.filter(d => d !== dayIndex)
                } else {
                  days.push(dayIndex)
                }
                onChange && onChange(days)
              }}
            >
              {day}
            </Button>
          )
        })}
      </div>
    </StyledDayPicker>
  )
}
DayPicker.propTypes = {
  days: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.string,
  turnedOnColor: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
}
